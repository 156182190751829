<template>
  <div
    class="_stream-health"
    v-loading="pageLoading"
    element-loading-background="rgba(0, 0, 0, 0.1)"
  >
    <div class="_stream-health__head">
      <y-button size="small" plain @click="refresh">Refresh</y-button>
      <y-button size="small" plain @click="exportReport">
        Export Report
      </y-button>
    </div>
    <div class="_stream-health__main" ref="healthRef">
      <div class="_stream-health__title">Viewer Engagement</div>
      <div class="_stream-health__avg-data">
        <div class="_stream-health__avg-item">
          <h4>Avg Bitrate</h4>
          <em>{{ avgBps }}</em>
        </div>
        <div class="_stream-health__avg-item">
          <h4>Avg Frame Rate</h4>
          <em>{{ avgFps }}</em>
        </div>
      </div>
      <div class="_stream-health__block">
        <!-- Alert History -->
        <div
          class="_stream-health__alert"
          :class="{ '_stream-health__alert-empty': abnormalList.length === 0 }"
        >
          <div class="_stream-health__alert-title">Alert History</div>
          <div class="_stream-health__alert-head">
            <ul>
              <li>Time</li>
              <li>Waring</li>
            </ul>
          </div>
          <div class="_stream-health__alert-content">
            <ol>
              <li
                class="_stream-health__alert-empty-item"
                v-if="abnormalList.length === 0"
              >
                No data yet
              </li>
              <li
                v-for="(item, index) in abnormalList"
                :key="index"
                :class="[
                  '_stream-health__alert-item',
                  item.streamStatus === 2
                    ? '_stream-health__alert-item--poor'
                    : item.streamStatus === 3
                    ? '_stream-health__alert-item--interruption'
                    : ''
                ]"
              >
                <span class="_stream-health__alert-time">
                  {{ item.currentTimeMills | formatTime }}
                </span>
                <span class="_stream-health__alert-warning">
                  <b class="_health-area__info-logo-warn"></b>
                  <span v-show="item.streamStatus === 2">
                    Poor network status
                  </span>
                  <span v-show="item.streamStatus === 3">
                    Network interruption
                  </span>
                </span>
              </li>
            </ol>
          </div>
        </div>
        <!-- /Alert History -->

        <!-- Stream Metrics -->
        <div class="_stream-health__metrics" ref="metricsRef"></div>
        <!-- /Stream Metrics -->
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import html2canvas from 'html2canvas'
import * as echarts from 'echarts'
import { saveFile } from '@/utils/tools'

var SPLIT_NUMBER = 5 //分割线的数量

//获取刻度最大值max和刻度间隔interval
//arr是业务数据的数组
function getMaxAndInterval(arr) {
  var max = 1.0 //默认值
  var interval = 0.2 //默认值
  if (arr && arr.length > 0) {
    var maxValue =
      arr.reduce(function (v1, v2) {
        return v1 > v2 ? v1 : v2
      }) - 0 //获取最大值
    if (maxValue > 0) {
      maxValue = Math.ceil(maxValue) //向上取整
      interval = Math.ceil(maxValue / SPLIT_NUMBER) //向上取整
      max = SPLIT_NUMBER * interval //重设最大值刻度值
    }
  }
  return [max, interval]
}

export default {
  props: {
    consoleInfo: Object
  },
  data() {
    return {
      activityId: this.$route.params.id,
      avgBps: '--',
      avgFps: '--',
      abnormalList: [],
      metricsOption: {
        tooltip: {
          trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        title: {
          text: 'Stream Metrics',
          textStyle: {
            color: '#fff',
            fontSize: 13
          },
          top: 10,
          left: 20
        },
        legend: {
          data: ['Bitrate', 'Frame Rate'],
          icon: 'circle',
          itemHeight: 8, // 修改icon图形大小
          top: 10,
          right: 20,
          textStyle: {
            fontSize: 13,
            color: '#fff'
          }
        },
        grid: {
          top: 84,
          left: 60,
          bottom: 50,
          right: 40
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
          axisLine: {
            lineStyle: {
              color: 'rgba(255,255,255,0.15)'
            }
          },
          axisLabel: {
            color: 'rgba(255, 255, 255, 0.4)'
          }
        },
        yAxis: [
          {
            name: 'Bitrate',
            type: 'value',
            nameTextStyle: {
              color: '#fff'
            },
            splitLine: {
              lineStyle: {
                type: 'dashed', //设置网格线类型 dashed：虚线   solid:实线
                width: 1,
                color: 'rgba(255,255,255,0.25)'
              }
            },
            axisLabel: {
              color: 'rgba(255, 255, 255, 0.4)'
            },
            max: 10000,
            min: 0
          },
          {
            name: 'Frame Rate',
            type: 'value',
            nameTextStyle: {
              color: '#fff'
            },
            splitLine: {
              lineStyle: {
                type: 'dashed', //设置网格线类型 dashed：虚线   solid:实线
                width: 1,
                color: 'rgba(255,255,255,0.25)'
              }
            },
            axisLabel: {
              color: 'rgba(255, 255, 255, 0.4)'
            },
            max: 80,
            min: 0,
            interval: 16
          }
        ],
        series: [
          {
            name: 'Bitrate',
            data: [],
            type: 'line',
            yAxisIndex: 0
          },
          {
            name: 'Frame Rate',
            data: [],
            type: 'line',
            yAxisIndex: 1
          }
        ]
      },
      pageLoading: false
    }
  },
  filters: {
    formatTime(timestamp) {
      return moment(timestamp).format('hh:mm A')
    }
  },
  beforeDestroy() {
    this.metricsChartInstance.dispose()
  },
  mounted() {
    this.renderMetricsChat()
    // activityStatus: 直播间状态0:未开始,1:已删除,2:结束,3:预约,4:直播中,5:暂停
    if (!this.consoleInfo || this.consoleInfo.activityStatus === 2) {
      this.fetchAvgFps()
      this.fetchStreamAlert()
      this.fetchBpsFpsMetrics()
    }
  },
  methods: {
    renderMetricsChat() {
      this.metricsChartInstance = echarts.init(this.$refs.metricsRef)
      this.metricsChartInstance.setOption(this.metricsOption)
    },
    async fetchAvgFps() {
      this.pageLoading = true
      const res = await this.$serve.statistics.avgFps({
        queryId: this.activityId
      })
      this.pageLoading = false
      if (res === this.$serve.FAIL) {
        return
      }
      this.avgBps = Math.floor(res.avgBps / 1000) + ' kbps'
      this.avgFps = Math.floor(res.avgFps) + ' fps'
    },
    async fetchStreamAlert() {
      this.pageLoading = true
      const res = await this.$serve.statistics.streamAlert({
        queryId: this.activityId
      })
      this.pageLoading = false
      if (res === this.$serve.FAIL) {
        return
      }
      this.abnormalList = res
    },
    async fetchBpsFpsMetrics() {
      this.pageLoading = true
      const res = await this.$serve.statistics.bpsFpsMetrics({
        queryId: this.activityId
      })
      this.pageLoading = false
      if (res === this.$serve.FAIL) {
        return
      }
      if (res.length) {
        const bpsArr = res.map((item) => Math.floor(item.bps / 1024))
        const fpsArr = res.map((item) => item.fps)
        const maxIntervalBitrate = getMaxAndInterval(bpsArr)
        const maxIntervalFrame = getMaxAndInterval(fpsArr)
        this.metricsChartInstance.setOption({
          xAxis: {
            data: res
              .map((item) => item.currentTimeMills)
              .map((item) => moment(item).format('MMM D, hh:mm A'))
          },
          yAxis: [
            {
              max: maxIntervalBitrate[0], //指定max
              interval: maxIntervalBitrate[1] //指定interval
            },
            {
              max: maxIntervalFrame[0], //指定max
              interval: maxIntervalFrame[1] //指定interval
            }
          ],
          series: [
            {
              data: bpsArr
            },
            {
              data: fpsArr
            }
          ]
        })
      }
    },
    refresh() {
      this.fetchAvgFps()
      this.fetchStreamAlert()
      this.fetchBpsFpsMetrics()
    },
    exportReport() {
      html2canvas(this.$refs.healthRef, {
        useCORS: true // 开启跨域设置，需要后台设置cors
      }).then((canvas) => {
        // toDataURL函数生成img标签的可用数据  图片格式转成 base64
        let dataURL = canvas.toDataURL('image/png')
        saveFile(dataURL, 'health.png')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
._stream-health {
  color: #fff;
}
._stream-health__head {
  height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: rgba(41, 43, 60, 0.4);
  padding: 0 20px;
  margin: 0px 20px 0;
  .el-button {
    background: transparent;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.15);
    &:hover,
    &:focus {
      background: transparent;
      color: #ff4d7c;
      border-color: #ff4d7c !important;
    }
  }
}
._stream-health__main {
  padding: 24px 20px;
  background: #1e1f2b;
}
._stream-health__title {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px 10px;
  position: relative;
  font-size: 18px;
  margin-bottom: 16px;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -10px;
    width: 4px;
    height: 20px;
    background: #ff0043;
    border-radius: 2px;
  }
}
._stream-health__avg-data {
  background: rgba(41, 43, 60, 0.4);
  border-radius: 4px;
  display: flex;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1px;
    height: 64px;
    border: 1px solid rgba(255, 255, 255, 0.15);
  }
}
._stream-health__avg-item {
  flex: 1;
  text-align: center;
  padding: 24px 0;
  h4 {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    margin: 0 0 18px;
  }
  em {
    font-style: normal;
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
  }
}
._stream-health__block {
  display: flex;
  margin-top: 20px;
}
._stream-health__alert {
  width: 400px;
  height: 352px;
  margin-right: 16px;
  background: rgba(41, 43, 60, 0.4);
  border-radius: 4px;
  padding: 12px;
  display: flex;
  flex-direction: column;
}
._stream-health__metrics {
  flex: 1;
  height: 352px;
  background: rgba(41, 43, 60, 0.4);
  border-radius: 4px;
}
._stream-health__alert-title {
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 12px;
  padding: 0 12px;
}
._stream-health__alert-head {
  ul {
    display: flex;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.06);
    height: 32px;
    line-height: 32px;
    background: #292b3c;
    color: rgba(255, 255, 255, 0.4);
    padding: 0 12px;
    li {
      flex: 1;
    }
  }
}
._stream-health__alert-content {
  position: relative;
  flex: 1;
  ol {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;
      background: transparent;
    }
    &::-webkit-scrollbar-track {
      border-radius: 0;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(102, 102, 102, 1);
      transition: all 0.2s;
      border-radius: 2px;
    }
    li {
      display: flex;
      height: 32px;
      line-height: 32px;
      box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.06);
      padding: 0 12px;
      span {
        flex: 1;
        font-size: 12px;
        font-family: HelveticaNeue;
        color: rgba(255, 255, 255, 0.4);
      }
    }
  }
}
._stream-health__alert-empty-item {
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.4);
  height: 94px;
  display: flex;
  align-items: center;
  justify-content: center;
}
._stream-health__alert-item--poor {
  ._stream-health__alert-warning {
    display: flex;
    align-items: center;
    b {
      width: 12px;
      height: 12px;
      margin-right: 8px;
      background: url(~@assets/images/history-warn.png) no-repeat;
      background-size: 100%;
    }
    span {
      color: #ffac45;
    }
  }
}
._stream-health__alert-item--interruption {
  ._stream-health__alert-warning {
    display: flex;
    align-items: center;
    b {
      width: 12px;
      height: 12px;
      margin-right: 8px;
      background: url(~@assets/images/history-error.png) no-repeat;
      background-size: 100%;
    }
    span {
      color: #f62213;
    }
  }
}
._stream-health__alert-empty {
  // height: 186px;
  background-color: rgba(41, 43, 60, 0.5);
  padding: 12px;
  flex: none;
  border-radius: 4px;
  ._stream-health__alert-title {
    padding: 0;
  }
  ul {
    box-shadow: none;
  }
  ._stream-health__alert-content {
    ol {
      border: 1px solid rgba(255, 255, 255, 0.06);
      border-top: 0;
      ._stream-health__alert-empty-item {
        font-size: 14px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.4);
        height: 240px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
