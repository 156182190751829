export const firstLetterToUpper = (string) => {
  return string.replace(/\b(\w)(\w*)/, function($0, $1, $2) {
    return `${$1.toUpperCase()}${$2}`
  })
}

export const getUndefinedProps = (obj = {}) => {
  return Object.entries(obj).reduce((pre, [k, v]) => {
    if (v?.type) {
      return {
        ...pre,
        [k]: {
          type: v.type,
          default: undefined
        }
      }
    }
    return {
      ...pre,
      [k]: {
        type: v,
        default: undefined
      }
    }
  }, {})
}
