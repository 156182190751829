<template>
  <div class="video-player">
    <div class="play-box">
      <img class="bg" v-if="data.coverUrl" :src="data.coverUrl" alt="" />
      <img
        class="play"
        src="~@assets/images/designer/play.png"
        alt=""
      />
    </div>
    <div
      class="countdown-box"
      :class="{
        top: data.countdownPosition === 'top',
        bottom: data.countdownPosition === 'bottom',
        fullscreen: data.countdownPosition === 'fullscreen'
      }"
      v-if="data.countdown"
    >
      <div class="msg">
        <div class="value">01</div>
        <div class="key">dy</div>
        <div class="value">05</div>
        <div class="key">h</div>
        <div class="value">58</div>
        <div class="key">min</div>
        <div class="value">11</div>
        <div class="key">sec</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      Type: Object,
      default: {}
    }
  }
}
</script>
<style lang="scss" scoped>
.video-player {
  position: relative;
  .play-box {
    width: 100%;
    height: 211px;
    position: relative;
    background-color: #ffccd9;
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      background-color: #ffffff;
    }
    .play {
      top: 0;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 60px;
      height: 60px;
      margin: auto;
      z-index: 100;
    }
  }
  .countdown-box {
    background-color: rgba(0, 0, 0, 0.8);
    .msg {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      .value {
        font-size: 20px;
        font-family: HelveticaNeue-Medium, HelveticaNeue;
        font-weight: 500;
        color: #ffffff;
      }
      .key {
        font-size: 14px;
        font-family: HelveticaNeue;
        color: #ffffff;
        margin: 0 6px;
      }
    }
    &.top {
      width: 100%;
      height: 40px;
      position: absolute;
      top: 0;
      z-index: 100;
    }
    &.bottom {
      width: 100%;
      height: 40px;
      position: absolute;
      bottom: 0;
      z-index: 100;
    }
    &.fullscreen {
      width: 100%;
      height: 211px;
      position: absolute;
      top: 0;
      z-index: 100;
      .msg {
        height: 211px;
      }
    }
  }
}
</style>
