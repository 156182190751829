<template>
  <div class="document-main">
    <div class="fix-box">
      <div class="fix-select">
        <div class="key">Document Title</div>
        <div class="value">
          <el-switch v-model="data.titleSwitch"></el-switch>
        </div>
      </div>
    </div>
    <div class="name-box">
      <el-input
        v-model="data.fileTitle"
        placeholder="Add a document title"
        maxlength="30"
        @input="checkWordTitle"
      ></el-input>
    </div>
    <div class="cover-img-box">
      <div class="loading-mask" v-if="status === 1 || status === 2"></div>
      <upload
        materialType="oss_material_images"
        @uploadComplete="uploadComplete"
        @onProcess="uploadProgress"
        @beforeUpload="beforeUpload"
        suffix="file"
        :filters="{
          mime_types: [
            {
              title: 'pdf',
              extensions: 'pdf'
            }
          ],
          max_file_size: '20mb'
        }"
      >
        <div class="upload-box" v-if="status === 0">
          <div class="icon"></div>
          <div class="icon-text">Upload document</div>
        </div>
        <div class="uploading-box" v-if="status === 1">
          <div class="msg">
            <div class="file-title">{{ file.name }}</div>
            <div class="text">Uploading{{ watingString }}</div>
          </div>
        </div>
        <div class="transcoding-box" v-if="status === 2">
          <div class="text">Document transcoding{{ watingString }}</div>
        </div>
        <div class="has-img" v-if="status === 3">
          <img :src="images[currentPage - 1]" alt="" />
          <div class="change-img">Change document</div>
        </div>
        <div class="fail-box" v-if="status === 4">
          <div class="text">
            Document transcoding failed, please try again！
          </div>
        </div>
      </upload>
    </div>
    <div class="tips" style="margin-top: 8px">
      Recommend PDF and the file size does not exceed 20MB.
    </div>
  </div>
</template>

<script>
import upload from '@components/upload'

export default {
  props: {
    data: {
      Type: Object,
      default: {}
    }
  },
  components: {
    upload
  },
  data() {
    return {
      status: 0, // 0：待上传 1：上传中 2：转码中 3：上传成功 4：上传失败
      file: {},
      currentPage: 1,
      pageTotal: 1,
      images: [],
      watingString: '.',
      watingInterval: null,
      timer: null,
      first: true
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.getFile()
    }
  },
  methods: {
    uploadComplete(file) {
      this.transcod(file)
    },
    beforeUpload() {
      clearInterval(this.watingInterval)
      this.watingInterval = null
      this.watingInterval = setInterval(() => {
        if (this.watingString === '...') {
          this.watingString = '.'
        } else {
          this.watingString += '.'
        }
      }, 700)
    },
    uploadProgress(file) {
      this.file = file
      this.status = 1
    },
    async transcod(file) {
      this.status = 2
      const res = await this.$serve.transfer.saveTransferFIle({
        data: {
          fileUrl: file.url,
          activityId: this.$route.params.id
        }
      })
      if (res === this.$serve.FAIL) {
        clearInterval(this.watingInterval)
        this.watingInterval = null
        return
      }
      this.getFile()
    },
    async getFile() {
      if (this.first) {
        this.first = false
        this.watingInterval = setInterval(() => {
          if (this.watingString === '...') {
            this.watingString = '.'
          } else {
            this.watingString += '.'
          }
        }, 700)
      }
      const res = await this.$serve.transfer.getFile({
        queryId: this.$route.params.id
      })
      if (res === this.$serve.FAIL) {
        clearInterval(this.watingInterval)
        this.watingInterval = null
        return
      }
      // 转码失败
      if (res.transferCodeStatus === 0) {
        clearInterval(this.watingInterval)
        this.watingInterval = null
        this.status = 4
      }
      // 转码成功
      if (res.transferCodeStatus === 1) {
        clearInterval(this.watingInterval)
        this.watingInterval = null
        this.status = 3
        this.images = JSON.parse(res.imageUrl)
        this.data.images = JSON.parse(res.imageUrl)
        this.currentPage = 1
        this.pageTotal = this.images.length
      }
      // 转码中
      if (res.transferCodeStatus === 2) {
        this.status = 2
        this.timer = setTimeout(() => {
          clearTimeout(this.timer)
          this.timer = null
          this.getFile()
        }, 2000)
      }
      // 未上传文档
      if (res.transferCodeStatus === 3) {
        clearInterval(this.watingInterval)
        this.watingInterval = null
        this.status = 0
      }
    },
    checkWordTitle() {
      const reg = /^[^\s].*$/g
      if (!reg.test(this.data.fileTitle)) {
        this.data.fileTitle = this.data.fileTitle.slice(1)
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.watingInterval);
    this.watingInterval = null;
    clearTimeout(this.timer);
    this.timer = null;
    if (!this.data.fileTitle.trim()) {
      this.data.fileTitle = ''
      this.data.titleSwitch = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.document-main {
  padding: 16px;

  .tips {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: $-color-black-40;
    line-height: 18px;
  }

  .fix-box {
    margin-bottom: 24px;

    .fix-select {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .key {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: $-color-black-80;
      }

      .value {
        margin-left: auto;
      }
    }

    .text {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: $-color-black-40;
    }
  }

  .name-box {
    margin-bottom: 16px;

    ::v-deep input {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: $-color-black-80;
    }
  }

  .cover-img-box {
    position: relative;

    .upload-box {
      width: 100%;
      height: 193px;
      background: rgba(247, 248, 250, 1);
      border: 1px dashed rgba(0, 0, 0, 0.15);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .icon {
        width: 8px;
        height: 8px;
        background: url(~@assets/images/designer/upload@2x.png)
          no-repeat;
        background-size: 100%;
      }

      .icon-text {
        font-size: 14px;
        font-family: HelveticaNeue;
        color: $-color-black-40;
        margin-left: 6px;
      }
    }

    .has-img {
      width: 100%;
      height: 193px;
      position: relative;

      &:hover {
        .change-img {
          display: block;
        }
      }

      img {
        width: 100%;
        height: 100%;
      }

      .change-img {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        line-height: 193px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        background-color: rgba(0, 0, 0, 0.4);
        font-family: HelveticaNeue;
        cursor: pointer;
      }
    }

    .uploading-box {
      width: 100%;
      height: 193px;
      background: #f7f8fa;
      border: 1px dashed rgba(0, 0, 0, 0.15);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 24px;

      .msg {
        .file-title {
          font-size: 16px;
          font-family: HelveticaNeue;
          color: #ff0043;
          margin-bottom: 4px;
          text-align: center;
        }

        .text {
          font-size: 12px;
          font-family: HelveticaNeue;
          color: rgba(0, 0, 0, 0.4);
          text-align: center;
        }
      }
    }

    .transcoding-box {
      width: 100%;
      height: 193px;
      background: #f7f8fa;
      border: 1px dashed rgba(0, 0, 0, 0.15);
      display: flex;
      align-items: center;
      justify-content: center;

      .text {
        font-size: 14px;
        font-family: HelveticaNeue;
        color: rgba(0, 0, 0, 0.8);
        text-align: center;
      }
    }

    .fail-box {
      width: 100%;
      height: 193px;
      background: #f7f8fa;
      border: 1px dashed rgba(0, 0, 0, 0.15);
      display: flex;
      align-items: center;
      justify-content: center;

      .text {
        font-size: 14px;
        font-family: HelveticaNeue;
        color: rgba(0, 0, 0, 0.8);
        text-align: center;
      }
    }

    .loading-mask {
      position: absolute;
      top: -10px;
      bottom: -10px;
      left: -10px;
      right: -10px;
      z-index: 1000;
    }
  }
}
</style>
