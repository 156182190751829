<template>
  <div class="live-info-edit">
    <div class="name-box">
      <div class="text">直播标题</div>
      <el-input v-model="data.title" placeholder="请输入直播名称" maxlength="32" show-word-limit></el-input>
    </div>
    <div class="name-box">
      <div class="text">直播介绍</div>
      <el-input type="textarea" rows="4" v-model="data.text" placeholder="请输入说明文字，最多100字" maxlength="100" show-word-limit></el-input>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    data: {
      Type: Object,
      default: () => {
        return {}
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./edit.scss";
</style>
