import { fieldPropsKeys } from '../config/field-props'
import { formItemPropsKeys } from '../config/form-item-props'

/**
 * 外部传入属性fields处理
 * 即formItemProps 或者 attributes 中的属性不需要强制放置在 即formItemProps 和 attributes 内部
 * formItemProps 或者 attributes 中的属性也可以直接放置在field对象上面
 * 如 { type: 'input', formItemProps: { label: '文本域' }, attributes: { placeholder: '请输入' } } 也可简写成 { type: 'input', label: '文本域', placeholder: '请输入' }
 * 注意:
 * 简写形式当 filed 属性和 formItemProps 或者 attributes 产生冲突的时候, 以 filed 属性为准
 * 当 formItemProps 或者 attributes 产生冲突. 以 formItemProps 为准
 */
const formatFields = (fields) => {
  const newFields = fields.map((field) => {
    const fieldEntries = Object.entries(field)

    if (fieldEntries.length === 0) {
      // 统一filed配置数据 没有配置属性的话 统一为空 也可以解决 Cannot read property 'xxx' of undefined 的报错
      return {
        formItemProps: {},
        attributes: {},
        events: {}
      }
    }

    const newField = fieldEntries.reduce((pre, [k, v]) => {
      // formItemProps 和 attributes 默认为空对象 以避免后续可能需要用到该对象的属性而产生  Cannot read property 'xxx' of undefined 的报错
      const filed = {
        ...pre,
        formItemProps: pre.formItemProps || {},
        attributes: pre.attributes || {},
        events: pre.events || {}
      }

      // fieldProps中的属性传入什么就是什么
      if (fieldPropsKeys.includes(k)) {
        if (['formItemProps', 'attributes', 'events'].includes(k)) {
          // 出现混写(即有formItemProps内部属性传入, 又有formItemProps字段传入)的时候 以实际formItemProps或attributes或events传值为主
          return {
            ...filed,
            [k]: {
              ...filed[k],
              ...v
            }
          }
        }
        return {
          ...filed,
          [k]: v
        }
      }
      // 简写形式传formItemProps的值时 最终还是处理成formItemProps的属性传入内部
      if (formItemPropsKeys.includes(k)) {
        return {
          ...filed,
          formItemProps: {
            [k]: v,
            ...filed.formItemProps
          }
        }
      }

      // 简写形式传其他值时 全部作为attributes的属性 函数类型的全部作为events的属性
      return {
        ...filed,
        attributes: {
          [k]: v,
          ...filed.attributes
        },
        events: {
          ...(typeof v === 'function' ? { [k]: v } : null),
          ...filed.events
        }
      }
    }, {})

    return newField
  })

  return newFields
  // todo 英文可能是自定义渲染 filed 定义了render属性, 但是不用定义prop
  // 这里要过滤prop没有传入的属性
  // newFields.filter((field) => !!field.formItemProps.prop)
}

export default formatFields
