const apis = [
  {
    // 获取房间token
    fn: 'getInviteGuestsToken',
    method: 'POST',
    url: '/overseas/invite-guests/getToken',
    hideloading: true
  },
  {
    // 开启连麦
    fn: 'openInteraction',
    method: 'POST',
    url: '/overseas/invite-guests/openInteraction'
  },
  {
    // 关闭连麦
    fn: 'closeInteraction',
    method: 'POST',
    url: '/overseas/invite-guests/closeInteraction'
  },
  {
    // 禁言
    fn: 'muteAudio',
    method: 'POST',
    url: '/overseas/invite-guests/muteAudio'
  }
]

export const baseUrl = process.env.VUE_APP_OVERSEAS_URL

export default apis
