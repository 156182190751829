var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner-edit"},[_c('div',{staticClass:"cover-box"},[_c('div',{staticClass:"text"},[_vm._v("Style")]),_c('el-radio-group',{model:{value:(_vm.data.coverType),callback:function ($$v) {_vm.$set(_vm.data, "coverType", $$v)},expression:"data.coverType"}},[_c('el-radio',{attrs:{"label":"default"}},[_vm._v("Default")]),_c('el-radio',{attrs:{"label":"image"}},[_vm._v("Custom")])],1),(_vm.data.coverType === 'image')?_c('div',{staticClass:"add-bg-box"},[_c('upload',{attrs:{"materialType":"oss_material_images","suffix":"image","filters":{
          mime_types: [
            {
              title: 'jpg,png',
              extensions: 'jpg,png',
            } ],
          max_file_size: '2mb',
        }},on:{"uploadComplete":_vm.uploadComplete}},[(_vm.data.coverUrl)?_c('div',{staticClass:"has-img"},[_c('img',{attrs:{"src":_vm.data.coverUrl,"alt":""}}),_c('div',{staticClass:"change-img"},[_vm._v("Change image")])]):_c('div',{staticClass:"no-img"},[_c('div',{staticClass:"upload-box"},[_c('div',{staticClass:"icon"}),_c('div',{staticClass:"icon-text"},[_vm._v("Upload image")])])])])],1):_vm._e(),(_vm.data.coverType === 'image')?_c('div',{staticClass:"tips"},[_c('p',[_vm._v(" Recommended pixels: 750*98px, supports JPG, PNG and the size does not exceed 2MB; ")])]):_vm._e()],1),(_vm.enabledShow)?_c('img-crop',{attrs:{"cover":_vm.reCoverUrl,"visible":_vm.enabled,"w":_vm.w,"h":_vm.h,"limt":_vm.limt},on:{"crop-upload-success":_vm.cutUploadSuccess,"cancel":_vm.cutCancel}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }