<template>
  <el-dropdown-menu class="y-dropdown-menu" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </el-dropdown-menu>
</template>

<script>
import { DropdownMenu } from 'element-ui'

export default {
  name: 'y-dropdown-menu',
  _name: 'Dropdown 下拉菜单组',
  $depends: [DropdownMenu],
  components: {
    [DropdownMenu.name]: DropdownMenu
  }
}
</script>
