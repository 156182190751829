<template>
  <div class="template-preview-com">
    <div class="bigview">
      <div class="view-mask" @click="hidePreview"></div>
      <div class="preview-content">
        <div class="tab-title">
          Mobile
          <div class="border-line"></div>
        </div>
        <div class="preview-wrapper wrp-mobile" v-if="activeTabs === 'mobile'">
          <iframe
            :src="previewUrl + '?platform=moblie'"
            class="preview-h5"
          ></iframe>
          <y-button
            size="small"
            type="primary"
            class="btn-apply"
            :loading="loading"
            @click="releaseTemplate"
            :disabled="canDecorate"
          >Apply</y-button>
        </div>
        <div class="preview-wrapper wrp-web" v-if="activeTabs === 'Web'">
          <iframe :src="previewUrl" class="preview-web"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentTemplate: {
      type: Object,
      default: () => {
        return {}
      }
    },
    canDecorate: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      activeTabs: 'mobile',
      loading: false,
    }
  },
  computed: {
    previewUrl() {
      return process.env.VUE_APP_H5 + '/template/' + this.currentTemplate.id
    }
  },
  methods: {
    hidePreview() {
      this.$emit('closePreview')
    },
    async releaseTemplate() {
      this.loading = true;
      const res = await this.$serve.activity.applyDesigner({
        data: {
          type: 1,
          data: JSON.stringify(this.currentTemplate.data),
          activityId: this.$route.params.id
        }
      })
      this.loading = false;
      if (res === this.$serve.FAIL) {
        return false
      }
      this.$message({
        message: "Apply Success！",
        type: "success",
        duration: 1000,
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.template-preview-com {
  .bigview {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.25);
    z-index: 100001;
    display: flex;
    align-items: center;
    justify-content: center;
    .preview-content {
      width: 1280px;
      height: 820px;
      margin: 0 auto;
      background-color: #ffffff;
      position: relative;
      z-index: 100;
      padding: 10px 16px 16px 16px;
      .preview-wrapper {
        border-radius: 4px;
        position: relative;
        &.wrp-mobile {
          width: 375px;
          height: 732px;
          margin: 0 auto;
          background-color: rgba(0, 0, 0, 0.06);
          .preview-h5 {
            width: 375px;
            height: 732px;
            overflow: hidden;
            border: 1px solid rgba(0, 0, 0, 0.06);
          }
        }
        &.wrp-web {
          width: 1216px;
          height: 684px;
          margin: 0 auto;
          background-color: rgba(0, 0, 0, 0.06);
          .preview-web {
            width: 1216px;
            height: 684px;
            overflow: hidden;
            border: 1px solid rgba(0, 0, 0, 0.06);
          }
        }
        .btn-apply {
          position: absolute;
          right: -104px;
          top: 40px;
          width: 88px;
          border-radius: 4px;
          font-size: 14px;
        }
      }
    }
    .view-mask {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 100;
    }
    .preview-tabs {
      width: 256px;
      margin: 0 auto;
    }
    .tab-title {
      position: relative;
      width: 256px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      margin: 0 auto 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      box-sizing: border-box;
      .border-line {
        position: absolute;
        left: 76px;
        bottom: -1px;
        width: 104px;
        border-bottom: 2px solid rgba(255, 0, 67, 1);
      }
    }
  }
}
</style>
