const apis = [
  {
    // 获取平台绑定信息（PC）
    fn: 'bindList',
    method: 'GET',
    url: '/overseas/user-platform/bind-list',
    hideloading: true
  },
  {
    // 获取对应已绑定平台信息（安卓/PC）
    fn: 'info',
    method: 'GET',
    url: '/overseas/user-platform/info'
  },
  {
    // 平台绑定（安卓）
    fn: 'bind',
    method: 'GET',
    url: '/overseas/user-platform/bind'
  },
  {
    // 平台绑定（PC）
    fn: 'bindPC',
    method: 'GET',
    url: '/overseas/user-platform/bind/pc'
  },
  {
    // 获取最新绑定平台信息（安卓）
    fn: 'boundList',
    method: 'GET',
    url: '/overseas/user-platform/boundList'
  },
  {
    // 获取用户已绑定平台类型（PC）
    fn: 'bindPlatformType',
    method: 'GET',
    url: '/overseas/user-platform/bindPlatformType',
    hideloading: true
  },
  {
    // 解绑平台（安卓/PC）
    fn: 'unbind',
    method: 'GET',
    url: '/overseas/user-platform/unbind'
  }
]

export const baseUrl = process.env.VUE_APP_OVERSEAS_URL

export default apis
