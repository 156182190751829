/**
 * yProFormProps
 * y-pro-form属性
 */
const props = {
  // el-form 原生属性配置
  formProps: Object,
  // proForm 样式
  proFormStyle: Object,
  // form 样式
  formStyle: Object,
  // 表单选项配置 内部元素属性查看./field-props.js中的fieldProps
  fields: {
    type: Array,
    default: () => []
  },
  /**
   * 表单提交重置按钮配置
   * align style resetText submitText resetButtonProps submitButtonProps
   */
  submitter: [Boolean, Object],
  // 是否加边框
  border: {
    type: Boolean,
    default: false
  },
  // 是否栅格布局配置
  grid: {
    type: Boolean,
    default: false
  },
  // 栅格布局配置
  layout: {
    type: Object,
    default: () => ({
      xs: 24,
      sm: 12,
      lg: 8,
      xl: 6
    })
  },
  // 展开伸缩数量配置, 默认为0, 不展示展开
  flexNum: {
    type: Number,
    default: 0
  },
  // 默认是否展开伸缩配置, 默认不展开
  defaultFlexOpen: {
    type: Boolean,
    default: false
  },
  // inline布局的配置, 可对fields分组展示行内布局
  groups: Array
}

export const propsKeys = Object.keys(props)

export default props
