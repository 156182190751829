import { createI18n } from 'vue-i18n-composable'

import en from './en'
import zhHans from './zh-hans'
import zhHant from './zh-hant'

export const messages = {
  ['en']: en,
  ['zh-Hans']: zhHans,
  ['zh-Hant']: zhHant
}

const i18n = createI18n({
  locale: localStorage.getItem('language') || 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages // set locale messages
  // If you need to specify other options, you can set other options
  // ...
})

export default i18n
