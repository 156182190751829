var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"document-main"},[_c('div',{staticClass:"fix-box"},[_c('div',{staticClass:"fix-select"},[_c('div',{staticClass:"key"},[_vm._v("Document Title")]),_c('div',{staticClass:"value"},[_c('el-switch',{model:{value:(_vm.data.titleSwitch),callback:function ($$v) {_vm.$set(_vm.data, "titleSwitch", $$v)},expression:"data.titleSwitch"}})],1)])]),_c('div',{staticClass:"name-box"},[_c('el-input',{attrs:{"placeholder":"Add a document title","maxlength":"30"},on:{"input":_vm.checkWordTitle},model:{value:(_vm.data.fileTitle),callback:function ($$v) {_vm.$set(_vm.data, "fileTitle", $$v)},expression:"data.fileTitle"}})],1),_c('div',{staticClass:"cover-img-box"},[(_vm.status === 1 || _vm.status === 2)?_c('div',{staticClass:"loading-mask"}):_vm._e(),_c('upload',{attrs:{"materialType":"oss_material_images","suffix":"file","filters":{
        mime_types: [
          {
            title: 'pdf',
            extensions: 'pdf'
          }
        ],
        max_file_size: '20mb'
      }},on:{"uploadComplete":_vm.uploadComplete,"onProcess":_vm.uploadProgress,"beforeUpload":_vm.beforeUpload}},[(_vm.status === 0)?_c('div',{staticClass:"upload-box"},[_c('div',{staticClass:"icon"}),_c('div',{staticClass:"icon-text"},[_vm._v("Upload document")])]):_vm._e(),(_vm.status === 1)?_c('div',{staticClass:"uploading-box"},[_c('div',{staticClass:"msg"},[_c('div',{staticClass:"file-title"},[_vm._v(_vm._s(_vm.file.name))]),_c('div',{staticClass:"text"},[_vm._v("Uploading"+_vm._s(_vm.watingString))])])]):_vm._e(),(_vm.status === 2)?_c('div',{staticClass:"transcoding-box"},[_c('div',{staticClass:"text"},[_vm._v("Document transcoding"+_vm._s(_vm.watingString))])]):_vm._e(),(_vm.status === 3)?_c('div',{staticClass:"has-img"},[_c('img',{attrs:{"src":_vm.images[_vm.currentPage - 1],"alt":""}}),_c('div',{staticClass:"change-img"},[_vm._v("Change document")])]):_vm._e(),(_vm.status === 4)?_c('div',{staticClass:"fail-box"},[_c('div',{staticClass:"text"},[_vm._v(" Document transcoding failed, please try again！ ")])]):_vm._e()])],1),_c('div',{staticClass:"tips",staticStyle:{"margin-top":"8px"}},[_vm._v(" Recommend PDF and the file size does not exceed 20MB. ")])])}
var staticRenderFns = []

export { render, staticRenderFns }