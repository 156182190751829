import { Message, Loading } from '@base'
import axios from 'axios'
import { localStore } from '@/utils/store'
import constStorage from '@constant/storage'
import constHttpcode from '@constant/httpcode'
import store from '@/store'
import { getValidParam } from '@utils/tools'
import router from '@/router'

/**
 * 请求拦截
 */
axios.interceptors.request.use(
  (config) => {
    const tokenVal = localStore.get(constStorage.LOCAL.TOKENVAL)
    const tokenKey = localStore.get(constStorage.LOCAL.TOKENKEY)
    if (tokenVal) {
      config.headers[tokenKey] = tokenVal
    }
    // 数据来源：1-box，2-PC
    config.headers['source'] = 2
    config.headers['timeZone'] =
      Intl.DateTimeFormat().resolvedOptions().timeZone
    config.data = getValidParam(config.data)
    config.params = getValidParam(config.params)
    if (
      config.headers['Content-Type'] === 'application/x-www-form-urlencoded'
    ) {
      const formData = new FormData()
      for (let k in config.data || {}) {
        if (Object.prototype.hasOwnProperty.call(config.data, k)) {
          formData.append(k, config.data[k])
        }
      }
      config.data = formData
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

/**
 * 响应拦截
 */
axios.interceptors.response.use(
  (response) => {
    const { data, config } = response
    if (config.originalResult) {
      return response
    }
    if (data.code === constHttpcode.SUCCESS) {
      return data.data
    }
    return Promise.reject({
      code: data.code,
      msg: data.message
    })
  },
  ({ response, ...rest }) => {
    if (response) {
      return Promise.reject({
        code: response.status,
        msg: response.statusText
      })
    }
    return Promise.reject({
      code: rest.status,
      msg: rest.statusText || 'Request Error'
    })
  }
)

/**
 * Return Promise<any>
 * @param {object} headers 请求头部
 * @param {string} method 请求方式
 * @param {string} url 请求地址
 * @param {boolean} hideloading 是否隐藏loading动画
 * @param {object} data Post 请求参数
 * @param {object} params Get 请求参数
 * @param {Function} processOptions options处理
 * @param {String} storeKey store唯一值
 * @param {boolean} originalResult 结果返回原始数据
 */
const request = async (options = {}) => {
  const { processOptions } = options

  const {
    headers = {},
    method = 'GET',
    url = '/',
    hideloading = false,
    hideError = false,
    data,
    params,
    storeKey,
    ...config
  } = typeof processOptions === 'function' && processOptions(options)
    ? processOptions(options)
    : options

  let loadingInstance
  store.commit('loadingUpdate', {
    [storeKey]: true
  })

  if (!hideloading) {
    loadingInstance = Loading.service({ fullscreen: true })
  }

  try {
    const res = await axios({
      method,
      url,
      headers,
      data,
      params,
      ...config
    })

    store.commit('exceptionUpdate', {
      [storeKey]: null
    })

    return res
  } catch (error) {
    store.commit('exceptionUpdate', {
      [storeKey]: error
    })
    if (error.code === constHttpcode.EXPIRED) {
      localStore.clearAll()
      if (
        router.history.current.path !== '/user/login' &&
        url !== '/overseas/user/queryInfo'
      ) {
        router.replace('/user/login')
      }
    }
    if (!hideError) {
      Message.error(error.msg)
    }
    return constHttpcode.FAIL
  } finally {
    loadingInstance && loadingInstance.close()
    store.commit('loadingUpdate', {
      [storeKey]: false
    })
  }
}

export default request

/**
 *  返回结果处理
 */
function ApiResult(statusCode, data, message, result) {
  this.statusCode = statusCode
  this.result = result
  this.data = data || {}
  this.message = message || ''
  this.succeed = function () {
    return this.statusCode == 200
  }
  this.fail = function () {
    return !this.succeed()
  }
}
export function get(url, params, ...rest) {
  return new Promise((resolve, reject) => {
    axios(
      {
        method: 'get',
        url,
        params
      },
      ...rest
    )
      .then((res) => {
        resolve(new ApiResult(res.code, res.data, res.msg, res))
      })
      .catch((err) => {
        reject(err)
      })
  })
}
