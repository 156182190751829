import { render, staticRenderFns } from "./_document-area.vue?vue&type=template&id=08b56d2c&scoped=true&"
import script from "./_document-area.vue?vue&type=script&lang=js&"
export * from "./_document-area.vue?vue&type=script&lang=js&"
import style0 from "./_document-area.vue?vue&type=style&index=0&id=08b56d2c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08b56d2c",
  null
  
)

export default component.exports