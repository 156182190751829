<template>
  <div class="live-info" :style="data.stylesheet.style">
    <div class="first-row" v-show="data.title">{{ data.title }}</div>
    <div class="second-row">
      <div class="detail" :style="{color: stylesheet.style.secondaryColor.value}">{{data.text}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      Type: Object,
      default: () => {
        return {};
      },
    },
    stylesheet: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.live-info {
  padding: 16px 12px;
  .first-row {
    margin-bottom: 9px;
    font-size: 16px;
    font-family: HelveticaNeue;
    font-weight: 500;
  }
  .second-row {
    width: 100%;
    //height: 50px;
    display: flex;
    align-items: center;
  }
  .detail {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    word-break: break-all;
  }
}
</style>
