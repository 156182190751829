<template>
  <div class="album-edit">
    <div class="height-box">
      <div class="text">Block Height</div>
      <div class="seelct-source-radio">
        <el-radio v-model="data.stylesheet.height" label="auto">Adaptive</el-radio>
        <el-radio v-model="data.stylesheet.height" label="fixed">Fixed</el-radio>
      </div>
    </div>
    <div class="height-box">
      <div class="text">Layout</div>
      <div class="seelct-source-radio">
        <el-radio v-model="data.tableStyle" label="fall">Waterfall flow</el-radio>
      </div>
    </div>
    <div class="fix-box">
      <div class="fix-select">
        <div class="key">Title</div>
        <div class="value">
          <el-switch v-model="data.titleSwitch"> </el-switch>
        </div>
      </div>
    </div>
    <div class="name-box">
      <el-input
        v-model="data.albumTitle"
        placeholder="Add an Image Group title"
        maxlength="30"
        @input="checkWordTitle"
      ></el-input>
    </div>
    <div class="height-box">
      <div class="text">Upload Image</div>
      <div class="xcxx-box">
        <div class="no-album" v-if="!data.albumId" @click="choiceMedia">Upload Image</div>
        <div class="has-album" v-else>
          <div class="xc-bg"></div>
          <div class="xc-name">{{ data.albumName }}</div>
          <div class="xc-btn">
            <div class="do-btn" @click="choiceMedia">modify</div>
            <div class="do-btn" @click="deleteMedia">delete</div>
          </div>
        </div>
      </div>
    </div>
    <album-dialog
      :title="'Image Group'"
      :visible="visibleAlbum"
      v-if="visibleAlbumShow"
      :resultList="resultList"
      :getAlbum="getAlbum"
      :getImageById="imageGroupList"
      @updateAlbum="updateAlbum"
    ></album-dialog>
  </div>
</template>
<script>
import albumDialog from './albumDialog';

export default {
  props: {
    data: {
      Type: Object,
      default: {},
    },
  },
  components: {
    albumDialog,
  },
  data() {
    return {
      visibleAlbum: false,
      visibleAlbumShow: false,
      resultList: [],
      getAlbum: '',
      imageGroupList: '',
    }
  },
  watch: {
    "data.stylesheet.height": function (newV) {
      if (newV === 'fixed') {
        this.data.stylesheet.style.height = "340px";
        this.data.stylesheet.style.overflowY = "auto";
      } else {
        this.data.stylesheet.style.height = "auto";
        this.data.stylesheet.style.overflowY = "hidden";
      }
    },
  },
  created() {
    if (this.data.albumId) {
      this.resultList = [{ groupId: this.data.albumId }];
    }
  },
  methods: {
    updateAlbum(data, resultList, albumList) {
      if (data) { // data，true：保存， false：取消、关闭
        // console.log(resultList);
        this.data.albumId = resultList[0].groupId;
        this.data.albumName = resultList[0].name;
        this.data.albumList = albumList;
        this.resultList = resultList;
      }
      this.visibleAlbum = false;
      setTimeout(() => {
        this.visibleAlbumShow = false;
      }, 200);
    },
    choiceMedia() {
      this.visibleAlbumShow = true;
      setTimeout(() => {
        this.visibleAlbum = true;
      }, 200);
    },
    deleteMedia() {
      this.$confirm("Are you sure to delete the Image Group？", "Prompt", {
        confirmButtonText: "Done",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.data.albumId = "";
        this.data.albumName = "";
        this.data.albumList = [];
        this.resultList = [];
      });
    },
    checkWordTitle() {
      const reg = /^[^\s].*$/g
      if (!reg.test(this.data.albumTitle)) {
        this.data.albumTitle = this.data.albumTitle.slice(1)
      }
    },
  },
  beforeDestroy() {
    if (!this.data.albumTitle.trim()) {
      this.data.albumTitle = 'Add an Image Group title'
      this.data.titleSwitch = false;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./edit.scss";
</style>
