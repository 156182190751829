/**
 * [formProps](https://element.eleme.cn/2.14/#/zh-CN/component/form#form-attributes)
 * el-form 原生属性
 */
const formProps = {
  model: Object,
  rules: Object,
  inline: {
    type: Boolean,
    default: false
  },
  labelPosition: {
    type: String,
    default: 'right'
  },
  labelWidth: String,
  labelSuffix: String,
  hideRequiredAsterisk: {
    type: Boolean,
    default: false
  },
  showMessage: {
    type: Boolean,
    default: true
  },
  inlineMessage: {
    type: Boolean,
    default: false
  },
  statusIcon: {
    type: Boolean,
    default: false
  },
  validateOnRuleChange: {
    type: Boolean,
    default: true
  },
  size: String,
  disabled: {
    type: Boolean,
    default: false
  }
}

export const formPropsKeys = Object.keys(formProps)

export default formProps
