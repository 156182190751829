<template>
  <el-footer class="y-footer" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </el-footer>
</template>

<script>
import { Footer } from 'element-ui'

export default {
  name: 'y-footer',
  _name: 'Footer 底栏容器',
  $depends: [Footer],
  components: {
    [Footer.name]: Footer
  }
}
</script>
