<template>
  <div class="banner">
    <div class="banner-box">
      <img class="bg" v-if="data.coverType === 'image' && data.coverUrl" :src="data.coverUrl" alt="" />
      <img class="bg" :src="data.defaultUrl" v-else />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      Type: Object,
      default: {},
    },
  },
};
</script>
<style lang="scss" scoped>
.banner {
  position: relative;
  .banner-box {
    width: 100%;
    height: 49px;
    position: relative;
    background-color: #f1f8ff;
    img {
      width: 100%;
      height: 100%;
    }
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }
  }
}
</style>
