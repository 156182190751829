<template>
  <div class="page-yolo-cast-resports" ref="resportsRef">
    <div class="page-yolo-cast-resports__tab">
      <y-tabs v-model="typeActive" type="card">
        <y-tab-pane
          v-for="tab in tabs"
          :key="tab.name"
          :label="tab.label"
          :name="tab.name"
        ></y-tab-pane>
      </y-tabs>
    </div>

    <div v-if="shwoAlert" class="page-yolo-cast-resports__alert">
      <i class="ui-icon-alert"></i>
      The report will be generated after the live, please wait…
    </div>

    <div class="page-yolo-cast-resports__main">
      <component :is="currentComponent" :consoleInfo="consoleInfo"></component>
    </div>
  </div>
</template>

<script>
import SideBar from '../_side-bar'
import LiveData from './_live-data'
import Viewers from './_viewers'
import StreamHealth from './_stream-health'

export default {
  components: {
    SideBar,
    LiveData,
    Viewers,
    StreamHealth
  },
  props: {
    consoleInfo: Object,
    liveInfo: Object
  },
  data() {
    return {
      tabs: [
        {
          label: 'Live Data',
          name: 'live-data',
          component: 'live-data'
        },
        {
          label: 'Viewers',
          name: 'viewers',
          component: 'viewers'
        },
        {
          label: 'Stream Health',
          name: 'stream-health',
          component: 'stream-health'
        }
      ],
      typeActive: 'live-data'
    }
  },
  computed: {
    shwoAlert() {
      if (!this.consoleInfo) {
        return true
      }
      // activityStatus: 直播间状态0:未开始,1:已删除,2:结束,3:预约,4:直播中,5:暂停
      const { activityStatus } = this.consoleInfo
      if (activityStatus === 2) {
        return false
      }
      return true
    },
    currentComponent() {
      const curretnTab = this.tabs.find((tab) => tab.name === this.typeActive)
      return curretnTab.component
    }
  }
}
</script>

<style lang="scss" scoped>
.page-yolo-cast-resports {
  flex: 1;
}
.page-yolo-cast-resports__tab {
  ::v-deep {
    .el-tabs__header {
      margin: 20px 20px 0;
    }
    .el-tabs__nav-wrap {
      padding: 0;
    }
    .el-tabs__nav-wrap::after {
      height: 1px;
      background-color: rgba(255, 255, 255, 0.15);
    }
    .el-tabs__item {
      width: 145px;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      text-align: center;
      color: rgba(255, 255, 255, 0.4);
      border-left-color: #3f404a !important;
      &.is-active {
        color: #ff4d7c;
      }
    }
    .el-tabs--card > .el-tabs__header {
      border-bottom-color: #3f404a !important;
    }
    .el-tabs--card > .el-tabs__header .el-tabs__nav {
      border-radius: 2px 2px 0 0;
      border-color: #3f404a;
    }
    .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
      border-bottom-color: #1e1f2b;
    }
  }
}
.page-yolo-cast-resports__alert {
  width: auto;
  margin: 20px 20px 0;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  background: rgba(255, 172, 69, 0.06);
  color: #ffac45;
  font-size: 14px;
  font-weight: 500;
  i {
    margin-right: 16px;
  }
}
</style>
