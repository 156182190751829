<template>
  <div class="image-article">
    <div class="has-articleContent" v-if="data.articleContent" v-html="data.articleContent"></div>
    <div class="no-articleContent" v-else>点击编辑图文内容</div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      Type: Object,
      default: {},
    },
    stylesheet: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>
<style lang="scss">
.image-article {
  width: 100%;
  word-break: break-all;
  b,
  strong {
    font-weight: bolder !important;
  }
  i {
    font-style: italic;
  }
  ol {
    padding-left: 20px;
  }
  .no-articleContent {
    width: 100%;
    height: 88px;
    line-height: 88px;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: $primary;
    background-color: #f1f8ff;
  }
  .has-articleContent {
    padding: 12px;
    table {
      border: 1px solid rgba(0, 0, 0, 0.15) !important;
      thead {
        tr {
          th {
            border: 1px solid rgba(0, 0, 0, 0.15) !important;
            height: 30px;
          }
        }
      }
      tbody {
        tr {
          th {
            border: 1px solid rgba(0, 0, 0, 0.15) !important;
            height: 30px;
          }
          td {
            border: 1px solid rgba(0, 0, 0, 0.15) !important;
            height: 30px;
          }
        }
      }
    }
  }
}
</style>
