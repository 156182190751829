<script>
export default {
  name: 'custom-content',
  props: {
    render: Function,
    column: {
      type: Object,
      default: () => ({})
    },
    columnIndex: Number,
    scope: {
      type: Object,
      default: () => ({})
    }
  },
  render(h) {
    const { prop } = this.column
    const { row, $index: rowIndex } = this.scope
    let callParams = [h, row, rowIndex, this.columnIndex, this.scope]
    if (prop !== undefined) {
      callParams = [h, row[prop], row, rowIndex, this.columnIndex, this.scope]
    }

    /**
     * h 必须传递 不然会引起h is not defined
     * row[prop] 当前colum值
     * row 当前记录数据
     * rowIndex 当前row索引
     * this.columnIndex 当前colum索引
     * this.scope 当前记录集合
     */
    const content = this.render.call(this, ...callParams)

    if (content === null || content === undefined) {
      return ''
    }

    // 返回的元素自带标签 那么直接返回
    if (content.constructor.name === 'VNode') {
      if (content.tag === 'template') {
        // 处理元素为空标签的情况下, 默认修改为span
        content.tag = 'div'
      }
      return content
    }

    // 返回的元素没有标签, 外面包裹一层标签
    return <span>{content}</span>
  }
}
</script>
