<template>
  <el-row class="y-row" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </el-row>
</template>

<script>
import { Row } from 'element-ui'

export default {
  name: 'y-row',
  _name: 'Row 栅格布局行',
  $depends: [Row],
  components: {
    [Row.name]: Row
  }
}
</script>
