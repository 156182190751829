const apis = [
  {
    // 获取时间段内直播统计数据
    fn: 'index',
    method: 'GET',
    url: '/overseas/statistics-center',
    hideloading: true
  },
  {
    // 事件列表
    fn: 'listEvent',
    method: 'POST',
    url: '/overseas/statistics-center/list-event',
    hideloading: true
  },
  {
    // 获取直播间统计数据
    fn: 'activity',
    method: 'GET',
    url: '/overseas/statistics-center/activity',
    hideloading: true
  },
  {
    // 获取观众地理位置分布
    fn: 'region',
    method: 'GET',
    url: '/overseas/statistics-center/region-audience',
    hideloading: true
  },
  {
    // 获取区域下的城市观众数量
    fn: 'regionAudience',
    method: 'GET',
    url: '/overseas/statistics-center/region-audience',
    hideloading: true
  },
  {
    // 获取直播的平均帧率码率
    fn: 'avgFps',
    method: 'GET',
    url: '/overseas/statistics-center/bps-fps/avg',
    hideloading: true
  },
  {
    // 获取直播流告警数据
    fn: 'streamAlert',
    method: 'GET',
    url: '/overseas/statistics-center/stream-alert',
    hideloading: true
  },
  {
    // 获取直播流帧率码率实时数据
    fn: 'bpsFpsMetrics',
    method: 'GET',
    url: '/overseas/statistics-center/bps-fps-metrics',
    hideloading: true
  },
  {
    // 获取实时流在线人数折线图
    fn: 'streamOnline',
    method: 'GET',
    url: '/overseas/statistics-center/stream-online',
    hideloading: true
  },
  {
    // 事件和观众参与度折线图
    fn: 'eventAudienceCount',
    method: 'GET',
    url: '/overseas/statistics-center/event-audience-count',
    hideloading: true
  },
  {
    // 导出事件列表
    fn: 'listExport',
    method: 'POST',
    url: '/overseas/statistics-center/list-event/export',
    hideloading: true,
    originalResult: true,
    responseType: 'blob'
  },
  {
    // 获取直播观众列表
    fn: 'audiencePage',
    method: 'POST',
    url: '/overseas/statistics-center/audience/page',
    hideloading: true
  },
  {
    // 获取用户来源占比
    fn: 'sourceProportion',
    method: 'GET',
    url: '/overseas/statistics-center/source-proportion',
    hideloading: true
  },
  {
    // 获取用户新老占比
    fn: 'oldAudienceProportion',
    method: 'GET',
    url: '/overseas/statistics-center/old-audience-proportion',
    hideloading: true
  }
]

export const baseUrl = process.env.VUE_APP_OVERSEAS_URL

export default apis
