<template>
  <div class="y-pro-list-page">
    <y-pro-form
      ref="formRef"
      v-bind="formatFormProps"
      @submit="formSubmit"
      @reset="formReset"
    >
      <slot slot="before-addon" name="form-before-addon"></slot>
      <slot slot="after-addon" name="form-after-addon"></slot>
    </y-pro-form>
    <slot name="addon"></slot>
    <y-pro-table
      ref="tableRef"
      :init-load="false"
      v-loading="pageLoading"
      element-loading-text="loading.."
      v-bind="formatTableProps"
      v-on="tableListeners"
    ></y-pro-table>
  </div>
</template>

<script>
import props from './config/props'

import formatFormProps from './utils/formatFormProps'
import formatTableProps from './utils/formatTableProps'

const tableListeners = [
  'sizeChange',
  'prevClick',
  'nextClick',
  'currentChange',
  'selection-change',
  'filter-change',
  'sort-change'
]

export default {
  name: 'y-list-page',
  props,
  computed: {
    formatFormProps() {
      return formatFormProps(this.$props)
    },
    formatTableProps() {
      return formatTableProps(this.$props)
    },
    tableListeners() {
      const listeners = Object.entries(this.$listeners).reduce(
        (pre, [k, v]) => {
          if (tableListeners.includes(k)) {
            return {
              ...pre,
              [k]: v
            }
          }
          return pre
        },
        {}
      )
      return listeners
    }
  },
  mounted() {
    this.refresh()
  },
  methods: {
    /**
     * 内部表单传参确认
     */
    formSubmit(model) {
      this.$emit('submit', model)
      this.$refs.tableRef.fetchData({
        ...model,
        page: 1
      })
    },
    /**
     * 内部表单传参重置
     */
    formReset(model) {
      this.$emit('reset', model)
      this.$refs.tableRef.fetchData({
        ...model,
        page: 1
      })
    },
    fetchData(data) {
      const { model } = this.$refs.formRef.formatFormProps
      this.$refs.tableRef.fetchData({
        ...model,
        ...data
      })
    },
    /**
     * 刷新数据
     */
    refresh() {
      this.fetchData({
        page: 1
      })
    }
  }
}
</script>
