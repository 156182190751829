<template>
  <div class="video-list-edit">
    <div class="fix-box">
      <div class="fix-select">
        <div class="key">Video Title</div>
        <div class="value">
          <el-switch v-model="data.titleSwitch"></el-switch>
        </div>
      </div>
      <el-input
        placeholder="Add a video title"
        v-model="data.title"
        maxlength="30"
        @input="checkWord"
      ></el-input>
    </div>
    <div class="style-box">
      <div class="text">Video arrangement</div>
      <div class="size-box">
        <el-radio-group v-model="data.typeStyle">
          <el-radio label="col">Waterfall flow</el-radio>
          <el-radio label="row">Horizontal sliding</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="list-box">
      <div class="height-box">
        <div class="text">Add Videos</div>
        <div class="xcxx-box">
          <div class="no-video" @click="choiceMedia">Add video source</div>
        </div>
        <div class="tips" style="margin-top: 8px">
          Supports MP4 video format and the size does not exceed 5GB;
        </div>
        <el-progress style="margin-top: 5px;" :text-inside="true" :stroke-width="18" :percentage="percent" color="#ffccd9" v-if="percent > 0"></el-progress>
      </div>
      <div class="has-video" v-if="data.videoList.length > 0">
        <div
          class="video-item"
          v-for="(item, index) in data.videoList"
          :key="index"
        >
          <div class="cover-box">
            <img class="cover" :src="item.coverUrl" alt="" v-if="item.coverUrl" />
            <div class="default-bg" alt="" v-else></div>
            <div class="delete-logo">
              <img src="~@assets/images/designer/shanchu@2x.png" alt="" @click="deleteItem(item, index)" />
            </div>
          </div>
          <!--<div class="video-text overout-two">{{ item.name }}</div>-->
        </div>
      </div>
    </div>
    <div class="fix-box looping-box">
      <div class="fix-select">
        <div class="key">Looping</div>
        <div class="value">
          <el-switch v-model="data.looping"></el-switch>
        </div>
      </div>
    </div>
    <div class="fix-box">
      <div class="fix-select" style="border-bottom: 0;">
        <div class="key">Video Switching Settings</div>
      </div>
      <el-radio-group v-model="data.setting">
<!--        <el-radio label="continue">Continue playing when switching.</el-radio>-->
<!--        <br>-->
        <div style="height: 14px;"></div>
        <el-radio label="resume">Resume first frame and pause when switching.</el-radio>
        <br>
        <div style="height: 14px;"></div>
        <el-radio label="pause">Pause when switching.</el-radio>
        <br>
        <div style="height: 14px;"></div>
      </el-radio-group>
    </div>
    <!--视频列表--预告页装修-->
    <media-dialog-checkbox
      :title="'Select Video'"
      groupTitle="All Videos"
      :buttonText="'上传视频'"
      :maxSize="'100mb'"
      :extensions="'mp4'"
      :visible="visibleVideo"
      v-if="visibleVideoShow"
      :resultList="data.videoList"
      @updateMedia="updateMediaVideo"
      :materialType="2"
    ></media-dialog-checkbox>
  </div>
</template>
<script>
import mediaDialogCheckbox from "@/components/media-dialog-checkbox";

export default {
  props: {
    data: {
      Type: Object,
      default: () => {
        return {}
      },
    },
  },
  components: {
    mediaDialogCheckbox
  },
  data() {
    return {
      percent: 0,
      visibleVideo: false, // 选择视频
      visibleVideoShow: false, // 选择视频数据控制
    };
  },
  created() {
    console.log(this.data);
  },
  methods: {
    deleteItem(item, index) {
      this.$confirm("Are you sure to delete the Video？", "Prompt", {
        confirmButtonText: "Done",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.data.videoList.splice(index, 1);
      });
    },
    checkWord() {
      const reg = /^[^\s].*$/g
      if (!reg.test(this.data.title)) {
        this.data.title = this.data.title.slice(1)
      }
    },
    choiceMedia() {
      this.visibleVideoShow = true;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.visibleVideo = true;
      }, 200);
    },
    updateMediaVideo(data, resultList) {
      if (data) {
        // data，true：保存， false：取消、关闭
        this.data.videoList = resultList;
      }
      this.visibleVideo = false;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.visibleVideoShow = false;
      }, 200);
    },
  },
  beforeDestroy() {
    if (!this.data.title.trim()) {
      this.data.title = '';
      this.data.titleSwitch = false;
    }
  }
};
</script>
<style lang="scss">
@import "./edit.scss";
</style>
