const apis = [
  {
    // 获取上传素材token
    fn: 'materialToken',
    method: 'GET',
    url: '/overseas/media-center/material/token',
    hideloading: true,
  }
]

export const baseUrl = process.env.VUE_APP_OVERSEAS_URL

export default apis
