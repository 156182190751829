import md5 from 'js-md5'

export default {
  LOCAL: {
    TOKENKEY: md5('yololiv-token-key'),
    TOKENVAL: md5('yololiv-token-val')
  },
  SESSION: {
    REFRESH: md5('refresh') // 刷新页面
  }
}
