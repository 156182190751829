<template>
  <el-main class="y-main" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </el-main>
</template>

<script>
import { Main } from 'element-ui'

export default {
  name: 'y-main',
  _name: 'Main 主要区域容器',
  $depends: [Main],
  components: {
    [Main.name]: Main
  }
}
</script>
