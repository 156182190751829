const apis = [
  {
    // 获取主播端控制台信息
    fn: 'info',
    method: 'GET',
    url: '/overseas/console/info',
    hideloading: true
  },
  {
    // 获取主播端控制台信息
    fn: 'realTimeStream',
    method: 'GET',
    url: '/overseas/console/real-time-stream',
    hideloading: true,
    hideError: true
  },
  {
    // 获取观众观看数据
    fn: 'viewData',
    method: 'GET',
    url: '/overseas/console/view-data',
    hideloading: true,
    hideError: true
  },
  {
    // 获取网络流状态(前端轮询调用)
    fn: 'getNetworkStatus',
    method: 'GET',
    url: '/overseas/console/get-network-status',
    hideloading: true,
    hideError: true
  },
  {
    // 评论开关
    fn: 'chatSwitchPut',
    method: 'POST',
    url: '/overseas/console/chat-switch',
    hideloading: true
  },
  {
    // 获取允许评论开关
    fn: 'chatSwitch',
    method: 'GET',
    url: '/overseas/console/chat-switch',
    hideloading: true
  },
  {
    // 获取流异常报警
    fn: 'getAbnormalStream',
    method: 'GET',
    url: '/overseas/console/real-time-stream-alert',
    hideloading: true
  },
  {
    // 获取当前直播间统计信息
    fn: 'getStatistics',
    method: 'GET',
    url: '/overseas/console/view-statistics',
    hideloading: true
  },
  {
    // 输入分享频道名字
    fn: 'shareChannelInput',
    method: 'POST',
    url: '/overseas/console/share/channel',
    hideloading: true
  },
  {
    // 获取分享频道列表
    fn: 'shareChannelList',
    method: 'GET',
    url: '/overseas/console/share/channel/list',
    hideloading: true
  },
  {
    // 获取最近分享频道列表
    fn: 'shareChannelListRecent',
    method: 'GET',
    url: '/overseas/console/share/channel/recent',
    hideloading: true
  },
  {
    // 替换直播回放视频
    fn: 'replacePlayback',
    method: 'PUT',
    url: '/overseas/console/playback',
    hideloading: true
  },
  {
    // 聊天针对用户禁言
    fn: 'chatMute',
    method: 'POST',
    url: '/overseas/console/chat/mute',
    hideloading: true
  },
  {
    // 聊天针对用户禁言
    fn: 'chatUnmute',
    method: 'DELETE',
    url: '/overseas/console/chat/mute',
    hideloading: true
  },
  {
    // 删除用户聊天评论
    fn: 'chatDelete',
    method: 'DELETE',
    url: '/overseas/console/chat',
    hideloading: true
  }
]

export const baseUrl = process.env.VUE_APP_OVERSEAS_URL

export default apis
