import { propsKeys } from '../config/props'
import { tablePropsKeys } from '../config/table-props'
import { paginationKeys } from '../config/pagination'

/**
 * 外部传入属性处理, 可兼容el-pagination属性
 * 和yProTableProps冲突的时候, 以yProTableProps设计为准
 */
const formatPagination = (props) => {
  const { pagination } = props
  const newPagination = Object.entries(props).reduce((pre, [k, v]) => {
    // 外部传入的属性在propsKeys范围内或属于tableProps的范围内的属性, 不作处理
    // 这里tableProps的范围内的属性不作处理是为了假如传了tableProps和paginationProps共同有的属性的话, 那么该传值只会在tableProps有效
    if ([...tablePropsKeys, ...propsKeys].includes(k)) {
      return pre
    }

    // 外部传入的属性在paginationProps范围内, 和 paginationProps 合并, 且以原有的paginationProps为准
    if (paginationKeys.includes(k)) {
      return {
        [k]: v,
        ...pre
      }
    }

    // 暂不处理其他属性
    return pre
  }, pagination)
  return newPagination
}

export default formatPagination
