<template>
  <div class="video-play-edit">
    <div class="cover-box">
      <div class="text">Thumbnail Image</div>
      <div>
        <div class="cover-img-box" @click="choiceMedia('image')">
          <div class="upload-box" v-if="!data.coverUrl">
            <div class="icon"></div>
            <div class="icon-text">Upload image</div>
          </div>
          <div class="has-img" v-else>
            <img :src="data.coverUrl" alt="" />
            <div class="change-img">Change image</div>
            <div class="delete-logo" @click.stop="deleteItem">
              <img src="~@assets/images/designer/shanchu@2x.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="tips" style="margin-top: 8px">
        hint:<br>
        Recommend JPG or PNG at 750x422 resolution, less than 20MB.
      </div>
    </div>
    <div class="video-box">
      <div class="video-select-box">
        <div>
          <div class="fix-box">
            <div class="fix-select">
              <div class="key">Pre-recorded Video</div>
              <div class="value">
                <el-switch v-model="data.trailer.enabled"> </el-switch>
              </div>
            </div>
            <div
              v-if="!data.trailer.url"
              class="seelct-source"
              @click="choiceMedia('video')"
            >Select video source</div>
            <div
              class="source-result"
              v-if="data.trailer.url"
            >
              <div class="title c-overout">{{ data.trailer.title }}</div>
              <div class="btn-updata" @click="choiceMedia('video')">
                <div>modify</div>
              </div>
              <div class="btn-delete" @click="deletePre">delete</div>
            </div>
            <div class="tips">
              hint:<br>
              Recommend MP4 video format, less than 5GB.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="fix-box">
      <div class="fix-select">
        <div class="key">Looping</div>
        <div class="value">
          <el-switch v-model="data.looping"></el-switch>
        </div>
      </div>
    </div>
    <div class="fix-box">
      <div class="fix-select" style="border-bottom: 0;">
        <div class="key">Countdown Timer</div>
        <div class="value">
          <el-switch v-model="data.countdown"></el-switch>
        </div>
      </div>
      <div class="key-position">Position</div>
      <el-radio-group v-model="data.countdownPosition">
        <el-radio label="top">Top</el-radio>
        <br>
        <div style="height: 14px;"></div>
        <el-radio label="bottom">Bottom</el-radio>
        <br>
        <div style="height: 14px;"></div>
        <el-radio label="fullscreen">Middle</el-radio>
        <br>
        <div style="height: 14px;"></div>
      </el-radio-group>
    </div>
    <media-dialog-radio
      v-if="visibleMediaShowCover"
      :title="title"
      :button-text="buttonText"
      :extensions="extensions"
      :visible="visibleMediaCover"
      :material-type="materialtype"
      :w="w"
      :h="h"
      :limt="limt"
      @updateMedia="uploadSuccessCover"
      suffix="image"
      :groupTitle="groupTitle"
      :multi_selection="false"
    ></media-dialog-radio>
  </div>
</template>
<script>
import mediaDialogRadio from '@/components/media-dialog-radio';

export default {
  props: {
    data: {
      Type: Object,
      default: {},
    },
  },
  components: {
    mediaDialogRadio
  },
  data() {
    return {
      type: '',
      materialtype: 1,
      groupTitle: '',
      title: '',
      buttonText: '',
      extensions: '',
      visiblePre: false,
      visiblePreShow: false,
      resultListPre: [], // 预告片数据
      w: 750,
      h: 422,
      limt: '20MB',
      visibleMediaCover: false,
      visibleMediaShowCover: false,
    };
  },
  created() {
    // console.log(this.data)
  },
  methods: {
    isImage() {
      this.type = 'image';
      this.limt = '20MB';
      this.materialtype = 1;
      this.suffix = 'image';
      this.groupTitle = "All Pictures";
      this.title = 'Select Image';
      this.buttonText = 'Upload Picture';
      this.extensions = 'jpg,png';
    },
    isVideo() {
      this.type = 'video';
      this.limt = '5GB';
      this.materialtype = 2;
      this.suffix = 'video'
      this.groupTitle = "All Videos"
      this.title = 'Select Video';
      this.buttonText = 'Upload Video';
      this.extensions = 'mp4';
    },
    uploadCompleteVideo(file) {
      this.data.trailer.url = file.url
      this.data.trailer.title = file.name;
    },
    deletePre() {
      this.$confirm("Are you sure to delete the Video？", "Prompt", {
        confirmButtonText: "Done",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.data.trailer.title = '';
        this.data.trailer.id = '';
        this.data.trailer.url = '';
      });
    },
    deleteItem() {
      this.$confirm("Are you sure to delete the Image？", "Prompt", {
        confirmButtonText: "Done",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.data.coverUrl = '';
      });
    },
    async uploadSuccessCover (data, resultList) {
      if (data) {
        if (this.type === 'image') {
          this.data.coverUrl = resultList[0].url
        } else {
          this.uploadCompleteVideo(resultList[0])
        }
      }
      this.visibleMediaCover = false;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.visibleMediaShowCover = false;
      }, 200);
    },
    choiceMedia(type) {
      if (type === 'image') {
        this.isImage();
      } else {
        this.isVideo();
      }
      this.visibleMediaShowCover = true;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.visibleMediaCover = true;
      }, 200);
    }
  },
};
</script>
<style lang="scss">
.video-play-edit {
  padding: 16px;
  .tips {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: $-color-black-40;
    line-height: 18px;
  }
  .cover-box {
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    .text {
      font-size: 14px;
      font-family: HelveticaNeue;
      font-weight: 500;
      color: $-color-black-80;
      margin-bottom: 12px;
    }
    .upload-box {
      width: 100%;
      height: 193px;
      background: rgba(247, 248, 250, 1);
      border: 1px dashed rgba(0, 0, 0, 0.15);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .icon {
        width: 8px;
        height: 8px;
        background: url(~@assets/images/designer/upload@2x.png)
          no-repeat;
        background-size: 100%;
      }
      .icon-text {
        font-size: 14px;
        font-family: HelveticaNeue;
        color: $-color-black-40;
        margin-left: 6px;
      }
    }
    .has-img {
      width: 100%;
      height: 193px;
      position: relative;
      &:hover {
        .change-img {
          display: block;
        }
        .delete-logo {
          display: block;
        }
      }

      img {
        width: 100%;
        height: 100%;
      }

      .change-img {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        line-height: 193px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        background-color: rgba(0, 0, 0, 0.4);
        font-family: HelveticaNeue;
        cursor: pointer;
      }
    }
    .delete-logo {
      display: none;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      z-index: 2000;
      background-color: rgba(0, 0, 0, 0.4);
      text-align: center;
      line-height: 24px;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  .fix-box {
    margin-top: 24px;
    margin-bottom: 24px;
    .fix-select {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      .key {
        font-size: 14px;
        font-family: HelveticaNeue;
        font-weight: 500;
        color: $-color-black-80;
      }
      .value {
        margin-left: auto;
      }
    }
    .key-position {
      font-size: 14px;
      font-family: HelveticaNeue;
      color: $-color-black-40;
      margin-bottom: 14px;
    }
  }
  .video-box {
    .title-box {
      font-size: 14px;
      font-family: HelveticaNeue;
      font-weight: 500;
      color: $-color-black-80;
      margin-bottom: 12px;
      &::before {
        content: "*";
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #f62213;
        margin-right: 4px;
      }
    }
    .video-select-box {
      .seelct-source-radio {
        margin-bottom: 12px;
      }
      .seelct-source {
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: $-color-black-80;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.15);
        cursor: pointer;
        margin-bottom: 8px;
      }
      .source-result {
        width: 100%;
        height: 40px;
        line-height: 40px;
        background-color: $gray;
        display: flex;
        align-items: center;
        padding: 0 16px;
        .title {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: $-color-black-80;
          width: 180px;
        }
        .btn-updata {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: $primary;
          margin-left: auto;
          cursor: pointer;
        }
        .btn-delete {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: $primary;
          margin-left: 12px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
