import YLoading from '../../base/loading'
import YListPage from './src/main'

YListPage.install = function(Vue) {
  Vue.prototype.$loading = YLoading.service
  Vue.use(YLoading.directive)
  Vue.component(YListPage.name, YListPage)
}

export default YListPage
