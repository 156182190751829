<template>
  <div class="page-yolo-cast-setup">
    <!-- attribute-type -->
    <div
      class="page-yolo-cast-setup__block page-yolo-cast-setup__attribute-type"
    >
      <div class="page-yolo-cast-setup__block-inner">
        <side-bar
          :tabs="tabs"
          default-active="basic"
          v-model="typeActive"
        ></side-bar>
      </div>
    </div>
    <!-- /attribute-type -->

    <!-- attribute-content -->
    <div
      class="
        page-yolo-cast-setup__block page-yolo-cast-setup__attribute-content
      "
    >
      <div class="page-yolo-cast-setup__block-inner">
        <component
          :is="currentComponent"
          :consoleInfo="consoleInfo"
          :liveInfo="liveInfo"
        ></component>
      </div>
    </div>
    <!-- /attribute-content -->
  </div>
</template>

<script>
import SideBar from '../_side-bar'
import Basic from './_basic'
import Embed from './_embed'
import Templates from './_templates'

export default {
  components: {
    SideBar,
    Basic,
    Embed,
    Templates
  },
  props: {
    consoleInfo: Object,
    liveInfo: Object
  },
  data() {
    return {
      tabs: [
        {
          label: 'Event',
          value: 'basic',
          icon: 'ui-icon-basic',
          activeIcon: 'ui-icon-basic-hover',
          component: 'Basic'
        },
        {
          label: 'Embed',
          value: 'embed',
          icon: 'ui-icon-embed',
          activeIcon: 'ui-icon-embed-hover',
          component: 'Embed'
        },
        {
          label: 'Page Decorate',
          value: 'decorate',
          icon: 'ui-icon-templates',
          activeIcon: 'ui-icon-templates-hover',
          component: 'Templates'
        }
      ],
      typeActive: 'basic'
    }
  },
  computed: {
    currentComponent() {
      const curretnTab = this.tabs.find((tab) => tab.value === this.typeActive)
      return curretnTab.component
    }
  }
}
</script>

<style lang="scss" scoped>
.page-yolo-cast-setup {
  display: flex;
}
.page-yolo-cast-setup__block {
  position: relative;
}
.page-yolo-cast-setup__block-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-x: auto;
}
.page-yolo-cast-setup__attribute-type {
  width: 186px;
  border-right: 1px solid rgba(255, 255, 255, 0.15);
}
.page-yolo-cast-setup__attribute-content {
  flex: 1;
}
</style>
