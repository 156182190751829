<template>
  <div class="_health-area">
    <div class="_health-eara__head">
      <h4>Streaming Health</h4>
    </div>
    <div class="_health-eara-main">
      <div
        class="_health-eara__info _health-eara__info-row"
        v-loading="loading"
        element-loading-background="rgba(0, 0, 0, 0.1)"
      >
        <div class="_health-eara__info-block">
          <p class="_health-eara__info-label">Real-time Bitrate</p>
          <p class="_health-eara__info-value">{{ bps }} kbps</p>
        </div>
        <div class="_health-eara__info-block">
          <p class="_health-eara__info-label">Real-time Frame Rate</p>
          <p class="_health-eara__info-value">{{ videoFps }} fps</p>
        </div>
        <div class="_health-eara__info-block">
          <p class="_health-eara__info-label">Avg Bitrate</p>
          <p class="_health-eara__info-value">{{ avgBps }} kbps</p>
        </div>
        <div class="_health-eara__info-block">
          <p class="_health-eara__info-label">Avg Frame Rate</p>
          <p class="_health-eara__info-value">{{ avgFps }} fps</p>
        </div>
      </div>
      <div
        class="_health-eara__info _health-eara__info-block _health-eara__chat"
      >
        <div class="_health-eara__chat-inner" ref="healthBitrateRef"></div>
      </div>
      <div class="_health-eara__info _health-eara__info-network" :class="{'empty-newwork': abnormalList.length === 0}">
        <div class="_health-eara__info-network-title">Alert History</div>
        <div class="_health-eara__info-network-head">
          <ul>
            <li>Time</li>
            <li>Waring</li>
          </ul>
        </div>
        <div class="_health-eara__info-network-content">
          <ol style="overflow: auto; height: 100%">
            <li class="empty-alery" v-if="abnormalList.length === 0">No data yet</li>
            <li
              v-for="(item, index) in abnormalList"
              :key="index"
              :class="[
                '_health-eara__info-network-item',
                item.streamStatus === 2
                  ? '_health-eara__info-network-item--poor'
                  : item.streamStatus === 3 ? '_health-eara__info-network-item--interruption'
                  : ''
              ]"
            >
              <span class="_health-eara__info-network-time">{{ item.time }}</span>
              <span class="_health-eara__info-network-warning">
                <b class="_health-area__info-logo-warn"></b>
                <span v-show="item.streamStatus === 2 ">Poor network status</span>
                <span v-show="item.streamStatus === 3 ">Network interruption</span>
              </span>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import moment from 'moment'

export default {
  props: {
    consoleInfo: Object,
    liveInfo: Object
  },
  data() {
    return {
      count: 200,
      bps: 0,
      maxBps: 0,
      videoFps: 0,
      maxDideoFps: 0,
      avgFps: 0,
      avgBps: 0,
      datatimer: null,
      bitrateList: [],
      frameRateList: [],
      timeList: [],
      bitrateOption: {
        title: {
          text: 'Stream Metrics',
          textStyle: {
            color: '#fff',
            fontSize: 14
          },
          top: 12,
          left: 12
        },
        grid: {
          left: 50,
          bottom: 35,
        },
        legend: {
          data: ['Bitrate', 'Frame Rate', ],
          right: 15,
          top: 12,
          icon: "circle",
          itemHeight: 8, // 修改icon图形大小
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF',
          },
        },
        xAxis: {
          type: 'category',
          data: [],
          boundaryGap: false,
          axisLabel: {
            interval: 1,
            // 坐标轴刻度标签换行处理
            formatter: function (params) {
              let newParamsName = ""; // 最终拼接成的字符串
              let paramsNameNumber = params.length; // 实际标签的个数
              let provideNumber = 12; // 每行能显示的字的个数
              let rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 换行的话，需要显示几行，向上取整
              /**
               * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
               */
              // 条件等同于rowNumber>1
              if (paramsNameNumber > provideNumber) {
                /** 循环每一行,p表示行 */
                for (let p = 0; p < rowNumber; p++) {
                  let tempStr = ""; // 表示每一次截取的字符串
                  let start = p * provideNumber; // 开始截取的位置
                  let end = start + provideNumber; // 结束截取的位置
                  // 此处特殊处理最后一行的索引值
                  if (p == rowNumber - 1) {
                    // 最后一次不换行
                    tempStr = params.substring(start, paramsNameNumber);
                  } else {
                    // 每一次拼接字符串并换行
                    tempStr = params.substring(start, end) + "\n";
                  }
                  newParamsName += tempStr; // 最终拼成的字符串
                }
              } else {
                // 将旧标签的值赋给新标签
                newParamsName = params;
              }
              //将最终的字符串返回
              return newParamsName;
            },
          },
        },
        yAxis: [
          {
            type: 'value',
            splitLine: {
              lineStyle: {
                type: 'dashed', //设置网格线类型 dashed：虚线   solid:实线
                width: 1,
                color: ['rgba(255, 255, 255, 0.25)']
              }
            }
          },
          {
            type: 'value',
            splitLine: {
              lineStyle: {
                type: 'dashed', //设置网格线类型 dashed：虚线   solid:实线
                width: 1,
                color: ['rgba(255, 255, 255, 0.25)']
              }
            }
          },
        ],
        series: [
          {
            name: 'Bitrate',
            data: [],
            type: 'line',
            symbol: 'none',
            lineStyle: {
              width: 1
            },
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                lineStyle: {
                  color: 'rgba(116, 96, 253, 1)', // 改变折线颜色
                },
              },
            },
          },
          {
            name: 'Frame Rate',
            data: [],
            type: 'line',
            symbol: 'none',
            lineStyle: {
              width: 1
            },
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                lineStyle: {
                  color: 'rgba(111, 217, 197, 1)', // 改变折线颜色
                },
              },
            },
          }
        ]
      },
      chart: null,
      abnormalList: [],
    }
  },
  computed: {
    loading() {
      return this.$store.state.loading['/overseas/console/real-time-stream']
    }
  },
  watch: {
    consoleInfo: {
      handler(val) {
        if (!val) {
          return
        }
        const { livestreamStatus } = val
        this.run(livestreamStatus)
      },
      immediate: true
    },
    liveInfo: {
      handler(val) {
        if (!val) {
          return
        }
        const { livestreamStatus } = val
        this.run(livestreamStatus)
      },
      immediate: true
    }
  },
  async mounted() {
    await this.renderBitrateChart()
    await this.changeData()
  },
  beforeDestroy() {
    if (this.datatimer) {
      clearTimeout(this.datatimer)
    }
  },
  methods: {
    // 根据livestreamStatus状态值执行业务流程
    // livestreamStatus: 直播流状态0:未开始,1:就绪,2:直播中,3:失败,4:连接中,5:结束
    run(livestreamStatus) {
      if ([2, 4].includes(livestreamStatus)) {
        this.loopFetchRealTimeStream()
      } else {
        if (this.datatimer) {
          clearTimeout(this.datatimer)
        }
      }
    },
    // 获取实时码率
    async fetchRealTimeStream() {
      const res = await this.$serve.console.realTimeStream({
        params: {
          livestreamId: this.consoleInfo.livestreamId
        }
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.bps = Math.ceil(res[res.length - 1].bps / 1000) || 0
      this.maxBps = Number(this.getMax(res).toFixed(0))
      this.avgBps = (res[res.length - 1].avgBps / 1000).toFixed(2) || 0.00

      this.videoFps = res[res.length - 1].videoFps || 0
      this.maxDideoFps = this.maxDideoFps > this.videoFps ? this.maxDideoFps : this.videoFps
      this.avgFps = res[res.length - 1].avgFps || 0
      // 图表数据处理
      this.timeList = []
      this.bitrateList = []
      this.frameRateList = []
      res.forEach(item => {
        this.timeList.push(moment(item.currentTimeMills).format('hh:mm A'))
        this.bitrateList.push(item.bps / 1000)
        this.frameRateList.push(item.videoFps)
      })
      this.changeData(this.maxBps + 200)
    },
    // 轮询获取实时码率
    loopFetchRealTimeStream() {
      this.fetchRealTimeStream()
      this.getAbnormalStream()
      if (this.datatimer) {
        clearTimeout(this.datatimer)
      }
      this.datatimer = setTimeout(() => {
        this.loopFetchRealTimeStream()
        this.getAbnormalStream()
      }, 60 * 1000)
    },
    // 渲染图表
    renderBitrateChart() {
      this.chart = echarts.init(this.$refs.healthBitrateRef)
      this.chart.setOption(this.bitrateOption)
    },
    changeData(maxBps = 400) {
      this.chart.setOption({
        xAxis: {
          type: 'category',
          data: this.timeList
        },
        yAxis: [
          {
            type: 'value',
            min: 0,
            max: maxBps,
            interval: maxBps / 5,
            splitLine: {
              lineStyle: {
                type: 'dashed', //设置网格线类型 dashed：虚线   solid:实线
                width: 1,
                color: ['rgba(255, 255, 255, 0.25)']
              }
            }
          },
          {
            type: 'value',
            min: 0,
            max: 60,
            interval: 60 / 5,
            splitLine: {
              lineStyle: {
                type: 'dashed', //设置网格线类型 dashed：虚线   solid:实线
                width: 1,
                color: ['rgba(255, 255, 255, 0.25)']
              }
            }
          },
        ],
        series: [
          {
            name: 'Bitrate',
            data: this.bitrateList,
            type: 'line',
            symbol: 'none',
            lineStyle: {
              width: 1
            },
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                lineStyle: {
                  color: 'rgba(116, 96, 253, 1)', // 改变折线颜色
                },
              },
            },
          },
          {
            name: 'Frame Rate',
            data: this.frameRateList,
            type: 'line',
            symbol: 'none',
            lineStyle: {
              width: 1
            },
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                lineStyle: {
                  color: 'rgba(111, 217, 197, 1)', // 改变折线颜色
                },
              },
            },
          }
        ]
      })
    },
    async getAbnormalStream() {
      const res = await this.$serve.console.getAbnormalStream({
        params: {
          livestreamId: this.consoleInfo.livestreamId
        }
      })
      if (res === this.$serve.FAIL) {
        return
      }
      res.forEach(item => {
        item.time = moment(item.currentTimeMills).format('hh:mm A')
      })
      this.abnormalList = res
    },
    getMax(data) {
      let arr = []
      for(let i = 0; i < data.length; i++){
        arr.push(data[i]['bps']);
      }
      return Math.max(...arr) / 1000;
    }
  }
}
</script>
<style lang="scss" scoped>
._health-area {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  color: #fff;
}
._health-eara__head {
  h4 {
    margin: 0;
    font-size: 20px;
  }
}
._health-eara-main {
  flex: 1;
  display: flex;
  flex-direction: column;
}
._health-eara__info {
  margin-top: 24px;
}
._health-eara__info-row {
  display: flex;
  flex-wrap: wrap;
  ._health-eara__info-block {
    width: 48%;
    height: 64px;
    margin-bottom: 16px;
    &:nth-child(2n) {
      margin-left: 4%;
    }
    &:nth-child(3), &:nth-child(4) {
      margin-bottom: 0;
    }
  }
}
._health-eara__info-block {
  background: rgba(41, 43, 60, 0.4);
  border-radius: 4px;
  padding: 4px 12px 6px;
}
._health-eara__info-label {
  color: rgba(255, 255, 255, 0.4);
  font-size: 12px;
  margin-bottom: 4px;
  line-height: 18px;
}
._health-eara__info-value {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}
._health-eara__chat {
  width: 100%;
  padding-top: 64%;
  position: relative;
}
._health-eara__chat-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
._health-eara__info-network {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 12px;
}
._health-eara__info-network-title {
  font-size: 14px;
  font-family: HelveticaNeue-Medium, HelveticaNeue;
  font-weight: 500;
  color: #FFFFFF;
  margin-bottom: 12px;
  padding: 0 12px;
}
._health-eara__info-network-head {
  ul {
    display: flex;
    background: #292b3c;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.06);
    height: 32px;
    line-height: 32px;
    background: rgba(41, 43, 60, 0.4);
    color: rgba(255, 255, 255, 0.4);
    padding: 0 12px;
    li {
      flex: 1;
    }
  }
}
._health-eara__info-network-content {
  position: relative;
  flex: 1;
  ol {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    &::-webkit-scrollbar {
      width: 6px;
      background: transparent;
    }
    &::-webkit-scrollbar-track {
      border-radius: 0;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(102, 102, 102, 1);
      transition: all 0.2s;
      border-radius: 2px;
    }
    li {
      display: flex;
      height: 32px;
      line-height: 32px;
      box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.06);
      padding: 0 12px;
      span {
        flex: 1;
        font-size: 14px;
        font-family: HelveticaNeue;
        color: rgba(255, 255, 255, 0.4);
      }
    }
  }
}
._health-eara__info-network-item--poor {
  ._health-eara__info-network-warning {
    display: flex;
    align-items: center;
    b {
      width: 12px;
      height: 12px;
      margin-right: 8px;
      background: url(~@assets/images/history-warn.png) no-repeat;
      background-size: 100%;
    }
    span {
      color: #ffac45;
    }
  }
}
._health-eara__info-network-item--interruption {
  ._health-eara__info-network-warning {
    display: flex;
    align-items: center;
    b {
      width: 12px;
      height: 12px;
      margin-right: 8px;
      background: url(~@assets/images/history-error.png) no-repeat;
      background-size: 100%;
    }
    span {
      color: #f62213;
    }
  }
}
.empty-newwork {
  height: 186px;
  background-color: rgba(41, 43, 60, 0.5);
  padding: 12px;
  flex: none;
  border-radius: 4px;
  ._health-eara__info-network-title {
    padding: 0;
  }
  ul {
    box-shadow: none;
  }
  ._health-eara__info-network-content {
    height: 96px;
    flex: none;
    ol {
      height: 96px !important;
      border: 1px solid rgba(255, 255, 255, 0.06);
      border-top: 0;
      .empty-alery {
        font-size: 14px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.4);
        height: 94px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
