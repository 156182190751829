const scroll = {
  bind(el) {
    el.$timer = null;
    el.$drag = function (e) {
      let disx = e.pageX - el.offsetLeft;
      let disy = e.pageY - el.offsetTop;
      document.onmousemove = function (e){
        el.style.right = `${document.body.clientWidth - e.pageX + disx -  el.clientWidth}px`;
        el.style.bottom = `${document.body.clientHeight - e.pageY + disy - el.clientHeight}px`;
        if (parseInt(el.style.right) <= 0) {
          el.style.right = '0px';
        }
        if (parseInt(el.style.bottom) <= 0) {
          el.style.bottom = '0px';
        }
        if (parseInt(el.style.right) >= document.body.clientWidth - el.clientWidth) {
          el.style.right = `${document.body.clientWidth - el.clientWidth}px`;
        }
        if (parseInt(el.style.bottom) >= document.body.clientHeight - el.clientHeight) {
          el.style.bottom = `${document.body.clientHeight - el.clientHeight}px`;
        }
      }
      document.onmouseup = function(){
        document.onmousemove = document.onmouseup = null;
      }
    }
    el.addEventListener('mousedown', el.$drag)
  },
  unbind(el) {
    el.removeEventListener('mousedown', el.$drag)
  }
}
export default scroll
