import { Message } from '@base'
import AWS from 'aws-sdk'
import serve from '@/serve'
import store from '@/store'
import { uid } from "uid";

const plupload = window.plupload

const GENERIC_ERROR = -100;
const HTTP_ERROR = -200;
const IO_ERROR = -300;
const SECURITY_ERROR = -400;
const INIT_ERROR = -500;
const FILE_SIZE_ERROR = -600;
const FILE_EXTENSION_ERROR = -601;
const FILE_DUPLICATE_ERROR = -602;
const IMAGE_FORMAT_ERROR = -700;
const IMAGE_DIMENSIONS_ERROR = -702;

const errors = {
  [GENERIC_ERROR]: "A generic error occurred within the block",
  [HTTP_ERROR]: "A network error has occurred",
  [IO_ERROR]: "Errors caused by security problems",
  [SECURITY_ERROR]: "A network error has occurred",
  [INIT_ERROR]: "An error occurred while initializing the block",
  [FILE_SIZE_ERROR]: "Resources size exceeds limit",
  [FILE_EXTENSION_ERROR]: "The file format is not supported yet.",
  [FILE_DUPLICATE_ERROR]: "Configuration does not allow duplicate resources",
  [IMAGE_FORMAT_ERROR]: "Picture format error",
  [IMAGE_DIMENSIONS_ERROR]: "The resources size exceeds the maximum that plupload can handle",
};

class AwsUploader {
  constructor({
    browse_button,
    suffix = 'default',
    materialType = 1,
    filters = {},
    random = true,
    BeforeUpload,
    UploadComplete,
    multi_selection,
    Error,
    UploadProgress,
    max_file_size,
    extensions,
    fileAdded,
  } = {}) {
    filters.max_file_size =
      max_file_size !== undefined ? max_file_size : filters.max_file_size;
    filters.mime_types = [
      ...(filters.mime_types || []),
      ...(extensions ? [{ title: extensions, extensions }] : []),
    ];
    this.browse_button = browse_button
    this.suffix = suffix
    this.materialType = materialType
    this.uploader = null
    this.s3 = null
    this.awsTokens = null
    this.filters = filters
    this.processTxtEl = null
    this.random = random
    this.BeforeUpload = BeforeUpload
    this.UploadComplete = UploadComplete
    this.fileAdded = fileAdded
    this.UploadProgress = UploadProgress
    this.multi_selection = multi_selection
    this.s3upload = null
    this.uploaderOption = {
      browse_button,
      suffix,
      materialType,
      filters,
      random,
      BeforeUpload,
      UploadComplete,
      fileAdded,
      multi_selection,
      Error,
      UploadProgress,
      max_file_size,
      extensions,
    }
    this.init()
  }

  init() {
    this.creaeteUploader()
  }

  creaeteUploader() {
    const _this = this;
    const uploaderOption = this.uploaderOption;
    this.uploader = new plupload.Uploader({
      runtimes: 'html5,flash,silverlight,html4',
      browse_button: this.browse_button,
      flash_swf_url: '../plupload-2.1.2/js/Moxie.swf',
      silverlight_xap_url: '../plupload-2.1.2/js/Moxie.xap',
      multi_selection: this.multi_selection,
      url: '/',
      filters: this.filters,
      init: {
        BeforeUpload: () => {
          return false
        },
        FilesAdded: async (_, files) => {
          if (files.length > 100) {
            Message.error("Upload up to 100 pictures！");
            // 将队列中的待上传图片全部清空
            this.uploader.files.splice(0);
            return false;
          }
          if (this.fileAdded) {
            this.fileAdded(files)
          }
          // 遍历检测文件名称不允许超过100个字
          for (let i = 0; i < files.length; i++) {
            files[i].materialType = this.materialType;
            files[i].uid = uid();
            files[i].groupId = sessionStorage.getItem('currentGroupGroupId')
              ? sessionStorage.getItem('currentGroupGroupId') : undefined;
            if (files[i].name.length >=100) {
              files[i].name = `${files[i].name.slice(0, 95)}...`
            }
          }
          if (this.materialType === 1) {
            for (let i = 0; i < files.length; i++) {
              const file = files[i]
              await _this.getAwsToken(file, files)
              _this.uploader.start()
            }
          } else {
            // 将进行中的文件及后续选中的文件放到store中
            store.commit('uploadModalUpdate', {
              visible: true,
              count: store.getters['uploadModal'].count || 0,
              uploadList: [...store.getters['uploadModal'].uploadList || [], ...files],
            })
          }
        },
        Error: (uploader, errObject) => {
          if (uploaderOption.Error) {
            uploaderOption.Error.call(
              null,
              uploader,
              errObject,
              errObject && errObject.message
            );
            return;
          }
          if (errObject && errObject.code) {
            if (errObject.code === -600) {
              Message.error(
                `The resources exceeds ${_this.uploaderOption.filters.max_file_size}. Please try a smaller one.`
              );
              return;
            }
            Message.error(errors[errObject.code]);
          } else {
            Message.error("Resources upload failed, please try again！");
          }
          // if (_this.processTxtEl) _this.processTxtEl.innerText = "Re Upload";
        },
      }
    })
    this.uploader.init()
  }

  async getAwsToken(file, files) {
    const res = await serve.media.materialToken({
      params: {
        materialType: this.materialType,
        time: new Date().getTime()
      }
    })
    if (res === serve.FAIL) {
      return serve.FAIL
    }
    this.awsTokens = res
    // await this.delay()
    await this.awsUpload(file, files)
  }

  async awsUpload(puploadFile, files) {
    const {
      bucketName,
      accessKeyId,
      secretAccessKey,
      sessionToken,
      region,
      dir,
      fileId
    } = this.awsTokens
    const file = puploadFile.getNative();
    const splitArr = file.name.split('.')
    const fileKey = `${dir}.${splitArr[splitArr.length - 1]}`
    const s3 = new AWS.S3({
      apiVersion: '2006-03-01',
      accessKeyId,
      secretAccessKey,
      sessionToken,
      region,
      'Access-Control-Allow-Credentials': '*',
      ACL: 'public-read'
    })
    const params = {
      Bucket: bucketName,
      Key: fileKey,
      ContentType: file.type,
      Body: file,
      time: new Date().getTime()
    }
    this.s3upload = s3.upload(params, {
      partSize: 10 * 1024 * 1024,
      queueSize: 1
    })
    this.s3upload
      .on('httpUploadProgress', (progress) => {
        if (this.UploadProgress) {
          this.UploadProgress(file, progress, files)
        } else {
          // const percent =
          //   ((progress.loaded / progress.total) * 100).toFixed(0) + '%'
          // this.processTxtEl.innerText = percent
        }
      })
      .send(async (err, data) => {
        if (err) {
          if (err.code !== 'RequestAbortedError') {
            Message.error(err.message || 'error')
          }

          this.uploader.state = plupload.FAILED
          puploadFile.status = plupload.FAILED
          // this.processTxtEl.innerText = 'Upload Again'
          if (this.UploadComplete) {
            await this.UploadComplete('fail', err, this.uploader, puploadFile, fileId, files)
          }
        }
        puploadFile.url = data.Location
        this.uploader.state = plupload.DONE
        puploadFile.status = plupload.DONE
        if (this.UploadComplete) {
          await this.UploadComplete('success', '', this.uploader, puploadFile, fileId, files)
        }
      })
  }

  cancelAllUpload() {
    if (this.s3upload) {
      this.s3upload.removeAllListeners('httpUploadProgress')
      this.s3upload.abort()
    }
  }
}

export default AwsUploader
