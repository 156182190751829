import { propsKeys } from '../config/props'
import { formPropsKeys } from '../config/form-props'
/**
 * 外部传入属性处理, 可兼容el-form属性
 * 和yProFormProps冲突的时候, 以yProFormProps设计为准
 */
const formatFormProps = (props) => {
  const { formProps = {} } = props
  const newFormProps = Object.entries(props).reduce((pre, [k, v]) => {
    // 外部传入的属性在propsKeys范围内, 不作处理
    if (propsKeys.includes(k)) {
      return pre
    }

    // 外部传入的属性在formPropsKeys范围内, 和 formProps 合并, 且以原有的formProps为准
    if (formPropsKeys.includes(k)) {
      return {
        [k]: v,
        ...pre
      }
    }

    // 暂不处理其他属性
    return pre
  }, formProps)
  return newFormProps
}

export default formatFormProps
