/**
 * 删除对象中属性值为null、undefined和''的属性
 */
export function getValidParam(obj, empty = true) {
  const param = {}
  if (obj === null || obj === undefined || obj === '') return param
  for (var key in obj) {
    if (Object.prototype.toString.call(obj) === 'Object') {
      param[key] = getValidParam(obj[key])
    } else if (
      obj[key] !== null &&
      obj[key] !== undefined &&
      (empty ? true : obj[key] !== '')
    ) {
      param[key] = obj[key]
    }
  }

  return param
}

export function randomString(len = 32) {
  const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  const maxPos = chars.length
  let str = ''
  for (let i = 0; i < len; i += 1) {
    str += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return str
}

export function saveFile(pic, name) {
  const elink = document.createElement('a')
  elink.download = name
  elink.style.display = 'none'
  elink.href = pic
  document.body.appendChild(elink)
  elink.click()
  URL.revokeObjectURL(elink.href)
  document.body.removeChild(elink)
}

export function exportFile(blobData, name) {
  const fileReader = new FileReader()
  fileReader.readAsDataURL(blobData)
  fileReader.onload = (event) => {
    saveFile(event.target.result, name)
  }
}

export function exportFileWithName(res) {
  let fileName = ''
  const contentDisposition = res.headers['content-disposition']
  if (contentDisposition) {
    fileName = window.decodeURI(
      res.headers['content-disposition'].split('=')[1],
      'UTF-8'
    )
  }
  exportFile(res.data, fileName)
}


export function secondToDate(result) {
  var h = Math.floor(result / 3600)
  var m = Math.floor((result / 60) % 60)
  var s = Math.floor(result % 60)
  return (result =
    (h < 10 ? '0' + h : h) +
    ':' +
    (m < 10 ? '0' + m : m) +
    ':' +
    (s < 10 ? '0' + s : s))
}