<template>
  <div :class="['_live-status', statusStyleText]">{{ statusTxt }}</div>
</template>

<script>
// 直播间状态0:未开始,1:已删除,2:结束,3:预约,4:直播中,5:暂停
const statusTextMap = {
  ['-1']: 'unkown',
  ['0']: 'Resume',
  ['2']: 'Ended',
  ['3']: 'Schedule',
  ['4']: 'On Air',
  ['5']: 'Resume'
}

const statusStyleTextMap = {
  ['-1']: 'unkown',
  ['0']: 'resume',
  ['2']: 'ended',
  ['3']: 'schedule',
  ['4']: 'live',
  ['5']: 'resume'
}

export default {
  props: {
    status: Number
  },
  computed: {
    statusTxt() {
      return statusTextMap[this.status + ''] || statusTextMap[-1]
    },
    statusStyleText() {
      return (
        '_live-status--' +
        (statusStyleTextMap[this.status + ''] || statusStyleTextMap[-1])
      )
    }
  }
}
</script>

<style lang="scss" scoped>
._live-status {
  width: 66px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  border-radius: 12px;
}
._live-status--unkown {
  background: rgba(0, 0, 0, 0.6);
}
._live-status--live {
  background: rgba(255, 0, 67, 0.6);
}
._live-status--ended {
  background: rgba(0, 0, 0, 0.6);
}
._live-status--schedule {
  background: rgba(99, 194, 27, 0.6);
}
._live-status--resume {
  background: rgba(243, 144, 0, 0.6);
}
</style>
