<template>
  <el-aside class="y-aside" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </el-aside>
</template>

<script>
import { Aside } from 'element-ui'

export default {
  name: 'y-aside',
  _name: 'Aside 侧边栏容器',
  $depends: [Aside],
  components: {
    [Aside.name]: Aside
  }
}
</script>
