<template>
  <div class="introduction-edit">
    <div class="bg-box">
      <div class="text">Background</div>
      <div class="seelct-source-radio">
        <el-radio v-model="data.stylesheet.background.type" label="default">Default</el-radio>
        <el-radio v-model="data.stylesheet.background.type" label="image">Custom</el-radio>
      </div>
      <div class="tips" v-if="data.stylesheet.background.type === 'image'">
        <div class="title">
          Recommended pixels: 750*{{h}}px, supports JPG, PNG and the size does not exceed 20MB;
        </div>
      </div>
      <div
        class="add-bg-box"
        v-if="data.stylesheet.background.type === 'image'"
      >
        <div @click="choiceMedia">
          <div class="has-img" v-if="data.stylesheet.background.url">
            <img :src="data.stylesheet.background.url" alt="" />
            <div class="change-img">Change image</div>
          </div>
          <div class="no-img" v-else>
            <div class="icon"></div>
            <div class="value">Upload image</div>
          </div>
        </div>
      </div>
    </div>
    <div class="name-box">
      <div class="text">Event Name</div>
      <el-input
        v-model="data.title"
        placeholder="Add a event name"
        @change="getActiveHeight"
        maxlength="30"
        @input="checkWordTitle"
      ></el-input>
    </div>
    <div class="fix-box">
      <div class="fix-select">
        <div class="key">Description</div>
        <div class="value">
          <el-switch v-model="data.showDetail" @change="getActiveHeight('switch')"></el-switch>
        </div>
      </div>
    </div>
    <div class="name-box">
      <el-input
        type="textarea"
        rows="4"
        v-model="data.detailContent"
        placeholder="Add an event description"
        maxlength="300"
        show-word-limit
        @change="getActiveHeight"
        @input="checkWordContent"
      ></el-input>
    </div>
    <div class="fix-box">
      <div class="fix-select">
        <div class="key">Share</div>
        <div class="value">
          <el-switch v-model="data.share" @change="getActiveHeight('switch')"></el-switch>
        </div>
      </div>
    </div>
    <media-dialog-radio
      v-if="visibleMediaShowCover"
      :title="'Select Image'"
      :button-text="'Upload Picture'"
      :extensions="'jpg,png'"
      :visible="visibleMediaCover"
      :material-type="1"
      :w="w"
      :h="h"
      :limt="limt"
      @updateMedia="uploadSuccessCover"
      suffix="image"
      groupTitle="All Pictures"
      :multi_selection="false"
    ></media-dialog-radio>
  </div>
</template>
<script>
import mediaDialogRadio from '@/components/media-dialog-radio';

export default {
  props: {
    data: {
      Type: Object,
      default: () => {
        return {}
      },
    },
  },
  components: {
    mediaDialogRadio,
  },
  data() {
    return {
      intro: null, // 直播简介
      w: 750,
      h: 120,
      limt: '20MB',
      visibleMediaCover: false,
      visibleMediaShowCover: false,
    }
  },
  watch: {
    'data.id': function() {
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.intro = document.getElementsByClassName('com-item active')[0];
        this.h = this.intro.clientHeight * 2;
      }, 200)
    },
  },
  mounted () {
    this.$nextTick(() =>{
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.intro = document.getElementsByClassName('com-item active')[0];
        this.h = this.intro.clientHeight * 2;
      }, 200)
    })
  },
  methods: {
    getActiveHeight(val) {
      if (val === 'switch') {
        let timer = setTimeout(() => {
          clearTimeout(timer);
          timer = null;
          this.intro = document.getElementsByClassName('com-item active')[0];
          this.h = this.intro.clientHeight * 2;
        }, 100)
      } else {
        this.intro = document.getElementsByClassName('com-item active')[0];
        this.h = this.intro.clientHeight * 2;
      }
    },
    checkWordTitle() {
      const reg = /^[^\s].*$/g
      if (!reg.test(this.data.title)) {
        this.data.title = this.data.title.slice(1)
      }
    },
    checkWordContent() {
      const reg = /^[^\s].*$/g
      if (!reg.test(this.data.detailContent)) {
        this.data.detailContent = this.data.detailContent.slice(1)
      }
    },
    async uploadSuccessCover (data, resultList) {
      if (data) {
        const url = resultList[0].url;
        this.data.stylesheet.background.url = url;
        this.data.stylesheet.style.backgroundImage = `url(${url})`;
      }
      this.visibleMediaCover = false;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.visibleMediaShowCover = false;
      }, 200);
    },
    choiceMedia() {
      this.visibleMediaShowCover = true;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.visibleMediaCover = true;
      }, 200);
    }
  },
  beforeDestroy() {
    if (!this.data.detailContent.trim()) {
      this.data.detailContent = '';
      this.data.showDetail = false;
    }
  }
};
</script>
<style lang="scss" scoped>
@import './edit.scss';
</style>
