<template>
  <div class="media-dialog-radio">
    <y-dialog
      :visible="dialogVisible"
      width="840px"
      top="5vh"
      :before-close="handleBeforeClose"
      :modal-append-to-body="false"
      :close-on-press-escape="false"
      :title="title"
      @click="cancel($event)"
    >
      <div class="up-wrap" v-if="false">
        <!-- 图片素材库上传-->
        <y-button
          size="small"
          type="primary"
          ref="uploadRef"
        >
          <i class="a-upload"></i>
          {{ buttonText }}
        </y-button>
        <!--<div class="upload-video-check">
          <el-checkbox v-model="checked"></el-checkbox>
          <span style="margin-left: 5px">Save to Media Center</span>
        </div>-->
      </div>
      <div class="list-box">
        <div class="left" v-loading="fetchListLoading">
          <div
            v-for="(item, index) in leftList"
            :key="item.groupId"
            class="left-item"
            :class="{ active: item.active }"
            @click="selectCur(item, index)"
          >
            <div class="key-name overout">{{ item.label }}</div>
            <!--<div class="key-num">({{ item.num }})</div>-->
          </div>
        </div>
        <div class="line"></div>
        <div class="right" v-loading="fetchListByIdLoading">
          <div
            v-for="(item, index) in rightList"
            :key="index"
            :class="['right-item', item.select ? 'select-item' : '']"
            @click="choice(item, index)"
          >
            <div class="key key-img">
              <template v-if="materialType === 1">
                <img class="media-img" :src="item.url" alt="" />
              </template>
              <template v-if="materialType === 2">
                <img class="media-img" :src="item.coverUrl" alt="" />
              </template>
              <template v-if="materialType === 3">
                <img class="media-img" :src="item.coverUrl" alt="" />
              </template>
            </div>
            <div
              class="value overout"
            >
              {{ item.name }}
            </div>
            <div class="select-mask" v-show="item.select"></div>
            <!--<div v-if="item.select" class="seelct"></div>-->
          </div>
          <div v-if="rightList.length > 0" class="pagination-box">
            <y-pagination
              background
              :current-page="currentPage"
              :page-size="currentPageSize"
              layout="prev, pager, next, jumper"
              :total="total"
              @current-change="handleCurrentChange"
            >
            </y-pagination>
          </div>
          <div class="no-data-box" v-if="rightList.length === 0">
            <div class="no-data-logo"></div>
            <div class="no-data-text">NO DATA</div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleBeforeClose">Cancel</el-button>
        <el-button
          type="primary"
          :disabled="resultListEmit.length === 0"
          size="small"
          @click="handleSubmit"
          >Done</el-button
        >
      </div>
    </y-dialog>
    <img-crop
      :cover="reCoverUrl"
      :visible="enabled"
      v-if="enabledShow"
      :w="w"
      :h="h"
      :limt="limt"
      @crop-upload-success="cutUploadSuccess"
      @cancel="cutCancel"
      :materialId="currentMedia.materialId"
    ></img-crop>
  </div>
</template>

<script>
import ImgCrop from '@components/img-crop';

export default {
  components: {
    ImgCrop
  },
  props: {
    visible: {
      // 弹窗显示
      type: Boolean,
      default: false,
    },
    title: {
      // 标题
      type: String,
      default: '',
    },
    extensions: {
      type: String,
    },
    buttonText: {
      // 上传按钮文字
      type: String,
      default: '',
    },
    resultList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 媒体类型, 1：图片 2：视频
    materialType: {
      type: Number,
    },
    w: {
      default: 120,
    },
    h: {
      default: 120,
    },
    limt: {
      default: '2MB',
    },
    suffix: {
      type: String,
      default: '',
    },
    cut: {
      type: Boolean,
      default: true,
    },
    groupTitle: {
      type: String,
      default: 'All',
    },
    multi_selection: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fetchListLoading() {
      return this.$store.state.loading['/overseas/media-center/group/list']
    },
    fetchListByIdLoading() {
      return this.$store.state.loading['/overseas/media-center/material/page']
    },
  },
  data () {
    return {
      dialogVisible: false,
      leftList: [], // 媒体库左侧导航数据
      rightList: [],
      total: 0,
      // TODO: 待优化
      // eslint-disable-next-line eqeqeq
      currentPageSize: 15,
      currentPage: 1,
      currentGroup: {}, // 当前选中的分组
      resultListEmit: [],
      currentMedia: {}, // 当前选中的媒体
      uploadDialogVisible: false,
      checked: true, // 图片保存到素材库
      enabled: false, // 图片剪切字段
      reCoverUrl: '', // 待剪切的图片
      fileName: '', // 上传后的文件名称
      enabledShow: false, // 控制图片初始化
      saveMediaMsg: {}, // 需要保存到素材库的数据
    };
  },
  watch: {
    visible (newV) {
      this.dialogVisible = newV;
    },
    currentPage () {
      this.getListByGroup();
    },
  },
  async created () {
    this.resultListEmit = JSON.parse(JSON.stringify(this.resultList));
    await this.getGroup();
    await this.getListByGroup();
  },
  methods: {
    initTestUploader() {
      if (this.materialType === 2 || this.materialType === 3) {
        return false;
      }
      new this.$AwsUploader({
        browse_button: this.$refs.uploadRef.$el,
        suffix: this.suffix,
        multi_selection: this.multi_selection,
        materialType: this.materialType,
        filters: {
          mime_types: [{ title: this.extensions, extensions: this.extensions }],
          max_file_size: this.limt
        },
        UploadComplete: (type, err, _, file, fileId) => {
          if (type === 'success') {
            this.uploadSuccess(file.url, file, fileId);
          } else {
            this.$message.error("Resources upload failed, please try again！")
          }
        },
        UploadProgress: () => {
          // 占位，阻止construct内部的方法的执行
        },
      })
    },
    // 关闭当前的窗口
    handleBeforeClose () {
      this.$emit('updateMedia', false);
    },
    // 关闭当前的窗口，点击【X】
    cancel (event) {
      this.$emit('cancel');
      event.stopPropagation();
      return false;
    },
    // 点击左侧导航
    selectCur (item) {
      this.leftList.forEach((item) => {
        item.active = false;
      });
      item.active = true;
      this.rightList = [];
      // 切换左侧源，清空选中数据和结果数据
      this.currentGroup = item;
      this.currentPage = 1;
      this.getListByGroup();
    },
    // 切换分页
    handleCurrentChange (val) {
      this.currentPage = val;
    },
    // 选中当前素材
    choice (item) {
      if (item.select) {
        item.select = false;
        this.resultListEmit = [];
        this.currentMedia = {};
      } else {
        this.rightList.forEach((item) => {
          item.select = false;
        });
        item.select = true;
        this.resultListEmit = [item];
        this.currentMedia = item;
      }
    },
    // 对右上角重新赋值
    resetIndex () {
      for (let r = 0; r < this.rightList.length; r++) {
        for (let c = 0; c < this.resultListEmit.length; c++) {
          if (
            this.rightList[r].url === this.resultListEmit[c].url
          ) {
            this.rightList[r].select = true;
            break;
          }
        }
      }
    },
    // 获取左侧的左侧导航
    async getGroup () {
      const res  = await this.$serve['media-center'].getMediaGroupList({
        params: {
          materialType: this.materialType,
        }
      })
      if (res === this.$serve.FAIL) {
        return
      }
      let resList = res.groupList || [];
      resList.unshift({
        materialCount: res.materialTotalCount,
        groupId: '',
        groupName: this.groupTitle,
      })
      resList.forEach((item, index) => {
        item.active = false;
        if (index === 0) {
          item.active = true;
        }
        item.label = item.groupName;
        item.num = item.materialCount;
        item.id = item.groupId;
      });
      this.leftList = resList;
      if (this.leftList.length > 0) {
        this.currentGroup = this.leftList[0] || {};
      }
    },
    // 根据导航列表获取素材列表
    async getListByGroup () {
      const res  = await this.$serve['media-center'].getMediaByGroupId({
        params: {
          page: this.currentPage,
          pageSize: this.currentPageSize,
          groupId: this.currentGroup.id,
          materialType: this.materialType,
          transcodeStatus: this.materialType !== 1 ? 3 : undefined,
        }
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.rightList = res.records || [];
      this.resetIndex();
      this.total = res.totalCount;
    },
    // 从媒体库选择
    handleSubmit () {
      // 如果选择的素材是图片且需要剪切
      if (this.materialType === 1 && this.cut) {
        // 不是本地上传的文件，没有保存到素材库的信息
        this.saveMediaMsg = {
          materialId: this.resultListEmit[0].materialId
        };
        const url = this.resultListEmit[0].url;
        this.fileName = this.resultListEmit[0].name;
        this.cutImage(url)
      } else {
        this.$emit('updateMedia', true, this.resultListEmit);
      }
    },
    // 本地上传成功
    async uploadSuccess (url, file, fileId) {
      // 需要保存到素材库的数据值
      this.saveMediaMsg = {
        url,
        file,
        fileId
      }
      if (this.materialType === 1 && this.cut) {
        // 需要图片剪切功能
        this.fileName = file.name;
        // 本地上传后之前选中的文件全部清空，
        // 因为图片裁剪的地方需要根据materialId判断是否本地上传然后关闭弹窗的时候清空云内存
        this.currentMedia = {};
        this.resultListEmit = [];
        this.rightList.forEach(item => {
          item.select = false;
        })
        this.cutImage(url)
      } else {
        // 不需要图片剪切功能
        if (this.saveMediaMsg.fileId && this.checked) {
          await this.materialSave();
        }
        this.$emit('updateMedia', true, [
          {
            name: file.name,
            url: url,
            id: this.saveMediaMsg.materialId
          }
        ]);
      }
    },
    // 保存素材库
    async materialSave () {
      const { url, file, fileId } = this.saveMediaMsg;
      const mutipFileList = [{
        url: url,
        fileId: fileId,
        fileName: file.name,
      }]
      const res = await this.$serve['media-center'].saveMediaUrl({
        data: {
          materialType: this.materialType,
          materialList: mutipFileList,
          groupId: undefined,
        }
      })
      if (res === this.$serve.FAIL) {
        return false
      }
      this.saveMediaMsg.materialId = res.materials[0].materialId;
    },
    cutCancel () {
      this.enabled = false;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.enabledShow = false;
        this.reCoverUrl = '';
      }, 200);
    },
    // 图片如果大小不符合需要剪切的回调
    async cutUploadSuccess (url, fileId) {
      this.saveMediaMsg = {
        url,
        file: {
          name: `${new Date().getTime()}.jpg`
        },
        fileId
      }
      await this.materialSave();
      this.$emit('updateMedia', true, [
        {
          name: this.fileName,
          url: url,
          id: fileId, // this.saveMediaMsg.materialId,
        },
      ]);
      this.enabled = false;
    },
    cutImage(url) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const _this = this;
      const img = new Image();
      img.src = url;
      img.onload = async function () {
        // 如果上传后的图片宽高不符合设置的预期，那么需要进行图片的剪切
        if (
          (this.width / this.height).toFixed(1) !== (_this.w / _this.h).toFixed(1)
          // parseInt(this.height) !== _this.h ||
          // parseInt(this.width) !== _this.w
        ) {
          _this.reCoverUrl = url;
          _this.enabledShow = true;
          let timer = setTimeout(() => {
            clearTimeout(timer);
            timer = null;
            _this.enabled = true;
          }, 200);
          return false;
        }
        // 上传后图片大小正合适无须剪切
        if (_this.saveMediaMsg.fileId && _this.checked) {
          await _this.materialSave();
        }
        _this.$emit('updateMedia', true, [
          {
            name: _this.fileName,
            url: url,
            id: _this.saveMediaMsg.materialId
          }
        ]);
      };
    }
  },
};
</script>

<style lang="scss">
@import "media-dialog-radio";
.media-dialog-radio {
  .el-dialog__header {
    line-height: initial;
  }
  .el-dialog__body {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  .el-dialog__footer {
    padding: 16px 24px;
    .dialog-footer {
      height: 32px;
      line-height: 32px;
    }
  }
  .el-pagination {
    line-height: 28px;
  }
  .el-dialog__body {
    padding: 16px !important;
  }
}
</style>
