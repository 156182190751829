<template>
  <el-dropdown-item class="y-dropdown-item" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </el-dropdown-item>
</template>

<script>
import { DropdownItem } from 'element-ui'

export default {
  name: 'y-dropdown-item',
  _name: 'Dropdown 下拉菜单选项',
  $depends: [DropdownItem],
  components: {
    [DropdownItem.name]: DropdownItem
  }
}
</script>
