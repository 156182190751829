const apis = [
  {
    // 获取分组列表
    fn: 'getMediaGroupList',
    method: 'GET',
    url: '/overseas/media-center/group/list',
    hideloading: true,
  },
  {
    // 根据分组ID获取媒体列表
    fn: 'getMediaByGroupId',
    method: 'GET',
    url: '/overseas/media-center/material/page',
    hideloading: true,
  },
  {
    // 保存上传素材地址
    fn: 'saveMediaUrl',
    method: 'POST',
    url: '/overseas/media-center/material',
    hideloading: true,
  },
  {
    // 重新编辑素材命名
    fn: 'mediaReName',
    method: 'PUT',
    url: '/overseas/media-center/material',
    hideloading: true,
  },
  {
    // 删除保存素材信息
    fn: 'mediaDelete',
    method: 'POST',
    url: '/overseas/media-center/material/del',
    hideloading: true,
  },
  {
    // 新增媒体素材分组
    fn: 'addNewMediaGroup',
    method: 'POST',
    url: '/overseas/media-center/group',
    hideloading: true,
  },
  {
    // 修改媒体分组名字
    fn: 'mediaGroupReName',
    method: 'PUT',
    url: '/overseas/media-center/group',
    hideloading: true,
  },
  {
    // 删除媒体分组
    fn: 'groupDelete',
    method: 'DELETE',
    url: '/overseas/media-center/group',
    hideloading: true,
  },
  {
    // 移动素材分组信息
    fn: 'mediaMove',
    method: 'PUT',
    url: '/overseas/media-center/material-group',
    hideloading: true,
  },
  {
    // 移除素材所在分组
    fn: 'mediaRemove',
    method: 'POST',
    url: '/overseas/media-center/material-group/del',
    hideloading: true,
  },
  {
    // 获取素材详情
    fn: 'getMediaDetail',
    method: 'GET',
    url: '/overseas/media-center/material',
    hideloading: true,
  },
  {
    // 撤销已上传素材
    fn: 'revokeMedia',
    method: 'PUT',
    url: '/overseas/media-center/material/revoke',
    hideloading: true,
  },
  {
    // 回放视频转码
    fn: 'getTranscodeMedia',
    method: 'PUT',
    url: '/overseas/media-center/material/transcode',
    hideloading: true,
  }
]

export const baseUrl = process.env.VUE_APP_OVERSEAS_URL

export default apis
