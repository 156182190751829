<template>
  <div class="_chat-area-chat">
    <div class="_chat-area-chat-inner">
      <div class="_chat-area__records" ref="commentListRef" @scroll="scroll">
        <!-- _chat-area__record -->
        <div class="_chat-area__records-inner" ref="commentListInnerRef">
          <div v-show="!loaded" class="_chat-area__records-loading">
            <i style="font-size: 20px" class="el-icon-loading"></i>
          </div>
          <template v-if="formatRecords.length">
            <div
              v-for="record in formatRecords"
              :key="record.audienceChatId"
              :class="[
                '_chat-area__record',
                {
                  '_chat-area__record--self': record.identity === 1,
                  '_chat-area__record--custom': record.identity === 2
                }
              ]"
            >
              <div v-if="record.showTime" class="_chat-area__record-head">
                {{ formatTime(+record.chatTime) }}
              </div>
              <div class="_chat-area__record-main">
                <div class="_chat-area__record-main-inner">
                  <div class="_chat-area__record-avatar">
                    <!-- identity 1 主播 2 观众 -->
                    <el-avatar
                      :size="28"
                      :src="
                        record.avatar ||
                        (record.identity === 1
                          ? defaultAnchorAvatar
                          : defaultAudienceAvatar)
                      "
                    ></el-avatar>
                  </div>
                  <div class="_chat-area__record-content">
                    <div class="_chat-area__record-nickname">
                      {{ record.nickname }}
                    </div>
                    <div class="_chat-area__record-msg">
                      <p>{{ record.content }}</p>
                    </div>
                  </div>
                  <div v-if="record.identity === 2" class="_chat-area__control">
                    <span></span>
                    <p v-if="record.isMute" @click="controlUser(record)">
                      <i
                        style="font-size: 20px"
                        v-if="record.muteing"
                        class="el-icon-loading"
                      ></i>
                      <i v-else class="ui-icon-unmute"></i>
                      Unmute
                    </p>
                    <p v-else @click="controlUser(record)">
                      <i
                        v-if="record.muteing"
                        style="font-size: 20px"
                        class="el-icon-loading"
                      ></i>
                      <i v-else class="ui-icon-mute"></i>
                      Mute
                    </p>
                    <span></span>
                    <p @click="deleteRecord(record)">
                      <i
                        style="font-size: 20px"
                        v-if="record.deleting"
                        class="el-icon-loading"
                      ></i>
                      <i v-else class="ui-icon-delete2"></i>
                      Delete
                    </p>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div
            v-if="!formatRecords.length && loaded"
            class="_chat-area-chat__empty"
          >
            <img src="@/assets/images/icon-chat-empty.png" alt="empty" />
            Join the conversation
            <br />
            Remember to be cool and play nice!
          </div>
        </div>
        <!-- /_chat-area__record -->
      </div>

      <!-- 新消息提示 -->
      <div
        class="_chat-area__more"
        v-show="moreRecords.length > 0"
        @click="more"
      >
        {{ moreRecords.length > 99 ? '99+' : moreRecords.length }} messages
      </div>
      <!-- /新消息提示 -->
    </div>

    <!-- * activityStatus: 直播间状态0:未开始,1:已删除,2:结束,3:预约,4:直播中,5:暂停 v-if="commentActionShow" -->
    <div class="_chat-area__action">
      <div class="_chat-area__action-avatar">
        <el-avatar
          :size="28"
          :src="user.avatar || defaultAnchorAvatar"
        ></el-avatar>
      </div>
      <div
        :class="[
          '_chat-area__action-content',
          {
            '_chat-area__action-content--focus': focus
          }
        ]"
      >
        <input
          type="text"
          v-model.trim="content"
          maxlength="200"
          @focus="focus = true"
          @blur="focus = false"
          placeholder="Say something"
          @keyup.enter="send"
        />
        <i v-show="!chatLoading" class="ui-icon-send" @click="send"></i>
        <i
          style="font-size: 20px"
          v-show="chatLoading"
          class="el-icon-loading"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import throttle from 'lodash/throttle'
import defaultAudienceAvatar from '@/assets/images/icon-avatar.png'
import defaultAnchorAvatar from '@/assets/images/icon-avatar-red.png'
import { sortListByTime, formatListByAddTime } from './helper'

export default {
  props: {
    consoleInfo: Object,
    liveInfo: Object
  },
  data() {
    return {
      active: 'chat',
      activityId: this.$route.params.id,
      page: 1, // 评论列表分页
      pageSize: 50,
      records: [],
      moreRecords: [],
      muteList: [],
      focus: false,
      loaded: false, // 是否加载完毕
      // 是否会造成滚动副作用: 该标识用来避免设置 scrollTop 导致重复执行 scroll 中的 if(this.isBottom()) 事件
      canCauseScrollEffect: false,
      content: '',
      sendLoading: false,
      enableChat: false,
      defaultAudienceAvatar,
      defaultAnchorAvatar,
      currentControlRecord: null
    }
  },
  computed: {
    user() {
      return this.$store.state.userInfo
    },
    pageLoading() {
      return this.$store.state.loading['/overseas/cast/page']
    },
    chatLoading() {
      return this.$store.state.loading['/overseas/chat/send']
    },
    formatRecords() {
      const records = formatListByAddTime(this.records)
      // 去重
      const recordsIds = records.map((record) => record.audienceChatId)
      const filterRecords = records.filter((record, index) => {
        return recordsIds.indexOf(record.audienceChatId) === index
      })
      const newRecords = filterRecords.map((item) => {
        const currentItem = this.muteList.find(
          (muteItem) => muteItem.audienceId === item.audienceId
        )
        if (currentItem) {
          item.isMute = 1
        } else {
          item.isMute = 0
        }
        return item
      })
      return newRecords
    },
    commentActionShow() {
      // activityStatus: 直播间状态0:未开始,1:已删除,2:结束,3:预约,4:直播中,5:暂停
      if (this.liveInfo) {
        if (this.liveInfo.activityStatus === 4) {
          return true
        } else {
          return false
        }
      }
      return this.consoleInfo.activityStatus === 4
    }
  },
  watch: {
    liveInfo: {
      handler(val) {
        if (!val) {
          return
        }
        // activityStatus: 直播间状态0:未开始,1:已删除,2:结束,3:预约,4:直播中,5:暂停
        const { activityStatus } = val
        if (activityStatus === 4) {
          this.fetchRealtimeCommentLoop()
          return
        }
        if (this.realtimeCommentLoopTimer) {
          clearTimeout(this.realtimeCommentLoopTimer)
        }
      },
      immediate: true
    }
  },
  async mounted() {
    await this.fetchMuteList()
    await this.fetchCommentList()
    // activityStatus: 直播间状态0:未开始,1:已删除,2:结束,3:预约,4:直播中,5:暂停
    if (this.consoleInfo?.activityStatus !== 2) {
      this.fetchRealtimeCommentLoop()
    }
  },
  beforeDestroy() {
    if (this.realtimeCommentLoopTimer) {
      clearTimeout(this.realtimeCommentLoopTimer)
    }
  },
  methods: {
    formatTime(time) {
      return moment(time).format('hh:mm A')
    },
    async fetchCommentList() {
      const res = await this.$serve.audience.chatPage({
        params: {
          page: this.page,
          pageSize: this.pageSize,
          activityId: this.activityId
        }
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.records = [...sortListByTime(res.records), ...this.records]
      // 当请求返回的数据量未超过分页尺寸的时候, 说明请求的数据已经全部加载完毕
      if (res.records.length < res.pageSize) {
        this.loaded = true
      }
      if (this.page === 1) {
        // 第一页的时候必然需要致页面到底部, 会造成滚动事件
        this.canCauseScrollEffect = true
      }
      this.keepListPosition()
      this.page = this.page + 1
      await this.fetchCommentListUntilFillContainer()
    },
    async fetchMuteList() {
      const res = await this.$serve.audience.muteList({
        params: {
          activityId: this.activityId
        }
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.muteList = res
    },
    // 实时获取评论
    async fetchRealtimeComment() {
      const records = [...this.records, ...this.moreRecords]
      const lastComment = records[records.length - 1]
      const res = await this.$serve.audience.chatLoop({
        params: {
          activityId: this.activityId,
          audienceChatId: lastComment?.audienceChatId
        }
      })
      if (res === this.$serve.FAIL) {
        return
      }
      if (this.isBottom()) {
        this.records.push(...sortListByTime(res.chats))
        this.scrollToBottom()
      } else {
        this.moreRecords.push(...sortListByTime(res.chats))
      }
    },
    // 轮训获取评论
    fetchRealtimeCommentLoop() {
      this.fetchRealtimeComment()
      if (this.realtimeCommentLoopTimer) {
        clearTimeout(this.realtimeCommentLoopTimer)
      }
      this.realtimeCommentLoopTimer = setTimeout(() => {
        this.fetchRealtimeCommentLoop()
      }, 1000 * 2)
    },
    // 评论列表滚动触发
    scroll: throttle(function () {
      if (this.canCauseScrollEffect) {
        this.canCauseScrollEffect = false
        return
      }
      if (this.isTop()) {
        // 还未加载完毕 且 在非加载中, 可以去请求数据
        if (!this.loaded && !this.pageLoading) {
          this.fetchCommentList()
        }
        return
      }
      if (this.isBottom()) {
        // 滚动的时候, 当触达底部的时候, 和点击更多消息处理一致
        this.more()
      }
    }, 500),
    // xx 条新消息点击处理
    more() {
      this.records = [...this.records, ...this.moreRecords]
      this.moreRecords = []
      this.clearComments()
      this.scrollToBottom()
    },
    // 评论列表是否到达顶部
    isTop() {
      return this.$refs.commentListRef.scrollTop < 10
    },
    // 评论列表是否到达底部
    isBottom() {
      const commentListRef = this.$refs.commentListRef
      if (!commentListRef) {
        return false
      }
      const commentListScrollH = commentListRef.scrollHeight
      const commentListScrollTopAndHeight =
        commentListRef.scrollTop + commentListRef.offsetHeight
      return commentListScrollH - commentListScrollTopAndHeight < 10
    },
    async scrollToBottom() {
      await this.$nextTick()
      const commentListRef = this.$refs.commentListRef
      if (commentListRef) {
        this.canCauseScrollEffect = true
        commentListRef.scrollTop = commentListRef.scrollHeight
      }
    },
    // 保持评论列表原始高度
    async keepListPosition() {
      if (!this.$refs.commentListRef) {
        return
      }
      // 获取列表内容原始高度
      const { scrollTop, scrollHeight } = this.$refs.commentListRef
      await this.$nextTick()
      // 获取列表渲染之后的高度
      const { scrollHeight: nowScrollHeight } = this.$refs.commentListRef
      if (scrollHeight !== nowScrollHeight) {
        const scrollHeightDiff = nowScrollHeight - scrollHeight
        this.$refs.commentListRef.scrollTop = scrollTop + scrollHeightDiff
      }
    },
    // 持续获取列表直到数据占满评论容器为止
    async fetchCommentListUntilFillContainer() {
      await this.$nextTick()
      if (!this.$refs.commentListRef || !this.$refs.commentListInnerRef) {
        return
      }
      const { offsetHeight } = this.$refs.commentListRef
      if (
        offsetHeight >= this.$refs.commentListInnerRef.scrollHeight &&
        !this.loaded
      ) {
        await this.fetchCommentList()
      }
    },
    // 点击发送内容
    async send() {
      if (this.chatLoading) {
        return
      }
      if (!this.content.trim()) {
        this.$message.warning('input can not be empty')
        return
      }
      const sendData = {
        content: this.content,
        audienceId: this.user.userId,
        nickname: this.user.nickname,
        activityId: this.activityId,
        // 身份：1-主播，2-观众
        identity: 1,
        avatar: this.user.avatar
      }
      const res = await this.$serve.audience.chatAdd({
        data: sendData
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.content = ''

      this.records.push(...this.moreRecords, {
        ...sendData,
        audienceChatId: res.audienceChatId,
        chatTime: +new Date() + ''
      })
      this.moreRecords = []
      this.scrollToBottom()
    },
    // 节点过多的时候，清除节点, 防止页面卡顿
    clearComments() {
      if (this.records.length >= this.pageSize) {
        // 只保留最后第一个分页的数据
        this.records = [...this.records].slice(-this.pageSize)
        this.page = 2
        this.loaded = false
      }
    },
    async enableChatAction() {
      const res = await this.$serve.console.chatSwitchPut({
        data: {
          enableChat: this.enableChat ? 1 : 0,
          activityId: this.activityId
        }
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$message.success('success')
    },
    async controlUser(record) {
      // isMute 是否禁言：0-否，1-是
      if (record.isMute === 1) {
        await this.unMuteUser(record)
      } else {
        await this.muteUser(record)
      }
    },
    // 禁言
    async muteUser(record) {
      record.muteing = true
      const res = await this.$serve.audience.chatMute({
        data: {
          audienceId: record.audienceId,
          activityId: this.activityId
        }
      })
      record.muteing = false
      if (res === this.$serve.FAIL) {
        return
      }
      record.isMute = 1
      this.muteList.push(record)
      this.$message.success('mute success')
    },
    // 解除禁言
    async unMuteUser(record) {
      record.muteing = true
      const res = await this.$serve.audience.unChatMute({
        params: {
          audienceId: record.audienceId,
          activityId: this.activityId
        }
      })
      record.muteing = false
      if (res === this.$serve.FAIL) {
        return
      }
      const currentMuteIndex = this.muteList.findIndex(
        (muteItem) => muteItem.audienceId === record.audienceId
      )
      this.muteList.splice(currentMuteIndex, 1)
      this.$message.success('unmute success')
    },
    // 删除评论
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async deleteRecord(record) {
      const { showTime, isMute, ...params } = record
      record.deleting = true
      const res = await this.$serve.audience.deleteChat({
        params: {
          activityId: this.activityId,
          ...params
        }
      })
      record.deleting = false
      if (res === this.$serve.FAIL) {
        return
      }
      const index = this.records.findIndex(
        (item) => item.audienceChatId === record.audienceChatId
      )
      this.records.splice(index, 1)
      this.$message.success('delete success')
      this.fetchCommentListUntilFillContainer()
    }
  }
}
</script>

<style lang="scss" scoped>
._chat-area-chat {
  padding-bottom: 64px;
  height: 100%;
  position: relative;
}
._chat-area-chat-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 64px;
  width: 100%;
}
._chat-area__main {
  flex: 1;
  position: relative;
}
._chat-area__records {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  transform: translateZ(0);
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
}
._chat-area__records-loading {
  padding: 8px;
  text-align: center;
}
._chat-area__records-empty {
  height: 200px;
  line-height: 200px;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
}
._chat-area__record {
  margin-top: 8px;
}
._chat-area__record-head {
  padding: 12px 0 20px;
  text-align: center;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}
._chat-area__record-main {
  display: flex;
}
._chat-area__record-main-inner {
  display: inline-flex;
  position: relative;
}
._chat-area__record-avatar {
  overflow: hidden;
  margin-right: 12px;
  i {
    width: 28px;
    height: 28px;
  }
}
._chat-area__record-content {
  width: 220px;
  padding: 8px 12px 12px;
  background: #292b3c;
  border-radius: 8px;
  font-size: 14px;
}
._chat-area__record-nickname {
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 4px;
  word-break: break-all;
  font-weight: bold;
}
._chat-area__record-msg {
  color: #ffffff;
  word-break: break-all;
  text-align: left;
}
._chat-area__record--self {
  ._chat-area__record-main {
    justify-content: flex-end;
  }
  ._chat-area__record-avatar {
    margin-right: 0;
    margin-left: 12px;
    order: 1;
  }
  ._chat-area__record-content {
    text-align: right;
  }
}
._chat-area__record--custom {
  ._chat-area__record-main-inner {
    position: relative;
    &:hover {
      ._chat-area__control {
        opacity: 0.9;
      }
    }
  }
  ._chat-area__control {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #ff4d7c;
    border-radius: 8px;
    opacity: 0;
    color: #fff;
    justify-content: space-around;
    align-items: center;
    transition: all 0.2s;
    span {
      flex: 1;
    }
    p {
      cursor: pointer;
      display: flex;
      align-items: center;
      i {
        margin-right: 8px;
      }
    }
  }
}
._chat-area__action {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  background: #292b3c;
  padding: 12px 24px 12px 16px;
  margin: 8px -16px -12px -16px;
}
._chat-area__action-avatar {
  margin-right: 16px;
  overflow: hidden;
  i {
    width: 28px;
    height: 28px;
  }
}
._chat-area__action-content {
  flex: 1;
  display: flex;
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.15);
  padding: 10px 0;
  transition: all 0.2s;
  input {
    flex: 1;
    border: none;
    outline: none;
    background: none;
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    caret-color: #ff0043;
    &::placeholder {
      color: rgba(255, 255, 255, 0.25);
    }
  }
  i {
    cursor: pointer;
  }
}
._chat-area__action-content--focus {
  box-shadow: 0px 1px 0px 0px $--color-primary;
}
._chat-area__more {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 4px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.15);
  padding: 8px 16px;
  border-radius: 30px;
  cursor: pointer;
}
._chat-area-chat__empty {
  padding: 42px 0 42px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.4);
  text-align: center;
  line-height: 20px;
  img {
    display: block;
    width: 80px;
    height: 80px;
    margin: 0 auto 16px;
  }
}
</style>
