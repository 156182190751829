var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"_chat-area-chat"},[_c('div',{staticClass:"_chat-area-chat-inner"},[_c('div',{ref:"commentListRef",staticClass:"_chat-area__records",on:{"scroll":_vm.scroll}},[_c('div',{ref:"commentListInnerRef",staticClass:"_chat-area__records-inner"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loaded),expression:"!loaded"}],staticClass:"_chat-area__records-loading"},[_c('i',{staticClass:"el-icon-loading",staticStyle:{"font-size":"20px"}})]),(_vm.formatRecords.length)?_vm._l((_vm.formatRecords),function(record){return _c('div',{key:record.audienceChatId,class:[
              '_chat-area__record',
              {
                '_chat-area__record--self': record.identity === 1,
                '_chat-area__record--custom': record.identity === 2
              }
            ]},[(record.showTime)?_c('div',{staticClass:"_chat-area__record-head"},[_vm._v(" "+_vm._s(_vm.formatTime(+record.chatTime))+" ")]):_vm._e(),_c('div',{staticClass:"_chat-area__record-main"},[_c('div',{staticClass:"_chat-area__record-main-inner"},[_c('div',{staticClass:"_chat-area__record-avatar"},[_c('el-avatar',{attrs:{"size":28,"src":record.avatar ||
                      (record.identity === 1
                        ? _vm.defaultAnchorAvatar
                        : _vm.defaultAudienceAvatar)}})],1),_c('div',{staticClass:"_chat-area__record-content"},[_c('div',{staticClass:"_chat-area__record-nickname"},[_vm._v(" "+_vm._s(record.nickname)+" ")]),_c('div',{staticClass:"_chat-area__record-msg"},[_c('p',[_vm._v(_vm._s(record.content))])])]),(record.identity === 2)?_c('div',{staticClass:"_chat-area__control"},[_c('span'),(record.isMute)?_c('p',{on:{"click":function($event){return _vm.controlUser(record)}}},[(record.muteing)?_c('i',{staticClass:"el-icon-loading",staticStyle:{"font-size":"20px"}}):_c('i',{staticClass:"ui-icon-unmute"}),_vm._v(" Unmute ")]):_c('p',{on:{"click":function($event){return _vm.controlUser(record)}}},[(record.muteing)?_c('i',{staticClass:"el-icon-loading",staticStyle:{"font-size":"20px"}}):_c('i',{staticClass:"ui-icon-mute"}),_vm._v(" Mute ")]),_c('span'),_c('p',{on:{"click":function($event){return _vm.deleteRecord(record)}}},[(record.deleting)?_c('i',{staticClass:"el-icon-loading",staticStyle:{"font-size":"20px"}}):_c('i',{staticClass:"ui-icon-delete2"}),_vm._v(" Delete ")]),_c('span')]):_vm._e()])])])}):_vm._e(),(!_vm.formatRecords.length && _vm.loaded)?_c('div',{staticClass:"_chat-area-chat__empty"},[_c('img',{attrs:{"src":require("@/assets/images/icon-chat-empty.png"),"alt":"empty"}}),_vm._v(" Join the conversation "),_c('br'),_vm._v(" Remember to be cool and play nice! ")]):_vm._e()],2)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.moreRecords.length > 0),expression:"moreRecords.length > 0"}],staticClass:"_chat-area__more",on:{"click":_vm.more}},[_vm._v(" "+_vm._s(_vm.moreRecords.length > 99 ? '99+' : _vm.moreRecords.length)+" messages ")])]),_c('div',{staticClass:"_chat-area__action"},[_c('div',{staticClass:"_chat-area__action-avatar"},[_c('el-avatar',{attrs:{"size":28,"src":_vm.user.avatar || _vm.defaultAnchorAvatar}})],1),_c('div',{class:[
        '_chat-area__action-content',
        {
          '_chat-area__action-content--focus': _vm.focus
        }
      ]},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.content),expression:"content",modifiers:{"trim":true}}],attrs:{"type":"text","maxlength":"200","placeholder":"Say something"},domProps:{"value":(_vm.content)},on:{"focus":function($event){_vm.focus = true},"blur":[function($event){_vm.focus = false},function($event){return _vm.$forceUpdate()}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.send.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.content=$event.target.value.trim()}}}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(!_vm.chatLoading),expression:"!chatLoading"}],staticClass:"ui-icon-send",on:{"click":_vm.send}}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.chatLoading),expression:"chatLoading"}],staticClass:"el-icon-loading",staticStyle:{"font-size":"20px"}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }