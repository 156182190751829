<template>
  <div class="page-templates">
    <div
      class="template-content"
      v-loading="loading"
      element-loading-background="rgba(0, 0, 0, 0.1)"
    >
      <div class="tab-title">My Templates</div>
      <div class="template-list">
        <div class="item empty" @click="toDecorate()">
          <div class="key">
            <div class="flex-key">
              <div class="plus">+</div>
              <div class="plus-text">Create a new template</div>
            </div>
          </div>
          <div class="value">Custom</div>
        </div>
        <div class="item" v-for="(item, index) in templateList" :key="index">
          <div class="key">
            <nested :modules="item.data" :fromTemplate="true" />
            <div class="mask-key"></div>
          </div>
          <div class="value">{{ item.name }}</div>
          <div class="mask-btn-box">
            <div class="btn-box">
              <div class="btn-use" @click="toDecorate(item, 'edit')">Edit</div>
              <y-button
                type="primary"
                class="btn-apply"
                size="small"
                @click="releaseTemplate(item)"
                :loading="item.loading"
                :disabled="canDecorate"
              >
                Apply
              </y-button>
            </div>
            <div class="btn-preview" @click="toPreivew(item)">
              <div class="icon-preivew"></div>
              <div class="text-preivew">Preview</div>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination-box">
        <el-pagination
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :page-sizes="[10, 20, 40, 60, 80]"
          layout="total, prev, pager, next, sizes"
          :total="total"
          background
        ></el-pagination>
      </div>
    </div>
    <TemplatePreview
      v-if="previewShow"
      :currentTemplate="currentTemplate"
      @closePreview="closePreview"
      :canDecorate="canDecorate"
    ></TemplatePreview>
  </div>
</template>
<script>
import Nested from '@/views/yolo-cast/designer/nested'
import TemplatePreview from '@/components/template-preview'

export default {
  props: {
    consoleInfo: Object,
    liveInfo: Object
  },
  components: {
    Nested,
    TemplatePreview
  },
  data() {
    return {
      activeTabs: 'my',
      currentPage: 1,
      pageSize: 20,
      templateList: [],
      total: 0,
      templatesDialogVisible: false,
      activityId: this.$route.params.id,
      previewShow: false, // 展示预览
      currentTemplate: {},
      hasHotTemplates: true,
      hasMyTemplates: true
    }
  },
  computed: {
    loading() {
      return this.$store.state.loading[
        '/overseas/activity-decoration-template/list'
      ]
    },
    previewUrl() {
      return process.env.VUE_APP_H5 + '/template/' + this.currentTemplate.id
    },
    // activityStatus: 直播间状态0:未开始,1:已删除,2:结束,3:预约,4:直播中,5:暂停
    // 直播中和已结束不能修改装修
    canDecorate() {
      const consoleInfoActivityStatus = this.consoleInfo?.activityStatus
      const liveInfoActivityStatus = this.liveInfo?.activityStatus
      const activityStatus = liveInfoActivityStatus ?? consoleInfoActivityStatus
      switch (activityStatus) {
        case 0:
          return false;
        case 1:
          return false;
        case 2:
          return true
        case 3:
          return false;
        case 4:
          return true
        case 5:
          return true
        default:
          return false;
      }
    },
  },
  created() {
    console.log(this.consoleInfo, 333)
    console.log(this.liveInfo, 222)
    this.getTempData()
  },
  methods: {
    async getTempData() {
      const res = await this.$serve.activity.getTemplateList({
        params: {
          purpose: this.activeTabs === 'hot' ? 3 : 1,
          currentPage: this.currentPage,
          pageSize: this.pageSize
        }
      })
      if (res === this.$serve.FAIL) {
        return false
      }
      this.templateList = []
      res.records.forEach((item) => {
        item.data = JSON.parse(item.data)
        this.templateList.push({
          ...item,
          loading: false
        })
      })

      this.total = res.totalCount
    },
    async changeTempTabs() {
      this.currentPage = 1
      await this.getTempData()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getTempData()
    },
    handleSizeChange(val) {
      this.currentPage = 1
      this.pageSize = val
      this.getTempData()
    },
    async toDecorate(item) {
      if (item) {
        this.$router.push(`/yolo-cast-design?templateId=${item.id}&edit=true`)
      } else {
        this.$router.push(`/yolo-cast-design?edit=true`)
      }
    },
    closePreview() {
      this.previewShow = false
      this.currentTemplate = {}
    },
    toPreivew(item) {
      this.previewShow = true
      this.currentTemplate = item
    },
    async releaseTemplate(item) {
      item.loading = true
      const res = await this.$serve.activity.applyDesigner({
        data: {
          type: 1,
          data: JSON.stringify(item.data),
          activityId: this.$route.params.id
        }
      })
      item.loading = false
      if (res === this.$serve.FAIL) {
        return false
      }
      this.$message({
        message: 'Apply Success！',
        type: 'success',
        duration: 1000
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-title {
  height: 48px;
  line-height: 48px;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  padding-left: 24px;
}
.template-content {
  .template-list {
    padding: 25px 0 72px 0;
    overflow: auto;
    height: calc(100vh - 121px);
    &::-webkit-scrollbar {
      width: 6px;
      background: transparent;
    }
    &::-webkit-scrollbar-track {
      border-radius: 0;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(102, 102, 102, 1);
      transition: all 0.2s;
      border-radius: 2px;
    }
    .item {
      width: 180px;
      margin-left: 24px;
      margin-bottom: 24px;
      position: relative;
      height: 348px;
      float: left;
      .key {
        width: 100%;
        height: 320px;
        border-radius: 4px;
        box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.12);
        box-sizing: border-box;
        overflow: hidden;
        background: #ffffff;
        .mask-key {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 10;
        }
        .result-drag {
          zoom: 0.48;
        }
      }
      .value {
        width: 100%;
        max-height: 32px;
        word-break: break-all;
        text-align: left;
        font-size: 14px;
        color: #ffffff;
        margin-top: 12px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      &:hover {
        .mask-btn-box {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .mask-btn-box {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 320px;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 1000;
        .btn-box {
          .btn-apply {
            width: 112px;
            border-radius: 4px;
          }
          .btn-use {
            width: 112px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            border-radius: 4px;
            border: 1px solid #ffffff;
            font-size: 14px;
            color: #ffffff;
            cursor: pointer;
            margin-bottom: 16px;
          }
        }
        .btn-preview {
          position: absolute;
          bottom: 13px;
          left: 53px;
          display: flex;
          align-items: center;
          cursor: pointer;
          .icon-preivew {
            width: 14px;
            height: 14px;
            background: url(~@assets/images/icon-preivew.png) no-repeat;
            background-size: 100%;
            margin-right: 8px;
          }
          .text-preivew {
            font-size: 12px;
            font-weight: 400;
            color: #ffffff;
            line-height: 14px;
          }
        }
      }
    }
    .empty {
      cursor: pointer;
      .key {
        background: rgba(41, 43, 60, 1);
        border-radius: 4px;
        border: 1px dashed rgba(255, 255, 255, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0;
        .plus {
          font-size: 38px;
          color: rgba(255, 255, 255, 0.6);
          margin-bottom: 2px;
          text-align: center;
        }
        .plus-text {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.6);
          text-align: center;
        }
      }
    }
  }
  .pagination-box {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    text-align: right;
    padding-top: 15px;
    background-color: rgba(30, 31, 43, 1);
    z-index: 1000;
    ::v-deep .el-pagination__total {
      color: #ffffff;
    }
  }
}
</style>
