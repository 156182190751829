const renderItem = {
  methods: {
    // 动态渲染组件方法
    renderComponet(h, component, field, model) {
      const {
        attributes: { children = [], ...restAttributes },
        events,
        formItemProps: { prop }
      } = field
      return (
        <component
          {...{ attrs: restAttributes }}
          {...{ on: events }}
          v-model={model[prop]}
        >
          {children.map((item, i) => (
            <template key={i} slot={item.slot}>
              {item.render ? item.render?.call(this, h) : item.content}
            </template>
          ))}
        </component>
      )
    },
    renderSwitch(h, field, model) {
      return this.renderComponet(h, 'y-switch', field, model)
    },
    renderCheckbox(h, field, model) {
      return this.renderComponet(h, 'y-checkbox', field, model)
    },
    renderTime(h, field, model) {
      return this.renderComponet(h, 'y-time-picker', field, model)
    },
    renderDatetPicker(h, field, model) {
      return this.renderComponet(h, 'y-date-picker', field, model)
    },
    renderDate(h, field, model) {
      field.attributes.type = 'date'
      return this.renderComponet(h, 'y-date-picker', field, model)
    },
    renderDates(h, field, model) {
      field.attributes.type = 'Dates'
      return this.renderComponet(h, 'y-date-picker', field, model)
    },
    renderDateyear(h, field, model) {
      field.attributes.type = 'year'
      return this.renderComponet(h, 'y-date-picker', field, model)
    },
    renderDatemonth(h, field, model) {
      field.attributes.type = 'month'
      return this.renderComponet(h, 'y-date-picker', field, model)
    },
    renderDateweek(h, field, model) {
      field.attributes.type = 'week'
      return this.renderComponet(h, 'y-date-picker', field, model)
    },
    renderDatetime(h, field, model) {
      field.attributes.type = 'datetime'
      return this.renderComponet(h, 'y-date-picker', field, model)
    },
    renderDaterange(h, field, model) {
      field.attributes.type = 'daterange'
      return this.renderComponet(h, 'y-date-picker', field, model)
    },
    renderDateMonthrange(h, field, model) {
      field.attributes.type = 'monthrange'
      return this.renderComponet(h, 'y-date-picker', field, model)
    },
    renderDatetimerange(h, field, model) {
      field.attributes.type = 'datetimerange'
      return this.renderComponet(h, 'y-date-picker', field, model)
    },
    renderTextarea(h, field, model) {
      field.attributes.type = 'textarea'
      return this.renderComponet(h, 'y-input', field, model)
    },
    renderInput(h, field, model) {
      return this.renderComponet(h, 'y-input', field, model)
    },
    renderPassword(h, field, model) {
      field.attributes.type = 'password'
      return this.renderComponet(h, 'y-input', field, model)
    },
    renderNumber(h, field, model) {
      field.attributes.type = 'number'
      return this.renderComponet(h, 'y-input', field, model)
    },
    renderInputNumber(h, field, model) {
      return this.renderComponet(h, 'y-input-number', field, model)
    },
    renderRate(h, field, model) {
      return this.renderComponet(h, 'y-rate', field, model)
    },
    renderColorPicker(h, field, model) {
      return this.renderComponet(h, 'y-color-picker', field, model)
    },
    renderTransfer(h, field, model) {
      return this.renderComponet(h, 'y-transfer', field, model)
    },
    renderSlider(h, field, model) {
      return this.renderComponet(h, 'y-slider', field, model)
    },
    renderCascader(h, field, model) {
      return this.renderComponet(h, 'y-cascader', field, model)
    },
    renderSelect(h, field, model) {
      const {
        attributes: { options = [], ...restAttributes } = {},
        events,
        formItemProps: { prop }
      } = field
      return (
        <y-select
          {...{ attrs: restAttributes }}
          {...{ on: events }}
          v-model={model[prop]}
          options={options}
        >
          {options.map((attrs, i) => (
            <y-option key={i} {...{ attrs }}></y-option>
          ))}
        </y-select>
      )
    },
    renderCheckboxGroup(h, field, model) {
      const {
        attributes: { options = [], ...restAttributes } = {},
        events,
        formItemProps: { label, prop }
      } = field
      if (!Array.isArray(model[prop])) {
        return (
          <span style={{ color: 'red' }}>
            请设置{label}的默认值为数组类型的数据
          </span>
        )
      }
      return (
        <y-checkbox-group
          {...{ attrs: restAttributes }}
          {...{ on: events }}
          v-model={model[prop]}
          options={options}
        >
          {options.map(({ render, ...attrs }, i) => (
            <y-checkbox key={i} {...{ attrs }}>
              {render ? render?.call(this, h) : attrs.content}
            </y-checkbox>
          ))}
        </y-checkbox-group>
      )
    },
    renderRadio(h, field, model) {
      const {
        attributes,
        events,
        formItemProps: { prop }
      } = field
      return (
        <y-radio
          {...{ attrs: attributes }}
          {...{ on: events }}
          v-model={model[prop]}
        >
          {'  '}
        </y-radio>
      )
    },
    renderRadioGroup(h, field, model) {
      const {
        attributes: { options = [], ...restAttributes } = {},
        events,
        formItemProps: { prop }
      } = field
      return (
        <y-radio-group
          {...{ attrs: restAttributes }}
          {...{ on: events }}
          v-model={model[prop]}
        >
          {options.map((attrs, i) => (
            <y-radio key={i} {...{ attrs }}></y-radio>
          ))}
        </y-radio-group>
      )
    }
  }
}

export default renderItem
