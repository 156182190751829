import formProps from '@pro/pro-form/src/config/form-props'
import proFormProps from '@pro/pro-form/src/config/props'
import pagination from '@pro/pro-table/src/config/pagination'
import tableProps from '@pro/pro-table/src/config/table-props'
import proTableProps from '@pro/pro-table/src/config/props'

import listPageFormProps from './form-props'
import listPageTableProps from './table-props'

import { getUndefinedProps } from '../utils/tools'

/**
 * yProListPageProps
 * y-pro-list-page 属性
 * 兼容formProps、proFormProps、pagination、tableProps、proTableProps
 * 属性发生冲突时, 以 table pagination form 属性顺序为主, 合并到props
 * listPageFormProps 和 listPageTableProps 使用getUndefinedProps格式化是为了后面formatProps可使用undefined过滤且再forEach的时候不被undefined覆盖掉
 */
const props = {
  ...formProps,
  ...proFormProps,
  ...getUndefinedProps(listPageFormProps),
  ...pagination,
  ...tableProps,
  ...proTableProps,
  ...getUndefinedProps(listPageTableProps),
  pageLoading: false
}

export default props
