import YLoading from './loading'
import YMessageBox from './message-box'
import YNotification from './notification'
import YMessage from './message'

const files = require.context('./', true, /^\.\/.+\/index.js$/)

const components = files.keys().reduce((pre, next) => {
  const file = files(next).default
  const name = file?.$name || file?.name
  if (name?.startsWith('y-')) {
    return [...pre, [name, file.$depends, file]]
  }
  return [...pre]
}, [])

export const install = function (Vue) {
  Vue.prototype.$loading = YLoading.service
  Vue.prototype.$msgbox = YMessageBox
  Vue.prototype.$alert = YMessageBox.alert
  Vue.prototype.$confirm = YMessageBox.confirm
  Vue.prototype.$prompt = YMessageBox.prompt
  Vue.prototype.$notify = YNotification
  Vue.prototype.$message = YMessage

  Vue.use(YLoading.directive)

  components.forEach(([name, depends, component]) => {
    // 将依赖的原始组件重新注册一遍, 即可使用原始组件
    if (depends) {
      depends.forEach((depend) => {
        Vue.component(depend.name, depend)
      })
    }
    Vue.component(name, component)
  })
}

export default {
  install
}

export const Loading = YLoading
export const MessageBox = YMessageBox
export const Notification = YNotification
export const Message = YMessage
