<template>
  <div class="comment-edit">
    <div class="comment-set">
      <span>管理评论请前往</span>
      <a @click="toLiveSet">直播设置</a>
    </div>
    <div class="bg-box">
      <div class="text">组件背景</div>
      <div class="seelct-source-radio">
        <el-radio v-model="data.stylesheet.background.type" label="default">
          默认
        </el-radio>
        <el-radio v-model="data.stylesheet.background.type" label="image">
          图片
        </el-radio>
      </div>
      <div class="tips" v-if="data.stylesheet.background.type === 'image'">
        <div class="title">
          支持JPG、JPEG、PNG，大小不超过2MB；尺寸比例不对时，图片将被裁剪或拉伸以铺满画面
        </div>
      </div>
      <div
        class="add-bg-box"
        v-if="data.stylesheet.background.type === 'image'"
        @click="changeCover('comBg')"
      >
        <div class="has-img" v-if="data.stylesheet.background.url">
          <img :src="data.stylesheet.background.url" alt="" />
          <div class="change-img">更换图片</div>
        </div>
        <div class="no-img" v-else>
          <div class="key">+</div>
          <div class="value">上传图片</div>
        </div>
      </div>
    </div>
    <div class="fix-box">
      <div class="fix-select">
        <div class="key">弹幕</div>
        <div class="value">
          <el-switch v-model="data.barrageValue"></el-switch>
        </div>
      </div>
      <div class="text">
        开启后，观众进入直播间后所有历史评论均会以弹幕形式展示；关闭后，仅显示当前发送的弹幕评论。
      </div>
    </div>
    <div class="fix-box" v-if="data.barrageValue">
      <div class="fix-select">
        <div class="key">历史弹幕</div>
        <div class="value">
          <el-switch v-model="data.historyBarrageValue"></el-switch>
        </div>
      </div>
      <div class="text">
        当评论过多时建议关闭历史弹幕显示，否则可能干扰当前弹幕评论展示。
      </div>
    </div>
    <div class="fix-box">
      <div class="fix-select">
        <div class="key">发红包按钮</div>
        <div class="value">
          <el-switch v-model="data.redpackValue"></el-switch>
        </div>
      </div>
    </div>
    <div class="redpack-box" v-if="data.redpackValue">
      <div class="text">按钮样式</div>
      <div class="select-redpack">
        <div
          class="redpack-item"
          :class="{ active: data.activityId === index }"
          v-for="(item, index) in redpackUrlList"
          :key="index"
          @click="
            data.redpackUrl = item.url
            data.activityId = index
          "
        >
          <div class="border-mask" v-show="data.activityId === index"></div>
          <img :src="item.url" alt="" v-if="!item.sm" />
          <img
            :src="item.url"
            alt=""
            v-if="item.sm"
            style="width: 60px; height: 50px"
          />
          <img
            @click="delRedPackCover(item.id)"
            v-if="!item.isDefault"
            class="del-icon"
            src="~@assets/images/designer/shanchu@2x.png"
            alt=""
          />
        </div>
        <div class="add-bg-box">
          <div class="no-img" @click="changeCover('redPackBg')">
            <div class="key">+</div>
            <div class="value">上传图片</div>
          </div>
        </div>
      </div>
      <div class="tips">
        <div class="title">
          建议：100*100 像素，JPG、JPEG、PNG格式，大小不超过500K
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { branchUrl } from '@config/env'
import redImg from '@/assets/images/designer/1.png'

export default {
  props: {
    data: {
      Type: Object,
      default: {}
    }
  },
  data() {
    return {
      redpackUrlList: [
        {
          url: redImg,
          isDefault: true
        },
        {
          url: 'https://static.yunxi.tv/yunxi/static/redpack-default/defult_hongbao_7@2x.png',
          isDefault: true
        },
        {
          url: 'https://static.yunxi.tv/yunxi/static/redpack-default/defult_hongbao_2@2x.png',
          isDefault: true
        },
        {
          url: 'https://static.yunxi.tv/yunxi/static/redpack-default/defult_hongbao_3@2x.png',
          isDefault: true
        },
        {
          url: 'https://static.yunxi.tv/yunxi/static/redpack-default/defult_hongbao_4@2x.png',
          isDefault: true,
          sm: true // 特殊处理：60*50
        },
        {
          url: 'https://static.yunxi.tv/yunxi/static/redpack-default/defult_hongbao_5@2x.png',
          isDefault: true
        },
        {
          url: 'https://static.yunxi.tv/yunxi/static/redpack-default/defult_hongbao_6@2x.png',
          isDefault: true
        }
      ],
      materialList: '', // 获取素材库分组列表
      imageGroupList: '', // 获取素材库图片
      visibleMediaCover: false, // 封面图处理
      visibleMediaShowCover: false, // 封面图处理
      imgType: '',
      cut: false,
      w: '', // 宽
      h: '', // 高
      limt: '2M' // 限制大小
    }
  },
  created() {
    // this.getRedPackCover();
  },
  methods: {
    toLiveSet() {
      const routeData = this.$router.resolve({
        path: '/live/activity/info',
        query: {
          liveId: this.$route.query.liveId,
          activeName: 'consoleCenter' // 表示从直播设置过来
        }
      })
      window.open(branchUrl + routeData.href, '_blank')
    },
    uploadSuccessBg(resultList) {
      // 本地上传
      if (resultList[0].fromLocation) {
        this.data.stylesheet.background.url = resultList[0].url
        this.data.stylesheet.style.backgroundImage = `url(${resultList[0].url})`
      } else {
        // 媒体库选择
        this.data.stylesheet.background.url = resultList[0].material.url
        this.data.stylesheet.style.backgroundImage = `url(${resultList[0].material.url})`
      }
    },
    async uploadSuccessRedPackage(resultList) {
      // 本地上传
      let url
      if (resultList[0].fromLocation) {
        url = resultList[0].url
      } else {
        // 媒体库选择
        url = resultList[0].material.url
      }
      await this.saveRedPackCover(url)
      await this.getRedPackCover()
    },
    uploadSuccessCover(data, resultList) {
      if (data) {
        // 评论背景
        if (this.imgType === 'comBg') {
          this.uploadSuccessBg(resultList)
        }
        // 红包图片
        if (this.imgType === 'redPackBg') {
          this.uploadSuccessRedPackage(resultList)
        }
      }
      this.visibleMediaCover = false
      let timer = setTimeout(() => {
        clearTimeout(timer)
        timer = null
        this.visibleMediaShowCover = false
      }, 200)
    },
    changeCover(imgType) {
      this.imgType = imgType
      if (imgType === 'comBg') {
        this.cut = false
        this.limt = '2M'
      }
      if (imgType === 'redPackBg') {
        this.cut = true
        this.limt = '500K'
      }
      this.visibleMediaShowCover = true
      let timer = setTimeout(() => {
        clearTimeout(timer)
        timer = null
        this.visibleMediaCover = true
      }, 200)
    },
    // 保存上传的红包封面
    async saveRedPackCover(url) {
      console.log(url)
      // const res = await saveRedPack({
      //   imgUrl: url,
      //   liveId: this.$route.query.liveId
      // })
      // if (res.fail()) {
      //   this.$message({
      //     message: res.message,
      //     type: 'error',
      //     duration: 1000
      //   })
      //   return false
      // }
    },
    // 获取上传的红包封面
    async getRedPackCover(url) {
      console.log(url)
      // const res = await getRedPack({
      //   liveId: this.$route.query.liveId
      // })
      // if (res.fail()) {
      //   this.$message({
      //     message: res.message,
      //     type: 'error',
      //     duration: 1000
      //   })
      //   return false
      // }
      // const imgList = []
      // if (res.data && res.data.list.length > 0) {
      //   res.data.list.forEach((item) => {
      //     imgList.push({
      //       url: item.imgUrl,
      //       isDefault: false,
      //       id: item.id
      //     })
      //   })
      // }
      // this.redpackUrlList = [...this.redpackUrlList.splice(0, 6), ...imgList]
    },
    // 删除红包封面
    delRedPackCover(id) {
      console.log(id)
      // this.$confirm('确定删除该红包样式？', '提示', {
      //   type: 'warning'
      // }).then(async () => {
      //   const res = await delRedPack({
      //     id: id
      //   })
      //   if (res.fail()) {
      //     this.$message({
      //       message: res.message,
      //       type: 'error',
      //       duration: 1000
      //     })
      //     return false
      //   }
      //   this.getRedPackCover()
      // })
    }
  }
}
</script>
<style lang="scss">
@import './edit.scss';
</style>
