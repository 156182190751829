<template>
  <div class="y-pro-form" :style="proFormStyle">
    <slot name="before-addon"></slot>
    <y-form
      @submit.native.prevent
      ref="formRef"
      v-bind="formatFormProps"
      :style="formStyle"
    >
      <!-- 栅格布局组件显示 -->
      <template v-if="grid">
        <y-row :gutter="20" :class="{ 'border-row': border }">
          <grid-form-item
            v-for="field in flexFormatFields"
            :key="field.formItemProps.prop"
            :formItemProps="field.formItemProps"
          >
            <dynamic-form-item
              :field="field"
              :model="formatFormProps.model"
            ></dynamic-form-item>
          </grid-form-item>
        </y-row>
      </template>

      <!-- 分组显示 -->
      <template v-else-if="formatFormProps.inline && groups">
        <div
          :class="{ 'border-group': border }"
          v-for="(inlineFields, i) in flexInlineGroupFormatFields"
          :key="i"
        >
          <base-form-item
            v-for="field in inlineFields"
            :key="field.formItemProps.prop"
            :formItemProps="field.formItemProps"
          >
            <dynamic-form-item
              :field="field"
              :model="formatFormProps.model"
            ></dynamic-form-item>
          </base-form-item>
        </div>
      </template>

      <!-- 加边框组件显示 -->
      <template v-else-if="border">
        <div
          :class="['border-items', { 'inline-items': formatFormProps.inline }]"
        >
          <border-form-item
            v-for="field in flexFormatFields"
            :key="field.formItemProps.prop"
            :formItemProps="field.formItemProps"
          >
            <dynamic-form-item
              :field="field"
              :model="formatFormProps.model"
            ></dynamic-form-item>
          </border-form-item>
        </div>
      </template>

      <!-- 基本显示 -->
      <template v-else>
        <base-form-item
          v-for="field in flexFormatFields"
          :key="field.formItemProps.prop"
          :formItemProps="field.formItemProps"
        >
          <dynamic-form-item
            :field="field"
            :model="formatFormProps.model"
          ></dynamic-form-item>
        </base-form-item>
      </template>

      <!-- 表单操作按钮 -->
      <y-form-item class="submitter" v-if="submitter" :style="submitterStyle">
        <div class="submitter-btns">
          <y-button
            :type="
              (submitter.submitButtonProps &&
                submitter.submitButtonProps.type) ||
              'primary'
            "
            @click="submit('formRef')"
            v-bind="submitter.submitButtonProps"
          >
            {{ submitter.submitText || '提交' }}
          </y-button>
          <y-button
            @click="reset('formRef')"
            v-bind="submitter.resetButtonProps"
          >
            {{ submitter.resetText || '重置' }}
          </y-button>
          <span
            v-if="flexNum !== 0"
            class="submitter-flexible"
            @click="changeOpen"
          >
            <i
              :class="flexOpen ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
            ></i>
            {{ flexOpen ? '收起' : '展开' }}
          </span>
        </div>
      </y-form-item>
    </y-form>
    <slot name="after-addon"></slot>
  </div>
</template>

<script>
import Form from '@base/form'
import FormItem from '@base/form-item'
import Button from '@base/button'
import Row from '@base/row'

import BaseFormItem from './coms/base-form-item'
import BorderFormItem from './coms/border-form-item.vue'
import GridFormItem from './coms/grid-form-item.vue'
import DynamicFormItem from './coms/dynamic-form-item'

import formProps from './config/form-props'
import props from './config/props'

import formatFormProps from './utils/formatFormProps'
import formatFields from './utils/formatFields'

export default {
  name: 'y-pro-form',
  components: {
    [Form.$name]: Form,
    [FormItem.$name]: FormItem,
    [Row.name]: Row,
    [Button.name]: Button,
    BaseFormItem,
    BorderFormItem,
    GridFormItem,
    DynamicFormItem
  },
  props: {
    /**
     * 这里把formProps的属性也作为yProFormProps, 是为了统一el-form原生属性, 方便开发者快速上手
     * formProps和props冲突的时候以props属性为准
     */
    ...formProps,
    ...props
  },
  data() {
    return {
      flexOpen: this.defaultFlexOpen
    }
  },
  computed: {
    submitterStyle() {
      if (typeof this.submitter !== 'object') {
        return {}
      }
      const { align, style } = this.submitter
      const newStyle = {
        textAlign: align || 'left',
        ...style
      }
      return newStyle
    },
    formatFormProps() {
      return formatFormProps(this.$props)
    },
    formatFields() {
      return formatFields(this.fields)
    },
    /**
     * 伸缩的表单域配置
     */
    flexFormatFields() {
      if (this.flexNum === 0 || this.flexOpen) {
        return this.formatFields
      }
      return this.formatFields.slice(0, this.flexNum)
    },
    /**
     * 分组行内布局
     */
    inlineGroupFormatFields() {
      if (!this.formatFormProps.inline) {
        return this.formatFields
      }
      if (!this.groups) {
        return this.formatFields
      }
      const inlineGroupFormatFields = this.groups.reduce((pre, next) => {
        // 要排除掉不在fields中的元素
        const filterItems = next.filter((item) =>
          this.formatFields
            .map((field) => field.formItemProps.prop)
            .includes(item)
        )

        return [
          ...pre,
          filterItems.map((item) => {
            const current = this.formatFields.find(
              (field) => field.formItemProps.prop === item
            )
            return current
          })
        ]
      }, [])
      return inlineGroupFormatFields
    },
    /**
     * 伸缩行内布局
     */
    flexInlineGroupFormatFields() {
      if (this.flexNum === 0 || this.flexOpen) {
        return this.inlineGroupFormatFields
      }

      return this.inlineGroupFormatFields.slice(0, this.flexNum)
    }
  },
  methods: {
    getFormInstance() {
      return this.$refs.formRef
    },
    submit() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.$emit('submit', this.formatFormProps.model)
        } else {
          return false
        }
      })
    },
    reset() {
      this.$refs.formRef.resetFields()
      this.$emit('reset', this.formatFormProps.model)
    },
    changeOpen() {
      this.flexOpen = !this.flexOpen
    }
  }
}
</script>

<style scoped lang="scss">
.border-row {
  padding-left: 1px;
  padding-top: 1px;
  margin: 0 !important;
  .grid-form-item {
    border: 1px solid #eaeefb;
    margin-left: -1px;
    margin-top: -1px;
    padding-top: 22px;
  }

  & + .submitter {
    margin-top: 22px;
  }
}

.border-group {
  border: 1px solid #eaeefb;
  padding-top: 10px;
  &:not(:first-child) {
    margin-top: -1px;
  }
  & + .submitter {
    margin-top: 20px;
  }
}

.border-form-item {
  &:not(:first-child) {
    margin-top: -1px;
  }
}

.border-items {
  & + .submitter {
    margin-top: 20px;
  }
}

.inline-items {
  display: inline-block;
  .border-form-item {
    display: inline-block;
  }

  & + .submitter {
    margin-left: 20px;
  }
}

.inline-items.border-items {
  padding-left: 1px;
  .border-form-item {
    margin-left: -1px;
  }
}

.submitter-flexible {
  margin-left: 10px;
  color: #606266;
  cursor: pointer;
}
</style>
