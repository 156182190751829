<template>
  <div class="album-dialog">
    <el-dialog
      :visible="dialogVisible"
      width="604px"
      top="5vh"
      @opened="handleOpen"
      :before-close="handleBeforeClose"
      @click="cancel($event)"
      :modal-append-to-body="false"
      :title="title"
    >
      <div class="list-box">
        <div class="left">
          <div
            class="left-item overout"
            v-for="(item, index) in leftList"
            :key="item.groupId"
            @click="choice(item, index)"
            :class="{ active: item.active }"
          >
            <span class="icon"></span>
            <span class="text">{{ item.name }}</span>
          </div>
          <div class="pagination-box">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-size="currentPageSize"
              layout="total, prev, pager, next"
              :total="total"
              background
            >
            </el-pagination>
          </div>
          <div class="empty-box" v-if="leftList.length === 0">No Data</div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleBeforeClose" size="small">cancel</el-button>
        <el-button
          type="primary"
          size="small"
          @click="handleSubmit"
          :disabled="resultListEmit.length === 0"
          >Done</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      // 弹窗显示
      type: Boolean,
      default: false,
    },
    title: {
      // 标题
      type: String,
      default: "",
    },
    resultList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    getAlbum: {
      type: Function,
    },
    getImageById: {
      type: Function,
    },
  },
  watch: {
    visible(newV) {
      this.dialogVisible = newV;
    },
    currentPage() {
      this.getliveList();
    },
  },
  data() {
    return {
      dialogVisible: false,
      currentPage: 1,
      leftList: [], // 相册列表
      resultListEmit: [], // 相册列表
      total: 0,
      currentPageSize: 6,
    };
  },
  async created() {
    let timer = setTimeout(() => {
      clearTimeout(timer);
      timer = null;
      this.total = 1;
      this.leftList = [{
        groupId: '111111',
        name: 'Demo1',
        albumId: '222222',
      }]
    })
    // console.log(this.resultList);
    // this.resultListEmit = JSON.parse(JSON.stringify(this.resultList));
    // await this.getliveList();
  },
  methods: {
    handleOpen() {
      //
    },
    handleBeforeClose() {
      this.$emit("updateAlbum", false);
    },
    async handleSubmit() {
      // console.log(this.resultListEmit);
      // let res = await this.getImageById({
      //   status: 0,
      //   groupId: this.resultListEmit[0].groupId,
      //   page: 1,
      //   pageSize: 100000,
      // });
      // if (res.fail()) {
      //   this.$message({
      //     message: res.message,
      //     type: "error",
      //     duration: 1000,
      //   });
      //   return false;
      // }
      // let albumList = [];
      // if (Array.isArray(res.data)) {
      //   res.data.forEach((item) => {
      //     albumList.push(item.materialImage.imageUrl);
      //   });
      // }
      let albumList = [
        'https://yxgo-test.oss-cn-hangzhou.aliyuncs.com/material/image/408596115093458944/tSe8QbdrzZYFpwWSjTtdETNppmEYC3ZE!320',
        'https://yxgo-test.oss-cn-hangzhou.aliyuncs.com/material/image/408596115093458944/8rKtzma7j3dFBrjnncabjwtfCZcw5sWW!320',
        'https://yxgo-test.oss-cn-hangzhou.aliyuncs.com/material/image/408596115093458944/MCX3YT3PBwTGa5YxFdJTxFDmcDysjGsx!320',
        'https://yxgo-test.oss-cn-hangzhou.aliyuncs.com/material/image/408596115093458944/FGeeX4nwb8sesJPYz7f6xTi27aP5Wzij!320',
        'https://yxgo-test.oss-cn-hangzhou.aliyuncs.com/material/image/408596115093458944/d6PNtzGj45iYQxeWZXCEEe5hH7rdQDm3!320'
      ];
      this.$emit("updateAlbum", true, this.resultListEmit, albumList);
    },
    cancel(event) {
      this.$emit("cancel");
      event.stopPropagation();
      return false;
    },
    // 切换每页的条数
    handleSizeChange(val) {
      console.log(val)
      // console.log(`每页 ${val} 条`);
    },
    // 切换分页
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    choice(item, index) {
      console.log(index);
      if (item.active) {
        item.active = false;
        this.resultListEmit = [];
      } else {
        this.leftList.forEach((item) => {
          item.active = false;
        });
        item.active = true;
        this.resultListEmit = [item];
      }
    },
    // 对右上角重新赋值
    resetIndex() {
      for (let r = 0; r < this.leftList.length; r++) {
        for (let c = 0; c < this.resultListEmit.length; c++) {
          if (this.leftList[r].groupId === this.resultListEmit[c].groupId) {
            this.leftList[r].active = true;
            break;
          }
        }
      }
    },
    async getliveList() {
      // this.currentPageSize = this.currentPage == 1 ? 5 : 6;
      const res = await this.getAlbum({
        materialType: 1,
        page: this.currentPage,
        pageSize: this.currentPageSize,
      });
      if (res.fail()) {
        this.$message({
          message: res.message,
          type: 'error',
          duration: 1000,
        });
        return false;
      }
      this.total = res.result.total;
      this.leftList = res.result.data || [];
      console.log(this.resultListEmit);
      if (this.resultListEmit.length > 0) {
        this.resetIndex();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .album-dialog {
    .list-box {
      width: 100%;
      height: 372px;
      .left {
        width: 100%;
        height: 372px;
        position: relative;
        overflow: auto;
        .left-item {
          padding: 0 24px;
          width: 100%;
          height: 48px;
          line-height: 48px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: $-color-black-80;
          cursor: pointer;
          display: flex;
          align-items: center;
          &:hover {
            background: #F0F6FF;
          }
          &.active {
            background: rgba(0, 0, 0, 0.04);
          }
          .icon {
            width: 20px;
            height: 20px;
            box-sizing: border-box;
            margin-right: 8px;
            background: url(~@assets/images/designer/folder@2x.png) no-repeat;
            background-size: 100%;
          }
          .text {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: $-color-black-80;
          }
        }
        .pagination-box {
          position: absolute;
          bottom: 20px;
          right: 15px;
        }
      }
    }
    .empty-box {
      line-height: 372px;
      text-align: center;
    }
    ::v-deep .el-dialog__body {
      padding: 0;
    }
    ::v-deep .el-dialog__header {
      color: red;
    }
  }
</style>
