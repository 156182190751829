<template>
  <div class="image-article-edit">
    <el-tooltip class="item" effect="dark" content="图片" placement="top">
      <div class="img-diy" @click="choiceMedia">
        <i class="w-e-icon-image-diy"></i>
      </div>
    </el-tooltip>
    <div id="edit"></div>
    <div class="calc-num">字数统计 {{ htmlTotal.length }}/10000</div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      Type: Object,
      default: {},
    },
  },
  data() {
    return {
      editor: null,
      visibleMedia: false,
      visibleMediaShow: false,
      htmlTotal: "",
      materialList: '',
      relationList: '',
    };
  },
  mounted() {
    // let that = this;
    // this.$nextTick(() => {
    //   this.editor = new E("#edit");
    //   this.editor.config.height = 500;
    //   this.editor.config.showFullScreen = false;
    //   this.editor.config.excludeMenus = [
    //     "emoticon",
    //     "video",
    //     "screen",
    //     "code",
    //     "quote",
    //     "indent",
    //     "todo",
    //   ];
    //   this.editor.config.onchange = function (html) {
    //     // 监控变化，同步更新到 textarea
    //     let regText = /<\/?.+?>/g;
    //     let regImg = /<img[^>]+>/g;
    //     let htmlText = html.replace(regText, ""); // 只有文字的文本信息
    //     let htmlimg = html.match(regImg); // 只有图片的文本信息
    //     if (htmlimg) {
    //       that.htmlTotal = htmlText + htmlimg;
    //     } else {
    //       that.htmlTotal = htmlText;
    //     }
    //     if (that.htmlTotal.length > 10000) {
    //       that.editor.txt.html(that.data.articleContent);
    //       return false;
    //     }
    //     that.data.articleContent = html;
    //   };
    //   this.editor.config.menus = [
    //     "fontSize",
    //     "head",
    //     "bold",
    //     "italic",
    //     "strikeThrough",
    //     "table",
    //     "underline",
    //     "splitLine",
    //     "backColor",
    //     "foreColor",
    //     "lineHeight",
    //     "link",
    //     "undo",
    //     "redo",
    //     "fontName",
    //     "image",
    //     "list",
    //     "justify",
    //   ];
    //   this.editor.config.colors = [
    //     "#ffffff",
    //     "#000000",
    //     "#eeece1",
    //     "#1f497d",
    //     "#4f81bd",
    //     "#c0504d",
    //     "#9bbb59",
    //     "#8064a2",
    //     "#4bacc6",
    //     "#f79646",
    //
    //     "#f2f2f2",
    //     "#7f7f7f",
    //     "#ddd9c3",
    //     "#c6d9f0",
    //     "#dbe5f1",
    //     "#f2dcdb",
    //     "#ebf1dd",
    //     "#e5e0ec",
    //     "#dbeef3",
    //     "#fdeada",
    //
    //     "#d8d8d8",
    //     "#595959",
    //     "#c4bd97",
    //     "#8db3e2",
    //     "#b8cce4",
    //     "#e5b9b7",
    //     "#d7e3bc",
    //     "#ccc1d9",
    //     "#b7dde8",
    //     "#fbd5b5",
    //
    //     "#bfbfbf",
    //     "#3f3f3f",
    //     "#938953",
    //     "#548dd4",
    //     "#95b3d7",
    //     "#d99694",
    //     "#c3d69b",
    //     "#b2a2c7",
    //     "#92cddc",
    //     "#fac08f",
    //
    //     "#a5a5a5",
    //     "#262626",
    //     "#494429",
    //     "#17365d",
    //     "#366092",
    //     "#953734",
    //     "#76923c",
    //     "#5f497a",
    //     "#31859b",
    //     "#e36c09",
    //
    //     "#7f7f7f",
    //     "#0c0c0c",
    //     "#1d1b10",
    //     "#0f243e",
    //     "#244061",
    //     "#632423",
    //     "#4f6128",
    //     "#3f3151",
    //     "#205867",
    //     "#974806",
    //
    //     "#c00000",
    //     "#ff0000",
    //     "#ffc000",
    //     "#ffff00",
    //     "#92d050",
    //     "#00b050",
    //     "#00b0f0",
    //     "#0070c0",
    //     "#002060",
    //     "#7030a0",
    //   ];
    //   this.editor.create();
    //   this.editor.txt.html(that.data.articleContent);
    // });
  },
  methods: {
    choiceMedia(mediaType) {
      this.mediaType = mediaType;
      this.visibleMediaShow = true;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.visibleMedia = true;
      }, 200);
    },
    updateMedia(data, resultList) {
      // data，true：保存， false：取消、关闭
      if (data) {
        // 本地上传
        if (resultList[0].fromLocation) {
          this.editor.cmd.do(
            "insertHTML",
            `<img src="${resultList[0].url}" alt="">`
          );
        } else {
          // 媒体库选择
          this.editor.cmd.do(
            "insertHTML",
            `<img src="${resultList[0].material.url}" alt="">`
          );
        }
      }
      this.visibleMedia = false;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.visibleMediaShow = false;
      }, 200);
    },
  },
};
</script>
<style lang="scss">
.image-article-edit {
  position: relative;
  b,
  strong {
    font-weight: bolder !important;
  }
  i {
    font-style: italic;
  }
  .calc-num {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: $-color-black-40;
    text-align: right;
    padding-top: 12px;
    padding-right: 12px;
  }
  .img-diy {
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    position: absolute !important;
    top: 43px;
    left: 287px;
    z-index: 100000;
    &:hover {
      background-color: #f6f6f6;
      i {
        color: #333;
      }
    }
    .w-e-icon-image-diy {
      color: #999;
      &:before {
        content: "\e90d";
      }
    }
  }
  #edit {
    position: relative;
    z-index: 1;
    .w-e-icon-pencil2 + .w-e-droplist,
    .w-e-icon-paint-brush + .w-e-droplist {
      width: 275px !important;
    }
    .w-e-text table {
      thead,
      tbody {
        tr {
          td,
          th {
            height: 30px !important;
          }
        }
      }
    }
  }
}
</style>
