<template>
  <div class="_embed">
    <div class="_embed__block _embed__info">
      <div class="_embed__block-inner c-no-scroll" v-scroll>
        <div class="_embed__head">
          <h4>
            Embed
            <el-tooltip effect="dark" placement="right-end">
              <div slot="content">
                It is only displayed as a fixed style
                <br />
                on the YoloLiv.
              </div>
              <i class="ui-icon-question looping-tip"></i>
            </el-tooltip>
          </h4>
        </div>
        <div class="_embed__main">
          <div class="_embed__form-item">
            <div class="_embed__form-item-head">
              <h6>Allow player to be embedded on the following</h6>
            </div>
            <div
              class="_embed__form-item-content"
              style="height: 36px; display: flex; align-items: center"
            >
              <el-input
                placeholder="example.com"
                size="medium"
                class="input-with-select"
                v-model="url"
              >
                <el-button
                  size="medium"
                  slot="append"
                  type="primary"
                  @click="activityAllowEmbedAdd"
                >
                  Done
                </el-button>
              </el-input>
            </div>
            <div
              v-loading="activityAllowEmbedDeleteLoading"
              element-loading-background="rgba(0, 0, 0, 0.1)"
            >
              <div
                class="_embed__embedding-url"
                v-for="url in urls"
                :key="url.id"
              >
                {{ url.allowedUrl }}
                <i
                  class="ui-icon-close"
                  @click="activityAllowEmbedDelete(url)"
                ></i>
              </div>
            </div>
          </div>
          <div class="_embed__form-item">
            <div class="_embed__form-item-head MB17">
              <h6>Integration Method</h6>
            </div>
            <div class="_embed__form-item-content">
              <y-radio v-model="method" label="inline">Inline Frame</y-radio>
              <y-radio v-model="method" label="link">Link</y-radio>
            </div>
          </div>
          <div class="_embed__form-item">
            <div class="_embed__form-item-head">
              <h6>Integration Functions</h6>
            </div>
            <div class="_embed__form-item-action">
              <y-button
                size="small"
                class="_embed__form-item-code-btn"
                @click="preview"
              >
                View on YoloLiv
                <i></i>
              </y-button>
            </div>
            <div class="_embed__form-item-content">
              <!--Live Event-->
              <div
                class="embed-second-head MT16"
                @click="preLiveToggle = !preLiveToggle"
              >
                Live Event
                <i class="show-sanjiao" v-show="!preLiveToggle"></i>
                <i class="hide-sanjiao" v-show="preLiveToggle"></i>
              </div>
              <div class="checkbox-content" v-show="!preLiveToggle">
                <y-switch
                  active-color="#FF4D7C"
                  inactive-color="#34374D"
                  v-model="settingInfo.showBrand"
                  :disabled="inLivingStatus"
                  @change="updataSetting"
                >
                </y-switch>
                Show branding
              </div>
              <div class="radio-content logo-radio-content" v-show="!preLiveToggle && settingInfo.showBrand">
                <y-radio
                  v-model="settingInfo.logoType"
                  :label="1"
                  :disabled="inLivingStatus"
                  @change="updataSetting"
                >YoloLiv logo</y-radio>
                <y-radio
                  v-model="settingInfo.logoType"
                  :label="2"
                  :disabled="inLivingStatus"
                  @change="updataSetting"
                >Custom logo</y-radio>
                <div class="logo-upload-content">
                  <div
                    class="upload-box"
                    v-show="settingInfo.showBrand && settingInfo.logoType === 2"
                    @click="choiceMedia('logo')"
                  >
                    <div class="no-img" v-if="!settingInfo.logoUrl">
                      <div class="icon"></div>
                    </div>
                    <div class="has-img" v-else>
                      <img :src="settingInfo.logoUrl" alt="" />
                      <div class="change-img" v-show="!inLivingStatus">Change image</div>
                    </div>
                  </div>
                  <div class="link-url" v-show="settingInfo.showBrand && settingInfo.logoType === 2">
                    <div class="link-url-text">Link this logo:</div>
                    <el-input
                      v-model="settingInfo.logoLinkUrl"
                      placeholder="Enter URL"
                      @change="updataLiveLogo"
                      size="medium"
                    ></el-input>
                  </div>
                </div>
                <div style="margin-left: 0;" class="tip-msg" v-show="settingInfo.showBrand && settingInfo.logoType === 2">
                  Recommended pixels: 360*96, supports JPG, PNG and the size does not exceed 20MB;
                </div>
              </div>
              <div class="checkbox-content" v-show="!preLiveToggle">
                <y-switch
                  active-color="#FF4D7C"
                  inactive-color="#34374D"
                  v-model="settingInfo.showThumbnail"
                  :disabled="inLivingStatus"
                  @change="updataSetting"
                >
                </y-switch>
                Thumbnail
                <div
                  class="upload-box"
                  v-show="settingInfo.showThumbnail"
                  @click="choiceMedia('thumbnail')"
                >
                  <div class="no-img" v-if="!settingInfo.thumbnailUrl">
                    <div class="icon"></div>
                  </div>
                  <div class="has-img" v-else>
                    <img :src="settingInfo.thumbnailUrl" alt="" />
                    <div class="change-img" v-show="!inLivingStatus">Change image</div>
                  </div>
                </div>
                <div class="tip-msg" v-show="settingInfo.showThumbnail">
                  Recommended pixels: 1920*1080px, supports JPG, PNG and the size does not exceed 20MB;
                </div>
              </div>
              <div class="checkbox-content" v-show="!preLiveToggle">
                <y-switch
                  active-color="#FF4D7C"
                  inactive-color="#34374D"
                  v-model="settingInfo.showTrailer"
                  :disabled="inLivingStatus"
                  @change="updataSetting"
                >
                </y-switch>
                Pre-recorded video
                <div
                  class="choose-video"
                  v-show="settingInfo.showTrailer"
                >
                  <div
                    class="no-video"
                    v-if="!settingInfo.trailerUrl"
                    @click="choiceMedia('video')"
                  >Choose Video</div>
                  <div class="has-video" v-else>
                    <div class="title c-overout">{{ settingInfo.trailerName }}</div>
                    <div class="btn-updata">
                      <div @click="choiceMedia('video')">modify</div>
                    </div>
                    <div class="btn-delete" @click="deletePre">Delete</div>
                  </div>
                </div>
              </div>
              <div class="checkbox-content" v-show="!preLiveToggle">
                <y-switch
                  active-color="#FF4D7C"
                  inactive-color="#34374D"
                  v-model="settingInfo.showCountdownTimer"
                  :disabled="inLivingStatus"
                  @change="updataSetting"
                >
                </y-switch>
                Countdown timer
              </div>
              <div class="checkbox-content" v-show="!preLiveToggle">
                <y-switch
                  active-color="#FF4D7C"
                  inactive-color="#34374D"
                  v-model="settingInfo.enableFontColor"
                  :disabled="inLivingStatus"
                  @change="updataSetting"
                >
                </y-switch>
                Customize font color
              </div>
              <div class="checkbox-content MT12" v-show="!preLiveToggle && settingInfo.enableFontColor">
                <div class="color-select-box">
                  <div class="key">Pick a color</div>
                  <div class="value">{{ settingInfo.fontColorValue }}</div>
                  <div class="btn">
                    <el-color-picker
                      :disabled="inLivingStatus"
                      v-model="settingInfo.fontColorValue"
                      @change="updataColor"
                    ></el-color-picker>
                  </div>
                </div>
              </div>
              <div class="checkbox-content" v-show="!preLiveToggle">
                <y-switch
                  active-color="#FF4D7C"
                  inactive-color="#34374D"
                  v-model="settingInfo.showSchedule"
                  :disabled="inLivingStatus"
                  @change="updataSetting"
                >
                </y-switch>
                Show event schedule
              </div>
              <div class="_embed__form-item-action" v-show="!preLiveToggle">
                <y-button
                  size="small"
                  class="_embed__form-item-code-btn "
                  @click="doCode('event')"
                  v-if="method === 'inline'"
                >
                  Event Embed Code
                </y-button>
                <y-button
                  size="small"
                  class="_embed__form-item-code-btn "
                  v-copy="eventEmbedCode"
                  v-else
                >
                  Copy to Clipboard
                </y-button>
              </div>
              <div class="divice-line" v-show="!preLiveToggle"></div>
              <!--Live Event-->

              <!--Event Details-->
              <div
                class="embed-second-head head-video-setting"
                @click="detailToggle = !detailToggle"
              >
                Event Details
                <i class="show-sanjiao" v-show="!detailToggle"></i>
                <i class="hide-sanjiao" v-show="detailToggle"></i>
              </div>
              <div class="checkbox-content" v-show="!detailToggle">
                <y-switch
                  active-color="#FF4D7C"
                  inactive-color="#34374D"
                  v-model="settingInfo.showEventDetail"
                  :disabled="inLivingStatus"
                  @change="updataSetting"
                >
                </y-switch>
                Show event detail
              </div>
              <div class="checkbox-content" v-show="!detailToggle">
                <y-switch
                  active-color="#FF4D7C"
                  inactive-color="#34374D"
                  v-model="settingInfo.showViewerData"
                  :disabled="inLivingStatus"
                  @change="updataSetting"
                >
                </y-switch>
                Show viewers data
              </div>
              <div class="checkbox-content" v-show="!detailToggle">
                <y-switch
                  active-color="#FF4D7C"
                  inactive-color="#34374D"
                  v-model="settingInfo.showPlatformShare"
                  :disabled="inLivingStatus"
                  @change="updataSetting"
                >
                </y-switch>
                Enable share
              </div>
              <div class="_embed__form-item-action" v-show="!detailToggle">
                <y-button
                  size="small"
                  class="_embed__form-item-code-btn "
                  @click="doCode('detail')"
                  v-if="method === 'inline'"
                >
                  Detail Embed Code
                </y-button>
                <y-button
                  size="small"
                  class="_embed__form-item-code-btn "
                  v-copy="detailEmbedCode"
                  v-else
                >
                  Copy to Clipboard
                </y-button>
              </div>
              <div class="divice-line" v-show="!detailToggle"></div>
              <!--Event Details-->

              <!--Enable Viewer to comment-->
              <div
                class="embed-second-head head-video-setting"
                @click="chartToggle = !chartToggle"
              >
                Chat
                <i class="show-sanjiao" v-show="!chartToggle"></i>
                <i class="hide-sanjiao" v-show="chartToggle"></i>
              </div>
              <div class="checkbox-content" v-show="!chartToggle">
                <y-switch
                  active-color="#FF4D7C"
                  inactive-color="#34374D"
                  v-model="settingInfo.showChat"
                  :disabled="inLivingStatus"
                  @change="updataSetting"
                >
                </y-switch>
                Show comments
              </div>
              <div class="_embed__form-item-action" v-show="!chartToggle">
                <y-button
                  size="small"
                  class="_embed__form-item-code-btn "
                  @click="doCode('chart')"
                  v-if="method === 'inline'"
                >
                  Chat Embed Code
                </y-button>
                <y-button
                  size="small"
                  class="_embed__form-item-code-btn "
                  v-copy="chartEmbedCode"
                  v-else
                >
                  Copy to Clipboard
                </y-button>
              </div>
              <div class="divice-line" v-show="!chartToggle"></div>
              <!--Enable Viewer to comment-->

              <!--Document-->
              <div
                class="embed-second-head head-video-setting"
                @click="documentToggle = !documentToggle"
              >
                Document
                <i class="show-sanjiao" v-show="!documentToggle"></i>
                <i class="hide-sanjiao" v-show="documentToggle"></i>
              </div>
              <div class="checkbox-content" v-show="!documentToggle">
                <y-switch
                  active-color="#FF4D7C"
                  inactive-color="#34374D"
                  v-model="settingInfo.showDocument"
                  :disabled="inLivingStatus"
                  @change="updataSetting"
                >
                </y-switch>
                Show file
                <div
                  class="upload-box upload-document"
                  v-show="settingInfo.showDocument"
                  @click="choiceMedia('document')"
                >
                  <div class="no-img" v-if="!settingInfo.documentId">
                    <div class="icon"></div>
                    Upload PDF
                  </div>
                  <div class="has-img" v-else>
                    <img :src="settingInfo.documentCoverUrl" alt="" />
                    <div class="change-img">Change document</div>
                  </div>
                </div>
                <div class="tip-msg" v-show="settingInfo.showDocument">
                  Suggested PDF and the file size does not exceed 20MB.
                </div>
              </div>
              <div class="_embed__form-item-action" v-show="!documentToggle">
                <y-button
                  size="small"
                  class="_embed__form-item-code-btn "
                  @click="doCode('pdf')"
                  v-if="method === 'inline'"
                >
                  PDF Embed Code
                </y-button>
                <y-button
                  size="small"
                  class="_embed__form-item-code-btn "
                  v-copy="pdfEmbedCode"
                  v-else
                >
                  Copy to Clipboard
                </y-button>
              </div>
              <div class="divice-line" v-show="!documentToggle"></div>
              <!--Document-->

              <!--Video List-->
              <div
                class="embed-second-head head-video"
                @click="videoToggle = !videoToggle"
              >
                Video List
                <i class="show-sanjiao" v-show="!videoToggle"></i>
                <i class="hide-sanjiao" v-show="videoToggle"></i>
              </div>
              <div class="checkbox-content" v-show="!videoToggle">
                <y-switch
                  active-color="#FF4D7C"
                  inactive-color="#34374D"
                  v-model="settingInfo.enableLoopVideo"
                  :disabled="inLivingStatus"
                  @change="updataSetting"
                >
                </y-switch>
                Looping
                <el-tooltip effect="dark" placement="right-end">
                  <div slot="content">
                    By enabling this option
                    <br />
                    the video will be played in a loop.
                  </div>
                  <i class="ui-icon-question looping-tip"></i>
                </el-tooltip>
              </div>
              <div class="checkbox-content" v-show="!videoToggle">
                <y-switch
                  active-color="#FF4D7C"
                  inactive-color="#34374D"
                  v-model="settingInfo.showRelatedVideo"
                  :disabled="inLivingStatus"
                  @change="updataVideo"
                >
                </y-switch>
                Add videos
                <div class="video-list" v-show="settingInfo.showRelatedVideo">
                  <y-button
                    size="small"
                    class="_embed__form-item-code-btn no-video"
                    @click="choiceMediaVideos"
                  >
                    Choose Videos
                  </y-button>
                  <div
                    class="has-video"
                    v-for="(item, index) in settingInfo.relatedVideoArray"
                    :key="index"
                  >
                    <div class="title c-overout">{{ item.name }}</div>
                    <div class="btn-updata">
                      <div @click="deleteItem(item, index)">Delete</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="embed-second-head head-video-setting ML12" v-show="!videoToggle">
                Video Switching Settings
              </div>
              <div class="radio-content" v-show="!videoToggle">
                <y-radio
                  v-model="settingInfo.videoSwitchPauseStatus"
                  :label="1"
                  :disabled="inLivingStatus"
                  @change="updataSetting"
                >
                  Resume first frame and pause when switching
                </y-radio>
              </div>
              <div class="radio-content parse-radio-content" v-show="!videoToggle">
                <y-radio
                  v-model="settingInfo.videoSwitchPauseStatus"
                  :label="2"
                  :disabled="inLivingStatus"
                  @change="updataSetting"
                >
                  Pause when switching
                </y-radio>
              </div>
              <div class="embed-second-head head-video-setting ML12" v-show="!videoToggle">
                Video arrangement
              </div>
              <div class="radio-content" v-show="!videoToggle">
                <y-radio
                  v-model="settingInfo.videoLayout"
                  :label="1"
                  :disabled="inLivingStatus"
                  @change="updataSetting"
                >
                  Waterfall flow
                </y-radio>
              </div>
              <div class="radio-content parse-radio-content" v-show="!videoToggle">
                <y-radio
                  v-model="settingInfo.videoLayout"
                  :label="2"
                  :disabled="inLivingStatus"
                  @change="updataSetting"
                >
                  Horizontal sliding
                </y-radio>
              </div>
              <div class="_embed__form-item-action" v-show="!videoToggle">
                <y-button
                  size="small"
                  class="_embed__form-item-code-btn "
                  @click="doCode('video')"
                  v-if="method === 'inline'"
                >
                  Video Embed Code
                </y-button>
                <y-button
                  size="small"
                  class="_embed__form-item-code-btn "
                  v-copy="videoEmbedCode"
                  v-else
                >
                  Copy to Clipboard
                </y-button>
              </div>
              <!--Video List-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="_embed__block _embed__playarea">
      <div class="_embed__block-inner play-area c-no-scroll" v-scroll>
        <div
          class="loading-mask"
          v-show="settingLoading"
          v-loading="settingLoading"
          element-loading-background="rgba(0, 0, 0, 0)"
        ></div>
        <play-area
          v-show="!settingLoading"
          @isLiving="isLiving"
          @outLiving="outLiving"
          :settingInfo="settingInfo"
          :consoleInfo="consoleInfo"
          :liveInfo="liveInfo"
        ></play-area>
      </div>
    </div>
    <media-dialog-radio
      v-if="visibleMediaShowCover"
      :title="title"
      :button-text="buttonText"
      :extensions="extensions"
      :visible="visibleMediaCover"
      :material-type="materialtype"
      :w="w"
      :h="h"
      :limt="limt"
      @updateMedia="uploadSuccessCover"
      suffix="image"
      :groupTitle="groupTitle"
      :multi_selection="false"
    ></media-dialog-radio>

    <media-dialog-checkbox
      :title="'Select Video'"
      groupTitle="All Videos"
      :buttonText="'Upload Video'"
      :maxSize="'100mb'"
      :extensions="'mp4'"
      :visible="visibleVideo"
      v-if="visibleVideoShow"
      :resultList="settingInfo.relatedVideoArray"
      @updateMedia="updateMediaVideo"
      :materialType="2"
      :maxlength="10"
    ></media-dialog-checkbox>

    <!--iframe-word-->
    <y-dialog
      :visible.sync="iframeVisible"
      width="504px"
      custom-class="embed-iframe-dialog"
    >
      <div slot="title" class="iframe-dialog-title">Copy & Paste into your Website</div>
      <div class="frame-content">
        <div class="code-box" v-if="embedType === 'event'">{{ eventEmbedCode }}</div>
        <div class="code-box" v-if="embedType === 'detail'">{{ detailEmbedCode }}</div>
        <div class="code-box" v-if="embedType === 'chart'">{{ chartEmbedCode }}</div>
        <div class="code-box" v-if="embedType === 'pdf'">{{ pdfEmbedCode }}</div>
        <div class="code-box" v-if="embedType === 'video'">{{ videoEmbedCode }}</div>
        <div class="size-box">
          <div class="size-title">
            Size
          </div>
          <div class="size-radio-content">
            <y-radio
              v-model="frameSize"
              label="responsive"
              @change="changeSize"
            >
              Responsive
            </y-radio>
            <i
              class="ui-icon-popover"
              v-popover:popover
            ></i>
            <y-popover
              content='The block will adapt to the height and width of its container'
              placement='right-start'
              ref='popover'
              width='279'
              popper-class="iframe-popver"
              trigger='hover'
            >
            </y-popover>
          </div>
          <div class="size-radio-content">
            <y-radio
              v-model="frameSize"
              label="fix"
              @change="changeSize"
            >
              Fixed size
            </y-radio>
          </div>
          <div class="fixed-size-box" v-if="frameSize === 'fix'">
            <div class="key">Width</div>
            <div class="value">
              <el-input
                v-model="frameWidth"
                size="small"
                @input="calcWidth"
              ></el-input>
              <div class="tip">px</div>
            </div>
            <div class="key">Height</div>
            <div class="value">
              <el-input
                v-model="frameHeight"
                size="small"
                @input="calcHeight"
              ></el-input>
              <div class="tip">px</div>
            </div>
        </div>
        </div>
      </div>
      <div slot="footer" class="_live-create__footer">
        <y-button size="small" @click="iframeVisible = false">Cancel</y-button>
        <y-button v-copy="eventEmbedCode" size="small" type="primary" v-if="embedType === 'event'">Copy Code</y-button>
        <y-button v-copy="detailEmbedCode" size="small" type="primary" v-if="embedType === 'detail'">Copy Code</y-button>
        <y-button v-copy="chartEmbedCode" size="small" type="primary" v-if="embedType === 'chart'">Copy Code</y-button>
        <y-button v-copy="pdfEmbedCode" size="small" type="primary" v-if="embedType === 'pdf'">Copy Code</y-button>
        <y-button v-copy="videoEmbedCode" size="small" type="primary" v-if="embedType === 'video'">Copy Code</y-button>
      </div>
    </y-dialog>
    <!--iframe-word-->
  </div>
</template>

<script>
import PlayArea from '../preview/_play-area'
import { IntegrationMethod } from '../types'
import mediaDialogRadio from '@/components/media-dialog-radio';
import mediaDialogCheckbox from "@/components/media-dialog-checkbox";

export default {
  components: {
    PlayArea,
    mediaDialogRadio,
    mediaDialogCheckbox
  },
  props: {
    consoleInfo: Object,
    liveInfo: Object
  },
  data() {
    return {
      activityId: this.$route.params.id,
      method: 'inline',
      url: '',
      urls: [],
      settingInfo: {
        // preliveInfo
        showSchedule: true, // Show event schedule
        showThumbnail: false, // Thumbnail
        thumbnailUrl: '', // thumbnailUrl
        thumbnailId: '', // thumbnailId
        showTrailer: false, // Pre-recorded video
        trailerUrl: '',
        trailerName: '',
        trailerId: '',
        trailerCoverUrl: '', // Pre-recorded video Url
        showCountdownTimer: false, // Countdown timer
        enableFontColor: false, // 颜色选择开关
        fontColorValue: '#FFFFFF', // 字体颜色
        // video
        enableLoopVideo: false, //Looping
        showEventDetail: false,
        showViewerData: false,
        showChat: false,
        showRelatedVideo: false, // Add video
        relatedVideoArray: [], // Video List
        // VideoSwitchingSettings
        videoSwitchPauseStatus: 1, // Video Switching Settings:
        // document
        showDocument: false, // Show file
        documentCoverUrl: '',
        documentId: '',
        // Customization
        showBrand: false, // Show file
        logoType: 1,
        logoUrl: '', // logo图片
        logoLinkUrl: '', // 点击logo图片跳转的链接
        logoId: '',
        showPlatformShare: false,
        showNumberOfComment: false,
        showColor: false, // 设置Schedule颜色开关
        color: '#ffffff',
        videoLayout: 1,
      },
      preLiveToggle: false,
      videoToggle: true,
      documentToggle: true,
      detailToggle: true,
      chartToggle: true,
      // 选择媒体库数据
      w: '',
      h: '',
      type: '',
      limt: '',
      materialtype: '',
      suffix: '',
      groupTitle: '',
      title: '',
      buttonText: '',
      extensions: '',
      visibleMediaCover: false,
      visibleMediaShowCover: false,
      // 媒体库多选视频
      visibleVideo: false, // 选择视频
      visibleVideoShow: false, // 选择视频数据控制
      inLivingStatus: false,
      settingLoading: false,
      iframeVisible: false,
      frameSize: 'responsive',
      frameWidth: '100%',
      frameHeight: '100%',
      embedType: '',
    }
  },
  computed: {
    playUrl() {
      return `${process.env.VUE_APP_H5}/live/${this.activityId}`
    },
    eventEmbedCode() {
      let lsb = this.settingInfo.showBrand;
      let llt = this.settingInfo.logoType;
      let lt = this.settingInfo.showThumbnail;
      let lpv = this.settingInfo.showTrailer;
      let lct = this.settingInfo.showCountdownTimer;
      let lcfc = this.settingInfo.enableFontColor;
      let lses = this.settingInfo.showSchedule;
      let frameWidth = this.frameSize === 'fix' ? `${this.frameWidth}px` : this.frameWidth;
      let frameHeight = this.frameSize === 'fix' ? `${this.frameHeight}px` : this.frameHeight;
      let playUrl = `${this.playUrl}?type=event&lsb=${lsb}&llt=${llt}&lt=${lt}&lpv=${lpv}&lct=${lct}&lcfc=${lcfc}&lses=${lses}`
      if (this.method === IntegrationMethod.link) return playUrl
      return `<iframe src="${playUrl}" frameborder="0" style="width:${frameWidth};height:${frameHeight};"></iframe>`
    },
    detailEmbedCode() {
      let esed = this.settingInfo.showEventDetail;
      let esvd = this.settingInfo.showViewerData;
      let ees = this.settingInfo.showPlatformShare;
      let frameWidth = this.frameSize === 'fix' ? `${this.frameWidth}px` : this.frameWidth;
      let frameHeight = this.frameSize === 'fix' ? `${this.frameHeight}px` : this.frameHeight;
      let playUrl = `${this.playUrl}?type=detail&esed=${esed}&esvd=${esvd}&ees=${ees}`
      if (this.method === IntegrationMethod.link) return playUrl
      return `<iframe src="${playUrl}" frameborder="0" style="width:${frameWidth};height:${frameHeight};"></iframe>`
    },
    chartEmbedCode() {
      let cevc = this.settingInfo.showChat;
      let frameWidth = this.frameSize === 'fix' ? `${this.frameWidth}px` : this.frameWidth;
      let frameHeight = this.frameSize === 'fix' ? `${this.frameHeight}px` : this.frameHeight;
      let playUrl = `${this.playUrl}?type=chart&cevc=${cevc}`
      if (this.method === IntegrationMethod.link) return playUrl
      return `<iframe src="${playUrl}" frameborder="0" style="width:${frameWidth};height:${frameHeight};"></iframe>`
    },
    pdfEmbedCode() {
      let dsf = this.settingInfo.showDocument;
      let frameWidth = this.frameSize === 'fix' ? `${this.frameWidth}px` : this.frameWidth;
      let frameHeight = this.frameSize === 'fix' ? `${this.frameHeight}px` : this.frameHeight;
      let playUrl = `${this.playUrl}?type=pdf&dsf=${dsf}`
      if (this.method === IntegrationMethod.link) return playUrl
      return `<iframe src="${playUrl}" frameborder="0" style="width:${frameWidth};height:${frameHeight};"></iframe>`
    },
    videoEmbedCode() {
      let vl = this.settingInfo.enableLoopVideo;
      let vav = this.settingInfo.showRelatedVideo;
      let vvl = this.settingInfo.videoSwitchPauseStatus;
      let vva = this.settingInfo.videoLayout;
      let frameWidth = this.frameSize === 'fix' ? `${this.frameWidth}px` : this.frameWidth;
      let frameHeight = this.frameSize === 'fix' ? `${this.frameHeight}px` : this.frameHeight;
      let playUrl = `${this.playUrl}?type=video&vl=${vl}&vav=${vav}&vvl=${vvl}&vva=${vva}`
      if (this.method === IntegrationMethod.link) return playUrl
      return `<iframe src="${playUrl}" frameborder="0" style="width:${frameWidth};height:${frameHeight};"></iframe>`
    },
    activityAllowEmbedDeleteLoading() {
      return this.$store.state.loading[`/overseas/activity-allow-embed/delete`]
    },
  },
  mounted() {
    this.settingLoading = true;
    this.fetchtActivityAllowEmbed()
    this.getLiveSetting()
  },
  methods: {
    preview() {
      window.open(this.playUrl)
    },
    // 初始化允许访问网址
    async fetchtActivityAllowEmbed() {
      const res = await this.$serve.activity.activityAllowEmbedGet({
        queryId: this.activityId
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.urls = res || []
    },
    // 新增允许访问网址
    async activityAllowEmbedAdd() {
      if (!this.url.trim()) {
        this.$message.warning('url can not be empty')
        return
      }
      if (this.urls.length === 5) {
        this.$message.warning('Only allow to be embedded on 5 hosts at most.')
        return
      }
      const res = await this.$serve.activity.activityAllowEmbedAdd({
        data: {
          url: this.url,
          activityId: this.activityId
        }
      })
      if (res === this.$serve.FAIL) {
        return false
      }
      this.url = ''
      this.$message.success('success')
      this.fetchtActivityAllowEmbed()
    },
    // 删除允许访问网址
    async activityAllowEmbedDelete(item) {
      const res = await this.$serve.activity.activityAllowEmbedDelete({
        queryId: item.id
      })
      if (res === this.$serve.FAIL) {
        return false
      }
      this.$message.success('success')
      this.fetchtActivityAllowEmbed()
    },
    isThumbnail() {
      this.type = 'thumbnail';
      this.limt = '20MB';
      this.materialtype = 1;
      this.suffix = 'image';
      this.groupTitle = "All Pictures";
      this.title = 'Select Image';
      this.buttonText = 'Upload Picture';
      this.extensions = 'jpg,png';
      this.w = 1920;
      this.h = 1080;
    },
    isLogo() {
      this.type = 'logo';
      this.limt = '20MB';
      this.materialtype = 1;
      this.suffix = 'image';
      this.groupTitle = "All Pictures";
      this.title = 'Select Image';
      this.buttonText = 'Upload Picture';
      this.extensions = 'jpg,png';
      this.w = 360;
      this.h = 96;
    },
    isVideo() {
      this.type = 'video';
      this.limt = '5GB';
      this.materialtype = 2;
      this.suffix = 'video'
      this.groupTitle = "All Videos"
      this.title = 'Select Video';
      this.buttonText = 'Upload Video';
      this.extensions = 'mp4';
    },
    async isDocument() {
      this.type = 'document';
      this.limt = '20MB';
      this.materialtype = 3;
      this.suffix = 'file'
      this.groupTitle = "All Documents"
      this.title = 'Select Document';
      this.buttonText = 'Upload Document';
      this.extensions = 'pdf';
    },
    choiceMedia(type) {
      if (this.inLivingStatus) {
        return false;
      }
      if (type === 'thumbnail') {
        this.isThumbnail();
      } else if (type === 'logo') {
        this.isLogo();
      } else if (type === 'document') {
        this.isDocument();
      } else {
        this.isVideo();
      }
      this.visibleMediaShowCover = true;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.visibleMediaCover = true;
      }, 200);
    },
    async uploadSuccessCover (data, resultList) {
      if (data) {
        if (this.type === 'thumbnail') {
          this.settingInfo.thumbnailUrl = resultList[0].url;
          this.settingInfo.thumbnailId = resultList[0].id;
          this.updataLiveThumbnail();
        } else if (this.type === 'logo') {
          this.settingInfo.logoUrl = resultList[0].url;
          this.settingInfo.logoId = resultList[0].id;
          this.updataLiveLogo()
        } else if (this.type === 'document') {
          this.settingInfo.documentCoverUrl = resultList[0].coverUrl;
          this.settingInfo.documentId = resultList[0].materialId;
          this.updataLiveDocument()
        } else {
          this.settingInfo.trailerUrl = resultList[0].url;
          this.settingInfo.trailerName = resultList[0].name;
          this.settingInfo.trailerId = resultList[0].materialId;
          this.settingInfo.trailerCoverUrl = resultList[0].coverUrl;
          this.updataLiveTrailer()
        }
      }
      this.visibleMediaCover = false;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.visibleMediaShowCover = false;
      }, 200);
    },
    async updataLiveThumbnail() {
      let params = {
        activityId: this.$route.params.id,
        thumbnailUrl: this.settingInfo.thumbnailUrl,
        thumbnailId: this.settingInfo.thumbnailId ? this.settingInfo.thumbnailId + '' : undefined,
      }
      const res = await this.$serve.activity.updataLiveThumbnail({
        data: {
          ...params
        }
      })
      if (res === this.$serve.FAIL) {
        return false
      }
    },
    async updataLiveTrailer() {
      let params = {
        activityId: this.$route.params.id,
        trailerId: this.settingInfo.trailerId,
      }
      const res = await this.$serve.activity.updataLiveTrailer({
        data: {
          ...params
        }
      })
      if (res === this.$serve.FAIL) {
        return false
      }
    },
    deletePre() {
      this.$confirm("Are you sure to delete the Video？", "Prompt", {
        confirmButtonText: "Done",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(async () => {
        const res = await this.$serve.activity.deleteLiveTrailer({
          queryId: this.$route.params.id,
        })
        if (res === this.$serve.FAIL) {
          return false
        }
        this.settingInfo.trailerUrl = '';
        this.settingInfo.trailerName = '';
        this.settingInfo.trailerId = '';
        this.settingInfo.trailerCoverUrl = '';
      });
    },
    async updataVideo() {
      const res = await this.$serve.activity.changeRelatedVideoShow({
        queryId: this.$route.params.id,
      })
      if (res === this.$serve.FAIL) {
        return false
      }
    },
    choiceMediaVideos() {
      if (this.inLivingStatus) {
        return false;
      }
      this.visibleVideoShow = true;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.visibleVideo = true;
      }, 200);
    },
    updateMediaVideo(data, resultList) {
      if (data) {
        // data，true：保存， false：取消、关闭
        this.settingInfo.relatedVideoArray = resultList;
        this.addRelatedVideo();
      }
      this.visibleVideo = false;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.visibleVideoShow = false;
      }, 200);
    },
    async addRelatedVideo() {
      let relatedVideoIds = [];
      this.settingInfo.relatedVideoArray.forEach(item => {
        relatedVideoIds.push(item.materialId + '')
      })
      let params = {
        activityId: this.$route.params.id,
        relatedVideoIds: relatedVideoIds,
      }
      const res = await this.$serve.activity.addRelatedVideo({
        data: {
          ...params
        }
      })
      if (res === this.$serve.FAIL) {
        return false
      }
    },
    deleteItem(item, index) {
      this.$confirm("Are you sure to delete the Video？", "Prompt", {
        confirmButtonText: "Done",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(async () => {
        const res = await this.$serve.activity.deleteRelatedVideo({
          queryId: `${this.$route.params.id}/${item.materialId}`
        })
        if (res === this.$serve.FAIL) {
          return false
        }
        this.settingInfo.relatedVideoArray.splice(index, 1);
      });
    },
    isLiving() {
      this.inLivingStatus = true;
    },
    outLiving() {
      this.inLivingStatus = false;
    },
    async getLiveSetting() {
      const res = await this.$serve.activity.getLiveSetting({
        queryId: this.$route.params.id
      })
      this.settingLoading = false;
      if (res === this.$serve.FAIL) {
        return false
      }
      // 重新对关联视频数组数据重写
      if (res.relatedVideoArray && res.relatedVideoArray.length > 0) {
        res.relatedVideoArray.forEach(item => {
          item.materialId = item.relatedVideoId;
          item.name = item.relateVideoName;
          item.coverUrl = item.relatedVideoCoverUrl;
          item.url = item.relatedVideoUrl;
        })
      }
      this.settingInfo = {
        showSchedule: !!res.showSchedule, // Show event schedule
        showThumbnail: !!res.showThumbnail, // Thumbnail
        thumbnailUrl: res.thumbnail.thumbnailUrl, // thumbnailUrl
        thumbnailId: res.thumbnail.thumbnailId, // thumbnailId
        showTrailer: !!res.showTrailer, // Pre-recorded video
        trailerUrl: res.trailer.trailerUrl,
        trailerName: res.trailer.trailerName,
        trailerId: res.trailer.trailerId,
        trailerCoverUrl: res.trailer.trailerCoverUrl, // Pre-recorded video Url
        showCountdownTimer: !!res.showCountdownTimer, // Countdown timer
        enableFontColor: !!res.enableFontColor,
        fontColorValue: res.fontColorValue ? res.fontColorValue : '#FFFFFF',
        enableLoopVideo: !!res.enableLoopVideo, //Looping
        showRelatedVideo: !!res.showRelatedVideo, // Add video
        showEventDetail: !!res.showEventDetail,
        showViewerData: !!res.showViewerData,
        showChat: !!res.showChat,
        relatedVideoArray: res.relatedVideoArray || [], // Video List
        videoSwitchPauseStatus: res.videoSwitchPauseStatus, // Video Switching Settings:
        videoLayout: res.videoLayout,
        showDocument: !!res.showDocument, // Show file
        documentCoverUrl: res.document.documentCoverUrl,
        documentId: res.document.documentId,
        showBrand: !!res.showBrand, // Show file
        logoType: res.logo.logoType ? res.logo.logoType : 1,
        logoUrl: res.logo.logoUrl, // logo图片
        logoLinkUrl: res.logo.logoLinkUrl, // 点击logo图片跳转的链接
        logoId: res.logo.logoId,
        showPlatformShare: !!res.showPlatformShare,
      }
    },
    async updataSetting() {
      const params = {
        activityId: this.$route.params.id,
        showSchedule: this.settingInfo.showSchedule ? 1 : 0,
        showCountdownTimer: this.settingInfo.showCountdownTimer ? 1 : 0,
        enableFontColor: this.settingInfo.enableFontColor ? 1 : 0,
        enableLoopVideo: this.settingInfo.enableLoopVideo ? 1 : 0,
        videoSwitchPauseStatus: this.settingInfo.videoSwitchPauseStatus,
        videoLayout: this.settingInfo.videoLayout,
        showPlatformShare: this.settingInfo.showPlatformShare ? 1 : 0,
        showTrailer: this.settingInfo.showTrailer ? 1 : 0,
        showBrand: this.settingInfo.showBrand ? 1 : 0,
        logoType: this.settingInfo.logoType,
        showDocument: this.settingInfo.showDocument ? 1 : 0,
        showThumbnail: this.settingInfo.showThumbnail ? 1 : 0,
        showEventDetail: this.settingInfo.showEventDetail ? 1 : 0,
        showViewerData: this.settingInfo.showViewerData ? 1 : 0,
        showChat: this.settingInfo.showChat ? 1 : 0,
      }
      const res = await this.$serve.activity.updataLiveSetting({
        data: {
          ...params
        }
      })
      if (res === this.$serve.FAIL) {
        return false
      }
    },
    async updataLiveLogo() {
      let params = {
        activityId: this.$route.params.id,
        logoUrl: this.settingInfo.logoUrl,
        logoId: this.settingInfo.logoId ? this.settingInfo.logoId + '' : undefined,
        logoLinkUrl: this.settingInfo.logoLinkUrl
      }
      const res = await this.$serve.activity.updataLiveLogo({
        data: {
          ...params
        }
      })
      if (res === this.$serve.FAIL) {
        return false
      }
    },
    async updataLiveDocument() {
      let params = {
        activityId: this.$route.params.id,
        documentId: this.settingInfo.documentId,
      }
      const res = await this.$serve.activity.updataLiveDocument({
        data: {
          ...params
        }
      })
      if (res === this.$serve.FAIL) {
        return false
      }
    },
    async updataColor(val) {
      this.settingInfo.fontColorValue = val;
      let params = {
        activityId: this.$route.params.id,
        fontColorValue: this.settingInfo.fontColorValue,
      }
      const res = await this.$serve.activity.updataLiveColor({
        data: {
          ...params
        }
      })
      if (res === this.$serve.FAIL) {
        return false
      }
    },
    doCode(type) {
      this.embedType = type;
      this.iframeVisible = true
    },
    changeSize(val) {
      if (val === 'responsive') {
        this.frameWidth = '100%';
        this.frameHeight = '100%';
      } else {
        this.frameWidth = '0';
        this.frameHeight = '0';
      }
    },
    calcWidth() {
      !this.frameWidth && (this.frameWidth = '0');
      this.frameWidth = this.frameWidth.replace(/[^\d]/g,'')
    },
    calcHeight() {
      !this.frameHeight && (this.frameHeight = '0');
      this.frameHeight = this.frameHeight.replace(/[^\d]/g,'')
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/icon.scss';
.MB17 {
  margin-bottom: 17px !important;
}
.MT12 {
  margin-top: 12px !important;
}
.MT16 {
  margin-top: 16px;
}
._embed {
  // padding: 12px 24px;
  // color: #fff;
  display: flex;
  height: 100%;
  color: #fff;
}
._embed__head {
  margin-bottom: 6px;
  h4 {
    height: 48px;
    line-height: 48px;
    margin: 0;
    font-size: 20px;
  }
}
._embed__block {
  position: relative;
}
._embed__block-inner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
._embed__info {
  width: 432px;
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  ._embed__block-inner {
    padding: 0 20px 40px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 6px;
      background: transparent;
    }
    &::-webkit-scrollbar-track {
      border-radius: 0;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(102, 102, 102, 1);
      transition: all 0.2s;
      border-radius: 2px;
    }
  }
  h4 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
  }
}
._embed__form-item {
  padding: 12px 0;
  h6 {
    color: rgba(255, 255, 255, 0.6);
    margin: 0;
  }
  .el-radio {
    color: #fff;
  }
  ._embed__form-item-select {
    position: relative;
    display: inline-block;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }
  ::v-deep {
    .el-input__inner {
      background-color: #292b3c !important;
      border: none;
      color: rgba(255, 255, 255, 1);
    }
    .el-radio__label {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.8);
    }
    .el-checkbox__label {
      color: rgba(255, 255, 255, 0.8);
    }
    .el-radio__input.is-checked .el-radio__inner {
      background: #fff;
      border-color: rgba(255, 77, 124, 1);
      &::after {
        background: rgba(255, 77, 124, 1);
        width: 8px;
        height: 8px;
      }
    }
    .el-radio__input.is-checked + .el-radio__label {
      color: rgba(255, 255, 255, 0.8);
    }
    .el-select-dropdown {
      border: none;
      background-color: red;
    }
    .el-select-dropdown__list {
      background-color: #292b3c;
    }
    .el-select-dropdown__item {
      background-color: #292b3c;
      color: rgba(255, 255, 255, 0.4);
    }
    .el-select-dropdown__item.hover,
    .el-select-dropdown__item:hover {
      background-color: #383a4c;
      color: rgba(255, 255, 255, 0.4);
    }
    .popper__arrow,
    .el-popper[x-placement^='bottom'] .popper__arrow::after {
      border-bottom-color: #292b3c;
    }
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: rgba(255, 255, 255, 0.8) !important;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #FF4D7C;
      border-color: #FF4D7C;
    }
  }
  .ui-icon-question {
    vertical-align: -3px;
  }
}
._embed__form-item-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  h6 {
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 22px;
  }
}
._embed__form-item-action {
  margin-top: 24px;
  .el-button {
    i {
      vertical-align: -3px;
      display: inline-block;
      background-repeat: no-repeat;
      background-position: center;
      -webkit-background-size: cover;
      background-size: cover;
    }
    &.el-button--small {
      padding: 9px 8px;
      border-radius: 4px;
    }
  }
}
._embed__form-item-code {
  ._embed__form-item-content {
    min-height: 96px;
    background: #292b3c;
    border-radius: 8px;
    padding: 8px 12px;
    color: rgba(255, 255, 255, 1);
    white-space: break-spaces;
    word-break: break-all;
    line-height: 20px;
  }
}
._embed__form-item-code-btn {
  background: transparent;
  color: #ff4d7c;
  border-color: #ff4d7c;
  font-weight: 600;
  i {
    @extend .ui-icon-eye2-hover;
  }
  &:hover {
    background: #ff4d7c;
    color: #fff;
    i {
      @extend .ui-icon-eye2;
    }
  }
}
._embed__form-item-copy-btn {
  background: #ff4d7c;
  border-color: #ff4d7c;
  position: relative;
  &:hover ::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #fff;
    opacity: 0.1;
  }
}
._embed__playarea {
  flex: 1;
  ._embed__block-inner {
    padding: 24px 0;
    overflow-y: auto;
  }
}
._embed__embedding-url {
  display: inline-block;
  background: rgba(255, 255, 255, 0.04);
  font-size: 12px;
  padding: 8px 12px;
  border-radius: 4px;
  position: relative;
  margin: 10px 10px 0 0;
  i {
    display: none;
    position: absolute;
    top: -8px;
    right: -8px;
    cursor: pointer;
  }
  &:hover {
    i {
      display: block;
    }
  }
}
.el-input-group__append {
  border: none;
  background: #ff4d7c;
  overflow: hidden;
}
.el-input-group__append .el-button {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  background: #ff4d7c;
  color: #fff;
  border-radius: 0;
}
.embed-second-head {
  font-size: 14px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 16px;
  position: relative;
  cursor: pointer;
  user-select: none;
}
.head-video, .head-video-setting, .head-customization {
  margin-top: 24px;
}
.checkbox-content {
  margin-top: 24px;
  margin-left: 12px;
  color: rgba(255, 255, 255, 0.8);
  &:first-child {
    margin-top: 16px;
  }
  .el-checkbox {
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
  }
  .el-switch {
    margin-right: 8px;
  }
}
.radio-content {
  margin-top: 20px;
  margin-left: 24px;
  &.parse-radio-content {
    margin-top: 16px;
  }
  ::v-deep.el-radio__label {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
  }
  ::v-deep.el-radio__input.is-checked + .el-radio__label {
    color: rgba(255, 255, 255, 0.8);
  }
  &.logo-radio-content {
    margin-left: 65px !important;
  }
}
.upload-box {
  background: #292B3C;
  border-radius: 2px;
  box-sizing: border-box;
  margin-top: 12px;
  margin-left: 53px;
  color: rgba(151, 151, 151, 1);
  text-align: center;
  line-height: 88px;
  font-size: 24px;
  cursor: pointer;
  width: 88px;
  height: 88px;
  .no-img {
    width: 88px;
    height: 88px;
    border: 1px dashed rgba(255, 255, 255, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      width: 12px;
      height: 12px;
      background: url(~@assets/images/designer/upload@2x.png)
      no-repeat;
      background-size: 100%;
    }
  }
  .has-img {
    width: 88px;
    height: 88px;
    position: relative;
    &:hover {
      .change-img {
        display: block;
      }
      .delete-logo {
        display: block;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .change-img {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      line-height: 88px;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      background-color: rgba(0, 0, 0, 0.4);
      cursor: pointer;
    }
  }
}
.tip-msg {
  height: 30px;
  font-size: 13px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  line-height: 15px;
  margin-top: 8px;
  margin-left: 53px;
  word-break: keep-all;
}
.choose-video {
  margin-left: 53px;
  margin-top: 12px;
  .no-video {
    width: 322px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    font-size: 14px;
    color: #FFFFFF;
    cursor: pointer;
  }
  .has-video {
    width: 322px;
    height: 40px;
    line-height: 40px;
    background-color:  #292B3C;;
    display: flex;
    align-items: center;
    padding: 0 16px;
    .title {
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      width: 180px;
    }
    .btn-updata {
      font-size: 14px;
      color: rgba(255, 77, 124, 1);
      margin-left: auto;
      cursor: pointer;
    }
    .btn-delete {
      font-size: 14px;
      color: rgba(255, 77, 124, 1);
      margin-left: 16px;
      cursor: pointer;
    }
  }
}
.video-list {
  margin-left: 53px;
  margin-top: 12px;
  .no-video {
    width: 158px;
    height: 40px;
    border-radius: 2px;
    margin-left: 0;
  }
  .has-video {
    width: 322px;
    height: 40px;
    line-height: 40px;
    background-color:  #292B3C;;
    display: flex;
    align-items: center;
    padding: 0 16px;
    margin-top: 12px;
    .title {
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      width: 180px;
    }
    .btn-updata {
      font-size: 14px;
      color: rgba(255, 77, 124, 1);
      margin-left: auto;
      cursor: pointer;
    }
    .btn-delete {
      font-size: 14px;
      color: rgba(255, 77, 124, 1);
      margin-left: 16px;
      cursor: pointer;
    }
  }
}
.upload-document {
  width: 322px !important;
  height: 187px !important;
  .no-img {
    width: 322px !important;
    height: 187px !important;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.4);
    .icon {
      margin-right: 8px;
    }
  }
  .has-img {
    width: 322px !important;
    height: 187px !important;
    .change-img {
      line-height: 187px !important;
    }
  }
}
.logo-upload-content {
  display: flex;
  align-items: flex-end;
  .upload-box {
    margin-left: 0;
  }
  .link-url {
    margin-left: 16px;
    width: 184px;
    .link-url-text {
      font-size: 13px;
      color: rgba(255, 255, 255, 0.6);
      line-height: 15px;
      margin-bottom: 6px;
    }
    ::v-deep input {
      border-radius: 4px;
    }
  }
}
.count-box {
  margin-left: 53px;
}
.show-sanjiao {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ffffff;
  position: absolute;
  top: 5px;
  margin-left: 7px;
  cursor: pointer;
  user-select: none;
}
.hide-sanjiao {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #ffffff;
  position: absolute;
  top: 5px;
  margin-left: 7px;
  cursor: pointer;
  user-select: none;
}
.input-with-select {
  ::v-deep .el-input-group__append {
    background: #ff4d7c;
    border-color: #ff4d7c;
  }
}
.play-area {
  &::-webkit-scrollbar {
    width: 6px;
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    border-radius: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(102, 102, 102, 1);
    transition: all 0.2s;
    border-radius: 2px;
  }
}
.loading-mask {
  margin: 0 15.2vw;
  width: calc(100% - 30.4vw);
  height: calc(100vh - 108px);
  border-radius: 8px;
  background-color: #292B3C;
}
.looping-tip {
  margin-left: 4px;
  vertical-align: middle;
}
.divice-line {
  width: 100%;
  height: 1px;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  margin-top: 24px;
}
.color-select-box {
  width: 322px;
  padding: 0 12px;
  margin-left: 53px;
  margin-top: 12px;
  height: 48px;
  background: #292B3C;
  border-radius: 2px;
  display: flex;
  align-items: center;
  .key {
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
  }
  .value {
    font-size: 16px;
    font-weight: 400;
    color: #FF4D7C;
    margin-left: auto;
    margin-right: 12px;
  }
  .btn {
    ::v-deep .el-color-picker {
      width: 56px;
      height: 32px;
      .el-color-picker__mask {
        width: 56px;
        height: 32px;
      }
      .el-color-picker__trigger {
        width: 56px;
        height: 32px;
        border: 1px solid rgba(255, 255, 255, 0.15);
        .el-color-picker__icon {
          display: none;
        }
      }
    }
  }
}
.ML12 {
  margin-left: 12px;
}
.embed-iframe-dialog {
  .iframe-dialog-title {
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
  }
  .frame-content {
    padding: 0 8px;
  }
  .code-box {
    padding: 10px 16px;
    min-height: 80px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8);
    line-height: 20px;
    word-break: break-all;
    background: #F7F8FA;
    border-radius: 4px;
    user-select: none;
  }
  .size-box {
    ::v-deep .el-radio__label {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.8);
    }
    ::v-deep .el-radio__input.is-checked + .el-radio__label {
      color: rgba(0, 0, 0, 0.8);
    }
    .size-title {
      height: 19px;
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
      line-height: 19px;
      margin: 16px 0;
    }
    .size-radio-content {
      margin-bottom: 16px;
      margin-left: 12px;
      i {
        display: inline-block !important;
        vertical-align: bottom !important;
      }
      ::v-deep .el-radio {
        margin-right: 8px;
      }
      ::v-deep .el-popover--plain {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
      }
    }
    .fixed-size-box {
      display: flex;
      align-items: center;
      margin-left: 40px;
      .key {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        margin-right: 8px;
      }
      .value {
        margin-right: 32px;
        position: relative;
        ::v-deep input {
          width: 80px;
          background: #F7F8FA;
          padding-right: 28px;
        }
        .tip {
          position: absolute;
          right: 6px;
          top: 6px;
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
}
</style>
<style lang="scss">
.iframe-popver {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  word-break: keep-all;
}
</style>
