import YLoading from '@base/loading'
import YProTable from './src/main'

YProTable.install = function(Vue) {
  Vue.prototype.$loading = YLoading.service
  Vue.use(YLoading.directive)
  Vue.component(YProTable.name, YProTable)
}

export default YProTable
