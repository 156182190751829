<template>
  <div class="album" :style="data.stylesheet.style">
    <div class="select-box">
      <div class="title" v-if="data.titleSwitch" >{{ data.albumTitle }}</div>
      <div class="text" v-if="!data.albumId"></div>
    </div>
    <div class="album-box" v-if="data.albumId">
      <template>
        <div class="img-item" v-for="item in data.albumList" :key="item">
          <img :src="item" alt="">
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import waterfall from "vue-waterfall2";
Vue.use(waterfall);

export default {
  props: {
    data: {
      Type: Object,
      default: () => {
        return {};
      },
    },
    stylesheet: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      showAlbum: true,
    }
  },
  watch: {
    'data.albumId': function() {
      this.showAlbum = false;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.showAlbum = true;
      }, 200);
    }
  }
};
</script>
<style lang="scss" scoped>
.album {
  min-height: 150px;
  .select-box {
    padding: 12px;
    .title {
      font-size: 14px;
      font-family: HelveticaNeue-Medium, HelveticaNeue;
      font-weight: 500;
      color: $-color-black-80;
    }
    .text {
      width: 351px;
      height: 213px;
      background: url(~@assets/images/designer/album-empty@2x.png) no-repeat;
      background-size: 100%;
    }
  }
  .album-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .img-item {
      width: 49%;
      height: 105px;
      margin-bottom: 3px;
      text-align: center;
      background-color: #000000;
      img {
        height: 100%;
      }
    }
  }
  #vueWaterfall {
    overflow-x: hidden;
  }
}
</style>
