const apis = [
  {
    // 用户登录
    fn: 'login',
    method: 'POST',
    headers: {
      ['Content-Type']: 'application/x-www-form-urlencoded'
    },
    url: '/overseas/user/login',
    hideloading: true
  },
  {
    // 用户登出
    fn: 'logout',
    method: 'GET',
    url: '/overseas/user/logout'
  },
  {
    // 校验验证码
    fn: 'requestVerifyCode',
    method: 'GET',
    url: '/overseas/user/requestVerifyCode'
  },
  {
    // 用户注册信息校验
    fn: 'verifyRegisterInfo',
    method: 'POST',
    headers: {
      ['Content-Type']: 'application/x-www-form-urlencoded'
    },
    url: '/overseas/user/verifyRegisterInfo'
  },
  {
    // 用户注册
    fn: 'register',
    method: 'POST',
    headers: {
      ['Content-Type']: 'application/x-www-form-urlencoded'
    },
    url: '/overseas/user/register',
    hideError: true,
    hideloading: true
  },
  {
    // 校验验证码
    fn: 'verificationCode',
    method: 'POST',
    headers: {
      ['Content-Type']: 'application/x-www-form-urlencoded'
    },
    url: '/overseas/user/verificationCode',
    hideloading: true
  },
  {
    // 重置密码
    fn: 'reset',
    method: 'POST',
    headers: {
      ['Content-Type']: 'application/x-www-form-urlencoded'
    },
    url: '/overseas/user/reset',
    hideloading: true
  },
  {
    // 获取用户信息
    fn: 'queryInfo',
    method: 'GET',
    url: '/overseas/user/queryInfo',
    hideError: true
  },
  {
    // 验证邮箱
    fn: 'emailValid',
    method: 'GET',
    url: '/overseas/user/emailValid',
    hideloading: true
  },
  {
    // 绑定用户选择的语言
    fn: 'bindLanguage',
    method: 'POST',
    headers: {
      ['Content-Type']: 'application/x-www-form-urlencoded'
    },
    url: '/overseas/user/bindLanguage'
  },
  {
    // 更新用户的国家或地区信息
    fn: 'updateCountriesOrRegions',
    method: 'POST',
    headers: {
      ['Content-Type']: 'application/x-www-form-urlencoded'
    },
    url: '/overseas/user/updateCountriesOrRegions'
  },
  {
    // 获取国家和地区列表
    fn: 'getCountriesAndRegions',
    method: 'GET',
    url: '/overseas/user/getCountriesAndRegions'
  },
  {
    // 用户更新密码（PC）
    fn: 'updatePassword',
    method: 'POST',
    headers: {
      ['Content-Type']: 'application/x-www-form-urlencoded'
    },
    url: '/overseas/user/updatePassword'
  },
  {
    // 用户校验密码（PC）
    fn: 'verifyPassword',
    method: 'POST',
    headers: {
      ['Content-Type']: 'application/x-www-form-urlencoded'
    },
    url: '/overseas/user/verifyPassword'
  },
  {
    // 用户开播提醒
    fn: 'liveReminder',
    method: 'PUT',
    url: '/overseas/user/live-reminder'

  },
  {
    // 获取用户偏好设置
    fn: 'getPreferences',
    method: 'GET',
    url: '/overseas/user/preferences'
  },
  {
    // 更新用户头像
    fn: 'updataAvatar',
    method: 'PUT',
    url: '/overseas/user/avatar'
  }
]

export const baseUrl = process.env.VUE_APP_OVERSEAS_URL

export default apis
