const scroll = {
  bind(el) {
    el.$timer = null;
    el.$scroll = function () {
      el.classList.remove("c-no-scroll");
      el.$timer = setTimeout(() => {
        clearTimeout(el.$timer);
        el.$timer = null;
        el.classList.add("c-no-scroll");
      }, 1000)
    }
    el.addEventListener('scroll', el.$scroll)
  },
  unbind(el) {
    el.removeEventListener('scroll', el.$scroll)
  }
}
export default scroll
