import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './lib'
import './global.scss'
import OssUploader from '@/utils/uploader/oss-uploader'
import AwsUploader from '@/utils/uploader/aws-uploader'
import heVueImgPreview from 'hevue-img-preview'
import VueCompositionAPI from '@vue/composition-api'

import i18n from './locales'

Vue.use(VueCompositionAPI)

Vue.config.productionTip = false
Vue.prototype.$OssUploader = OssUploader
Vue.prototype.$AwsUploader = AwsUploader
// Vue.prototype.$downloadUrl = downloadUrl;
Vue.use(heVueImgPreview)

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
