<template>
  <div class="_head-area">
    <div class="_head-area__left">
      <div class="_head-area__logo">
        <img src="~@/assets/images/compang-logo.png" alt="logo" />
      </div>
      <div class="_head-area__division">|</div>
      <div class="_head-area__exit" @click="back">
        <i class="ui-icon-exit"></i>
      </div>
      <div class="_head-area__info">
        <h2 :title="title">
          {{ title }}
        </h2>
      </div>
      <!-- <div class="_head-area__division">|</div>
      <div class="_head-area__current">{{ activeTab.label }}</div> -->
    </div>
    <div class="_head-area__right">
      <y-tabs :value="active" @tab-click="toggle">
        <y-tab-pane
          v-for="tab in tabs"
          :key="tab.name"
          :label="tab.label"
          :name="tab.name"
        ></y-tab-pane>
      </y-tabs>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    consoleInfo: Object,
    active: String
  },
  data() {
    return {
      activityId: this.$route.params.id,
      tabs: [
        {
          label: 'Event Setup',
          name: 'setup'
        },
        {
          label: 'Monitor',
          name: 'monitor'
        }
      ]
    }
  },
  computed: {
    title() {
      return this.consoleInfo
        ? this.consoleInfo.title || 'Event Title'
        : 'Loading'
    },
    beginTime() {
      return this.consoleInfo
        ? moment(+this.consoleInfo.beginTime).format('YYYY/MM/DD HH:mm')
        : ''
    },
    activeTab() {
      return this.tabs.find((tab) => tab.name === this.active)
    }
  },
  methods: {
    back() {
      if (this.$route.query.tab) {
        this.$router.push('/analytics')
        return
      }
      this.$router.push('/yolo-cast')
    },
    toggle(activeInstance) {
      this.$emit('toggle', activeInstance.name)
    }
  }
}
</script>

<style lang="scss" scoped>
._head-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  color: #fff;
  position: relative;

  .el-button {
    background: transparent;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.15);
    &:hover,
    &:focus {
      background: transparent;
      color: #ffffff;
      border-color: rgba(255, 255, 255, 0.15);
    }
    &:focus {
      color: #ffffff;
      border-color: rgba(255, 255, 255, 0.15);
    }
  }
  .el-button--primary {
    background: transparent;
    color: $--color-primary;
    border-color: $--color-primary;
    &:hover,
    &:focus {
      background: transparent;
      color: $--color-primary;
      border-color: $--color-primary;
    }
    &:focus {
      color: $--color-primary;
      border-color: $--color-primary;
    }
  }
}
._head-area__left {
  display: flex;
  align-items: center;
}
._head-area__logo {
  display: flex;
  align-items: center;
  img {
    height: 32px;
  }
}
._head-area__exit {
  margin-right: 16px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  background: #292b3c;
  cursor: pointer;
  &:hover {
    background: #ff4d7c;
  }
  .ui-icon-exit {
    width: 20px;
    height: 20px;
  }
}
._head-area__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    font-size: 18px;
    margin: 0;
    max-width: 420px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
._head-area__division {
  margin: 0 24px;
  color: rgba(255, 255, 255, 0.15);
}
._head-area__current {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.6);
}
._head-area__right {
  position: absolute;
  right: 20px;
  bottom: 0;
  ::v-deep {
    .el-tabs__header {
      margin: 0;
    }
    .el-tabs__item {
      height: 60px;
      line-height: 60px;
      font-size: 18px;
      padding: 0 20px !important;
      color: rgba(255, 255, 255, 0.6);
      &.is-active {
        color: #ff4d7c;
        font-weight: 600;
      }
    }
    .el-tabs__active-bar {
      background-color: #ff4d7c;
    }
  }
}
</style>
