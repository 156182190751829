<template>
  <el-divider class="y-divider" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </el-divider>
</template>

<script>
import { Divider } from 'element-ui'

export default {
  name: 'y-divider',
  _name: 'Divider 分割线',
  $depends: [Divider],
  components: {
    [Divider.name]: Divider
  }
}
</script>
