<template>
  <div class="subscribe" :style="{backgroundImage: data.stylesheet.background.type === 'image' ? data.stylesheet.style.backgroundImage : ''}">
    <div class="subscribe-logo">
      <img :src="userInfo.avatar || 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'" alt="" v-if="!data.logoUrl">
      <img :src="data.logoUrl" alt="" v-else>
    </div>
    <div class="subscribe-username overout-two">{{data.username}}</div>
    <div class="subscribe-focus" v-show="data.url">View</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    data: {
      Type: Object,
      default: () => {
        return {}
      },
    },
    stylesheet: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    ...mapState({
      userInfo(state) {
        return state.userInfo
      },
    }),
  },
  created() {
    // console.log(this.data);
  }
};
</script>
<style lang="scss" scoped>
.subscribe {
  width: 100%;
  height: 50px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  background-size: 100%;
  .subscribe-logo {
    width: 32px;
    height: 32px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .subscribe-username {
    font-size: 14px;
    font-family: HelveticaNeue;
    font-weight: 500;
    margin-left: 12px;
    width: 62%;
  }
  .subscribe-focus {
    margin-left: auto;
    width: 40px;
    height: 24px;
    line-height: 24px;
    background-color: rgba(255, 0, 67, 1);
    border-radius: 4px;
    font-size: 12px;
    font-family: HelveticaNeue-Medium, HelveticaNeue;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
  }
}
</style>
