<template>
  <div class="image-swiper">
    <div
      class="img-box"
      :class="{
        'image-1280-fei': data.images.length > 0 && data.imageType == 1,
        'image-1280': data.images.length > 0 && data.imageType == 2,
        'image-280': data.images.length > 0 && data.imageType == 3,
        'image-100': data.images.length > 0 && data.imageType == 4,
      }"
    >
      <div class="no-image" v-if="data.images.length === 0">
        <div style="width: 100%; height: 64px"></div>
        <img src="~@assets/images/designer/lunbotu@2x.png" alt="" />
        <div class="text">点击编辑轮播图</div>
      </div>
      <div class="has-image" v-if="data.images.length > 0">
        <el-carousel indicator-position="outside">
          <el-carousel-item v-for="(item, index) in data.images" :key="index">
            <img :src="item.imageUrl" alt="" v-if="item.imageUrl" />
            <img
              src="~@assets/images/designer/swiper-default.png"
              alt=""
              v-else
            />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      Type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.image-swiper {
  overflow: hidden;
  background-color: #f1f8ff;
  .image-1280-fei {
    width: 345px;
    max-width: unset;
    height: 194px;
    margin: 10px 15px;
    border-radius: 8px;
  }
  .image-1280 {
    width: 375px;
    max-width: unset;
    height: 211px;
  }
  .image-280 {
    width: 375px;
    max-width: unset;
    height: 140px;
  }
  .image-100 {
    width: 375px;
    max-width: unset;
    height: 50px;
  }
  .img-box {
    .no-image {
      width: 374px;
      height: 214px;
      img {
        width: 56px;
        height: 52px;
        margin: 0 auto 8px;
      }
      .text {
        font-size: 14px;
        font-weight: 400;
        color: $-color-black-40;
        text-align: center;
      }
    }
    .has-image {
      width: 100%;
      height: 100%;
      ::v-deep .el-carousel--horizontal {
        width: 100% !important;
        height: 100% !important;
        overflow: hidden;
      }
      ::v-deep .el-carousel__container {
        width: 100% !important;
        height: 100% !important;
        overflow: hidden;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
