<template>
  <div class="banner-edit">
    <div class="cover-box">
      <div class="text">Style</div>
      <el-radio-group v-model="data.coverType">
        <el-radio label="default">Default</el-radio>
        <el-radio label="image">Custom</el-radio>
      </el-radio-group>
      <div class="add-bg-box" v-if="data.coverType === 'image'">
        <upload
          materialType="oss_material_images"
          @uploadComplete="uploadComplete"
          suffix="image"
          :filters="{
            mime_types: [
              {
                title: 'jpg,png',
                extensions: 'jpg,png',
              },
            ],
            max_file_size: '2mb',
          }"
        >
          <div class="has-img" v-if="data.coverUrl">
            <img :src="data.coverUrl" alt="" />
            <div class="change-img">Change image</div>
          </div>
          <div class="no-img" v-else>
            <div class="upload-box">
              <div class="icon"></div>
              <div class="icon-text">Upload image</div>
            </div>
          </div>
        </upload>
      </div>
      <div class="tips" v-if="data.coverType === 'image'">
        <p>
          Recommended pixels: 750*98px, supports JPG, PNG and the size does not exceed 2MB;
        </p>
      </div>
    </div>
    <img-crop
      :cover="reCoverUrl"
      :visible="enabled"
      v-if="enabledShow"
      :w="w"
      :h="h"
      :limt="limt"
      @crop-upload-success="cutUploadSuccess"
      @cancel="cutCancel"
    ></img-crop>
  </div>
</template>
<script>
import ImgCrop from '@components/img-crop';
import upload from '@components/upload'

export default {
  props: {
    data: {
      Type: Object,
      default: {},
    },
  },
  components: {
    ImgCrop,
    upload
  },
  data() {
    return {
      defaultCover: 'https://yololiv-host.oss-us-west-1.aliyuncs.com/yololiv-overseas/image/2021-11-11/sJGQJAr2MpiXYKxbsxidpZFAecPRjKHb.png',
      w: 750,
      h: 98,
      limt: '2M',
      reCoverUrl: '',
      enabled: false,
      enabledShow: false,
    }
  },
  created () {
    // console.log(this.data);
  },
  methods: {
    uploadComplete(file) {
      this.reCoverUrl = file.url;
      this.enabledShow = true;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.enabled = true;
      }, 200);
    },
    cutUploadSuccess(url) {
      this.enabled = false;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.enabledShow = false;
        this.reCoverUrl = '';
        this.data.coverUrl = url;
      }, 200);
    },
    cutCancel() {
      this.enabled = false;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.enabledShow = false;
        this.reCoverUrl = '';
      }, 200);
    },
  },
};
</script>
<style lang="scss">
.banner-edit {
  padding: 16px;
  .cover-box {
    .text {
      font-size: 14px;
      font-family: HelveticaNeue;
      font-weight: 500;
      color: $-color-black-80;
      margin-bottom: 16px;
    }
    .tips {
      margin-top: 8px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: $-color-black-40;
      line-height: 18px;
    }
    .add-bg-box {
      margin-top: 16px;
      .has-img {
        width: 344px;
        height: 72px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        &:hover {
          .change-img {
            display: block;
          }
        }
        .change-img {
          display: none;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          line-height: 72px;
          text-align: center;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          background-color: rgba(0, 0, 0, 0.4);
          font-family: HelveticaNeue;
          cursor: pointer;
        }
      }
    }
    .upload-box {
      width: 100%;
      height: 72px;
      background: rgba(247, 248, 250, 1);
      border: 1px dashed rgba(0, 0, 0, 0.15);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .icon {
        width: 8px;
        height: 8px;
        background: url(~@assets/images/designer/upload@2x.png)
          no-repeat;
        background-size: 100%;
      }
      .icon-text {
        font-size: 14px;
        font-family: HelveticaNeue;
        font-weight: 400;
        color: $-color-black-40;
        margin-left: 6px;
      }
    }
  }
}
</style>
