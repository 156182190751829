<template>
  <div class="_overview-eara">
    <div class="_overview-eara__head">
      <h4>Overview</h4>
    </div>
    <div class="_overview-eara__main">
      <ul class="_overview-eara__infos">
        <li class="_overview-eara__info">
          <p class="_overview-eara__info-label">Start Time:</p>
          <p class="_overview-eara__info-value">{{ beginTime }}</p>
        </li>
        <li class="_overview-eara__info">
          <p class="_overview-eara__info-label">View URL:</p>
          <p class="_overview-eara__info-value _overview-eara__info-block">
            <span class="_overview-eara__info-value-content">
              <span class="_overview-eara__info-value-content-inner c-ellipsis">
                {{ url }}
              </span>
            </span>
            <span class="_overview-eara__info-copy" v-copy="url">Copy</span>
          </p>
        </li>
        <li class="_overview-eara__info _overview-eara__info-share">
          <p class="_overview-eara__info-label">Share to:</p>
          <p class="_overview-eara__info-value">
            <span class="_overview-eara__info-share-btn" @click="shareFacebook">
              <i class="ui-icon-facebook"></i>
            </span>
            <span class="_overview-eara__info-share-btn" @click="shareTwitter">
              <i class="ui-icon-twitter"></i>
            </span>
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    consoleInfo: Object
  },
  computed: {
    url() {
      if (!this.consoleInfo) {
        return 'loading'
      }

      return process.env.VUE_APP_H5 + '/live/' + this.consoleInfo.activityId
    },
    beginTime() {
      return this.consoleInfo
        ? moment(this.consoleInfo.beginTime * 1000).format(
            'MMM D, YYYY hh:mm A'
          )
        : 'loading'
    }
  },
  methods: {
    shareFacebook() {
      const params = [
        ['u', this.url],
        ['display', 'popup'],
        ['title', this.consoleInfo.title],
        ['quote', this.consoleInfo.description]
        // ['description', this.consoleInfo.description],
        // [
        //   'picture',
        //   'http://www.applezein.net/wordpress/wp-content/uploads/2015/03/facebook-logo.jpg'
        // ]
      ]
      const str = params.map((item) => item.join('=')).join('&')
      window.open(`https://www.facebook.com/sharer/sharer.php?${str}`)
    },
    shareTwitter() {
      const params = [
        ['url', this.url],
        ['text', this.consoleInfo.description]
      ]
      const str = params.map((item) => item.join('=')).join('&')
      window.open(`https://twitter.com/intent/tweet?${str}`)
    }
  }
}
</script>

<style lang="scss" scoped>
._overview-eara {
  padding: 12px 24px;
  color: #fff;
}
._overview-eara__head {
  h4 {
    margin: 0;
    font-size: 20px;
  }
}
._overview-eara__info {
  padding: 24px 0;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
  }
}
._overview-eara__info-label {
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.6);
}
._overview-eara__info-value {
  line-height: 18px;
  display: flex;
}
._overview-eara__info-value-content {
  flex: 1;
  position: relative;
}
._overview-eara__info-value-content-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
._overview-eara__info-block {
  padding: 0 8px;
  line-height: 28px;
  background: rgba(255, 255, 255, 0.04);
  border-radius: 4px;
}
._overview-eara__info-copy {
  margin-left: 12px;
  color: #ff4d7c;
  cursor: pointer;
}
._overview-eara__info-share-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: rgba(255, 255, 255, 0.06);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.5s;
  & + ._overview-eara__info-share-btn {
    margin-left: 12px;
  }
  &:hover {
    background-color: #ff4d7c;
  }
}
</style>
