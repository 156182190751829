<template>
  <div class="video-list">
    <div class="video-list-title" v-show="data.titleSwitch">{{ data.titleSwitch ? data.title : '' }}</div>
    <div
      v-if="data.videoList.length === 0"
      :class="{
        'video-list-row': data.typeStyle === 'row',
        'video-list-col': data.typeStyle === 'col',
      }"
    ></div>
    <div
      v-if="data.videoList.length > 0"
      class="has-video"
      :class="{
        row: data.typeStyle === 'row',
        col: data.typeStyle === 'col',
      }"
    >
      <div
        class="video-item"
        v-for="(item, index) in data.videoList"
        :key="index"
      >
        <div class="cover-box">
           <img class="cover" :src="item.coverUrl" alt="" v-if="item.coverUrl" />
          <div class="default-bg" alt="" v-else></div>
          <img class="play-logo" src="~@assets/images/designer/play.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      Type: Object,
      default: () => {
        return {}
      }
    },
    stylesheet: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  created() {
    console.log(this.data)
  },
};
</script>
<style lang="scss" scoped>
.video-list {
  .video-list-title {
    padding: 12px;
    font-size: 14px;
    font-family: HelveticaNeue;
    font-weight: 500;
  }
  .video-list-row {
    background: url(~@assets/images/designer/video-list-row@2x.png)
      no-repeat;
    height: 97px;
    background-size: contain;
  }
  .video-list-col {
    background: url(~@assets/images/designer/video-list-col@2x.png)
      no-repeat;
    height: 209px;
    background-size: contain;
  }
  .video-zone-two {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .video-zone-row {
    display: flex;
    overflow-x: scroll;
  }
  .video-zone {
    display: flex;
    flex-wrap: wrap;
  }
  .has-video {
    display: flex;
    flex-wrap: wrap;
    padding-top: 12px;
    .video-item {
      position: relative;
      box-sizing: border-box;
      .default-bg {
        width: 100%;
        height: 100%;
        background: url('https://yololiv-host.oss-us-west-1.aliyuncs.com/yololiv-overseas/image/2021-11-11/XmY6JZFW6EkmGkJRZT6hQxemFi3Mj3QE.png') no-repeat;
        background-size: 100%;
      }
      .cover {
        width: 100%;
        height: 100%;
      }
      .delete-logo {
        width: 24px;
        height: 24px;
        position: absolute;
        top: -12px;
        right: -12px;
        cursor: pointer;
        display: none;
      }
      .play-logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
      .cover-box {
        width: 344px;
        height: 193px;
        margin: 0 auto;
        position: relative;
        background-color: #000000;
        .cover {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .video-text {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        padding: 12px;
      }
    }
  }
  .two {
    padding: 12px;
    justify-content: space-between;
    .video-item {
      width: 49%;
      .play-logo {
        width: 30px;
        height: 30px;
      }
      .cover-box {
        width: 100%;
        height: 100px;
      }
      .video-text {
        width: 100%;
        height: 43px;
        word-break: break-all;
      }
    }
  }
  .row {
    overflow-x: hidden;
    flex-wrap: nowrap;
    padding: 12px;
    .video-item {
      width: 140px;
      margin-right: 8px;
      .play-logo {
        width: 23px;
        height: 23px;
      }
      .cover-box {
        width: 140px;
        height: 78px;
      }
      .video-text {
        width: 140px;
        height: 43px;
        word-break: break-all;
      }
    }
  }
  .col {
    padding: 12px;
    .video-item {
      width: 100%;
      margin-top: 10px;
      &:first-child {
        margin-top: 0;
      }
      .play-logo {
        width: 55px;
        height: 55px;
      }
      .cover-box {
        height: 197px;
      }
      .video-text {
        width: 100%;
        height: 43px;
        word-break: break-all;
      }
    }
  }
}
</style>
