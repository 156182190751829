const apis = [
  {
    // 获取聊天记录
    fn: 'chatPage',
    method: 'GET',
    url: '/overseas/chat/page',
    hideloading: true
  },
  {
    // 发送直播聊天评论
    fn: 'chatAdd',
    method: 'POST',
    url: '/overseas/chat/send',
    hideloading: true
  },
  {
    // 实时获取评论
    fn: 'chatLoop',
    method: 'GET',
    url: '/overseas/chat',
    hideloading: true,
    hideError: true
  },
  {
    // 聊天针对用户禁言
    fn: 'chatMute',
    method: 'POST',
    url: '/overseas/chat/mute',
    hideloading: true
  },
  {
    // 聊天用户解除禁言
    fn: 'unChatMute',
    method: 'DELETE',
    url: '/overseas/chat/mute',
    hideloading: true
  },
  {
    // 获取用户禁言信息
    fn: 'getChatMute',
    method: 'GET',
    url: '/overseas/chat/mute',
    hideloading: true
  },
  {
    // 获取禁言列表
    fn: 'muteList',
    method: 'GET',
    url: '/overseas/chat/mute/list',
    hideloading: true
  },
  {
    // 删除用户聊天评论
    fn: 'deleteChat',
    method: 'DELETE',
    url: '/overseas/chat',
    hideloading: true
  }
]

export const baseUrl = process.env.VUE_APP_OVERSEAS_URL

export default apis
