<template>
  <div class="_chat-area-chat">
    <div class="_chat-area-chat__load" v-if="loading">
      <i style="font-size: 20px" class="el-icon-loading"></i>
    </div>
    <template v-else-if="list.length">
      <div
        class="_chat-area-chat__mute-item"
        v-for="item in list"
        :key="item.audienceId"
      >
        <div class="_chat-area-chat__mute-avatar">
          <img :src="item.avatar || defaultAudienceAvatar" alt="avatar" />
        </div>
        <div class="_chat-area-chat__info">
          <p class="_chat-area-chat__usernamne">{{ item.name }}</p>
          <p class="_chat-area-chat__desc">
            Banned at {{ item.muteTime | hm }}
          </p>
        </div>
        <div class="_chat-area-chat__action">
          <y-button type="text" @click="unMuteUser(item)">Unmute</y-button>
        </div>
      </div>
    </template>
    <div v-else class="_chat-area-chat__empty">
      <img src="@/assets/images/icon-empty-mute.png" alt="empty" />
      No viewer has been muted.
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import defaultAudienceAvatar from '@/assets/images/icon-avatar.png'

export default {
  data() {
    return {
      activityId: this.$route.params.id,
      defaultAudienceAvatar,
      list: [],
      loading: false
    }
  },
  mounted() {
    this.fetchList()
  },
  filters: {
    hm(v) {
      return moment(+v).format('hh:mm A')
    }
  },
  methods: {
    async fetchList() {
      this.loading = true
      const res = await this.$serve.audience.muteList({
        params: {
          activityId: this.activityId
        }
      })
      this.loading = false
      if (res === this.$serve.FAIL) {
        return
      }
      this.list = res
    },
    async unMuteUser(record) {
      const res = await this.$serve.audience.unChatMute({
        params: {
          audienceId: record.audienceId,
          activityId: this.activityId
        }
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.fetchList()
    }
  }
}
</script>

<style lang="scss" scoped>
._chat-area-chat {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  color: #fff;
  padding-top: 8px;
}
._chat-area-chat__load {
  text-align: center;
}
._chat-area-chat__mute-item {
  display: flex;
  align-items: center;
  padding: 16px 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
._chat-area-chat__mute-avatar {
  width: 40px;
  height: 40px;
  background: #bfc4cd;
  border-radius: 20px;
  margin-right: 12px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}
._chat-area-chat__info {
  flex: 1;
}
._chat-area-chat__usernamne {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
._chat-area-chat__desc {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 2px;
}
._chat-area-chat__action {
  margin-left: 8px;
  .el-button {
    color: #ff4d7c;
  }
}
._chat-area-chat__empty {
  padding: 42px 0 42px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.4);
  text-align: center;
  img {
    display: block;
    width: 80px;
    height: 80px;
    margin: 0 auto 16px;
  }
}
</style>
