<template>
  <el-col v-bind="layout" class="grid-form-item">
    <y-form-item v-bind="formItemProps">
      <slot></slot>
    </y-form-item>
  </el-col>
</template>

<script>
import FormItem from '@base/form-item'
import Col from '@base/col'

export default {
  components: {
    [FormItem.$name]: FormItem,
    [Col.name]: Col
  },
  props: {
    formItemProps: Object,
    layout: {
      type: Object,
      default: () => ({
        xs: 24,
        sm: 12,
        lg: 8,
        xl: 6
      })
    }
  }
}
</script>
