import { propsKeys as proFormPropsKeys } from '@pro/pro-form/src/config/props'
import { formPropsKeys } from '@pro/pro-form/src/config/form-props'
import { listPageFormPropsKeys } from '../config/form-props'
import allFormPropsKeys from '../config/allFormPropsKeys'
import AllTablePropsKeys from '../config/AllTablePropsKeys'

import { FORM } from '../config/prefix'

import { firstLetterToUpper } from './tools'

const allProFormPropsKeys = [...formPropsKeys, ...proFormPropsKeys]

const formatFormProps = (props) => {
  const newProps = {}

  // 只要对allFormPropsKeys中包含且不和AllTablePropsKeys冲突的属性处理
  const validFormProps = Object.entries(props).filter(
    ([k, v]) =>
      allFormPropsKeys.includes(k) &&
      !AllTablePropsKeys.includes(k) &&
      /**
       * 在申请属性的时候, 默认把listPageFormProps属性都设置了undefined
       * 可以避免listPageFormProps值为undefined的属性把proFormPropsKeys的值给覆盖了的情况
       */
      v !== undefined
  )

  validFormProps.forEach(([k, v]) => {
    if (listPageFormPropsKeys.includes(k)) {
      const proFormProp = allProFormPropsKeys.find((key) => {
        return k === `${FORM}${firstLetterToUpper(key)}`
      })

      if (proFormProp) {
        newProps[proFormProp] = v
        return
      }
    }

    if (allProFormPropsKeys.includes(k)) {
      if (newProps[k] === undefined) {
        // 同样的属性声明两次, 比如y-f-inline和inline都传入了, 那么舍弃inline的传入
        newProps[k] = v
      }
      return
    }
  })
  return newProps
}

export default formatFormProps
