/**
 * [formItemProps](https://element.eleme.cn/2.14/#/zh-CN/component/form#form-item-attributes)
 * el-form-item 原生属性
 */

const formItemProps = {
  prop: String,
  label: String,
  labelWidth: String,
  required: {
    type: Boolean,
    default: false
  },
  rules: Object,
  error: String,
  showMessage: {
    type: Boolean,
    default: true
  },
  inlineMessage: {
    type: Boolean,
    default: false
  },
  size: String // medium / small / mini
}

export const formItemPropsKeys = Object.keys(formItemProps)

export default formItemProps
