<template>
  <div class="result-drag">
    <draggable
      class="drag-area"
      tag="div"
      :list="modules"
      :group="{ name: 'g1' }"
      animation="300"
      :move="onMove"
      ghost-class="ghost"
      :sort="false"
      handle=".nothing"
    >
      <div
        v-for="(el, index) in modules"
        :key="el.id"
        @click.stop="showItem(el)"
        class="com-item"
        @mouseover.stop="showBorder(el)"
        @mouseout.stop="hideBorder(el)"
        :class="{ hoverd: el.over, active: el.active, 'com-guide': el.type === 'guide', 'com-common': el.type !== 'guide' }"
      >
        <div
          class="mask"
          v-if="el.type !== 'menu-tab' && el.type !== 'menu-tab-content'"
        ></div>
        <div class="border right" v-if="el.active || el.over"></div>
        <div class="border top" v-if="el.active || el.over"></div>
        <div class="border left" v-if="el.active || el.over"></div>
        <div class="border bottom" v-if="el.active || el.over"></div>
        <div :id="el.id" v-if="el.type === 'video-player'">
          <video-player :data="el" :stylesheet="stylesheet"></video-player>
          <div class="tip" v-if="showTip">
            <div class="tip-msg">Live Streaming</div>
            <div class="yousanjiao"></div>
          </div>
          <div class="do-btn" v-if="el.active">
            <div class="to-top">
              <el-tooltip effect="dark" content="Move up" placement="right" v-if="index !== 1">
                <img
                  src="../../../../assets/images/designer/up.png"
                  alt=""
                  @click.stop="toUp(el, index)"
                />
              </el-tooltip>
              <img
                src="../../../../assets/images/designer/operation_up@2x.png"
                alt=""
                v-else
              />
            </div>
            <div class="to-bottom">
              <el-tooltip effect="dark" content="Move down" placement="right" v-if="index !== modules.length - 1">
                <img
                  src="../../../../assets/images/designer/down.png"
                  alt=""
                  @click.stop="toDown(el, index)"
                />
              </el-tooltip>
              <img
                src="../../../../assets/images/designer/operation_down@2x.png"
                alt=""
                v-else
              />
            </div>
            <div class="to-delete">
              <img
                src="../../../../assets/images/designer/operation_delete.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div :id="el.id" v-if="el.type === 'menu-tab'" class="tab-box">
          <div class="menu-tab">
            <div
              class="tab-box"
              :style="{ justifyContent: el.stylesheet.textAlign }"
            >
              <div
                class="tab-item"
                :class="{ active: item.show }"
                v-for="(item, index) in el.modules"
                :key="index"
                @click.stop="changeTab(el, index)"
              >
                {{ item.name }}
              </div>
            </div>
            <div
              class="content-box"
              v-for="(item, indexC) in el.modules"
              v-show="item.show"
              :key="`${item.name}${indexC}`"
              :style="{height: el.stylesheet.style.height, backgroundImage: el.stylesheet.background.type === 'image' ? el.stylesheet.style.backgroundImage : ''}"
            >
              <div style="display: flex" :style="{height: el.stylesheet.style.height}">
                <div class="seat-box" v-show="item.modules.length === 0">
                  可添加组件内容
                </div>
                <nested
                  :modules="item.modules"
                  :showTip="false"
                  @updataResult="updataResult"
                  @closeEdit="closeEdit"
                  :stylesheet="stylesheet"
                />
              </div>
            </div>
          </div>
          <div class="tip" v-if="showTip">
            <div class="tip-msg">菜单栏</div>
            <div class="yousanjiao"></div>
          </div>
          <div class="do-btn" v-if="el.active">
            <div class="to-top">
              <el-tooltip effect="dark" content="Move up" placement="right" v-if="index !== 1">
                <img
                  src="../../../../assets/images/designer/up.png"
                  alt=""
                  @click.stop="toUp(el, index)"
                />
              </el-tooltip>
              <img
                src="../../../../assets/images/designer/operation_up@2x.png"
                alt=""
                v-else
              />
            </div>
            <div class="to-bottom">
              <el-tooltip effect="dark" content="Move down" placement="right" v-if="index !== modules.length - 1">
                <img
                  src="../../../../assets/images/designer/down.png"
                  alt=""
                  @click.stop="toDown(el, index)"
                />
              </el-tooltip>
              <img
                src="../../../../assets/images/designer/operation_down@2x.png"
                alt=""
                v-else
              />
            </div>
            <div class="to-delete">
              <el-tooltip effect="dark" content="Delete" placement="right">
                <img
                  src="../../../../assets/images/designer/delete@2x.png"
                  alt=""
                  @click.stop="toDelete(el, index)"
                />
              </el-tooltip>
            </div>
          </div>
        </div>
        <div :id="el.id" v-if="el.type === 'introduction'">
          <introduction :data="el" :stylesheet="stylesheet"></introduction>
          <div class="tip" v-if="showTip">
            <div class="tip-msg">Introduction</div>
            <div class="yousanjiao"></div>
          </div>
          <div class="do-btn" v-if="el.active">
            <div class="to-top">
              <el-tooltip effect="dark" content="Move up" placement="right" v-if="index !== 1">
                <img
                  src="../../../../assets/images/designer/up.png"
                  alt=""
                  @click.stop="toUp(el, index)"
                />
              </el-tooltip>
              <img src="../../../../assets/images/designer/operation_up@2x.png" alt="" v-else />
            </div>
            <div class="to-bottom">
              <el-tooltip effect="dark" content="Move down" placement="right" v-if="index !== modules.length - 1">
                <img
                  src="../../../../assets/images/designer/down.png"
                  alt=""
                  @click.stop="toDown(el, index)"
                />
              </el-tooltip>
              <img src="../../../../assets/images/designer/operation_down@2x.png" alt="" v-else />
            </div>
            <div class="to-delete">
              <el-tooltip effect="dark" content="Delete" placement="right">
                <img
                  src="../../../../assets/images/designer/delete@2x.png"
                  alt=""
                  @click.stop="toDelete(el, index)"
                />
              </el-tooltip>
            </div>
          </div>
        </div>
        <div :id="el.id" v-if="el.type === 'album'">
          <album :data="el" :stylesheet="stylesheet"></album>
          <div class="tip" v-if="showTip">
            <div class="tip-msg">Image Group</div>
            <div class="yousanjiao"></div>
          </div>
          <div class="do-btn" v-if="el.active">
            <div class="to-top">
              <el-tooltip effect="dark" content="Move up" placement="right" v-if="index !== 1">
                <img
                  src="../../../../assets/images/designer/up.png"
                  alt=""
                  @click.stop="toUp(el, index)"
                />
              </el-tooltip>
              <img src="../../../../assets/images/designer/operation_up@2x.png" alt="" v-else />
            </div>
            <div class="to-bottom">
              <el-tooltip effect="dark" content="Move down" placement="right" v-if="index !== modules.length - 1">
                <img
                  src="../../../../assets/images/designer/down.png"
                  alt=""
                  @click.stop="toDown(el, index)"
                />
              </el-tooltip>
              <img src="../../../../assets/images/designer/operation_down@2x.png" alt="" v-else />
            </div>
            <div class="to-delete">
              <el-tooltip effect="dark" content="Delete" placement="right">
                <img
                  src="../../../../assets/images/designer/delete@2x.png"
                  alt=""
                  @click.stop="toDelete(el, index)"
                />
              </el-tooltip>
            </div>
          </div>
        </div>
        <div :id="el.id" v-if="el.type === 'image-swiper'">
          <image-swiper :data="el"></image-swiper>
          <div class="tip" v-if="showTip">
            <div class="tip-msg">轮播图</div>
            <div class="yousanjiao"></div>
          </div>
          <div class="do-btn" v-if="el.active">
            <div class="to-top">
              <el-tooltip effect="dark" content="Move up" placement="right" v-if="index !== 1">
                <img
                  src="../../../../assets/images/designer/up.png"
                  alt=""
                  @click.stop="toUp(el, index)"
                />
              </el-tooltip>
              <img src="../../../../assets/images/designer/operation_up@2x.png" alt="" v-else />
            </div>
            <div class="to-bottom">
              <el-tooltip effect="dark" content="Move down" placement="right" v-if="index !== modules.length - 1">
                <img
                  src="../../../../assets/images/designer/down.png"
                  alt=""
                  @click.stop="toDown(el, index)"
                />
              </el-tooltip>
              <img src="../../../../assets/images/designer/operation_down@2x.png" alt="" v-else />
            </div>
            <div class="to-delete">
              <el-tooltip effect="dark" content="Delete" placement="right">
                <img
                  src="../../../../assets/images/designer/delete@2x.png"
                  alt=""
                  @click.stop="toDelete(el, index)"
                />
              </el-tooltip>
            </div>
          </div>
        </div>
        <div :id="el.id" v-if="el.type === 'subscribe'">
          <subscribe :data="el" :stylesheet="stylesheet"></subscribe>
          <div class="tip" v-if="showTip">
            <div class="tip-msg">Card</div>
            <div class="yousanjiao"></div>
          </div>
          <div class="do-btn" v-if="el.active">
            <div class="to-top">
              <el-tooltip effect="dark" content="Move up" placement="right" v-if="index !== 1">
                <img
                  src="../../../../assets/images/designer/up.png"
                  alt=""
                  @click.stop="toUp(el, index)"
                />
              </el-tooltip>
              <img src="../../../../assets/images/designer/operation_up@2x.png" alt="" v-else />
            </div>
            <div class="to-bottom">
              <el-tooltip effect="dark" content="Move down" placement="right" v-if="index !== modules.length - 1">
                <img
                  src="../../../../assets/images/designer/down.png"
                  alt=""
                  @click.stop="toDown(el, index)"
                />
              </el-tooltip>
              <img src="../../../../assets/images/designer/operation_down@2x.png" alt="" v-else />
            </div>
            <div class="to-delete">
              <el-tooltip effect="dark" content="Delete" placement="right">
                <img
                  src="../../../../assets/images/designer/delete@2x.png"
                  alt=""
                  @click.stop="toDelete(el, index)"
                />
              </el-tooltip>
            </div>
          </div>
        </div>
        <div :id="el.id" v-if="el.type === 'image-article'">
          <image-article :data="el" :stylesheet="stylesheet"></image-article>
          <div class="tip" v-if="showTip">
            <div class="tip-msg">图文</div>
            <div class="yousanjiao"></div>
          </div>
          <div class="do-btn" v-if="el.active">
            <div class="to-top">
              <el-tooltip effect="dark" content="Move up" placement="right" v-if="index !== 1">
                <img
                  src="../../../../assets/images/designer/up.png"
                  alt=""
                  @click.stop="toUp(el, index)"
                />
              </el-tooltip>
              <img src="../../../../assets/images/designer/operation_up@2x.png" alt="" v-else />
            </div>
            <div class="to-bottom">
              <el-tooltip effect="dark" content="Move down" placement="right" v-if="index !== modules.length - 1">
                <img
                  src="../../../../assets/images/designer/down.png"
                  alt=""
                  @click.stop="toDown(el, index)"
                />
              </el-tooltip>
              <img src="../../../../assets/images/designer/operation_down@2x.png" alt="" v-else />
            </div>
            <div class="to-delete">
              <el-tooltip effect="dark" content="Delete" placement="right">
                <img
                  src="../../../../assets/images/designer/delete@2x.png"
                  alt=""
                  @click.stop="toDelete(el, index)"
                />
              </el-tooltip>
            </div>
          </div>
        </div>
        <div :id="el.id" v-if="el.type === 'video-list'">
          <video-list :data="el" :stylesheet="stylesheet"></video-list>
          <div class="tip" v-if="showTip">
            <div class="tip-msg">Video</div>
            <div class="yousanjiao"></div>
          </div>
          <div class="do-btn" v-if="el.active">
            <div class="to-top">
              <el-tooltip effect="dark" content="Move up" placement="right" v-if="index !== 1">
                <img
                  src="../../../../assets/images/designer/up.png"
                  alt=""
                  @click.stop="toUp(el, index)"
                />
              </el-tooltip>
              <img
                src="../../../../assets/images/designer/operation_up@2x.png"
                alt=""
                v-else
              />
            </div>
            <div class="to-bottom">
              <el-tooltip effect="dark" content="Move down" placement="right" v-if="index !== modules.length - 1">
                <img
                  src="../../../../assets/images/designer/down.png"
                  alt=""
                  @click.stop="toDown(el, index)"
                />
              </el-tooltip>
              <img
                src="../../../../assets/images/designer/operation_down@2x.png"
                alt=""
                v-else
              />
            </div>
            <div class="to-delete">
              <el-tooltip effect="dark" content="Delete" placement="right">
                <img
                  src="../../../../assets/images/designer/delete@2x.png"
                  alt=""
                  @click.stop="toDelete(el, index)"
                />
              </el-tooltip>
            </div>
          </div>
        </div>
        <div :id="el.id" v-if="el.type === 'comment'">
          <comment :data="el" :stylesheet="stylesheet"></comment>
          <div class="tip" v-if="showTip">
            <div class="tip-msg">评论</div>
            <div class="yousanjiao"></div>
          </div>
          <div class="do-btn" v-if="el.active">
            <div class="to-top">
              <el-tooltip effect="dark" content="Move up" placement="right" v-if="index !== 1">
                <img
                  src="../../../../assets/images/designer/up.png"
                  alt=""
                  @click.stop="toUp(el, index)"
                />
              </el-tooltip>
              <img
                src="../../../../assets/images/designer/operation_up@2x.png"
                alt=""
                v-else
              />
            </div>
            <div class="to-bottom">
              <el-tooltip effect="dark" content="Move down" placement="right" v-if="index !== modules.length - 1">
                <img
                  src="../../../../assets/images/designer/down.png"
                  alt=""
                  @click.stop="toDown(el, index)"
                />
              </el-tooltip>
              <img
                src="../../../../assets/images/designer/operation_down@2x.png"
                alt=""
                v-else
              />
            </div>
            <div class="to-delete">
              <el-tooltip effect="dark" content="Delete" placement="right">
                <img
                  src="../../../../assets/images/designer/delete@2x.png"
                  alt=""
                  @click.stop="toDelete(el, index)"
                />
              </el-tooltip>
            </div>
          </div>
        </div>
        <div :id="el.id" v-if="el.type === 'file'">
          <file :data="el" :stylesheet="stylesheet" :fromTemplate="fromTemplate"></file>
          <div class="tip" v-if="showTip">
            <div class="tip-msg">Document</div>
            <div class="yousanjiao"></div>
          </div>
          <div class="do-btn" v-if="el.active">
            <div class="to-top">
              <el-tooltip effect="dark" content="Move up" placement="right" v-if="index !== 1">
                <img
                  src="../../../../assets/images/designer/up.png"
                  alt=""
                  @click.stop="toUp(el, index)"
                />
              </el-tooltip>
              <img
                src="../../../../assets/images/designer/operation_up@2x.png"
                alt=""
                v-else
              />
            </div>
            <div class="to-bottom">
              <el-tooltip effect="dark" content="Move down" placement="right" v-if="index !== modules.length - 1">
                <img
                  src="../../../../assets/images/designer/down.png"
                  alt=""
                  @click.stop="toDown(el, index)"
                />
              </el-tooltip>
              <img
                src="../../../../assets/images/designer/operation_down@2x.png"
                alt=""
                v-else
              />
            </div>
            <div class="to-delete">
              <el-tooltip effect="dark" content="Delete" placement="right">
                <img
                  src="../../../../assets/images/designer/delete@2x.png"
                  alt=""
                  @click.stop="toDelete(el, index)"
                />
              </el-tooltip>
            </div>
          </div>
        </div>
        <div :id="el.id" v-if="el.type === 'banner'">
          <banner :data="el" :stylesheet="stylesheet"></banner>
          <!--<div class="tip" v-if="showTip">
            <div class="tip-msg">Banner</div>
            <div class="yousanjiao"></div>
          </div>-->
          <div class="do-btn" v-if="el.active">
            <div class="to-top">
              <img
                src="../../../../assets/images/designer/operation_up@2x.png"
                alt=""
              />
            </div>
            <div class="to-bottom">
              <img
                src="../../../../assets/images/designer/operation_down@2x.png"
                alt=""
              />
            </div>
            <div class="to-delete">
              <img
                src="../../../../assets/images/designer/operation_delete.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div :id="el.id" v-if="el.type === 'live-info'">
          <live-info :data="el" :stylesheet="stylesheet"></live-info>
          <div class="tip" v-if="showTip">
            <div class="tip-msg">直播信息</div>
            <div class="yousanjiao"></div>
          </div>
          <div class="do-btn" v-if="el.active">
            <div class="to-top">
              <el-tooltip effect="dark" content="Move up" placement="right" v-if="index !== 1">
                <img
                  src="../../../../assets/images/designer/up.png"
                  alt=""
                  @click.stop="toUp(el, index)"
                />
              </el-tooltip>
              <img
                src="../../../../assets/images/designer/operation_up@2x.png"
                alt=""
                v-else
              />
            </div>
            <div class="to-bottom">
              <el-tooltip effect="dark" content="Move down" placement="right" v-if="index !== modules.length - 1">
                <img
                  src="../../../../assets/images/designer/down.png"
                  alt=""
                  @click.stop="toDown(el, index)"
                />
              </el-tooltip>
              <img
                src="../../../../assets/images/designer/operation_down@2x.png"
                alt=""
                v-else
              />
            </div>
            <div class="to-delete">
              <el-tooltip effect="dark" content="Delete" placement="right">
                <img
                  src="../../../../assets/images/designer/delete@2x.png"
                  alt=""
                  @click.stop="toDelete(el, index)"
                />
              </el-tooltip>
            </div>
          </div>
        </div>
        <div :id="el.id" v-if="el.type === 'countdown'">
          <countdown :data="el" :stylesheet="stylesheet"></countdown>
          <div class="tip" v-if="showTip">
            <div class="tip-msg">直播倒计时</div>
            <div class="yousanjiao"></div>
          </div>
          <div class="do-btn" v-if="el.active">
            <div class="to-top">
              <el-tooltip effect="dark" content="Move up" placement="right" v-if="index !== 1">
                <img
                  src="../../../../assets/images/designer/up.png"
                  alt=""
                  @click.stop="toUp(el, index)"
                />
              </el-tooltip>
              <img
                src="../../../../assets/images/designer/operation_up@2x.png"
                alt=""
                v-else
              />
            </div>
            <div class="to-bottom">
              <el-tooltip effect="dark" content="Move down" placement="right" v-if="index !== modules.length - 1">
                <img
                  src="../../../../assets/images/designer/down.png"
                  alt=""
                  @click.stop="toDown(el, index)"
                />
              </el-tooltip>
              <img
                src="../../../../assets/images/designer/operation_down@2x.png"
                alt=""
                v-else
              />
            </div>
            <div class="to-delete">
              <el-tooltip effect="dark" content="Delete" placement="right">
                <img
                  src="../../../../assets/images/designer/delete@2x.png"
                  alt=""
                  @click.stop="toDelete(el, index)"
                />
              </el-tooltip>
            </div>
          </div>
        </div>
        <div :id="el.id" v-if="el.type === 'guide'">
          <guide :data="el" :stylesheet="stylesheet"></guide>
          <div class="tip" v-if="showTip">
            <div class="tip-msg">Guide Map</div>
            <div class="yousanjiao"></div>
          </div>
          <div class="do-btn" v-if="el.active">
            <div class="to-top">
              <img
                src="../../../../assets/images/designer/operation_up@2x.png"
                alt=""
              />
            </div>
            <div class="to-bottom">
              <img
                src="../../../../assets/images/designer/operation_down@2x.png"
                alt=""
              />
            </div>
            <div class="to-delete">
              <el-tooltip effect="dark" content="Delete" placement="right">
                <img
                  src="../../../../assets/images/designer/delete@2x.png"
                  alt=""
                  @click.stop="toDelete(el, index)"
                />
              </el-tooltip>
            </div>
          </div>
        </div>
        <div
          class="change-height"
          v-show="el.stylesheet.height === 'fixed' && el.active"
          @mousedown.stop.prevent="handleMouseDown($event, el)"
        ></div>
      </div>
    </draggable>
    <div class="btn-guide" v-if="JSON.stringify(JSON.stringify(modules)).indexOf('indexOfGuideSwitch') > 0">
      <y-button size="small" :type="pageType === 'guide' ? 'primary' : ''" @click="changePage('guide')">
        <i class="a-GM" v-if="pageType === 'guide'"></i>
        <i class="una-GM" v-else></i>
        Guide Map
      </y-button>
      <br>
      <y-button size="small" :type="pageType === 'live' ? 'primary' : ''" @click="changePage('live')">
        <i class="a-LP" v-if="pageType === 'live'"></i>
        <i class="una-LP" v-else></i>
        Live Page
      </y-button>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { uid } from "uid";
//中间部分的展示
import videoPlayer from "../components/video-player";
import introduction from "../components/introduction";
import album from "../components/album";
import imageSwiper from "../components/image-swiper";
import subscribe from "../components/subscribe";
import imageArticle from "../components/image-article";
import videoList from "../components/video-list";
import comment from "../components/comment";
import file from "../components/file";
import banner from "../components/banner";
import liveInfo from "../components/live-info";
import countdown from "../components/countdown";
import guide from "../components/guide";

// 右侧编辑部分
import videoPlayEdit from "../components/video-player/edit";
import albumEdit from "../components/album/edit";
import commentEdit from "../components/comment/edit";
import fileEdit from "../components/file/edit";
import imageArticleEdit from "../components/image-article/edit";
import imageSwiperEdit from "../components/image-swiper/edit";
import introductionEdit from "../components/introduction/edit";
import menuTabEdit from "../components/menu-tab/edit";
import subscribeEdit from "../components/subscribe/edit";
import videoListEdit from "../components/video-list/edit";
import bannerEdit from "../components/banner/edit";
import liveInfoEdit from "../components/live-info/edit";
import countdownEdit from "../components/countdown/edit";
import guideEdit from "../components/guide/edit";

export default {
  name: "Nested",
  components: {
    draggable,
    videoPlayer,
    introduction,
    album,
    imageSwiper,
    subscribe,
    imageArticle,
    videoList,
    comment,
    file,
    banner,
    liveInfo,
    countdown,
    guide
  },
  props: {
    modules: {
      required: true,
      type: Array,
    },
    showTip: {
      type: Boolean,
      default: true,
    },
    stylesheet: {
      type: Object,
      default: () => {
        return {};
      },
    },
    fromTemplate: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      moveName: "",
      drawer: false,
      showDrawer: false, // vIf产生，以方便数据的变动
      currentEdit: {
        name: "",
        edit: "",
      },
      currentCom: null, // 鼠标拖动时的对应组件
      pageType: 'guide'
    };
  },
  methods: {
    showBorder(el) {
      el.over = true;
      this.$forceUpdate();
    },
    hideBorder(el) {
      el.over = false;
      this.$forceUpdate();
    },
    async showItem(item) {
      // item.name = '233333';
      // 并重新设置唯一id
      if (!item.id) {
        item.id = uid();
      }
      this.$forceUpdate();
      switch (item.type) {
        case "album":
          this.currentEdit = {
            name: "Image Group",
            edit: albumEdit,
          };
          break;
        case "comment":
          this.currentEdit = {
            name: "评论",
            edit: commentEdit,
          };
          break;
        case "file":
          this.currentEdit = {
            name: "Document",
            edit: fileEdit,
          };
          break;
        case "image-article":
          this.currentEdit = {
            name: "图文",
            edit: imageArticleEdit,
          };
          break;
        case "image-swiper":
          this.currentEdit = {
            name: "轮播图",
            edit: imageSwiperEdit,
          };
          break;
        case "introduction":
          this.currentEdit = {
            name: "Introduction",
            edit: introductionEdit,
          };
          break;
        case "menu-tab":
          this.currentEdit = {
            name: "菜单栏",
            edit: menuTabEdit,
          };
          break;
        case "subscribe":
          this.currentEdit = {
            name: "Card",
            edit: subscribeEdit,
          };
          break;
        case "video-list":
          this.currentEdit = {
            name: "Video",
            edit: videoListEdit,
          };
          break;
        case "video-player":
          this.currentEdit = {
            name: "Live Streaming",
            edit: videoPlayEdit,
          };
          break;
        case "banner":
          this.currentEdit = {
            name: "Banner",
            edit: bannerEdit,
          };
          break;
        case "live-info":
          this.currentEdit = {
            name: "直播信息",
            edit: liveInfoEdit,
          };
          break;
        case "countdown":
          this.currentEdit = {
            name: "直播倒计时",
            edit: countdownEdit,
          };
          break;
        case "guide":
          this.currentEdit = {
            name: "Guide Map",
            edit: guideEdit,
          };
          break;
      }
      await this.$emit("updataResult", this.modules, this.currentEdit, item);
      // 并将当前的激活
      item.active = true;
    },
    swapArray(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    onMove(e) {
      this.moveName = e.relatedContext.element
        ? e.relatedContext.element.name
        : e.relatedContext.name;
    },
    toUp(el, index) {
      if (index <= 0) {
        return false;
      } else {
        // 上移
        this.swapArray(this.modules, index - 1, index);
      }
    },
    toDown(el, index) {
      if (index >= this.modules.length - 1) {
        return false;
      } else {
        // 下移
        this.swapArray(this.modules, index, index + 1);
      }
    },
    toDelete(el, index) {
      this.$confirm("You‘re about to delete a block. Are you sure you want to do that？", "Are you Sure？", {
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        if (el.limit) {
          el.existence--;
        }
        this.drawer = false;
        this.modules.splice(index, 1);
        this.$emit("closeEdit");
        // 特殊处理欢迎页
        if (el.type === 'guide') {
          let domsCommon = [...document.getElementsByClassName('com-common')];
          if (domsCommon.length > 0) {
            domsCommon.forEach(item => {
              item.style.display = 'block';
            })
          }
        }
        this.$forceUpdate();
        this.$emit('deleteCom')
      });
    },
    updataResult(data, data2, data3) {
      this.$emit("updataResult", data, data2, data3);
    },
    closeEdit() {
      this.$emit("closeEdit");
    },
    async changeTab(item, index) {
      this.showItem(item);
      await this.$emit("updataResult", this.modules, this.currentEdit, item);
      // 并将当前的激活
      item.active = true;
      console.log(item);
      item.modules.forEach((item) => {
        item.show = false;
      });
      this.$set(item.modules[index], 'show', true);
    },
    handleMouseDown(event, el) {
      // 鼠标按下时的位置
      let mouseDownY = event.clientY;
      let H = parseInt(el.stylesheet.style.height);

      document.onmousemove = function(ev) {
        let ev2 = ev || event;
        // 鼠标移动时的鼠标位置
        let mouseMoveY = ev2.clientY;
        let height = (mouseMoveY - mouseDownY) + H;
        if (height > 3000 || height < 340) {
          return false;
        }
        el.stylesheet.style.height = height + "px";
      };
      document.onmouseup = function () {
        document.onmousemove = null;
      };
      return false;
    },
    // 特殊处理欢迎页
    changePage(type) {
      if (this.pageType !== type && type === 'guide') {
        this.$emit("closeEdit");
        this.$emit('renderNoAdd', true)
        let domsCommon = [...document.getElementsByClassName('com-common')];
        if (domsCommon.length > 0) {
          domsCommon.forEach(item => {
            item.style.display = 'none';
          })
        }
        let domsGuide = [...document.getElementsByClassName('com-guide')];
        if (domsGuide.length > 0) {
          domsGuide[0].style.display = 'block';
        }
      } else if (this.pageType !== type && type === 'live'){
        this.$emit("closeEdit");
        this.$emit('renderNoAdd', false)
        let domsCommon = [...document.getElementsByClassName('com-common')];
        if (domsCommon.length > 0) {
          domsCommon.forEach(item => {
            item.style.display = 'block';
          })
        }
        let domsGuide = [...document.getElementsByClassName('com-guide')];
        if (domsGuide.length > 0) {
          domsGuide[0].style.display = 'none';
        }
      }
      this.pageType = type;
    }
  },
};
</script>
<style lang="scss">
@import "nested.scss";
</style>
