import Vue from 'vue'
import Bui from '@bui'
import serve from '@serve'
import Mqtt from '@utils/mqtt'
import copy from '@/directives/copy'
import scroll from '@/directives/scroll'
import drag from '@/directives/drag'
import infiniteScroll from 'vue-infinite-scroll'
import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.use(VueViewer)
Vue.prototype.$serve = serve
Vue.prototype.$mqtt = new Mqtt({
  host: process.env.VUE_APP_MQTTHOST, // 'mq.yunxi.tv',
  port: 8084
})
Vue.use(Bui)
Vue.use(infiniteScroll)
Vue.directive('copy', copy)
Vue.directive('scroll', scroll)
Vue.directive('drag', drag)
