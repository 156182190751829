<template>
  <y-form-item class="form-item" v-bind="formItemProps">
    <slot></slot>
  </y-form-item>
</template>

<script>
import FormItem from '@base/form-item'

export default {
  components: {
    [FormItem.$name]: FormItem
  },
  props: {
    formItemProps: Object
  }
}
</script>
