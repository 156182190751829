<template>
  <el-dropdown class="y-dropdown" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
    <slot slot="dropdown" name="dropdown"></slot>
  </el-dropdown>
</template>

<script>
import { Dropdown } from 'element-ui'

export default {
  name: 'y-dropdown',
  _name: 'Dropdown 下拉菜单',
  $depends: [Dropdown],
  components: {
    [Dropdown.name]: Dropdown
  }
}
</script>
