<template>
  <div class="_play-area">
    <!-- _play-area__status -->
    <div class="_play-area__status">
      <!-- 直播未开始/无流状态 -->
      <div class="_play-area__status-detail" v-if="current === 0">
        The live event hasn't started yet.
      </div>
      <!-- 直播暂停/无流状态 -->
      <div class="_play-area__status-detail" v-else-if="current === 5">
        The live event has paused.
      </div>
      <!-- 直播结束/无流状态 -->
      <div class="_play-area__status-detail" v-else-if="current === 2">
        The live event has ended.
      </div>
      <!-- 直播中/正常推流状态 -->
      <div class="_play-area__status-detail" v-else>
        <template v-if="networkStatus === 1">
          <audio-icon style="margin-right: 24px"></audio-icon>
          <p>The live event is in good progress.</p>
        </template>
        <template v-else-if="networkStatus === 2">
          <i class="ui-icon-danger"></i>
          <p>
            Network is poor. Please check your network connection and try again
            later.
          </p>
        </template>
        <template v-else-if="networkStatus === 3">
          <i class="ui-icon-warning"></i>
          <p>Your network connection is interrupted. Please check it out.</p>
        </template>
        <y-button
          class="_play-area__action"
          size="small"
          @click="stopLivingVisible = true"
        >
          End
        </y-button>
      </div>
    </div>
    <!-- /_play-area__status -->
    <!-- _play-area__container -->
    <div class="_play-area__container">
      <div
        v-if="playStatus === 'live'"
        class="_play-area__player"
        id="live-player"
      ></div>
      <div
        v-else-if="playStatus === 'back'"
        class="_play-area__player"
        id="back-player"
      ></div>
      <div
        v-else-if="playStatus === 'replace'"
        class="_play-area__player"
        id="replace-player"
      ></div>
      <div v-else class="_play-area__empty">
        {{ playStatusEmptyText }}
      </div>
      <div class="_play-area__mask" v-show="pauseMask" @click="maskTip"></div>
      <live-status v-if="consoleInfo" :status="status"></live-status>
    </div>
    <!-- /_play-area__container -->

    <!-- _play-area__info -->
    <div class="_play-area__actions">
      <div class="_play-area__replace">
        <p class="_play-area__replace-title">
          Playback Video
          <el-tooltip
            class="item"
            effect="dark"
            content="Replace the live stream with an edited video that you prefer."
            placement="right"
          >
            <i class="ui-icon-question" style="vertical-align: -2px"></i>
          </el-tooltip>
        </p>
        <y-button
          class="_play-area__replace-btn"
          size="mini"
          plain
          :disabled="status !== 2"
          @click="choiceMedia"
        >
          Replace Video
          <i class="ui-icon-change" style="vertical-align: -4px"></i>
        </y-button>
      </div>
      <div class="_play-area__share">
        <div class="_play-area-item" @click="shareTwitter">
          <i class="ui-icon-twitter-2"></i>
          <p>Twitter</p>
        </div>
        <div class="_play-area-item" @click="shareFacebook">
          <i class="ui-icon-facebook-2"></i>
          <p>Facebook</p>
        </div>
        <div class="_play-area-item" @click="copyVisible = true">
          <i class="ui-icon-copy-2"></i>
          <p>Copy Link</p>
        </div>
      </div>
    </div>
    <!-- /_play-area__info -->

    <!-- copy弹窗 -->
    <y-dialog
      title="Generat URL Scheme"
      :visible.sync="copyVisible"
      width="504px"
      @closed="copyDialogClosed"
    >
      <div class="_play-area__copy-dialog">
        <div class="_play-area__copy-item">
          <span class="_play-area__copy-item-label">URL:</span>
          <copy-content
            class="_play-area__copy-item-content"
            :text="url"
            color="rgba(0, 0, 0, 0.8)"
          ></copy-content>
        </div>
        <div class="_play-area__copy-item">
          <span class="_play-area__copy-item-label">Channel:</span>
          <div class="_play-area__copy-item-content">
            <div class="_play-area__copy-generate">
              <y-input
                v-model="channelName"
                size="mini"
                maxlength="10"
                placeholder="Add Channel"
              ></y-input>
              <span
                class="_play-area__copy-generate-loading"
                v-if="$store.state.loading['/overseas/console/share/channel']"
              >
                loading...
              </span>
              <y-button
                v-else
                type="text"
                size="small"
                :disabled="generateDisabled"
                @click="generateChannel"
              >
                Generate
              </y-button>
            </div>
            <p
              style="
                margin-top: 8px;
                font-size: 13px;
                color: rgba(0, 0, 0, 0.25);
                line-height: 16px;
              "
            >
              Tips：Only numbers, uppercase and lowercase English and some
              special characters are supported: !#$&'()*+,/:;=?@-._~
            </p>
            <ul
              class="_play-area__copy-chanel-items"
              v-loading="
                $store.state.loading['/overseas/console/share/channel/recent']
              "
            >
              <li
                class="_play-area__copy-chanel-item"
                v-for="item in channelList"
                :key="item.channelId"
                @click="selectChannel(item)"
              >
                {{ item.channelName }}
              </li>
            </ul>
          </div>
        </div>
        <div class="_play-area__copy-item _play-area__copy-url">
          <span v-if="channelId">{{ url + '?channelId=' + channelId }}</span>
        </div>
      </div>

      <div slot="footer" class="_play-area__copy-actions">
        <y-button size="small" @click="copyVisible = false">Cancel</y-button>
        <y-button
          size="small"
          type="primary"
          @click="sureCopy"
          :disabled="!channelId"
          v-copy="url + '?channelId=' + channelId"
        >
          Copy
        </y-button>
      </div>
    </y-dialog>
    <!-- /copy弹窗 -->

    <!-- 停止直播弹窗 -->
    <y-dialog title="Prompt" :visible.sync="stopLivingVisible" width="504px">
      <div class="_play-area__stop-dialog">
        you want to pause the live stream or just end it?
      </div>

      <div slot="footer" class="_play-area__stop-actions">
        <div class="_play-area__stop-dialog-cancel">
          <y-button type="text" size="small" @click="stopLivingVisible = false">
            Cancel
          </y-button>
        </div>
        <div class="_play-area__stop-dialog-sure">
          <y-button size="small" @click="pause">Pause</y-button>
          <y-button size="small" type="primary" @click="end">End</y-button>
        </div>
      </div>
    </y-dialog>
    <!-- /停止直播弹窗 -->

    <!--媒体库选择视频-->
    <media-dialog-radio
      v-if="visibleMediaShowVideo"
      :title="title"
      :button-text="buttonText"
      :extensions="extensions"
      :visible="visibleMediaVideo"
      :material-type="materialtype"
      :w="0"
      :h="0"
      :limt="limt"
      @updateMedia="uploadSuccessCover"
      suffix="image"
      :groupTitle="groupTitle"
      :multi_selection="false"
    ></media-dialog-radio>
    <!-- /媒体库选择视频-->
  </div>
</template>

<script>
import FlvPlayer from 'xgplayer-flv'
import HlsJsPlayer from 'xgplayer-hls.js'
import Player from 'xgplayer'
import LiveStatus from '../_live-status'
import CopyContent from '@components/copy-content'
import AudioIcon from '@components/dynamic-icon/audio-icon'
import mediaDialogRadio from '@/components/media-dialog-radio'

export default {
  components: {
    CopyContent,
    LiveStatus,
    AudioIcon,
    mediaDialogRadio
  },
  props: {
    consoleInfo: Object,
    liveInfo: Object
  },
  data() {
    return {
      loopTImer: null,
      networkStatus: 1, // 网络流状态：1-稳定，2-差，3-中断
      playStatus: 'empty', // 本地状态: empty live, back replace
      livePlayer: null,
      backPlayer: null,
      playStatusEmptyText: 'The live event hasn’t started yet.',
      stopLivingVisible: false,
      copyVisible: false,
      // 媒体库选择视频
      limt: '5GB',
      materialtype: 2,
      suffix: 'video',
      groupTitle: 'All Videos',
      title: 'Select Video',
      buttonText: 'Upload Video',
      extensions: 'mp4',
      visibleMediaShowVideo: false,
      visibleMediaVideo: false, // 媒体库选择视频
      channelName: '',
      channelId: '',
      channelList: []
    }
  },
  computed: {
    url() {
      if (!this.consoleInfo) {
        return 'loading'
      }

      return process.env.VUE_APP_H5 + '/live/' + this.consoleInfo.activityId
    },
    status() {
      return this.liveInfo?.activityStatus ?? this.consoleInfo?.activityStatus
    },
    pauseMask() {
      return this.liveInfo?.activityStatus === 5
    },
    // activityStatus: 直播间状态0:未开始,1:已删除,2:结束,3:预约,4:直播中,5:暂停
    // 3:失败,4:连接中 只在盒子当中使用
    current() {
      const consoleInfoActivityStatus = this.consoleInfo?.activityStatus
      const liveInfoActivityStatus = this.liveInfo?.activityStatus
      const activityStatus = liveInfoActivityStatus ?? consoleInfoActivityStatus
      switch (activityStatus) {
        case 0:
          return 0
        case 1:
          return 0
        case 2:
          return 2
        case 3:
          return 0
        case 4:
          return 4
        case 5:
          return 5
        default:
          return 0
      }
    },
    generateDisabled() {
      return !/^[!#$&'()*+,:;=?@\-._~\d\w]+$/.test(this.channelName)
    }
  },
  watch: {
    consoleInfo: {
      async handler(val) {
        if (this.liveInfo) {
          return
        }
        if (!val) {
          return
        }
        // activityStatus: 直播间状态0:未开始,1:已删除,2:结束,3:预约,4:直播中,5:暂停
        // pushModel: 推流类型: 1-直推，2-转推
        const {
          activityStatus,
          playBackUrl,
          flvLiveUrl,
          pushModel,
          replacePlayBackUrl
        } = val

        switch (activityStatus) {
          case 2:
            if ((!playBackUrl && !replacePlayBackUrl) || pushModel === 1) {
              this.playStatus = 'empty'
              this.playStatusEmptyText = 'The live event has ended.'
              return
            }
            if (replacePlayBackUrl || playBackUrl.endsWith('mp4')) {
              this.playStatus = 'replace'
              await this.$nextTick()
              this.renderRplacePlayer(replacePlayBackUrl || playBackUrl)
            } else if (playBackUrl) {
              this.playStatus = 'back'
              await this.$nextTick()
              this.renderBackXgPlayer(playBackUrl)
            }
            break
          case 4:
            if (!flvLiveUrl || pushModel === 1) {
              this.playStatus = 'empty'
              this.playStatusEmptyText = 'Connecting… Please wait.  '
              return
            }
            this.playStatus = 'live'
            await this.$nextTick()
            this.renderLiveXgPlayer(val.flvLiveUrl)
            this.getNetworkStatusLoop()
            break
          default:
            this.playStatus = 'empty'
            break
        }
      },
      immediate: true
    },
    liveInfo: {
      async handler(val) {
        if (!val) {
          return
        }
        // activityStatus: 直播间状态0:未开始,1:已删除,2:结束,3:预约,4:直播中,5:暂停
        const { activityStatus } = val
        // pushModel: 推流类型: 1-直推，2-转推
        const { playBackUrl, flvLiveUrl, pushModel } = this.consoleInfo
        switch (activityStatus) {
          case 2:
            if (!playBackUrl || pushModel === 1) {
              return
            }
            if (this.playStatus === 'back' && this.backPlayer) {
              this.backPlayer.play()
              return
            }
            this.playStatus = 'back'
            await this.$nextTick()
            this.renderBackXgPlayer(this.consoleInfo.playBackUrl)
            break
          case 4:
            if (!flvLiveUrl || pushModel === 1) {
              return
            }
            this.getNetworkStatusLoop()
            if (this.playStatus === 'live' && this.livePlayer) {
              this.livePlayer.play()
              return
            }
            this.playStatus = 'live'
            await this.$nextTick()
            this.renderLiveXgPlayer(this.consoleInfo.flvLiveUrl)
            break
          case 5:
            if (!flvLiveUrl || pushModel === 1) {
              return
            }
            if (this.loopTImer) {
              clearTimeout(this.loopTImer)
            }
            if (this.livePlayer) {
              this.livePlayer.pause()
            }
            break
          default:
            this.playStatus = 'empty'
            if (this.loopTImer) {
              clearTimeout(this.loopTImer)
            }
            break
        }
      },
      immediate: true
    },
    copyVisible(val) {
      if (val && !this.channelList.length) {
        this.fetchRecentChannel()
      }
    }
  },
  beforeDestroy() {
    this.livePlayer = null
  },
  methods: {
    async renderLiveXgPlayer(url) {
      this.livePlayer = new FlvPlayer({
        id: 'live-player',
        isLive: true,
        url,
        autoplay: true,
        videoInit: true,
        lang: 'en'
      })
    },
    async renderBackXgPlayer(url) {
      this.backPlayer = new HlsJsPlayer({
        id: 'back-player',
        isLive: false,
        url,
        videoInit: true,
        lang: 'en'
      })
    },
    async renderRplacePlayer(url) {
      this.replacePlayer = new Player({
        id: 'replace-player',
        isLive: false,
        url,
        videoInit: true,
        lang: 'en'
      })
    },
    maskTip() {
      this.$message.warning('Live has pause')
    },
    async getNetworkStatus() {
      const res = await this.$serve.console.getNetworkStatus({
        queryId: this.consoleInfo.livestreamId
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.networkStatus = res.networkStatus
    },
    getNetworkStatusLoop() {
      this.getNetworkStatus()
      if (this.loopTImer) {
        clearTimeout(this.loopTImer)
      }
      this.loopTImer = setTimeout(() => {
        this.getNetworkStatusLoop()
      }, 1000 * 5)
    },
    pause() {
      this.endPublish(1)
    },
    end() {
      this.endPublish(2)
    },
    // 操作类型: 1-暂时离开，2-停止直播
    async endPublish(type) {
      const res = await this.$serve.livestream.endPublish({
        params: {
          activityId: this.consoleInfo.activityId,
          type
        }
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.stopLivingVisible = false
      this.$message.success('Action Success')
    },
    shareTwitter() {
      const params = [
        ['url', this.url + '?channelId=1'],
        ['text', this.consoleInfo.description]
      ]
      const str = params.map((item) => item.join('=')).join('&')
      window.open(`https://twitter.com/intent/tweet?${str}`)
    },
    shareFacebook() {
      const params = [
        ['u', this.url + '?channelId=2'],
        ['display', 'popup'],
        ['title', this.consoleInfo.title],
        ['quote', this.consoleInfo.description]
        // ['description', this.consoleInfo.description],
        // [
        //   'picture',
        //   'http://www.applezein.net/wordpress/wp-content/uploads/2015/03/facebook-logo.jpg'
        // ]
      ]
      const str = params.map((item) => item.join('=')).join('&')
      window.open(`https://www.facebook.com/sharer/sharer.php?${str}`)
    },
    sureCopy() {
      //
    },
    choiceMedia() {
      this.visibleMediaShowVideo = true
      let timer = setTimeout(() => {
        clearTimeout(timer)
        timer = null
        this.visibleMediaVideo = true
      }, 200)
    },
    async uploadSuccessCover(data, resultList) {
      if (data) {
        // 媒体选择的最终结果：resultList[0]
        this.replacePlayback(resultList[0])
      }
      this.visibleMediaVideo = false
      let timer = setTimeout(() => {
        clearTimeout(timer)
        timer = null
        this.visibleMediaShowVideo = false
      }, 200)
    },
    async replacePlayback(material) {
      const res = await this.$serve.console.replacePlayback({
        data: {
          activityId: this.consoleInfo.activityId,
          materialId: material.materialId
        }
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$message({
        type: 'success',
        message: 'Replace Success',
        onClose() {
          location.reload()
        }
      })
    },
    async generateChannel() {
      const res = await this.$serve.console.shareChannelInput({
        data: {
          channelName: this.channelName
        }
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.channelId = res.channelId
      this.fetchRecentChannel()
    },
    async fetchRecentChannel() {
      const res = await this.$serve.console.shareChannelListRecent({
        data: {
          channelName: this.channelName
        }
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.channelList = res
    },
    selectChannel(item) {
      this.channelId = item.channelId
    },
    copyDialogClosed() {
      this.channelName = ''
      this.channelId = ''
    }
  }
}
</script>

<style lang="scss" scoped>
._play-area {
  // width: 720px;
  margin: 0 auto;
  max-width: 1100px;
}
._play-area__status-detail {
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 24px 0 48px;
  border: 1px solid rgba(255, 255, 255, 0.06);
  border-radius: 8px;
  background: rgba(41, 43, 60, 0.4);
  font-size: 18px;
  color: #fff;
  margin-bottom: 16px;
  p {
    flex: 1;
  }
}
._play-area__container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  background: #000000;
  border-radius: 7px;
  overflow: hidden;
}
._play-area__empty {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 29px;
  color: rgba(255, 255, 255, 0.25);
  text-align: center;
}
._play-area__player {
  position: absolute;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
}
._play-area__mask {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}
._live-status {
  position: absolute;
  top: 16px;
  left: 24px;
}
._play-area__action {
  background: transparent;
  border-color: #ff4d7c;
  color: #ff4d7c;
  height: 32px;
  width: 72px;
  &:focus,
  &:active {
    background: transparent;
    border-color: #ff4d7c;
    color: #ff4d7c;
  }
}
._play-area__stop-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
::v-deep {
  .el-dialog__body {
    padding: 16px 24px;
  }
  .el-dialog__footer {
    padding: 10px 24px 24px;
  }
}
._play-area__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  background: rgba(41, 43, 60, 0.4);
  box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.06);
  border-radius: 0px 0px 8px 8px;
  padding: 0 16px 0 20px;
  color: #fff;
}
._play-area__replace-title {
  margin-bottom: 6px;
  line-height: 22px;
}
._play-area__replace-btn {
  border: 1px solid #ff4d7c !important;
  color: #ff4d7c !important;
  background: transparent !important;
  &:hover,
  &:focus {
    background: transparent !important;
    border: 1px solid #ff4d7c !important;
    color: #ff4d7c !important;
  }
}
._play-area__replace-btn.is-disabled {
  opacity: 0.3;
}
._play-area__share {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 13px;
  line-height: 15px;
  margin-top: 3px;
  color: rgba(255, 255, 255, 0.6);
  p {
    margin-top: 3px;
  }
}
._play-area-item {
  width: 60px;
  margin-left: 14px;
  cursor: pointer;
}
._play-area__copy-item {
  display: flex;
  align-items: stretch;
  .com-copy-content {
    flex: 1;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 2px;
  }
  & + & {
    margin-top: 24px;
  }
}
._play-area__copy-item-label {
  width: 60px;
  display: block;
  line-height: 32px;
}
._play-area__copy-item-content {
  flex: 1;
}
._play-area__copy-chanel-items {
  display: flex;
}
._play-area__copy-chanel-item {
  height: 28px;
  background: #f7f8fa;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.8);
  line-height: 16px;
  padding: 6px 12px;
  margin: 8px 12px 0 0;
  cursor: pointer;
}
._play-area__copy-generate {
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  .el-button {
    margin: 0 8px 0;
    color: #ff4d7c;
    &.is-disabled {
      color: #c0c4cc;
    }
  }
  .el-input {
    flex: 1;
  }
  ::v-deep {
    .el-input__inner {
      border: none;
    }
  }
}
._play-area__copy-generate-loading {
  display: inline-block;
  margin: 0 8px 0;
  color: rgba(0, 0, 0, 0.25);
  height: 32px;
  text-align: center;
  width: 60px;
  line-height: 32px;
}
._play-area__copy-url {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  font-size: 14px;
  color: #ff0043;
  span {
    background: #f7f8fa;
    display: block;
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    // word-break: break-all;
    line-height: 18px;
    padding: 4px 10px;
    border-radius: 4px;
  }
}
</style>
