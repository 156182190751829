<template>
  <div class="upload">
    <div ref="uploadRef">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    suffix: {
      type: String,
      default: "material",
    },
    filters: Object,
    file: Object,
    random: {
      type: Boolean,
      default: true,
    },
    process: Function,
    getResponse: Function,
    materialType: String,
    buttonType: String,
    imageUrl: String,
    multiSelection: Boolean,
  },
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      this.initUploader();
    });
  },
  methods: {
    initUploader() {
      // eslint-disable-next-line
      new this.$OssUploader({
        every: true,
        random: this.random,
        suffix: this.suffix,
        browse_button: this.$refs.uploadRef,
        multi_selection: this.multiSelection,
        filters: this.filters,
        materialType: this.materialType,
        chunk_size: "0",
        extensions: this.filters.mime_types[0].extensions,
        UploadComplete: (_, file) => {
          this.$emit("uploadComplete", file);
        },
        BeforeUpload: (uploader, file) => {
          this.$emit("beforeUpload", uploader, file);
        },
        UploadProgress: (uploader, file) => {
          this.$emit('onProcess', file)
        },
        // FileUploaded: (uploader, file, responseObject) => {
        //   let response;
        //   try {
        //     response = JSON.parse(responseObject.response);
        //   } catch (e) {
        //     this.$message.error("上传失败");
        //   }
        //   if (response) {
        //     console.log(file);
        //     this.$emit("getFiles", file);
        //   }
        // },
      });
    },
  },
};
</script>
