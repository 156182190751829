import Uploader from './uploader'
import { get } from '@/serve/request'
import { randomString } from '../tools'
import 'element-ui/src/locale/lang/de'

let baseUrl = process.env.VUE_APP_OVERSEAS_URL

if (JSON.parse(process.env.VUE_APP_LIVE)) {
  baseUrl = ''
}

class OssUploader extends Uploader {
  constructor(option) {
    super(option)
    this.tokenOption = null
    this.init()
  }

  // 缓存policy, 不需要每次实例化的时候都去重新获取policy
  static tokenOption = null

  /* 获取上传的policy */
  async init() {
    // if (!this.every) {
    //   if (OssUploader.tokenOption) {
    //     this.initUploader();
    //     return;
    //   }
    // }
    this.getPolicy()
    this.initUploader()
  }

  async getPolicy() {
    let serverUrl = baseUrl + '/overseas/oss/getToken?type=' + this.suffix
    const res = await get(serverUrl)
    if (!this.every) {
      OssUploader.tokenOption = res.result
    } else {
      this.tokenOption = res.result
    }
  }

  /* 设置uploader选项 */
  setUploaderOption(file) {
    if (!OssUploader.tokenOption && !this.tokenOption) {
      return
    }

    const {
      dir,
      policy,
      accessKeyId: OSSAccessKeyId,
      signature,
      host,
      callback
    } = this.tokenOption || OssUploader.tokenOption
    const cutIndex = file.name.lastIndexOf('.')
    const suffix = file.name.slice(cutIndex)
    const fileKey = dir + randomString(32) + suffix
    const multipart_params = {
      key: fileKey,
      policy,
      OSSAccessKeyId,
      signature,
      callback,
      success_action_status: 200
    }
    file.url = host + '/' + fileKey
    this.uploader.setOption({
      url: host,
      multipart_params
    })
  }
}

export default OssUploader
