<template>
  <el-link class="y-link" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </el-link>
</template>

<script>
import { Link } from 'element-ui'

export default {
  name: 'y-link',
  _name: 'Link 文字链接',
  $depends: [Link],
  components: {
    [Link.name]: Link
  }
}
</script>
