export default {
  tips: {
    title: '你正加入一個直播活動',
    tip: '提示:',
    desc: [
      '最多5位觀眾可以加入YoloLiv直播活動',
      '使用wifi時請靠近路由器',
      '如果遇到您音頻迴聲，請聽耳塞'
    ]
  },
  support: {
    title: '您使用的瀏覽器不支持 YoloLiv 的技術',
    desc: '請在您的筆記本電腦/台式電腦上用Google瀏覽器打開'
  },
  failed: {
    title: '加入直播活動失敗',
    desc: '直播間未開始、已關閉或人數已達上限'
  },
  auth: {
    title: '你正加入一個直播活動',
    access: '无麥克風/攝像頭訪問權限',
    button: '允許麥克風/攝像頭訪問'
  },
  authError: {
    title: '瀏覽器禁用了你的麥克風和攝像頭',
    desc: [
      '1、加入直播，點擊地址欄上的相機圖標，',
      ['2、設置為', '永遠訪問', '，並點擊', '確認', ''],
      '3、再重新加載頁面'
    ]
  },
  preview: {
    title: '檢查一下你的攝像頭和麥克風',
    Mute: '靜音',
    Unmute: '不靜音',
    StopCam: '關閉攝像頭',
    OpenCam: '開啟攝像頭',
    Settings: '設置',
    Leave: '離開',
    join: '加入直播間',
    joinFail: '加入失敗',
    guestLabel: '訪客名'
  },
  setting: {
    Camera: '攝像頭',
    Audio: '音頻',
    resolution: '分辨率',
    Mic: '麥克風',
    Speaker: '揚聲器',
    Confirm: '确认',
    Cancel: '取消'
  },
  isoSafari: {
    title: '在 Safari 中打開此頁面',
    desc: ['要在此設備上使用 YoloLiv，', '請點擊導航欄中的 Safari 圖標', '。'],
    copy: '複製鏈接',
    question: '沒有 Safari 圖標？',
    answer: [
      '跟隨以下步驟：',
      '1、複製頁面鏈接按鈕',
      '2、打開Safari',
      '3、將鏈接粘貼到地址欄中'
    ],
    question2: '有筆記本電腦嗎？',
    answer2: '在您的筆記本電腦/台式機上打開此頁面體驗最佳'
  }
}
