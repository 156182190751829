<template>
  <div class="media-dialog-checkbox">
    <y-dialog
      :visible="dialogVisible"
      width="840px"
      top="5vh"
      :before-close="handleBeforeClose"
      :modal-append-to-body="false"
      :close-on-press-escape="false"
      :title="title"
      @click="cancel($event)"
    >
      <div class="media-list-box">
        <div class="left" v-loading="fetchListLoading">
          <div
            v-for="(item, index) in leftList"
            :key="item.label"
            class="left-item"
            :class="{ active: item.active }"
            @click="selectCur(item, index)"
          >
            <div class="key-name overout">{{ item.label }}</div>
            <!--<div class="key-num">({{ item.num }})</div>-->
          </div>
        </div>
        <div class="line"></div>
        <div class="right" v-loading="fetchListByIdLoading">
          <div
            v-for="(item, index) in rightList"
            :key="index"
            :class="['right-item', item.select ? 'select-item' : '']"
            @click="choice(item, index)"
          >
            <div class="key">
              <img
                :src="
                  item.coverUrl
                    ? item.coverUrl
                    : 'https://yololiv-host.oss-us-west-1.aliyuncs.com/yololiv-overseas/image/2021-11-11/XmY6JZFW6EkmGkJRZT6hQxemFi3Mj3QE.png'
                "
                alt=""
              />
            </div>
            <div
              :class="[
                'value',
                'overout',
              ]"
            >
              {{ item.name }}
            </div>
            <div class="select-mask" v-show="item.select"></div>
            <!--<div v-if="item.select" class="seelct">{{ item.index }}</div>-->
          </div>
          <div v-if="rightList.length > 0" class="pagination-box">
            <y-pagination
              background
              :current-page="currentPage"
              :page-size="currentPageSize"
              layout="prev, pager, next, jumper"
              :total="total"
              @current-change="handleCurrentChange"
            >
            </y-pagination>
          </div>
          <div class="no-data-box" v-if="rightList.length === 0">
            <div class="no-data-logo"></div>
            <div class="no-data-text">NO DATA</div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleBeforeClose">Cancel</el-button>
        <el-button
          type="primary"
          :disabled="resultListEmit.length === 0"
          size="small"
          @click="handleSubmit"
          >Done</el-button
        >
      </div>
    </y-dialog>
  </div>
</template>

<script>

export default {
  props: {
    visible: {
      // 弹窗显示
      type: Boolean,
      default: false,
    },
    title: {
      // 标题
      type: String,
      default: '',
    },
    extensions: {
      type: String,
    },
    maxSize: {
      type: String,
    },
    buttonText: {
      // 上传按钮文字
      type: String,
      default: '',
    },
    resultList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 媒体类型, 1：图片 2：视频 3：文档
    materialType: {
      type: Number,
    },
    groupTitle: {
      type: String,
      default: 'All',
    },
    maxlength: {
      type: Number,
    },
  },
  data () {
    return {
      dialogVisible: false,
      leftList: [], // 媒体库左侧导航数据
      rightList: [],
      total: 0,
      currentPageSize: 15,
      currentPage: 1,
      currentGroup: {}, // 当前选中的分组
      resultListEmit: [],
      uploadDialogVisible: false,
      checked: true, // 图片保存到素材库
    };
  },
  computed: {
    fetchListLoading() {
      return this.$store.state.loading['/overseas/media-center/group/list']
    },
    fetchListByIdLoading() {
      return this.$store.state.loading['/overseas/media-center/material/page']
    },
  },
  watch: {
    visible (newV) {
      this.dialogVisible = newV;
    },
    currentPage () {
      this.getListByGroup();
    },
  },
  async created () {
    this.resultListEmit = JSON.parse(JSON.stringify(this.resultList));
    await this.getGroup();
    await this.getListByGroup();
  },
  methods: {
    handleBeforeClose () {
      this.$emit('updateMedia', false);
    },
    handleSubmit () {
      this.$emit('updateMedia', true, [...this.resultListEmit]);
    },
    cancel (event) {
      this.$emit('cancel');
      event.stopPropagation();
      return false;
    },
    selectCur (item) {
      this.leftList.forEach((item) => {
        item.active = false;
      });
      item.active = true;
      this.rightList = [];
      // 切换左侧源，清空选中数据和结果数据
      // this.resultListEmit = [];
      this.currentGroup = item;
      this.currentPage = 1;
      this.getListByGroup();
    },
    // 切换分页
    handleCurrentChange (val) {
      this.currentPage = val;
    },
    choice (item) {
      if (this.maxlength && !item.select) {
        if (this.resultListEmit.length === 10) {
          return false;
        }
      }
      item.select = !item.select;
      if (item.select) {
        // 选中
        this.resultListEmit.push(item);
      } else {
        // 删除
        for (let i = 0; i < this.resultListEmit.length; i++) {
          if (
            item.url === this.resultListEmit[i].url
          ) {
            this.resultListEmit.splice(i, 1);
            break;
          }
        }
      }
      // 对右上角选中状态重新渲染
      this.resetIndex();
    },
    // 对右上角重新赋值
    resetIndex () {
      for (let r = 0; r < this.rightList.length; r++) {
        for (let c = 0; c < this.resultListEmit.length; c++) {
          if (
            this.rightList[r].url === this.resultListEmit[c].url
          ) {
            this.rightList[r].select = true;
            this.rightList[r].index = c + 1;
            break;
          }
        }
      }
    },
    async getGroup () {
      const res  = await this.$serve['media-center'].getMediaGroupList({
        params: {
          materialType: this.materialType,
        }
      })
      if (res === this.$serve.FAIL) {
        return
      }
      let resList = res.groupList || [];
      resList.unshift({
        materialCount: res.materialTotalCount,
        groupId: '',
        groupName: this.groupTitle,
      })
      resList.forEach((item, index) => {
        item.active = false;
        if (index === 0) {
          item.active = true;
        }
        item.label = item.groupName;
        item.num = item.materialCount;
        item.id = item.groupId;
      });
      this.leftList = resList;
      if (this.leftList.length > 0) {
        this.currentGroup = this.leftList[0] || {};
      }
    },
    async getListByGroup () {
      const res  = await this.$serve['media-center'].getMediaByGroupId({
        params: {
          page: this.currentPage,
          pageSize: this.currentPageSize,
          groupId: this.currentGroup.id,
          materialType: this.materialType,
          transcodeStatus: this.materialType === 2 ? 3 : undefined,
        }
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.rightList = res.records || [];
      this.resetIndex();
      this.total = res.totalCount;
    },
  },
};
</script>

<style lang="scss">
@import "media-dialog-checkbox";
.media-dialog-checkbox {
  .el-dialog__body {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  .el-dialog__footer {
    padding: 16px 24px;
  }
}
</style>
