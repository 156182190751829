import formProps from '@pro/pro-form/src/config/form-props'
import proFormProps from '@pro/pro-form/src/config/props'
import { firstLetterToUpper } from '../utils/tools'
import { FORM } from './prefix'

const listPageFormProps = Object.entries({
  ...formProps,
  ...proFormProps
}).reduce(
  (pre, [k, v]) => ({
    ...pre,
    [`${FORM}${firstLetterToUpper(k)}`]: v
  }),
  {}
)

export const listPageFormPropsKeys = Object.keys(listPageFormProps)

export default listPageFormProps
