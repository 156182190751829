import { formatRoutesByEnhanceMeta } from './helper'
// import yolocast from './modules/yolo-cast'
import settings from './modules/settings'
// import analytics from './modules/analytics'
// import media from './modules/media'

// const main = [yolocast, media, analytics, settings]
const main = [settings]

export default formatRoutesByEnhanceMeta(main)
