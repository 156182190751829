export default {
  tips: {
    title: 'You are joining a live streaming event',
    tip: 'Pro Tips:',
    desc: [
      'Up to 5 guests people can join a YoloLiv live streaming event',
      'Move close to your router when using wifi',
      'Wear earbuds if you experience audio echoes'
    ]
  },
  support: {
    title: 'Your browser doesn’t support YoloLiv‘s technology',
    desc: 'Please open this page on your laptop/desktop with Chrome browser'
  },
  failed: {
    title: 'Failed to join the live streaming event',
    desc: 'The live room has not started, closed or the guests limit has been reached.'
  },
  auth: {
    title: 'You are joining a live streaming event',
    access: 'No mic/cam access',
    button: 'Allow mic/cam access'
  },
  authError: {
    title: 'You are joining a live streaming event',
    desc: [
      '1. To join the live streaming, click the camera icon in your address bar.',
      ['2. Then check the ', 'Always allow ', 'option and click ', 'Done', '.'],
      '3. Then reload the page.'
    ]
  },
  preview: {
    title: 'Let’s check your camera and mic',
    Mute: 'Mute',
    Unmute: 'Unmute',
    StopCam: 'Stop cam',
    OpenCam: 'Open cam',
    Settings: 'Settings',
    Leave: 'Leave',
    join: 'Join Event',
    joinFail: 'Join Failed',
    guestLabel: 'Guest name'
  },
  setting: {
    Camera: 'Camera',
    Audio: 'Audio',
    resolution: 'Camera resolution',
    Mic: 'Mic',
    Speaker: 'Speaker',
    Confirm: 'Confirm',
    Cancel: 'Cancel'
  },
  isoSafari: {
    title: 'Open this page in Safari',
    desc: [
      'To use YoloLiv on this device,',
      ' tap the Safari icon',
      'in your navigation bar.'
    ],
    copy: 'Copy link',
    question: 'No Safari icon？',
    answer: [
      'Follow these steps：',
      '1、Copy the page link',
      '2、Open Safari',
      '3、Paste the link in your address bar'
    ],
    question2: 'Have a laptop?',
    answer2: 'Open this page on your laptop/desktop for best experience.'
  }
}
