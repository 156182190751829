import serve from '@/serve'

const module = {
  namespaced: true,
  state: {
    info: null, // 详情信息
    sources: [], // 来源
    drawingBoard: null,
    drawingBoardContainer: null,
    consoleInfo: null,
    liveInfo: null
  },
  getters: {
    drawingBoardReady(state) {
      if (!state.sources.length) {
        return false
      }
      return (
        state.sources.every((source) => source.ready) &&
        !!state.drawingBoardContainer
      )
    }
  },
  mutations: {
    infoUpdate(state, data) {
      state.info = data
    },
    sourcesUpdate(state, data) {
      state.sources = data
    },
    drawingBoardUpdate(state, data) {
      state.drawingBoard = data
    },
    drawingBoardContainerUpdate(state, data) {
      state.drawingBoardContainer = data
    },
    consoleInfoUpdate(state, data) {
      state.consoleInfo = data
    },
    liveInfoUpdate(state, data) {
      state.liveInfo = data
    }
  },
  actions: {
    async fetchLiveDetail({ commit }, activityId) {
      const res = await serve.activity.activityInfo({
        params: { activityId }
      })
      if (res === serve.FAIL) {
        return false
      }
      commit('infoUpdate', res)
      commit('sourcesUpdate', [res.userPushSignalSource])
    },
    async fetchConsoleInfo({ commit }, activityId) {
      const res = await serve.console.info({
        params: {
          activityId
        }
      })
      if (res === serve.FAIL) {
        return
      }
      commit('consoleInfoUpdate', res)
    }
  }
}

export default module
