<template>
  <div class="_chat-area">
    <div class="_chat-area__head">
      <y-tabs v-model="active">
        <y-tab-pane label="Chat" name="chat"></y-tab-pane>
        <y-tab-pane label="Mute" name="mute"></y-tab-pane>
      </y-tabs>
      <div class="_chat-area__comment-switch">
        <i class="ui-icon-more-setting"></i>
        <div class="_chat-area__comment-switch-content">
          Allow viewer to comments
          <y-switch
            v-model="enableChat"
            active-color="#FF4D7C"
            inactive-color="#1e1f2b"
            style="margin-left: 12px"
            @change="enableChatAction"
          ></y-switch>
        </div>
      </div>
    </div>
    <div class="_chat-area__main">
      <chat-area-chat
        v-if="active === 'chat'"
        :consoleInfo="consoleInfo"
        :liveInfo="liveInfo"
      ></chat-area-chat>
      <chat-area-mute v-if="active === 'mute'"></chat-area-mute>
    </div>
  </div>
</template>

<script>
import ChatAreaChat from './_chat-area-chat'
import ChatAreaMute from './_chat-area-mute'

export default {
  components: {
    ChatAreaChat,
    ChatAreaMute
  },
  props: {
    consoleInfo: Object,
    liveInfo: Object
  },
  data() {
    return {
      active: 'chat',
      activityId: this.$route.params.id,
      enableChat: false
    }
  },
  computed: {
    user() {
      return this.$store.state.userInfo
    }
  },
  async mounted() {
    this.fetchChatSwitch()
  },

  methods: {
    async fetchChatSwitch() {
      const res = await this.$serve.console.chatSwitch({
        params: {
          activityId: this.activityId
        }
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.enableChat = !!res.enableChat
    },
    async enableChatAction() {
      const res = await this.$serve.console.chatSwitchPut({
        data: {
          enableChat: this.enableChat ? 1 : 0,
          activityId: this.activityId
        }
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$message.success('success')
    }
  }
}
</script>

<style lang="scss" scoped>
._chat-area {
  padding: 12px 16px;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
}
._chat-area__head {
  margin: -12px -16px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  h4 {
    margin: 0;
    font-size: 20px;
  }
  ::v-deep {
    .el-tabs__header {
      margin: 0;
    }
    .el-tabs__item {
      height: 48px;
      line-height: 48px;
      font-size: 20px;
      color: #fff;
      &.is-active {
        color: #ff4d7c;
      }
    }
    .el-tabs__active-bar {
      background: #ff4d7c;
    }
  }
}
._chat-area__comment-switch {
  position: relative;
  width: 40px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    ._chat-area__comment-switch-content {
      display: block;
    }
  }
}
._chat-area__comment-switch-content {
  position: absolute;
  right: 40px;
  top: 20px;
  padding: 16px 16px 12px;
  font-size: 12px;
  background: #292b3c;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  white-space: nowrap;
  display: none;
  z-index: 19;
}
._chat-area__main {
  flex: 1;
  position: relative;
}
</style>
