<template>
  <div id="app">
    <router-view />
    <div
      class="common-doing-box"
      :class="{'close-box': closed}"
      v-if="visible"
      v-drag
    >
      <UploadModal @close="closeModal"></UploadModal>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import UploadModal from '@/components/upload-modal'

export default {
  components: {
    UploadModal
  },
  computed: {
    ...mapState({
      visible(state) {
        return state.uploadModal.visible
      },
    }),
  },
  data() {
    return {
      closed: false,
    }
  },
  methods: {
    closeModal() {
      this.closed = true;
      let timer = setTimeout(() =>{
        clearTimeout(timer);
        timer = null;
        this.closed = false;
      }, 2000)
    }
  }
}
</script>

<style lang="scss" scoped>
  .common-doing-box {
    position: fixed;
    bottom: 24px;
    right: 16px;
    overflow: hidden;
    z-index: 1000;
    cursor: all-scroll;
    opacity: 1;
  }
  .close-box {
    opacity: 0;
    transition: all 1s ease 0s;
  }
</style>
