/**
 * [pagination](https://element.eleme.cn/2.14/#/zh-CN/component/pagination#attributes)
 * el-pagination 原生属性
 */
const pagination = {
  small: {
    type: Boolean,
    default: false
  },
  background: {
    type: Boolean,
    default: false
  },
  pageSize: {
    type: Number,
    default: 10
  },
  total: Number,
  pageCount: Number,
  pagerCount: {
    type: Number,
    default: 7
  },
  currentPage: {
    type: Number,
    default: 1
  },
  layout: {
    type: String,
    default: 'prev, pager, next, jumper, ->, total'
  },
  pageSizes: {
    type: Array,
    default: () => [10, 20, 30, 40, 50, 100]
  },
  popperClass: String,
  prevText: String,
  nextText: String,
  disabled: {
    type: Boolean,
    default: false
  },
  hideOnSinglePage: Boolean
}

export const paginationKeys = Object.keys(pagination)

export default pagination
