<template>
  <div
    :class="[
      'page-yolo-cast-monitor',
      {
        'page-yolo-cast-monitor--alone': !currentTab.showPlay
      }
    ]"
  >
    <!-- attribute-type -->
    <div
      class="
        page-yolo-cast-monitor__block page-yolo-cast-monitor__attribute-type
      "
    >
      <div class="page-yolo-cast-monitor__block-inner">
        <side-bar
          :tabs="tabs"
          :default-active="typeActive"
          v-model="typeActive"
        ></side-bar>
      </div>
    </div>
    <!-- /attribute-type -->

    <!-- attribute-content -->
    <div
      class="
        page-yolo-cast-monitor__block page-yolo-cast-monitor__attribute-content
      "
    >
      <div class="page-yolo-cast-monitor__block-inner c-no-scroll" v-scroll>
        <component
          :is="currentTab.component"
          :consoleInfo="consoleInfo"
          :liveInfo="liveInfo"
        ></component>
      </div>
    </div>
    <!-- /attribute-content -->

    <!-- platform -->
    <div
      v-if="currentTab.showPlay"
      class="page-yolo-cast-monitor__block page-yolo-cast-monitor__platform"
    >
      <div class="page-yolo-cast-monitor__block-inner c-no-scroll" v-scroll>
        <play-area :consoleInfo="consoleInfo" :liveInfo="liveInfo"></play-area>
      </div>
    </div>
    <!-- /platform -->
  </div>
</template>

<script>
import SideBar from '../_side-bar'
import PlayArea from '../_play-area'
import ControlArea from './_control-area'
import OvewviewArea from './_overview-eara'
import HealthArea from './_health-area'
import AnlaysisArea from './_anlaysis-area'
import ChatArea from './_chat-area'
import DocumentArea from './_document-area'
import ResportsArea from '../resports'

export default {
  components: {
    SideBar,
    PlayArea,
    ControlArea,
    OvewviewArea,
    HealthArea,
    AnlaysisArea,
    ChatArea,
    DocumentArea,
    ResportsArea
  },
  props: {
    consoleInfo: Object,
    liveInfo: Object
  },
  data() {
    const querySub = ['chat', 'anlaysis', 'health', 'report'].includes(
      this.$route.query.sub
    )
      ? this.$route.query.sub
      : 'chat'
    return {
      tabs: [
        {
          label: 'Chat',
          value: 'chat',
          icon: 'ui-icon-chat',
          activeIcon: 'ui-icon-chat-hover',
          component: 'ChatArea',
          showPlay: true
        },
        {
          label: 'Viewer Analytics',
          value: 'anlaysis',
          icon: 'ui-icon-anlaysis',
          activeIcon: 'ui-icon-anlaysis-hover',
          component: 'AnlaysisArea',
          showPlay: true
        },
        {
          label: 'Streaming Health',
          value: 'health',
          icon: 'ui-icon-health',
          activeIcon: 'ui-icon-health-hover',
          component: 'HealthArea',
          showPlay: true
        },
        // {
        //   label: 'Overview',
        //   value: 'overview',
        //   icon: 'ui-icon-overview',
        //   activeIcon: 'ui-icon-overview-hover',
        //   component: 'OvewviewArea'
        // },
        // {
        //   label: 'Document',
        //   value: 'document',
        //   icon: 'ui-icon-file',
        //   activeIcon: 'ui-icon-file-hover',
        //   component: 'DocumentArea'
        // },
        {
          label: 'Report',
          value: 'report',
          icon: 'ui-icon-report',
          activeIcon: 'ui-icon-report-hover',
          component: 'ResportsArea',
          showPlay: false
        }
      ],
      typeActive: querySub
    }
  },
  computed: {
    currentTab() {
      return this.tabs.find((tab) => tab.value === this.typeActive)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-yolo-cast-monitor {
  display: flex;
}
.page-yolo-cast-monitor__block {
  position: relative;
}
.page-yolo-cast-monitor__block-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 6px;
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    border-radius: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(102, 102, 102, 1);
    transition: all 0.2s;
    border-radius: 2px;
  }
}
.page-yolo-cast-monitor__attribute-type,
.page-yolo-cast-monitor__attribute-content {
  border-right: 1px solid rgba(255, 255, 255, 0.15);
}
.page-yolo-cast-monitor__attribute-type {
  width: 186px;
}
.page-yolo-cast-monitor__attribute-content {
  width: 432px;
}
.page-yolo-cast-monitor__platform {
  flex: 1;
  .page-yolo-cast-monitor__block-inner {
    padding: 32px 48px;
  }
  ._control-area {
    margin-top: 16px;
  }
}

.page-yolo-cast-monitor--alone {
  .page-yolo-cast-monitor__attribute-content {
    flex: 1;
    border-right: none;
  }
  .page-yolo-cast-monitor__block-inner {
    display: flex;
  }
}
</style>
