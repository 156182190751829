import Vue from 'vue'
import VueRouter from 'vue-router'
import { localStore } from '@/utils/store'
import routes from './routes'
import store from '@/store'
// @ts-ignore
import constStorage from '@constant/storage'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, _, next) => {
  document.title = to.meta?.name || 'yololiv saas'
  store.commit('currentRouteUpdate', to)
  // next()
  // return

  // 1. 在登录页面
  if (to.path.startsWith('/user') || to.path.startsWith('/room')) {
    next()
    return
  }

  // 2. 没有 tokenVal 即还未登录的时候
  const tokenVal = localStore.get(constStorage.LOCAL.TOKENVAL)
  if (!tokenVal) {
    next('/user')
  }

  // 3. 未获取用户信息
  // @ts-ignore
  if (store.state.userInfo) {
    next()
    return
  }

  // 4. 获取用户信息
  const res = await store.dispatch('fetchUserInfo')
  if (res) {
    next()
  } else {
    next('/user')
  }
})

export default router
