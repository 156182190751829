<template>
  <el-radio class="y-radio" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </el-radio>
</template>

<script>
import { Radio } from 'element-ui'

export default {
  name: 'y-radio',
  _name: 'Radio 单选框',
  $depends: [Radio],
  components: {
    [Radio.name]: Radio
  }
}
</script>
