<template>
  <el-header class="y-header" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </el-header>
</template>

<script>
import { Header } from 'element-ui'

export default {
  name: 'y-header',
  _name: 'Header 顶栏容器',
  $depends: [Header],
  components: {
    [Header.name]: Header
  }
}
</script>
