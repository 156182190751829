<template>
  <div class="page-guide">
    <div class="countdown">5s</div>
    <div class="no-bg" v-if="!data.mobileCover">
      <img src="~@assets/images/designer/designer-empty@2x.png" alt="">
    </div>
    <div class="has-bg" v-else>
      <img :src="data.mobileCover" alt="">
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      data: {
        Type: Object,
        default: {},
      },
    },
    created() {
      console.log(this.data)
    }
  }
</script>

<style lang="scss" scoped>
  .page-guide {
    width: 100%;
    height: 667px;
    position: relative;
    .countdown {
      width: 48px;
      height: 28px;
      text-align: center;
      line-height: 28px;
      border-radius: 14px;
      background-color: rgba(0, 0, 0, 0.5);
      font-size: 14px;
      font-family: HelveticaNeue;
      color: #FFFFFF;
      position: absolute;
      right: 12px;
      top: 12px;
    }
    .no-bg {
      width: 100%;
      height: 667px;
      background: rgba(255, 204, 217, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 149px;
        height: 148px;
      }
    }
    .has-bg {
      width: 100%;
      height: 667px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
</style>
