const apis = [
  {
    // 开始直播推流
    fn: 'startPublish',
    method: 'POST',
    headers: {
      ['Content-Type']: 'application/x-www-form-urlencoded'
    },
    url: '/overseas/livestream/startPublish'
  },
  {
    // 结束直播
    fn: 'endPublish',
    method: 'GET',
    url: '/overseas/livestream/endPublish'
  }
]

export const baseUrl = process.env.VUE_APP_OVERSEAS_URL

export default apis
