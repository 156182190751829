export default {
  tips: {
    title: '你正加入一个直播活动',
    tip: '提示:',
    desc: [
      '最多5位访客可以加入YoloLiv直播活动',
      '使用wifi时请靠近路由器',
      '如果您遇到音频回声，请佩戴耳塞'
    ]
  },
  support: {
    title: '您使用的浏览器不支持 YoloLiv 的技术',
    desc: '请在您的笔记本电脑/台式电脑上用Google浏览器打开'
  },
  failed: {
    title: '加入直播活动失败',
    desc: '直播间未开始、已关闭或人数已达上限'
  },
  auth: {
    title: '你正加入一个直播活动',
    access: '无麦克风/摄像头访问权限',
    button: '允许麦克风/摄像头访问'
  },
  authError: {
    title: '浏览器禁用了你的麦克风和摄像头',
    desc: [
      '1、加入直播，点击地址栏上的相机图标，',
      ['2、设置为', '永远访问', '，并点击', '确认', ''],
      '3、再重新加载页面'
    ]
  },
  preview: {
    title: '检查一下你的摄像头和麦克风',
    Mute: '静音',
    Unmute: '不静音',
    StopCam: '关闭摄像头',
    OpenCam: '开启摄像头',
    Settings: '设置',
    Leave: '离开',
    join: '加入直播间',
    joinFail: '加入失败',
    guestLabel: '访客名'
  },
  setting: {
    Camera: '摄像头',
    Audio: '音频',
    resolution: '分辨率',
    Mic: '麦克风',
    Speaker: '扬声器',
    Confirm: '确认',
    Cancel: '取消'
  },
  isoSafari: {
    title: '在 Safari 中打开此页面',
    desc: ['要在此设备上使用 YoloLiv，', '请点击导航栏中的 Safari图标', '。'],
    copy: '复制链接',
    question: '没有 Safari 图标？',
    answer: [
      '跟随以下步骤：',
      '1、复制页面链接按钮',
      '2、打开Safari',
      '3、将链接粘贴到地址栏中'
    ],
    question2: '有笔记本电脑吗？',
    answer2: '您的笔记本电脑/台式机上打开此页面体验最佳'
  }
}
