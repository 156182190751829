<script>
import { Select, Option, OptionGroup } from 'element-ui'

export default {
  name: 'y-select',
  _name: 'Select 选择器',
  $depends: [Select],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [OptionGroup.name]: OptionGroup
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    group: {
      type: Boolean,
      default: false
    },
    remoteMethod: Function,
    fetch: Function, // 远程搜索接口, 一个返回promise的函数
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // options和loading数据内部接管, 且兼容el-select接口
      optionsData: this.options,
      loadingData: this.loading
    }
  },
  watch: {
    options(val) {
      this.optionsData = val
    }
  },
  mounted() {
    if (this.fetch) {
      this.fetchData()
    }
  },
  methods: {
    fetchData(params) {
      this.loadingData = true
      this.fetch(params)
        .then((options) => {
          this.optionsData = options
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => {
          this.loadingData = false
        })
    },
    remoteChange(query) {
      // 参数传递了远程搜索接口, 搜索数据
      if (this.fetch) {
        this.fetchData(query)
        return
      }
      // 兼容原始参数
      if (this.remoteMethod) {
        this.remoteMethod(query)
      }
    },
    renderOptions(h, options = []) {
      return options.map((option) => (
        <el-option
          key={option.value}
          label={option.label}
          value={option.value === undefined ? option.label : option.value}
          disabled={option.disabled}
        >
          {option.render && option.render(h, option)}
        </el-option>
      ))
    }
  },
  render(h) {
    if (this.fetch) {
      // 设置远程接口, el-select文档要求必须设置相应配置
      this.$attrs.filterable = true
      this.$attrs.remote = true
    }

    return (
      <el-select
        class="y-select"
        {...{ attrs: this.$attrs }}
        {...{ on: this.$listeners }}
        loading={this.loadingData}
        remoteMethod={this.remoteChange}
      >
        <template slot="prefix">{this.$slots.prefix}</template>
        <template slot="empty">{this.$slots.empty}</template>
        {this.group
          ? this.optionsData.map((group) => (
              <el-option-group key={group.label} label={group.label}>
                {this.renderOptions(h, group.options)}
              </el-option-group>
            ))
          : this.renderOptions(h, this.optionsData)}
      </el-select>
    )
  }
}
</script>
