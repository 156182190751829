<template>
  <div class="subscribe-edit">
    <div class="bg-box">
      <div class="text">Background</div>
      <div class="seelct-source-radio">
        <el-radio v-model="data.stylesheet.background.type" label="default">Default</el-radio>
        <el-radio v-model="data.stylesheet.background.type" label="image">Custom</el-radio>
      </div>
      <div class="tips" v-if="data.stylesheet.background.type === 'image'">
        <div class="title">Recommended pixels: 750*98px, supports JPG, PNG and the size does not exceed 20MB;</div>
      </div>
      <div
        class="add-bg-box"
        v-if="data.stylesheet.background.type === 'image'"
      >
        <div @click="choiceMedia('bg')">
          <div class="has-img" v-if="data.stylesheet.background.url">
            <img :src="data.stylesheet.background.url" alt="" />
            <div class="change-img">Change image</div>
          </div>
          <div class="no-img" v-else>
            <div class="icon"></div>
            <div class="value">Upload image</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-box">
      <div class="text">Name</div>
      <el-input
        placeholder="Please Input a Name"
        v-model="data.username"
        maxlength="25"
        @input="checkWordTitle"
      ></el-input>
    </div>
    <div class="bg-box s-logo-box">
      <div class="text">Logo</div>
      <div class="add-bg-box">
        <div @click="choiceMedia('logo')">
          <div class="no-img" v-if="!data.logoUrl">
            <div class="key">+</div>
          </div>
          <div class="has-img" v-else>
            <img :src="data.logoUrl" alt="" />
            <div class="change-img">Change image</div>
          </div>
        </div>
      </div>
      <div class="tips">
        <div class="title">Recommended pixels: 80*80px, supports JPG, PNG and the size does not exceed 20MB;</div>
      </div>
    </div>
    <div class="bg-box">
      <div class="text">URL</div>
      <el-input
        placeholder="Please input url"
        v-model.trim="data.url"
      ></el-input>
    </div>
    <media-dialog-radio
      v-if="visibleMediaShowCover"
      :title="'Select Image'"
      :button-text="'Upload Picture'"
      :extensions="'jpg,png'"
      :visible="visibleMediaCover"
      :material-type="1"
      :w="w"
      :h="h"
      :limt="limt"
      @updateMedia="uploadSuccessCover"
      suffix="image"
      groupTitle="All Pictures"
      :multi_selection="false"
    ></media-dialog-radio>
  </div>
</template>
<script>
import mediaDialogRadio from '@/components/media-dialog-radio';

export default {
  props: {
    data: {
      Type: Object,
      default: {},
    },
  },
  components: {
    mediaDialogRadio
  },
  data() {
    return {
      imgType: '',
      w: '',
      h: '',
      limt: '20MB',
      visibleMediaCover: false,
      visibleMediaShowCover: false,
    }
  },
  methods: {
    checkWordTitle() {
      const reg = /^[^\s].*$/g
      if (!reg.test(this.data.username)) {
        this.data.username = this.data.username.slice(1)
      }
    },
    isBg() {
      this.w = 750;
      this.h = 98;
      this.imgType = 'bg';
    },
    isLogo() {
      this.w = 80;
      this.h = 80;
      this.imgType = 'logo';
    },
    async uploadSuccessCover (data, resultList) {
      if (data) {
        const url = resultList[0].url;
        if (this.imgType === 'bg') {
          this.data.stylesheet.background.url = url;
          this.data.stylesheet.style.backgroundImage = `url(${url})`;
        } else if (this.imgType === 'logo') {
          this.data.logoUrl = url
        }
      }
      this.visibleMediaCover = false;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.visibleMediaShowCover = false;
      }, 200);
    },
    choiceMedia(type) {
      if (type === 'bg') {
        this.isBg()
      } else {
        this.isLogo()
      }
      this.visibleMediaShowCover = true;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.visibleMediaCover = true;
      }, 200);
    }
  },
};
</script>
<style lang="scss" scoped>
@import './edit.scss';
</style>
