<template>
  <div class="com-copy-content" :style="{ background, color }">
    <div class="com-copy-content__value">
      <div class="com-copy-content_value-inner">{{ text }}</div>
    </div>
    <div class="com-copy-content__action" v-copy="text">Copy</div>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    background: {
      type: String,
      default: 'rgba(255, 255, 255, 0.04)'
    },
    color: {
      type: String,
      default: '#fff'
    }
  }
}
</script>

<style lang="scss" scoped>
.com-copy-content {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 6px 8px;
  border-radius: 4px;
}
.com-copy-content__value {
  flex: 1;
  position: relative;
}
.com-copy-content_value-inner {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}
.com-copy-content__action {
  margin-left: 8px;
  color: #ff4d7c;
  cursor: pointer;
}
</style>
