/**
 * [tableProps](https://element.eleme.cn/2.14/#/zh-CN/component/table#table-attributes)
 * el-table 原生属性
 */
const tableProps = {
  data: Array,
  height: [String, Number],
  maxHeight: [String, Number],
  stripe: {
    type: Boolean,
    default: false
  },
  border: {
    type: Boolean,
    default: false
  },
  size: String,
  fit: {
    type: Boolean,
    default: true
  },
  showHeader: {
    type: Boolean,
    default: true
  },
  highlightCurrentRow: {
    type: Boolean,
    default: false
  },
  currentRowKey: [String, Number],
  rowClassName: Function,
  rowStyle: Function,
  cellClassName: Function,
  cellStyle: Function,
  headerRowClassName: Function,
  headerRowStyle: Function,
  headerCellClassName: Function,
  headerCellStyle: Function,
  rowKey: [Function, String],
  emptyText: {
    type: String,
    default: '暂无数据'
  },
  defaultExpandAll: {
    type: Boolean,
    default: false
  },
  expandRowKeys: Array,
  defaultSort: Object,
  tooltipEffect: String,
  showSummary: {
    type: Boolean,
    default: false
  },
  sumText: {
    type: String,
    default: '合计'
  },
  summaryMethod: Function,
  spanMethod: Function,
  selectOnIndeterminate: {
    type: Boolean,
    default: true
  },
  indent: {
    type: Number,
    default: 16
  },
  lazy: Boolean,
  load: Function,
  treeProps: {
    type: Object,
    default: () => ({ hasChildren: 'hasChildren', children: 'children' })
  }
}

export const tablePropsKeys = Object.keys(tableProps)

export default tableProps
