<template>
  <el-autocomplete
    class="y-autocomplete"
    v-bind="$attrs"
    v-on="$listeners"
    :fetch-suggestions="handleFetchSuggestions"
  >
    <slot slot="prefix" name="prefix"></slot>
    <slot slot="suffix" name="suffix"></slot>
    <slot slot="prepend" name="prepend"></slot>
    <slot slot="append" name="append"></slot>
  </el-autocomplete>
</template>

<script>
import { Autocomplete } from 'element-ui'

export default {
  name: 'y-autocomplete',
  _name: 'Autocomplete 带建议的输入框',
  $depends: [Autocomplete],
  components: {
    [Autocomplete.name]: Autocomplete
  },
  props: {
    fetch: Function, // 远程搜索接口, 一个返回promise的函数
    fetchSuggestions: Function // 为了兼容el-autocomplete的fetchSuggestions属性
  },
  methods: {
    handleFetchSuggestions(query, cb) {
      if (this.fetch) {
        this.fetch(query)
          .then((res) => {
            cb(res)
          })
          .catch((e) => {
            console.log(e)
          })
        return
      }
      // 为了兼容el-autocomplete的fetchSuggestions属性
      if (this.fetchSuggestions) {
        this.fetchSuggestions(query, cb)
      }
    }
  }
}
</script>
