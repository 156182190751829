<template>
  <div class="image-swiper-edit">
    <div class="style-box">
      <div class="text">选择样式</div>
      <div class="tips">
        <p>注：更改轮播图尺寸后可能导致内容模块已设置的图片被拉伸或挤压。</p>
      </div>
      <div class="size-box">
        <div
          class="size-item image-1280-fei"
          :class="{ 'image-1280-fei-pro': data.imageType === '1' }"
          @click="changeImgType('1', 1280, 720)"
        >
          <div
            class="size-item-text"
            :class="{ active: data.imageType === '1' }"
          >
            <div>1280*720</div>
            <div class="sm-text">(非通栏)</div>
          </div>
        </div>
        <div
          class="size-item image-1280"
          :class="{ 'image-1280-pro': data.imageType === '2' }"
          @click="changeImgType('2', 1280, 720)"
        >
          <div
            class="size-item-text"
            :class="{ active: data.imageType === '2' }"
          >
            <div>1280*720</div>
          </div>
        </div>
        <div
          class="size-item image-280"
          :class="{ 'image-280-pro': data.imageType === '3' }"
          @click="changeImgType('3', 750, 280)"
        >
          <div
            class="size-item-text"
            :class="{ active: data.imageType === '3' }"
          >
            <div>750*280</div>
          </div>
        </div>
        <div
          class="size-item image-100"
          :class="{ 'image-100-pro': data.imageType === '4' }"
          @click="changeImgType('4', 750, 100)"
        >
          <div
            class="size-item-text"
            :class="{ active: data.imageType === '4' }"
          >
            <div>750*100</div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-box">
      <div class="text">添加图片</div>
      <div class="tab-item" v-for="(item, index) in data.images" :key="index">
        <div class="title-box">
          <div class="img-title">{{ item.imgTitle }}</div>
          <div class="do-btn">
            <div class="to-top">
              <img
                src="~@assets/images/designer/up.png"
                alt=""
                v-if="index !== 0"
                @click.stop="toUp(item, index)"
              />
              <img
                src="~@assets/images/designer/operation_up@2x.png"
                alt=""
                v-else
              />
            </div>
            <div class="to-bottom">
              <img
                src="~@assets/images/designer/down.png"
                alt=""
                v-if="index !== data.images.length - 1"
                @click.stop="toDown(item, index)"
              />
              <img
                src="~@assets/images/designer/operation_down@2x.png"
                alt=""
                v-else
              />
            </div>
            <div class="to-delete">
              <img
                src="~@assets/images/designer/delete@2x.png"
                alt=""
                @click.stop="toDelete(item, index)"
              />
            </div>
          </div>
        </div>
        <div class="select-img">
          <div class="add-img" @click="chooseIndex(item, index)">
            <div class="no-img" v-if="!item.imageUrl">+添加</div>
            <div class="has-img" v-else>
              <img :src="item.imageUrl" alt="" />
              <div class="hover-tip">更换图片</div>
            </div>
          </div>
          <div class="change-link">
            <el-select v-model="item.jumpType" placeholder="请选择跳转">
              <el-option
                v-for="item in [
                  { value: 1, label: '无跳转' },
                  { value: 0, label: '站内链接' },
                  { value: 2, label: '站外链接' },
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="link-url">
            <div v-if="item.jumpType === 1"></div>
            <div v-if="item.jumpType === 0" @click="choiceLive(item, index)">
              <div class="no-video" v-if="!item.linkUrl">选择内容</div>
              <div class="has-video" v-else>
                <div class="video-title overout">{{ item.title }}</div>
                <div class="video-change">更换</div>
              </div>
            </div>
            <div v-if="item.jumpType === 2">
              <el-input
                placeholder="请输入链接"
                v-model="item.inputLinkUrl"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="add-tab" @click="addTab" v-if="data.images.length <= 4">
        添加图片({{ data.images.length }}/5)
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      Type: Object,
      default: {},
    },
  },
  data () {
    return {
      currentSwiperIndex: null, // 当前处理的图片
      visibleLive: false, // 选择直播
      visibleLiveShow: false, // 选择直播数据控制
      resultListLive: [], // 直播结果数据
      // getliveList: getliveList, // 获取直播列表接口
      // materialList: materialList, // 获取素材库分组列表
      // imageGroupList: imageGroupList, // 获取素材库图片
      visibleMediaCover: false, // 封面图处理
      visibleMediaShowCover: false, // 封面图处理
      imgType: '',
      w: 1280, // 宽
      h: 720, // 高
      limt: '10M', // 限制大小
      // relationList: videoGroupList, // 预告页装修根据素材库分组列表id获取素材
    };
  },
  methods: {
    swapArray (arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    toUp (el, index) {
      if (index <= 0) {
        return false;
      } else {
        // 上移
        this.swapArray(this.data.modules, index - 1, index);
      }
    },
    toDown (el, index) {
      if (index >= this.data.modules.length - 1) {
        return false;
      } else {
        // 下移
        this.swapArray(this.data.modules, index, index + 1);
      }
    },
    toDelete (el, index) {
      this.$confirm('是否删除该图片？', '提示', {
        confirmButtonText: "Done",
        cancelButtonText: "Cancel",
        type: 'warning',
      }).then(() => {
        this.data.images.splice(index, 1);
      });
    },
    addTab () {
      this.data.images.push({
        id: '',
        imageUrl: '', // 图片地址
        linkUrl: '', // 站内链接
        jumpType: '', // 0：站内链接 1：无跳转 2：站外跳转
        inputLinkUrl: '', // 站外链接
        title: '', // 站内链接名称
        imgTitle: `图片${this.data.images.length + 1}`,
      });
    },
    chooseIndex (item, index) {
      this.currentSwiperIndex = index;

      this.visibleMediaShowCover = true;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.visibleMediaCover = true;
      }, 200);
    },
    choiceLive (item, index) {
      this.currentSwiperIndex = index;
      if (item.id) {
        this.resultListLive = [
          {
            liveId: item.id,
            name: item.title,
          },
        ];
      } else {
        this.resultListLive = [];
      }
      this.visibleLiveShow = true;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.visibleLive = true;
      }, 200);
    },
    updateLive (data, resultList) {
      // data，true：保存， false：取消、关闭
      if (data) {
        this.data.images[this.currentSwiperIndex].id = resultList[0].liveId;
        this.data.images[this.currentSwiperIndex].title = resultList[0].name;
        this.data.images[this.currentSwiperIndex].linkUrl = resultList[0].viewUrl;
      }
      this.visibleLive = false;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.visibleLiveShow = false;
      }, 200);
    },
    uploadSuccessCover(data, resultList) {
      if (data) {
        // 本地上传
        if (resultList[0].fromLocation) {
          this.data.images[this.currentSwiperIndex].imageUrl = resultList[0].url;
        } else {
          // 媒体库选择
          this.data.images[this.currentSwiperIndex].imageUrl = resultList[0].material.url;
        }
      }
      this.visibleMediaCover = false;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.visibleMediaShowCover = false;
      }, 200);
    },
    changeImgType(imageType, w, h) {
      this.data.imageType = imageType;
      this.w = w;
      this.h = h
    }
  },
};
</script>
<style lang="scss">
@import './edit.scss';
</style>
