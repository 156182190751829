<template>
  <div class="file">
    <div class="file-box">
      <div class="select-box" v-if="data.titleSwitch">
        <div class="title">{{ data.fileTitle }}</div>
      </div>
      <img v-if="data.images.length > 0" class="bg" :src="data.images[0]" alt="" />
      <img
        class="play"
        src="~@assets/images/designer/file-empty@2x.png"
        alt=""
        v-else
      />
    </div>
  </div>
</template>

<script>
  export default {
  props: {
    data: {
      Type: Object,
      default: {},
    },
    stylesheet: {
      type: Object,
      default: () => {
        return {};
      },
    },
    fromTemplate: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      status: 0, // 0：待上传 1：上传中 2：转码中 3：上传成功 4：上传失败
      timer: null,
    }
  },
  created() {
    if (!this.fromTemplate && this.$route.query.id) {
      this.getFile();
    }
  },
  methods: {
    async getFile() {
      const res  = await this.$serve.transfer.getFile({
        queryId: this.$route.params.id
      })
      if (res === this.$serve.FAIL) {
        return
      }
      // 转码失败
      if (res.transferCodeStatus === 0) {
        this.status = 4;
      }
      // 转码成功
      if (res.transferCodeStatus === 1) {
        this.status = 3;
        this.data.images = JSON.parse(res.imageUrl);
      }
      // 转码中
      if (res.transferCodeStatus === 2) {
        this.status = 2;
        this.timer = setTimeout(() => {
          clearTimeout(this.timer);
          this.timer = null;
          this.getFile();
        }, 2000)
      }
      // 未上传文档
      if (res.transferCodeStatus === 3) {
        this.status = 0;
      }
    }
  },
    beforeDestroy() {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };
</script>
<style lang="scss" scoped>
.file {
  position: relative;
  .file-box {
    width: 100%;
    min-height: 211px;
    position: relative;
    background-color: #f1f8ff;
    .bg {
      width: 100%;
      height: 211px;
    }
    .play {
      width: 100%;
      height: 211px;
    }
    img {
      width: 100%;
      height: 211px;
    }
    .select-box {
      padding: 12px;
      background-color: #ffffff;
      .title {
        font-size: 14px;
        font-family: HelveticaNeue-Medium, HelveticaNeue;
        font-weight: 500;
        color: $-color-black-80;
      }
    }
  }
}
</style>
