const apis = [
  {
    // 注册信号源
    fn: 'userPushSignalSource',
    method: 'POST',
    headers: {
      ['Content-Type']: 'application/x-www-form-urlencoded'
    },
    url: '/overseas/user-push-signal-source/registerSource'
  }
]

export const baseUrl = process.env.VUE_APP_OVERSEAS_URL

export default apis
