<template>
  <div class="countdown">
    <div class="count-msg">
      <span class="key" :style="{color: stylesheet.style.primaryColor.value}">距开播</span>
      <span class="value" :style="{color: stylesheet.style.buttonColor.value}">66</span>
      <span class="key" :style="{color: stylesheet.style.primaryColor.value}">天</span>
      <span class="value" :style="{color: stylesheet.style.buttonColor.value}">16</span>
      <span class="key" :style="{color: stylesheet.style.primaryColor.value}">时</span>
      <span class="value" :style="{color: stylesheet.style.buttonColor.value}">16</span>
      <span class="key" :style="{color: stylesheet.style.primaryColor.value}">分</span>
      <span class="value" :style="{color: stylesheet.style.buttonColor.value}">16</span>
      <span class="key" :style="{color: stylesheet.style.primaryColor.value}">秒</span>
    </div>
    <div class="line"></div>
    <div class="count-btn" :style="{color: stylesheet.style.buttonColor.value}">预约直播</div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      Type: Object,
      default: () => {
        return {};
      },
    },
    stylesheet: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.countdown {
  width: 100%;
  height: 52px;
  padding: 12px;
  display: flex;
  align-items: center;
  .count-msg {
    .key {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      margin-right: 4px;
    }
    .value {
      font-size: 18px;
      font-family: DINAlternate-Bold, DINAlternate;
      font-weight: bold;
      margin-right: 4px;
    }
  }
  .line {
    width: 1px;
    height: 16px;
    background: rgba(0, 0, 0, 0.25);
    margin-left: auto;
    margin-right: 30px;
  }
  .count-btn {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    margin-right: 12px;
  }
}
</style>
