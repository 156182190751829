<template>
  <div class="img-crop" v-show="visible">
    <div v-show="dialogVisible" style="position:fixed; top:0; left:0; right:0; bottom:0; background: #000; opacity: .5;"></div>
    <y-dialog
      title="Image Cropper"
      width="800px"
      :visible.sync="dialogVisible"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      class="imgcrop"
      :id="id"
      :modal="false"
    >
      <div class="upload-head">
        <div class="upload-status">
          Recommend JPG or PNG at {{ w }}x{{ h }} resolution, less than {{ limt }}.
        </div>
      </div>

      <div class="clip-wrap" v-if="dialogVisibleShow">
        <div class="clip-area"><img class="croper-el" ref="image" :src="imageUrl" /></div>
        <div class="clip-show"></div>
      </div>

      <div class="upload-msg">
        <el-button @click="handleClose($event)">Cancel</el-button>
        <el-button
          type="primary"
          @click="saveTapped()"
          :loading="loading"
        >Done</el-button>
      </div>
    </y-dialog>
  </div>
</template>

<script>
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import { Message } from '@base'
import AWS from 'aws-sdk'
import serve from '@/serve'

export default {
  data() {
    return {
      dialogVisible: false,
      dialogVisibleShow: false,
      imageUrl: '',
      left: 0,
      top: 0,
      width: 0,
      height: 0,
      myCropper: null,
      awsTokens: {},
      loading: false,
    };
  },

  props: {
    visible: {
      default: false,
    },

    id: {
      default: 'cropper' + new Date().getTime(),
    },

    aspectRatio: {
      default: 1,
    },

    w: {
      default: 120,
    },

    h: {
      default: 120,
    },

    limt: {
      default: '2M'
    },

    fileSize: {
      default: '100K',
    },

    cover: {
      default:''
    },

    materialId: {
      type: String,
      default: ''
    },
  },

  computed: {},

  watch: {
    visible(newVal) {
      this.dialogVisible = newVal;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.dialogVisibleShow = newVal
      }, 100)
    },
  },
  created() {
    this.dialogVisibleShow = true;
    this.imageUrl = this.cover;
    setTimeout(() => {
      this.dialogVisible = true;
      this.myCropper = new Cropper(this.$refs.image, {
        aspectRatio: this.w / this.h,
        viewMode: 1,
        preview: '.clip-show',
        crop: (e) => {
          this.left = e.detail.x.toFixed(0);
          this.top = e.detail.y.toFixed(0);
          this.width = e.detail.width.toFixed(0);
          this.height = e.detail.height.toFixed(0);
        }
      });
    }, 200)
  },

  methods: {
    handleClose(event) {
      if (!this.materialId) {
        this.clearMedia();
      }
      this.$emit('update:visible', false);
      this.$emit('cancel');

      event && event.stopPropagation();
      return false;
    },

    async saveTapped() {
      if (parseInt(this.width) <= 0 || parseInt(this.height) <= 0) {
        this.$message({
          message: 'Please adjust the appropriate picture clipping scale！',
          type: 'warning',
        });
        return;
      }
      this.loading = true;
      const link = await this.myCropper.getCroppedCanvas({
        imageSmoothingQuality: 'high'
      }).toDataURL('image/png')
      const file = await this.dataURLtoFile(link, 'crop-picture.jpg');
      this.getAwsToken(file)
    },
    async getAwsToken(file) {
      const res = await serve.media.materialToken({
        params: {
          materialType: 1,
          time: new Date().getTime()
        }
      })
      if (res === serve.FAIL) {
        this.loading = false;
        return serve.FAIL
      }
      this.awsTokens = res
      await this.awsUpload(file)
    },
    async awsUpload(puploadFile) {
      const {
        bucketName,
        accessKeyId,
        secretAccessKey,
        sessionToken,
        region,
        dir,
        fileId
      } = this.awsTokens
      const file = puploadFile
      const fileKey = `${dir}${file.name}`
      const s3 = new AWS.S3({
        apiVersion: '2006-03-01',
        accessKeyId,
        secretAccessKey,
        sessionToken,
        region,
        'Access-Control-Allow-Credentials': '*',
        ACL: 'public-read'
      })
      const params = {
        Bucket: bucketName,
        Key: fileKey,
        ContentType: file.type,
        Body: file,
        time: new Date().getTime()
      }
      if (this.s3upload) {
        this.s3upload.removeAllListeners('httpUploadProgress')
        this.s3upload.abort()
      }
      this.s3upload = s3.upload(params, {
        partSize: 10 * 1024 * 1024,
        queueSize: 1
      })
      this.s3upload
        .send((err, data) => {
          if (err) {
            this.loading = false;
            if (err.code !== 'RequestAbortedError') {
              Message.error(err.message || 'error')
            }
            return
          }
          this.loading = false;
          this.$emit('crop-upload-success', data.Location, fileId)
        })
    },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n);
      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type: mime});
    },
    async clearMedia() {
      const res  = await this.$serve['media-center'].revokeMedia({
        data: {
          materialUrl: this.cover,
        }
      })
      if (res === this.$serve.FAIL) {
        return false
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.upload-msg {
  margin-top: 30px;
  text-align: center;
  display: block;
}
.upload-msg button {
  margin: 0 10px;
}

.clip-show {
  width: 250px;
  height: 180px;
  overflow: hidden;
  float: left;
  margin-left: 20px;
  margin-top: 30px;
  border: 1px solid #f5f5f5;
  border-radius: 2px;
}
.clip-wrap {
  overflow: hidden;
  margin-top: 30px;
}
.clip-area {
  width: 380px;
  height: 260px;
  float: left;
  border: 1px solid #f5f5f5;
  border-radius: 2px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.upload-head p span {
  margin-right: 30px;
  color: #33aaff;
}
.upload-head p {
  line-height: 36px;
}

.upload-head {
  overflow: hidden;
}

.upload-btn {
  float: left;
  margin-right: 20px;
}

.upload-status {
  float: left;
}

.img-crop .el-dialog {
  position: relative;
  z-index: 9999;
}

.img-box {
  float: left;
  margin-right: 40px;
}

.img-show {
  float: left;
}

.img-crop {
  position: relative;
  z-index: 100000;
}

.img-box {
  width: 300px;
  height: 300px;
  overflow: hidden;
  border-bottom: 1px solid #999999;
}

.img-show {
  width: 400px;
  height: 300px;
  overflow: hidden;
}

.img-crop .el-dialog--small {
  width: 800px !important;
  max-width: 800px !important;
}

.img-crop span {
  /*width: auto !important;*/
  font-size: inherit !important;
  color: inherit !important;
}

.avatar-uploader img {
  width: 100%;
  height: 100%;
  display: block;
}
.grey {
  background: #999 !important;
}
.imgcrop {
  z-index: 10000;
}
</style>
