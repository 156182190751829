import moment from 'moment'

// 根究时间戳从小到大排序列表
export const sortListByTime = (list) => {
  return list.sort((a, b) => a.chatTime - b.chatTime)
}

export const formatListByAddTime = (list) => {
  if (list.length < 2) {
    return list
  }
  let tempItem = list[0]
  let tempItemTime = moment(+tempItem.chatTime)
  tempItem.showTime = true
  for (let i = 1; i < list.length; i++) {
    const item = list[i]
    const itemTime = moment(+item.chatTime)
    if (tempItemTime.minute() !== itemTime.minute()) {
      tempItem = item
      tempItem.showTime = true
      tempItemTime = moment(+tempItem.chatTime)
    }
  }
  return list
}
