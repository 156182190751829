<template>
  <y-dialog
    title="Select Template"
    :visible.sync="templatesDialogVisible"
    width="840px"
    @close="closeTemplate"
  >
    <div class="template-content" v-loading="loading">
      <y-tabs v-model="activeTabs" @tab-click="changeTempTabs">
        <!--<y-tab-pane
          v-if="hasHotTemplates"
          label="Popular"
          name="hot"
        ></y-tab-pane>-->
        <y-tab-pane
          v-if="hasMyTemplates"
          label="My Templates"
          name="my"
        ></y-tab-pane>
      </y-tabs>
      <div class="template-list">
        <div class="item empty" @click="toDecorate()">
          <div class="key">
            <div class="flex-key">
              <div class="plus">+</div>
              <div class="plus-text">Use a blank template</div>
            </div>
          </div>
          <div class="value">Custom</div>
        </div>
        <div class="item" v-for="(item, index) in templateList" :key="index">
          <div class="key">
            <nested :modules="item.data" :fromTemplate="true"/>
            <div class="mask-key"></div>
          </div>
          <div class="value">{{ item.name }}</div>
          <div class="mask-btn-box">
            <div class="btn-box">
              <div class="btn-preview" @click="toPreivew(item)">Preview</div>
              <div class="btn-use" @click="useTemplate(item)">Apply</div>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination-box">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          layout="total, prev, pager, next"
          :total="total"
          background
        ></el-pagination>
      </div>
    </div>
    <TemplatePreview
      v-if="previewShow"
      :currentTemplate="currentTemplate"
      @closePreview="closePreview"
    ></TemplatePreview>
  </y-dialog>
</template>

<script>
import Nested from '@/views/yolo-cast/designer/nested'
import TemplatePreview from '@/components/template-preview'

export default {
  props: ['consoleInfo'],
  components: {
    Nested,
    TemplatePreview,
  },
  data() {
    return {
      activeTabs: 'my',
      currentPage: 1,
      pageSize: 11,
      templateList: [],
      total: 0,
      templatesDialogVisible: false,
      activityId: this.$route.params.id,
      previewShow: false, // 展示预览
      currentTemplate: {},
      hasHotTemplates: true,
      hasMyTemplates: true,
    }
  },
  computed: {
    loading() {
      return this.$store.state.loading[
        '/overseas/activity-decoration-template/list'
      ]
    },
    previewUrl() {
      return process.env.VUE_APP_H5 + '/template/' + this.currentTemplate.id
    }
  },
  methods: {
    show({ activeTabs, hasHotTemplates, hasMyTemplates } = {}) {
      if (activeTabs !== undefined) {
        this.activeTabs = activeTabs
      }
      if (hasHotTemplates !== undefined) {
        this.hasHotTemplates = hasHotTemplates
      }
      if (hasMyTemplates !== undefined) {
        this.hasMyTemplates = hasMyTemplates
      }
      this.templatesDialogVisible = true
      this.getTempData()
    },
    hide() {
      this.templatesDialogVisible = false
    },
    async getTempData() {
      const res = await this.$serve.activity.getTemplateList({
        params: {
          purpose: this.activeTabs === 'hot' ? 3 : 1,
          currentPage: this.currentPage,
          pageSize: this.pageSize
        }
      })
      if (res === this.$serve.FAIL) {
        return false
      }
      this.templateList = []
      res.records.forEach((item) => {
        item.data = JSON.parse(item.data)
        this.templateList.push(item)
      })

      this.total = res.totalCount
    },
    closeTemplate() {
      this.activeTabs = 'my'
      this.currentPage = 1
    },
    async changeTempTabs() {
      this.currentPage = 1
      await this.getTempData()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getTempData()
    },
    async toDecorate(item) {
      if (this.consoleInfo.isDecorated && this.consoleInfo.isDecorated == 1) {
        this.$confirm(
          'Once you choose a template, all the edits you made for the live event previously will not be saved. Are you sure to continue?',
          'Prompt',
          {
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        ).then(() => {
          if (item) {
            this.$router.push(`/yolo-cast-design/${this.activityId}?templateId=${item.id}`)
          } else {
            this.$router.push(`/yolo-cast-design/${this.activityId}`)
          }
        })
      } else {
        if (item) {
          this.$router.push(`/yolo-cast-design/${this.activityId}?templateId=${item.id}`)
        } else {
          this.$router.push(`/yolo-cast-design/${this.activityId}`)
        }
      }
    },
    useTemplate(item) {
      this.$emit('select', item)
    },
    hidePreview() {
      this.previewShow = false
    },
    toPreivew(item) {
      this.previewShow = true
      this.currentTemplate = item
    },
    closePreview() {
      this.previewShow = false;
      this.currentTemplate = {};
    },
  }
}
</script>

<style lang="scss" scoped>
.template-content {
  padding-bottom: 42px;
  .template-list {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    height: 568px;
    &::-webkit-scrollbar {
      width: 6px;
      background: transparent;
    }
    &::-webkit-scrollbar-track {
      border-radius: 0;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(102, 102, 102, 1);
      transition: all 0.2s;
      border-radius: 2px;
    }
    .item {
      width: 180px;
      margin-right: 20px;
      margin-bottom: 20px;
      position: relative;
      cursor: pointer;
      height: 348px;
      .key {
        width: 100%;
        height: 320px;
        border-radius: 4px;
        box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.12);
        box-sizing: border-box;
        overflow: hidden;
        .mask-key {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 10;
        }
        .result-drag {
          zoom: 0.48;
        }
      }
      .value {
        width: 100%;
        text-align: left;
        font-size: 14px;
        font-family: HelveticaNeue;
        color: rgba(0, 0, 0, 0.8);
        margin-top: 12px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      &:hover {
        .mask-btn-box {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .mask-btn-box {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 320px;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 1000;
        .btn-box {
          .btn-preview {
            width: 112px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            border-radius: 4px;
            border: 1px solid #ffffff;
            font-size: 14px;
            font-family: HelveticaNeue-Bold, HelveticaNeue;
            font-weight: bold;
            color: #ffffff;
            margin-bottom: 16px;
            cursor: pointer;
          }
          .btn-use {
            width: 112px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background: #ff4d7c;
            border-radius: 4px;
            font-size: 14px;
            font-family: HelveticaNeue-Bold, HelveticaNeue;
            font-weight: bold;
            color: #ffffff;
            cursor: pointer;
          }
        }
      }
    }
    .empty {
      .key {
        background: #f7f8fa;
        border-radius: 4px;
        border: 1px dashed rgba(0, 0, 0, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0;
        .plus {
          font-size: 51px;
          font-family: HelveticaNeue;
          color: rgba(0, 0, 0, 0.6);
          margin-bottom: 2px;
          text-align: center;
        }
        .plus-text {
          font-size: 14px;
          font-family: HelveticaNeue;
          color: rgba(0, 0, 0, 0.6);
          text-align: center;
        }
      }
    }
  }
  .pagination-box {
    position: absolute;
    bottom: 20px;
    right: 15px;
  }
}
.bigview {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.65);
  z-index: 100001;
  .preview-content {
    padding: 140px 0 0;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 625px;
    margin: 0 auto;
    .preview-wrapper {
      background-color: #e7f1ff;
      border-radius: 4px;
      padding: 10px;
      .preview-h5 {
        width: 375px;
        height: 650px;
        overflow: hidden;
        border: none;
      }
    }
  }
}
</style>
