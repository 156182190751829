<template>
  <div class="comment" :style="{height: data.stylesheet.style.height, background: data.stylesheet.style.background, backgroundImage: data.stylesheet.background.type === 'image' ? data.stylesheet.style.backgroundImage : ''}">
    <div class="comment-text" :style="{color: stylesheet.style.secondaryColor.value}">暂无评论</div>
    <div class="redpack-box" v-if="data.redpackValue">
      <img :src="data.redpackUrl" alt="" />
    </div>
    <div class="barrage-box"></div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      Type: Object,
      default: {},
    },
    stylesheet: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  created() {
    // console.log(this.data);
  }
};
</script>
<style lang="scss" scoped>
.comment {
  width: 100%;
  min-height: 340px;
  position: relative;
  background-position: center;
  background-size: 100%;
  .comment-text {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    text-align: center;
    line-height: 70px;
  }
  .redpack-box {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 225px;
    right: 16px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .barrage-box {
    width: 100%;
    height: 58px;
    background: url(~@assets/images/designer/dibu@2x.png) no-repeat;
    background-size: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
</style>
