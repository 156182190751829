<template>
  <div
    class="_live-data"
    v-loading="pageLoading"
    element-loading-background="rgba(0, 0, 0, 0.1)"
  >
    <div class="_live-data__head">
      <y-button size="small" plain @click="refresh">Refresh</y-button>
      <y-button size="small" plain @click="exportReport">
        Export Report
      </y-button>
    </div>
    <div class="_live-data__main" ref="livedataRef">
      <!-- overview -->
      <div class="_live-data__block _live-data__overview">
        <div class="_live-data__block-head">
          <h4>Overview</h4>
        </div>
        <div class="_live-data__block-main">
          <div class="_live-data__block-item">
            <div
              class="_live-data__overview-item"
              v-for="(item, i) in overview"
              :key="i"
            >
              <div class="_live-data__overview-item-inner">
                <h6>{{ item.title }}</h6>
                <em>{{ item.val }}</em>
                <div class="_live-data__overview-sub">
                  <span class="_live-data__overview-sub-title">
                    {{ item.subTitle }}
                  </span>
                  <span class="_live-data__overview-sub-val">
                    {{ item.subVal }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="_live-data__block-item">
            <div
              class="_live-data__overview-watch-item"
              v-for="(item, i) in watchData"
              :key="i"
            >
              <div class="_live-data__overview-watch-item-inner">
                <div class="_live-data__overview-watch-item-icon">
                  <i :class="item.icon"></i>
                </div>
                <div class="_live-data__overview-watch-item-info">
                  <h6>{{ item.title }}</h6>
                  <p>{{ item.val }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /overview -->

      <!-- Viewer Engagement -->
      <div class="_live-data__block _live-data__engagement">
        <div class="_live-data__block-head">
          <h4>
            Viewer
            <el-tooltip effect="dark" placement="right-end">
              <div slot="content">
                The number of viewers during the selected date range.
              </div>
              <i
                class="ui-icon-help-w"
                style="vertical-align: -1px; margin-left: 4px"
              ></i>
            </el-tooltip>
          </h4>
          <!-- <y-button type="text" size="small">
            Download
            <i class="ui-icon-download" style="vertical-align: -2px"></i>
          </y-button> -->
        </div>
        <div class="_live-data__block-main">
          <div class="_live-data__engagement-time">
            <div class="_live-data__engagement-time-inner" ref="viewRef"></div>
            <y-button
              size="small"
              type="text"
              @click="downloadViewImg"
              class="_live-data__download"
            >
              Download
              <i class="ui-icon-download" style="vertical-align: -2px"></i>
            </y-button>
          </div>
          <div class="_live-data__block-item">
            <div
              class="
                _live-data__engagement-item _live-data__engagement-activity
              "
            >
              <div
                class="_live-data__engagement-item-inner"
                ref="activityRef"
              ></div>
              <y-button
                size="small"
                type="text"
                @click="downloadActivityImg"
                class="_live-data__download"
              >
                Download
                <i class="ui-icon-download" style="vertical-align: -2px"></i>
              </y-button>
            </div>
            <div
              class="
                _live-data__engagement-item _live-data__engagement-terminal
              "
            >
              <div
                class="_live-data__engagement-item-inner"
                ref="terminalRef"
              ></div>
              <y-button
                size="small"
                type="text"
                @click="downloadTerminalImg"
                class="_live-data__download"
              >
                Download
                <i class="ui-icon-download" style="vertical-align: -2px"></i>
              </y-button>
            </div>
          </div>
        </div>
      </div>
      <!-- /Viewer Engagement -->
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import html2canvas from 'html2canvas'
import * as echarts from 'echarts'
import accounting from 'accounting'
import { saveFile, secondToDate } from '@/utils/tools'

export default {
  props: {
    consoleInfo: Object
  },
  data() {
    return {
      activityId: this.$route.params.id,
      overview: [
        {
          title: 'Live Time',
          key: 'liveDuration',
          val: '--:--:--',
          subTitle: 'Peak Time',
          subKey: 'peakAppearTime',
          subVal: '--:--'
        },
        {
          title: 'Viewers',
          key: 'audienceCount',
          val: '--',
          subTitle: 'Peak',
          subKey: 'peak',
          subVal: '--'
        },
        {
          title: 'Comments',
          key: 'commentCount',
          val: '--',
          subTitle: 'Engagement Rate',
          subKey: 'audienceActivityRate',
          subVal: '--'
        }
      ],
      watchData: [
        {
          title: 'Total View Time',
          key: 'totalWatchDuration',
          val: '--',
          icon: 'ui-icon-time-total'
        },
        {
          title: 'Avg View Time',
          key: 'avgWatchDuration',
          val: '--',
          icon: 'ui-icon-time-avg'
        }
      ],
      viewOption: {
        tooltip: {
          trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: 60,
          bottom: 35,
          right: 30
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
          axisLine: {
            lineStyle: {
              color: 'rgba(255,255,255,0.15)'
            }
          },
          axisLabel: {
            color: 'rgba(255, 255, 255, 0.4)'
            // showMinLabel: false,
            // showMaxLabel: false
          }
        },
        yAxis: [
          {
            type: 'value',
            splitLine: {
              lineStyle: {
                type: 'dashed', //设置网格线类型 dashed：虚线   solid:实线
                width: 1,
                color: 'rgba(255,255,255,0.25)'
              }
            },
            axisLabel: {
              color: 'rgba(255, 255, 255, 0.4)'
            },
            minInterval: 1,
            max: 10000,
            min: 0
          }
        ],
        series: [
          {
            data: [],
            type: 'line'
          }
        ]
      },
      activityOption: {
        title: [
          {
            text: 'Viewers commented',
            textStyle: {
              color: '#fff',
              fontSize: 13
            },
            top: 20,
            left: 20
          },
          {
            text: '',
            x: '50%',
            y: 'center',
            textAlign: 'center',
            textStyle: {
              fontSize: 13,
              color: 'rgba(255, 255, 255, 0.6)'
            }
          }
        ],
        legend: {
          orient: 'vertical', //垂直显示
          y: 'center', // 延Y轴居中
          right: 20,
          align: 'left',
          icon: 'circle',
          itemHeight: 8, // 修改icon图形大小
          textStyle: {
            color: '#fff'
          }
        },
        color: ['rgba(255, 221, 97, 1)', 'rgba(255, 255, 255, 1)'],
        series: [
          {
            type: 'pie',
            radius: ['45%', '60%'],
            avoidLabelOverlap: false,
            hoverAnimation: false,
            label: {
              show: false,
              position: 'center',
              color: 'rgba(255, 255, 255, 0.6)',
              fontSize: 13
            },
            labelLine: {
              show: false
            },
            data: [
              {
                value: 100,
                itemStyle: {
                  color: '#fff'
                }
              }
            ]
          },
          {
            type: 'pie',
            radius: ['45%', '60%'],
            avoidLabelOverlap: false,
            hoverAnimation: false,
            label: {
              show: false,
              position: 'center',
              color: 'rgba(255, 255, 255, 0.6)',
              fontSize: 13
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 0, name: 'Comment participants' },
              { value: 1, name: 'Total ' }
            ]
          }
        ]
      },
      terminalOption: {
        title: {
          text: 'Watch terminal',
          textStyle: {
            color: '#fff',
            fontSize: 13
          },
          top: 20,
          left: 20
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical', //垂直显示
          y: 'center', // 延Y轴居中
          right: 80,
          align: 'left',
          icon: 'circle',
          itemHeight: 8, // 修改icon图形大小
          textStyle: {
            color: '#FFFFFF'
          }
        },
        color: ['rgba(255, 221, 97, 1)', 'rgba(93, 211, 255, 1)'],
        series: [
          {
            type: 'pie',
            radius: '60%',
            selectedMode: 'single',
            data: [],
            label: {
              normal: {
                show: false,
                position: 'inside',
                formatter: '{b}:{d}%'
              }
            }
          }
        ]
      },
      terminalChartInstance: null,
      pageLoading: false
    }
  },
  beforeDestroy() {
    this.viewEchartInstance.dispose()
    this.activityChartInstance.dispose()
    this.terminalChartInstance.dispose()
  },
  mounted() {
    this.renderViewChart()
    this.renderActivityChart()
    this.renderTerminalChart()
    // activityStatus: 直播间状态0:未开始,1:已删除,2:结束,3:预约,4:直播中,5:暂停
    if (!this.consoleInfo || this.consoleInfo.activityStatus === 2) {
      this.fetchStatisticsData()
      this.fetchStreamOnline()
    }
  },
  methods: {
    renderViewChart() {
      this.viewEchartInstance = echarts.init(this.$refs.viewRef)
      this.viewEchartInstance.setOption(this.viewOption)
    },
    renderActivityChart() {
      this.activityChartInstance = echarts.init(this.$refs.activityRef)
      this.activityChartInstance.setOption(this.activityOption)
    },
    renderTerminalChart() {
      this.terminalChartInstance = echarts.init(this.$refs.terminalRef)
      this.terminalChartInstance.setOption(this.terminalOption)
    },
    async fetchStatisticsData() {
      this.pageLoading = true
      const res = await this.$serve.statistics.activity({
        queryId: this.activityId
      })
      this.pageLoading = false
      if (res === this.$serve.FAIL) {
        return
      }
      this.updateOverview(res)
      this.updateWatchData(res)
      this.activityChartInstance.setOption({
        title: [
          {},
          {
            text:
              (
                (res.commentAudienceCount * 100) / res.audienceCount || 0
              ).toFixed(2) + '%'
          }
        ],
        series: [
          {},
          {
            data: [
              {
                value: res.commentAudienceCount,
                name: 'Comment participants'
              },
              {
                value: res.commentAudienceCount
                  ? res.audienceCount - res.commentAudienceCount
                  : 1,
                name: 'Total '
              }
            ]
          }
        ]
      })
      this.terminalChartInstance.setOption({
        series: [
          {
            data: [
              {
                value: res.pcCount,
                name: 'PC'
              },
              {
                value: res.mobileCount,
                name: 'Mobile'
              }
            ]
          }
        ]
      })
    },
    async fetchStreamOnline() {
      this.pageLoading = true
      const res = await this.$serve.statistics.streamOnline({
        queryId: this.activityId
      })
      this.pageLoading = false
      if (res === this.$serve.FAIL) {
        return
      }
      if (res.length) {
        this.viewEchartInstance.setOption({
          xAxis: {
            data: res.map((item) =>
              moment(item.currentTimeMills).format('MMM D, hh:mm A')
            ),
            axisLabel: {
              showMinLabel: res.length < 4,
              showMaxLabel: res.length < 4
            }
          },
          series: [
            {
              data: res.map((item) => item.count)
            }
          ],
          yAxis: [
            {
              max: undefined
            }
          ]
        })
      }
    },
    updateOverview(data) {
      data.liveDuration = secondToDate(data.liveDuration)
      data.peakAppearTime = data.peakAppearTime
        ? moment(data.peakAppearTime).format('MMM D, hh:mm A')
        : '00:00:00'
      data.audienceActivityRate =
        ((data.commentAudienceCount * 100) / data.audienceCount || 0).toFixed(
          2
        ) + '%'
      data.audienceCount = accounting.formatNumber(data.audienceCount)
      data.commentCount = accounting.formatNumber(data.commentCount)
      data.totalWatchDuration = secondToDate(data.totalWatchDuration)
      data.avgWatchDuration = secondToDate(data.avgWatchDuration)
      this.overview.forEach((item) => {
        item.val = data[item.key]
        if (data[item.subKey] !== undefined) {
          item.subVal = data[item.subKey]
        }
      })
    },
    updateWatchData(data) {
      this.watchData.forEach((item) => {
        item.val = data[item.key]
      })
    },
    downloadViewImg() {
      saveFile(
        this.viewEchartInstance.getDataURL({
          type: 'png',
          pixelRatio: 1.5,
          backgroundColor: 'rgba(41, 43, 60, 0.4)'
        }),
        'engagement.png'
      )
    },
    downloadActivityImg() {
      saveFile(
        this.activityChartInstance.getDataURL({
          type: 'png',
          pixelRatio: 1.5,
          backgroundColor: 'rgba(41, 43, 60, 0.4)'
        }),
        'engagement.png'
      )
    },
    downloadTerminalImg() {
      saveFile(
        this.terminalChartInstance.getDataURL({
          type: 'png',
          pixelRatio: 1.5,
          backgroundColor: 'rgba(41, 43, 60, 0.4)'
        }),
        'engagement.png'
      )
    },
    refresh() {
      this.fetchStatisticsData()
      this.fetchStreamOnline()
    },
    exportReport() {
      html2canvas(this.$refs.livedataRef, {
        useCORS: true // 开启跨域设置，需要后台设置cors
      }).then((canvas) => {
        // toDataURL函数生成img标签的可用数据  图片格式转成 base64
        let dataURL = canvas.toDataURL('image/png')
        saveFile(dataURL, 'live-data.png')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
._live-data {
  color: #ffffff;
  h4,
  h6 {
    margin: 0;
  }
}
._live-data__head {
  height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: rgba(41, 43, 60, 0.4);
  padding: 0 20px;
  margin: 0px 20px 0;
  .el-button {
    background: transparent;
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.15);
    &:hover,
    &:focus {
      background: transparent;
      color: #ff4d7c;
      border-color: #ff4d7c !important;
    }
  }
}
._live-data__main {
  padding: 24px 20px;
  background: #1e1f2b;
}
._live-data__block-head {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  position: relative;
  font-size: 18px;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -10px;
    width: 4px;
    height: 20px;
    background: #ff4d73;
    border-radius: 2px;
  }
  p {
    font-size: 14px;
  }
}
._live-data__block-head {
  margin-bottom: 16px;
}
._live-data__block-item {
  display: flex;
  justify-content: space-between;
}
._live-data__overview {
  ._live-data__block-item {
    margin-right: -20px;
    & + ._live-data__block-item {
      margin-top: 20px;
    }
  }
}
._live-data__overview-item,
._live-data__overview-watch-item {
  flex: 1;
  padding-right: 20px;
}
._live-data__overview-item-inner,
._live-data__overview-watch-item-inner {
  padding: 20px 20px 24px 20px;
  border-radius: 4px;
  background: rgba(41, 43, 60, 0.4);
}
._live-data__overview-watch-item {
  ._live-data__overview-watch-item-inner {
    padding-top: 24px;
  }
}
._live-data__overview-item-inner {
  h6 {
    font-size: 13px;
    margin-bottom: 16px;
  }
  em {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
  }
}
._live-data__overview-sub {
  margin-top: 24px;
  font-size: 14px;
}
._live-data__overview-sub-title {
  color: rgba(255, 255, 255, 0.4);
  margin-right: 8px;
}
._live-data__overview-watch-item-inner {
  display: flex;
  align-items: center;
}
._live-data__overview-watch-item-icon {
  width: 56px;
  height: 56px;
  margin-right: 20px;
}
._live-data__overview-watch-item-info {
  flex: 1;
  h6 {
    font-size: 13px;
    margin-bottom: 8px;
  }
  p {
    font-size: 24px;
  }
}
._live-data__engagement {
  margin-top: 20px;
  ._live-data__block-item {
    margin-right: -20px;
  }
}
._live-data__engagement-time {
  width: 100%;
  height: 340px;
  margin-bottom: 16px;
  background: rgba(41, 43, 60, 0.4);
  border-radius: 4px;
  position: relative;
}
._live-data__engagement-time-inner {
  width: 100%;
  height: 340px;
}
._live-data__engagement-item {
  flex: 1;
  padding-right: 20px;
  position: relative;
}
._live-data__engagement-item-inner {
  border-radius: 4px;
  background: rgba(41, 43, 60, 0.4);
  height: 344px;
}
._live-data__download {
  position: absolute;
  top: 10px;
  right: 16px;
}
._live-data__engagement-item {
  ._live-data__download {
    right: 36px;
  }
}
::v-deep {
  .el-button--text {
    color: #ff4d7c;
  }
}
</style>
