<template>
  <div class="_anlaysis-area">
    <div class="_anlaysis-area__head">
      <h4>Viewer Analysis</h4>
    </div>
    <div
      class="_anlaysis-area__main"
      v-loading="loading"
      element-loading-background="rgba(0, 0, 0, 0.1)"
    >
      <div
        class="
          _anlaysis-area__info-status
        "
      >
        <ul>
          <li>
            <p class="_anlaysis-area__label">Watching Now</p>
            <p class="_anlaysis-area__value">{{ watchingNow }}</p>
          </li>
          <li>
            <p class="_anlaysis-area__label">Peak Viewers</p>
            <p class="_anlaysis-area__value">{{ peak }}</p>
          </li>
          <li>
            <p class="_anlaysis-area__label">Recorded Viewers</p>
            <p class="_anlaysis-area__value">{{ recordedViewCount }}</p>
          </li>
          <li>
            <p class="_anlaysis-area__label">Total Viewers</p>
            <p class="_anlaysis-area__value">{{ totalViewCount }}</p>
          </li>
          <li>
            <p class="_anlaysis-area__label">Total View Time</p>
            <p class="_anlaysis-area__value">{{ totalViewDuration }}</p>
          </li>
          <li>
            <p class="_anlaysis-area__label">Avg View Time</p>
            <p class="_anlaysis-area__value">{{ avgViewDuration }}</p>
          </li>
        </ul>
      </div>
      <div class="_anlaysis-area__info _anlaysis-area__info-engagement">
        <div
          class="_anlaysis-area__info-engagement-inner"
          ref="engagementRef"
        ></div>
      </div>
      <div class="_anlaysis-area__info _anlaysis-area__info-geographical">
        <div
          class="_anlaysis-area__info-geographical-inner"
          ref="terminal"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import moment from 'moment'
function formatDuration(val) {
  if(val < 60){
    let second = val < 10 ? `0${val}` : val;
    return `00:00:${second}`;
  } else {
    let min_total = Math.floor(val / 60); // 分钟
    min_total = min_total < 10 ? `0${min_total}` : min_total
    let sec = Math.floor(val % 60); // 余秒
    sec = sec < 10 ? `0${sec}` : sec
    if(min_total < 60){
      return '00:' + min_total + ":" + sec;
    } else {
      let hour_total = Math.floor(min_total / 60); // 小时数
      hour_total = hour_total < 10 ? `0${hour_total}` : hour_total
      let min = Math.floor(min_total % 60); // 余分钟
      min = min < 10 ? `0${min}` : min
      return hour_total + ":" + min + ":" + sec;
    }
  }
}

export default {
  props: {
    consoleInfo: Object,
    liveInfo: Object
  },
  data() {
    return {
      activityId: this.$route.params.id,
      watchingNow: 0, // Watching Now
      recordedViewCount: 0, // Recorded Viewers
      peak: 0, // Peak Viewers
      totalViewCount: 0, // Total Viewers
      totalViewDuration: 0, // Total View Duration
      avgViewDuration: 0, // Avg View Duration
      datatimer: null,
      frequence: 15,
      timeList: [],
      watchTerminal: [],
      viewEngagement: [],
      engagementChart: null,
      terminalChart: null,
      engagementOption: {
        title: {
          text: 'Viewer Engagement',
          textStyle: {
            color: '#fff',
            fontSize: 12
          },
          top: 12,
          left: 12
        },
        grid: {
          bottom: 40,
        },
        xAxis: {
          type: 'category',
          data: [],
          boundaryGap: false,
          axisLabel: {
            interval: 10,
            // 坐标轴刻度标签换行处理
            formatter: function (params) {
              let newParamsName = ""; // 最终拼接成的字符串
              let paramsNameNumber = params.length; // 实际标签的个数
              let provideNumber = 12; // 每行能显示的字的个数
              let rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 换行的话，需要显示几行，向上取整
              /**
               * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
               */
              // 条件等同于rowNumber>1
              if (paramsNameNumber > provideNumber) {
                /** 循环每一行,p表示行 */
                for (let p = 0; p < rowNumber; p++) {
                  let tempStr = ""; // 表示每一次截取的字符串
                  let start = p * provideNumber; // 开始截取的位置
                  let end = start + provideNumber; // 结束截取的位置
                  // 此处特殊处理最后一行的索引值
                  if (p == rowNumber - 1) {
                    // 最后一次不换行
                    tempStr = params.substring(start, paramsNameNumber);
                  } else {
                    // 每一次拼接字符串并换行
                    tempStr = params.substring(start, end) + "\n";
                  }
                  newParamsName += tempStr; // 最终拼成的字符串
                }
              } else {
                // 将旧标签的值赋给新标签
                newParamsName = params;
              }
              //将最终的字符串返回
              return newParamsName;
            },
          },
        },
        yAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              type: 'dashed', //设置网格线类型 dashed：虚线   solid:实线
              width: 1,
              color: ['rgba(255, 255, 255, 0.25)']
            }
          }
        },
        series: [
          {
            data: [],
            type: 'line',
            symbol: 'none',
            smooth: true,
            lineStyle: {
              width: 1
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1, //变化度
                //渐变色
                [
                  {
                    offset: 1,
                    color: 'rgba(65, 142, 255, 0)'
                  },
                  {
                    offset: 0,
                    color: 'rgba(65, 142, 255, 1)'
                  }
                ]
              )
            }
          }
        ]
      },
      terminalOption: {
        title: {
          text: 'Watch terminal',
          textStyle: {
            color: '#fff',
            fontSize: 12
          },
          top: 12,
          left: 12
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          data: ['PC', 'Mobile'],
          right: 33,
          top: 12,
          icon: "circle",
          itemHeight: 8, // 修改icon图形大小
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF',
          },
        },
        color:[
          'rgba(255, 221, 97, 1)',
          'rgba(93, 211, 255, 1)'
        ],
        series: [
          {
            type: 'pie',
            radius: '65%',
            center: ['50%', '55%'],
            selectedMode: 'single',
            data: [],
            label: {
              normal: {
                show: false,
                position: 'inside',
                formatter:"{b}:{d}%"
              },
            },
          }
        ]
      },
      gap: 0, // 120 /frequence(15) / 2 - 1
    }
  },
  computed: {
    loading() {
      return this.$store.state.loading['/overseas/console/view-data']
    }
  },
  watch: {
    consoleInfo: {
      handler(val) {
        if (!val) {
          return
        }
        const { activityStatus } = val
        this.run(activityStatus)
      },
      immediate: true
    },
    liveInfo: {
      handler(val) {
        if (!val) {
          return
        }
        const { activityStatus } = val
        this.run(activityStatus)
        console.log(2, 'liveInfo')
      },
      immediate: true
    }
  },
 async mounted() {
   this.frequence = Number(sessionStorage.getItem('chartFrequence'))
   this.gap = Number(sessionStorage.getItem('chartGap'))
   this.timeList = JSON.parse(sessionStorage.getItem('chartTimeList')) ? JSON.parse(sessionStorage.getItem('chartTimeList')) : []
   this.viewEngagement = JSON.parse(sessionStorage.getItem('chartViewEngagement')) ? JSON.parse(sessionStorage.getItem('chartViewEngagement')) : [0]
   this.watchTerminal = JSON.parse(sessionStorage.getItem('chartWatchTerminal')) ? JSON.parse(sessionStorage.getItem('chartWatchTerminal')) : [
     {
       value: 0,
       name: 'PC',
     },
     {
       value: 0,
       name: 'Mobile',
     }
   ]
    await this.renderEngagementChart()
    await this.renderTerminalChart()
   let fromSession = false
   if (sessionStorage.getItem('chartViewEngagement')) {
     fromSession = true
   }
    await this.changeData(fromSession)
  },
  beforeDestroy() {
    if (this.datatimer) {
      clearTimeout(this.datatimer)
    }
  },
  methods: {
    // 直播间状态0:未开始,1:已删除,2:结束,3:预约,4:直播中,5:暂停
    run(activityStatus) {
      if ([2, 4, 5].includes(activityStatus)) {
        this.loopFetchViewData()
      } else {
        if (this.datatimer) {
          clearTimeout(this.datatimer)
        }
      }
    },
    // 获取浏览观看统计数据
    async fetchViewData() {
      const res = await this.$serve.console.getStatistics({
        params: {
          activityId: this.activityId
        }
      })
      if (res === this.$serve.FAIL || !res) {
        return
      }
      this.watchingNow = res.watchingNow || 0
      this.recordedViewCount = res.recordedViewCount || 0
      this.peak = res.peak || 0
      this.totalViewCount = res.totalViewCount || 0
      this.totalViewDuration = formatDuration(res.totalViewDuration || 0)
      this.avgViewDuration = formatDuration(res.avgViewDuration || 0)
      this.frequence = res.frequence
      this.gap = parseInt(parseInt(parseInt(120 / this.frequence) / 2) - 1)
      const currentTime = moment(res.currentTimeMills).format('hh:mm A')
      this.changeData(false, this.watchingNow, res.pcCount, res.mobileCount, currentTime)
    },
    loopFetchViewData() {
      this.fetchViewData()
      if (this.datatimer) {
        clearTimeout(this.datatimer)
      }
      this.datatimer = setTimeout(() => {
        this.loopFetchViewData()
      }, (this.frequence || 15) * 1000)
    },
    // 渲染图表
    renderEngagementChart() {
      this.engagementChart = echarts.init(this.$refs.engagementRef)
      this.engagementChart.setOption(this.engagementOption)
    },
    // 渲染饼图图标
    renderTerminalChart() {
      this.terminalChart = echarts.init(this.$refs.terminal)
      this.terminalChart.setOption(this.terminalOption)
    },
    changeData(fromSession, watchingNow = 0, pcCount = 0, mobileCount = 0, currentTime = moment(new Date().getTime()).format('hh:mm A')) {
      if (!fromSession) {
        this.watchTerminal = [
          {
            value: pcCount,
            name: 'PC',
          },
          {
            value: mobileCount,
            name: 'Mobile',
          }
        ]
        this.viewEngagement.push(watchingNow)
        this.timeList.push(currentTime)
      }
      if (this.timeList.length === parseInt(120 / this.frequence) + 3) {
        this.timeList = this.timeList.splice(1)
        this.viewEngagement = this.viewEngagement.splice(1)
      }
      this.engagementChart.setOption({
        xAxis: {
          type: 'category',
          data: this.timeList,
          axisLabel: {
            interval: this.gap,
          }
        },
        series: [
          {
            data: this.viewEngagement,
            type: 'line',
            symbol: 'none',
            smooth: true,
            lineStyle: {
              width: 1
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1, //变化度
                //渐变色
                [
                  {
                    offset: 1,
                    color: 'rgba(65, 142, 255, 0)'
                  },
                  {
                    offset: 0,
                    color: 'rgba(65, 142, 255, 1)'
                  }
                ]
              )
            }
          }
        ],
      })
      this.terminalChart.setOption({
        series: [
          {
            type: 'pie',
            radius: '65%',
            center: ['50%', '55%'],
            selectedMode: 'single',
            data: this.watchTerminal,
            label: {
              normal: {
                show: false,
                position: 'inside',
                formatter:"{b}:{d}%"
              },
            },
          }
        ]
      })
      sessionStorage.setItem('chartTimeList', JSON.stringify(this.timeList || []))
      sessionStorage.setItem('chartViewEngagement', JSON.stringify(this.viewEngagement || []))
      sessionStorage.setItem('chartWatchTerminal', JSON.stringify(this.watchTerminal || []))
      sessionStorage.setItem('chartFrequence', this.frequence || 15)
      sessionStorage.setItem('chartGap', this.gap || 0)
    },
  }
}
</script>

<style lang="scss" scoped>
._anlaysis-area {
  padding: 12px 16px;
  position: relative;
  color: #fff;
}

._anlaysis-area__head {
  margin-bottom: 24px;
  h4 {
    margin: 0;
    font-size: 20px;
  }
}
._anlaysis-area__info {
  background: rgba(41, 43, 60, 0.4);
  overflow: hidden;
  &:not(:first-child) {
    margin-top: 24px;
  }
}
._anlaysis-area__info-status {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 10px;
    line-height: 20px;
    border-radius: 4px 0px 4px 0px;
    background: #292b3c;
    font-size: 12px;
  }
  ul {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
  }
  li {
    float: left;
    width: 48%;
    height: 64px;
    text-align: center;
    padding: 4px 12px 6px;
    background-color: rgba(41, 43, 60, 0.4);
    margin-bottom: 16px;
    border-radius: 4px;
    &:nth-child(2n) {
      margin-left: 4%;
    }
    &:nth-child(5), &:nth-child(6) {
      margin-bottom: 0;
    }
  }
}
._anlaysis-area__value {
  line-height: 28px;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 4px;
  text-align: left;
}
._anlaysis-area__label {
  line-height: 18px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
  text-align: left;
  margin-bottom: 4px;
}
._anlaysis-area__info-engagement {
  width: 100%;
  padding-top: 64%;
  position: relative;
  border-radius: 4px;
}
._anlaysis-area__info-engagement-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

._anlaysis-area__info-geographical {
  width: 100%;
  padding-top: 64%;
  position: relative;
  border-radius: 4px;
}
._anlaysis-area__info-geographical-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
</style>
