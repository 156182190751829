<template>
  <div class="guide-edit">
    <div class="cover-box mobile-cover">
      <div class="text">Moblie</div>
      <div>
        <div class="cover-img-box" @click="choiceMedia('mobile')">
          <div class="upload-box" v-if="!data.mobileCover">
            <div class="icon"></div>
            <div class="icon-text">Upload image</div>
          </div>
          <div class="has-img" v-else>
            <img :src="data.mobileCover" alt="" />
            <div class="change-img">Change image</div>
          </div>
        </div>
      </div>
      <div class="tips" style="margin-top: 8px">
        Recommended pixels: 750*1432px, supports JPG, PNG and the size does not exceed 20MB;
      </div>
    </div>
    <div class="cover-box web-cover">
      <div class="text">Web</div>
      <div>
        <div class="cover-img-box" @click="choiceMedia('web')">
          <div class="upload-box" v-if="!data.webCover">
            <div class="icon"></div>
            <div class="icon-text">Upload image</div>
          </div>
          <div class="has-img" v-else>
            <img :src="data.webCover" alt="" />
            <div class="change-img">Change image</div>
          </div>
        </div>
      </div>
      <div class="tips" style="margin-top: 8px">
        Recommended pixels: 1920*1080px, supports JPG, PNG and the size does not exceed 20MB;
      </div>
    </div>
    <media-dialog-radio
      v-if="visibleMediaShowCover"
      :title="'Select Image'"
      :button-text="'Upload Picture'"
      :extensions="'jpg,png'"
      :visible="visibleMediaCover"
      :material-type="1"
      :w="w"
      :h="h"
      :limt="limt"
      @updateMedia="uploadSuccessCover"
      suffix="image"
      groupTitle="All Pictures"
      :multi_selection="false"
    ></media-dialog-radio>
  </div>
</template>
<script>
  import mediaDialogRadio from '@/components/media-dialog-radio';

  export default {
    props: {
      data: {
        Type: Object,
        default: {},
      },
    },
    components: {
      mediaDialogRadio
    },
    data() {
      return {
        type: '',
        w: 750,
        h: 1432,
        limt: '20MB',
        visibleMediaCover: false,
        visibleMediaShowCover: false,
      };
    },
    created() {
      // console.log(this.data)
    },
    methods: {
      isMobile() {
        this.w = 750;
        this.h = 1432;
        this.type = 'mobile';
      },
      isWeb() {
        this.w = 1920;
        this.h = 1080;
        this.type = 'web';
      },
      async uploadSuccessCover (data, resultList) {
        if (data) {
          const url = resultList[0].url;
          if (this.type === 'mobile') {
            this.data.mobileCover = url
          } else {
            this.data.webCover = url
          }
        }
        this.visibleMediaCover = false;
        let timer = setTimeout(() => {
          clearTimeout(timer);
          timer = null;
          this.visibleMediaShowCover = false;
        }, 200);
      },
      choiceMedia(type) {
        if (type === 'mobile') {
          this.isMobile()
        } else {
          this.isWeb()
        }
        this.visibleMediaShowCover = true;
        let timer = setTimeout(() => {
          clearTimeout(timer);
          timer = null;
          this.visibleMediaCover = true;
        }, 200);
      }
    },
  };
</script>
<style lang="scss">
  .guide-edit {
    padding: 16px;
    .tips {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: $-color-black-40;
      line-height: 18px;
    }
    .cover-box {
      padding-bottom: 24px;
      margin-top: 20px;
      &:first-child {
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        margin-top: 0;
      }
      .text {
        font-size: 14px;
        font-family: HelveticaNeue;
        font-weight: 500;
        color: $-color-black-80;
        margin-bottom: 12px;
      }
      .upload-box {
        background: rgba(247, 248, 250, 1);
        border: 1px dashed rgba(0, 0, 0, 0.15);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .icon {
          width: 8px;
          height: 8px;
          background: url(~@assets/images/designer/upload@2x.png)
          no-repeat;
          background-size: 100%;
        }
        .icon-text {
          font-size: 14px;
          font-family: HelveticaNeue;
          color: $-color-black-40;
          margin-left: 6px;
        }
      }
      .has-img {
        position: relative;
        &:hover {
          .change-img {
            display: block;
          }
        }

        img {
          width: 100%;
          height: 100%;
        }

        .change-img {
          display: none;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          text-align: center;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          background-color: rgba(0, 0, 0, 0.4);
          font-family: HelveticaNeue;
          cursor: pointer
        }
      }
    }
    .mobile-cover {
      .upload-box {
        width: 162px;
        height: 288px;
        background: rgba(247, 248, 250, 1);
        border: 1px dashed rgba(0, 0, 0, 0.15);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .icon {
          width: 8px;
          height: 8px;
          background: url(~@assets/images/designer/upload@2x.png)
          no-repeat;
          background-size: 100%;
        }
        .icon-text {
          font-size: 14px;
          font-family: HelveticaNeue;
          color: $-color-black-40;
          margin-left: 6px;
        }
      }
      .has-img {
        width: 162px;
        height: 288px;
        position: relative;
        &:hover {
          .change-img {
            display: block;
          }
        }

        img {
          width: 100%;
          height: 100%;
        }

        .change-img {
          display: none;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          line-height: 288px;
          text-align: center;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          background-color: rgba(0, 0, 0, 0.4);
          font-family: HelveticaNeue;
        }
      }
    }
    .web-cover {
      .upload-box {
        width: 100%;
        height: 193px;
        background: rgba(247, 248, 250, 1);
        border: 1px dashed rgba(0, 0, 0, 0.15);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .icon {
          width: 8px;
          height: 8px;
          background: url(~@assets/images/designer/upload@2x.png)
          no-repeat;
          background-size: 100%;
        }
        .icon-text {
          font-size: 14px;
          font-family: HelveticaNeue;
          color: $-color-black-40;
          margin-left: 6px;
        }
      }
      .has-img {
        width: 100%;
        height: 193px;
        position: relative;
        &:hover {
          .change-img {
            display: block;
          }
        }

        img {
          width: 100%;
          height: 100%;
        }

        .change-img {
          display: none;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          line-height: 193px;
          text-align: center;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          background-color: rgba(0, 0, 0, 0.4);
          font-family: HelveticaNeue;
        }
      }
    }
  }
</style>
