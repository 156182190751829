<template>
  <div class="_document-area">
    <div class="_document-area__head">
      <h4>Document</h4>
      <y-switch
        active-color="#FF4D7C"
        inactive-color="#292B3C"
        v-model="documentStatus"
        :disabled="!list.length"
        @change="switchDocumentStatus"
      ></y-switch>
    </div>
    <div
      class="_document-area__main"
      v-loading="loading"
      element-loading-background="rgba(0, 0, 0, 0.1)"
    >
      <div class="_document-area__list" v-if="list.length">
        <div
          class="_document-area__img-item"
          v-for="(item, i) in list"
          :key="i"
          :style="{
            backgroundImage: 'url(' + item + ')'
          }"
          @click="preview(i)"
        >
          <span class="_document-area__img-item-indicator">
            Page {{ i + 1 }}/{{ list.length }}
          </span>
        </div>
      </div>
      <div class="_document-area__list-empty" v-else>
        Please
        <span class="_document-area__list-empty-action" @click="toDecorate">
          click
        </span>
        here to add a document
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    consoleInfo: Object,
    liveInfo: Object
  },
  data() {
    return {
      activityId: this.$route.params.id,
      list: [],
      loading: false,
      documentStatus: false
    }
  },
  computed: {
    switchDocumentStatusLoading() {
      return (
        this.$store.state.loading[
          '/overseas/activity-decoration-component/switchDocumentStatus'
        ] || this.$store.state.loading['/overseas/activity/getBasicConfig']
      )
    }
  },
  watch: {
    activityId: {
      handler() {
        this.getBasicConfig()
        this.getDocumentByActivityId()
      },
      immediate: true
    }
  },
  methods: {
    async getBasicConfig() {
      const res = await this.$serve.activity.getBasicConfig({
        queryId: this.activityId
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.documentStatus = res.documentStatus ? true : false
    },
    async getDocumentByActivityId() {
      this.loading = true
      const res = await this.$serve.activity.getDocumentByActivityId({
        queryId: this.activityId
      })
      this.loading = false
      if (!res || res === this.$serve.FAIL) {
        return
      }
      try {
        this.list = JSON.parse(res.data).images
      } catch (error) {
        console.log(error)
      }
    },
    async switchDocumentStatus(documentStatus) {
      const res = await this.$serve.activity.switchDocumentStatus({
        data: {
          activityId: this.activityId,
          // 文档开关状态:1-开启,2-关闭
          documentStatus: documentStatus ? 1 : 0
        }
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$message.success('success')
    },
    preview(initialViewIndex) {
      this.$viewerApi({
        images: this.list,
        options: {
          initialViewIndex
        }
      })
    },
    toDecorate() {
      const b = this.consoleInfo.beginTime
      if (this.consoleInfo.isDecorated && this.consoleInfo.isDecorated == 1) {
        this.$router.push(
          `/yolo-cast-design/${this.activityId}?isLiveDecorated=true&b=${b}`
        )
      } else {
        this.$router.push(`/yolo-cast-design/${this.activityId}?b=${b}`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
._document-area {
  padding: 12px 24px;
  color: #fff;
}
._document-area__head {
  display: flex;
  justify-content: space-between;
  h4 {
    margin: 0 0 20px 0;
    font-size: 20px;
  }
}
._document-area__img-item {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  background-color: #333;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  & + ._document-area__img-item {
    margin-top: 12px;
  }
}
._document-area__img-item-indicator {
  position: absolute;
  right: 16px;
  bottom: 12px;
  color: rgba(255, 255, 255, 0.8);
  background: rgba(0, 0, 0, 0.4);
  padding: 4px 8px;
  border-radius: 13px;
}
._document-area__list-empty {
  height: 216px;
  background: #292b3c;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.4);
  text-align: center;
  font-weight: bold;
}
._document-area__list-empty-action {
  display: inline-block;
  margin: 0 8px;
  cursor: pointer;
  color: #ff4d7c;
  text-decoration: underline;
}
</style>
