const fieldProps = {
  // 表单域的类型
  type: {
    type: String,
    default: 'input'
  },
  // 表单域el-form-item原生属性
  formItemProps: Object,
  // element 组件库表单元素原生属性
  attributes: Object,
  events: Object,
  // 表单域乘载数据, 可能在自定义渲染或其他情况下使用
  meta: Object,
  // 表单域自定义渲染, 配置了render之后, 以上type和attributes失效
  render: Function
}

export const fieldPropsKeys = Object.keys(fieldProps)

export default fieldProps
