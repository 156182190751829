import { render, staticRenderFns } from "./_play-area.vue?vue&type=template&id=8948703a&scoped=true&"
import script from "./_play-area.vue?vue&type=script&lang=js&"
export * from "./_play-area.vue?vue&type=script&lang=js&"
import style0 from "./_play-area.vue?vue&type=style&index=0&id=8948703a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8948703a",
  null
  
)

export default component.exports