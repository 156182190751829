<template>
  <div class="pc-designer-area">
    <div class="_play-area__status">
      <!-- 直播未开始/无流状态 -->
      <div class="_play-area__status-detail" v-if="current === 0">
        The live event hasn't started yet.
      </div>
      <!-- 直播暂停/无流状态 -->
      <div class="_play-area__status-detail" v-else-if="current === 5">
        The live event has paused.
      </div>
      <!-- 直播结束/无流状态 -->
      <div class="_play-area__status-detail" v-else-if="current === 2">
        The live event has ended.
      </div>
      <!-- 直播中/正常推流状态 -->
      <div class="_play-area__status-detail" v-else>
        <template v-if="networkStatus === 1">
          <audio-icon style="margin-right: 24px"></audio-icon>
          <p>The live event is in good progress.</p>
        </template>
        <template v-else-if="networkStatus === 2">
          <i class="ui-icon-danger"></i>
          <p>
            Network is poor. Please check your network connection and try again
            later.
          </p>
        </template>
        <template v-else-if="networkStatus === 3">
          <i class="ui-icon-warning"></i>
          <p>Your network connection is interrupted. Please check it out.</p>
        </template>
        <y-button
          class="_play-area__action"
          size="small"
          @click="stopLivingVisible = true"
        >
          End
        </y-button>
      </div>
    </div>
    <div class="play-area-stream">
      <div class="stream-box">
        <img
          class="thumbnail-url is-custom"
          v-if="settingCopy.showThumbnail && settingCopy.thumbnailUrl"
          :src="settingCopy.thumbnailUrl"
          alt=""
        />
        <img
          class="thumbnail-url is-default"
          v-else
          src="~@assets/images/default-thumbnail.png"
          alt=""
        />
        <div
          class="schedule-box"
          v-show="settingCopy.showSchedule"
          :style="{ color: settingCopy.fontColorValue }"
        >
          <span v-if="current === 0">The live event hasn't started yet.</span>
          <!-- 直播暂停/无流状态 -->
          <span v-else-if="current === 5">The live event has paused.</span>
          <!-- 直播结束/无流状态 -->
          <span v-else-if="current === 2">The live event has ended.</span>
        </div>
        <div
          class="left-and-bottom"
          v-if="settingCopy.showTrailer && settingCopy.trailerUrl"
        >
          <img class="cover-box" :src="settingCopy.trailerCoverUrl" alt="" />
          <img
            class="icon-player"
            src="~@assets/images/designer/play.png"
            alt=""
          />
        </div>
        <div class="right-and-top" v-show="settingCopy.showCountdownTimer">
          <div class="msg">
            <div class="value">{{ surplusTime.day < 10 ? `0${surplusTime.day}` : surplusTime.day }}</div>
            <div class="key">d</div>
            <div class="value">{{ surplusTime.hour < 10 ? `0${surplusTime.hour}` : surplusTime.hour }}</div>
            <div class="key">h</div>
            <div class="value">{{ surplusTime.min < 10 ? `0${surplusTime.min}` : surplusTime.min }}</div>
            <div class="key">m</div>
            <div class="value">{{ surplusTime.sec < 10 ? `0${surplusTime.sec}` : surplusTime.sec }}</div>
            <div class="key">s</div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="play-area-card"
      v-show="
        settingCopy.showPlatformShare ||
        settingCopy.showViewerData ||
        settingCopy.showEventDetail
      "
    >
      <div class="name-and-count">
        <div class="first-row">
          <div class="name-box c-overout" v-show="settingCopy.showEventDetail">
            {{ consoleInfo.title || 'Event Title' }}
          </div>
          <div class="count-box" v-show="settingCopy.showViewerData">
            <div class="key viewer"></div>
            <div class="value">0</div>
            <div class="key comment"></div>
            <div class="value">0</div>
          </div>
        </div>
        <div
          class="description-box c-overout-two"
          v-show="settingCopy.showEventDetail && consoleInfo.description"
        >
          {{ consoleInfo.description }}
        </div>
      </div>
      <div class="share-box" v-show="settingCopy.showPlatformShare">
        <div class="share-item item-twitter">
          <div class="share-logo twitter"></div>
          <div class="share-text">Twitter</div>
        </div>
        <div class="share-item item-facebook">
          <div class="share-logo facebook"></div>
          <div class="share-text">Facebook</div>
        </div>
        <div class="share-item item-copy">
          <div class="share-logo copy-Link"></div>
          <div class="share-text">Copy Link</div>
        </div>
      </div>
    </div>
    <div
      class="document-box"
      v-if="settingCopy.showDocument && settingCopy.documentId"
    >
      <div class="document-title">PDF</div>
      <div class="document-content" v-if="settingCopy.documentId">
        <img :src="settingCopy.documentCoverUrl" alt="" />
      </div>
    </div>
    <div class="videos-box" v-if="settingCopy.showRelatedVideo">
      <div class="videos-title">Related Videos</div>
      <div class="videos-content">
        <div
          class="video-item"
          v-for="(item, index) in settingCopy.relatedVideoArray"
          :key="index"
        >
          <img class="cover-box" :src="item.coverUrl" alt="" />
          <img
            class="icon-player"
            src="~@assets/images/designer/play.png"
            alt=""
          />
          <div class="video-item-name c-overout-two">{{ item.name }}</div>
        </div>
      </div>
    </div>

    <!-- 停止直播弹窗 -->
    <y-dialog title="Prompt" :visible.sync="stopLivingVisible" width="504px">
      <div class="_play-area__stop-dialog">
        you want to pause the live stream or just end it?
      </div>

      <div slot="footer" class="_play-area__stop-actions">
        <div class="_play-area__stop-dialog-cancel">
          <y-button type="text" size="small" @click="stopLivingVisible = false">
            Cancel
          </y-button>
        </div>
        <div class="_play-area__stop-dialog-sure">
          <y-button size="small" @click="pause">Pause</y-button>
          <y-button size="small" type="primary" @click="end">End</y-button>
        </div>
      </div>
    </y-dialog>
    <!-- /停止直播弹窗 -->
  </div>
</template>

<script>
import AudioIcon from '@components/dynamic-icon/audio-icon'

export default {
  components: {
    AudioIcon
  },
  props: {
    consoleInfo: Object,
    liveInfo: Object,
    settingInfo: Object
  },
  data() {
    return {
      loopTImer: null,
      networkStatus: 1, // 网络流状态：1-稳定，2-差，3-中断
      settingCopy: {},
      surplusTime: {},
      timer: null,
      stopLivingVisible: false
    }
  },
  computed: {
    // activityStatus: 直播间状态0:未开始,1:已删除,2:结束,3:预约,4:直播中,5:暂停
    // 直播中和已结束不能修改装修
    current() {
      const consoleInfoActivityStatus = this.consoleInfo?.activityStatus
      const liveInfoActivityStatus = this.liveInfo?.activityStatus
      const activityStatus = liveInfoActivityStatus ?? consoleInfoActivityStatus
      switch (activityStatus) {
        case 0:
          this.$emit('outLiving')
          return 0
        case 1:
          this.$emit('outLiving')
          return 0
        case 2:
          this.$emit('isLiving')
          return 2
        case 3:
          this.$emit('outLiving')
          return 0
        case 4:
          this.$emit('isLiving')
          return 4
        case 5:
          this.$emit('outLiving')
          return 5
        default:
          this.$emit('outLiving')
          return 0
      }
    }
  },
  created() {
    this.settingCopy = JSON.parse(JSON.stringify(this.settingInfo))
  },
  mounted() {
    this.getSurplusTime()
  },
  watch: {
    consoleInfo: {
      async handler(val) {
        if (this.liveInfo) {
          return
        }
        if (!val) {
          return
        }
        // activityStatus: 直播间状态0:未开始,1:已删除,2:结束,3:预约,4:直播中,5:暂停
        // pushModel: 推流类型: 1-直推，2-转推
        const { activityStatus, flvLiveUrl, pushModel } = val
        switch (activityStatus) {
          case 4:
            if (!flvLiveUrl || pushModel === 1) {
              return
            }
            this.getNetworkStatusLoop()
            break
          default:
            break
        }
      },
      immediate: true
    },
    liveInfo: {
      async handler(val) {
        if (!val) {
          return
        }
        // activityStatus: 直播间状态0:未开始,1:已删除,2:结束,3:预约,4:直播中,5:暂停
        const { activityStatus } = val
        // pushModel: 推流类型: 1-直推，2-转推
        const { flvLiveUrl, pushModel } = this.consoleInfo
        switch (activityStatus) {
          case 4:
            if (!flvLiveUrl || pushModel === 1) {
              return
            }
            this.getNetworkStatusLoop()
            break
          case 5:
            if (!flvLiveUrl || pushModel === 1) {
              return
            }
            if (this.loopTImer) {
              clearTimeout(this.loopTImer)
            }
            break
          default:
            if (this.loopTImer) {
              clearTimeout(this.loopTImer)
            }
            break
        }
      },
      immediate: true
    },
    settingInfo: {
      handler(val) {
        this.settingCopy = JSON.parse(JSON.stringify(val))
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async getNetworkStatus() {
      const res = await this.$serve.console.getNetworkStatus({
        queryId: this.consoleInfo.livestreamId
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.networkStatus = res.networkStatus
    },
    getNetworkStatusLoop() {
      this.getNetworkStatus()
      if (this.loopTImer) {
        clearTimeout(this.loopTImer)
      }
      this.loopTImer = setTimeout(() => {
        this.getNetworkStatusLoop()
      }, 1000 * 5)
    },
    getSurplusTime() {
      let surplusTime = {
        day: 0,
        hour: 0,
        min: 0,
        sec: 0
      }
      let second = 1000
      let minute = 60000
      let hour = minute * 60
      let day = hour * 24
      let nowTime = new Date().getTime() // 获取当前时间戳
      let ShiJianCha = this.consoleInfo.beginTime * 1000 - nowTime // 获取时间差
      if (ShiJianCha <= 0) {
        clearTimeout(this.timer)
        this.timer = null
        this.surplusTime = surplusTime
        return false
      }

      let dayC = ShiJianCha / day
      ShiJianCha = ShiJianCha % day

      let hourC = ShiJianCha / hour
      ShiJianCha = ShiJianCha % hour

      let minC = ShiJianCha / minute
      ShiJianCha = ShiJianCha % minute

      let secC = ShiJianCha / second

      if (dayC >= 1) {
        surplusTime.day = parseInt(dayC)
      }
      if (hourC >= 1) {
        surplusTime.hour = parseInt(hourC)
      }
      if (minC >= 1) {
        surplusTime.min = parseInt(minC)
      }
      if (secC >= 1) {
        surplusTime.sec = parseInt(secC)
      }
      this.surplusTime = surplusTime
      this.timer = setTimeout(() => {
        clearTimeout(this.timer)
        this.timer = null
        this.getSurplusTime()
      }, 1000)
    },
    pause() {
      this.endPublish(1)
    },
    end() {
      this.endPublish(2)
    },
    // 操作类型: 1-暂时离开，2-停止直播
    async endPublish(type) {
      const res = await this.$serve.livestream.endPublish({
        params: {
          activityId: this.consoleInfo.activityId,
          type
        }
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.stopLivingVisible = false
      this.$message.success('Action Success')
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer)
    this.timer = null
  }
}
</script>

<style lang="scss" scoped>
._play-area__action {
  background: transparent;
  border-color: #ff4d7c;
  color: #ff4d7c;
  height: 32px;
  width: 72px;
  &:focus,
  &:active {
    background: transparent;
    border-color: #ff4d7c;
    color: #ff4d7c;
  }
}
._play-area__stop-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
::v-deep {
  .el-dialog__body {
    padding: 16px 24px;
  }
  .el-dialog__footer {
    padding: 10px 24px 24px;
  }
}
.pc-designer-area {
  width: 740px;
  margin: 0 auto;
  ._play-area__status-detail {
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 24px 0 48px;
    border-radius: 8px;
    background: rgba(41, 43, 60, 0.4);
    font-size: 18px;
    color: #fff;
    margin-bottom: 16px;
    p {
      flex: 1;
    }
  }
  .play-area-stream {
    height: 414px;
    background: #000000;
    padding: 10px;
    .stream-box {
      width: 100%;
      height: 394px;
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      .thumbnail-url {
        width: 100%;
        height: 100%;
        position: absolute;
      }
      .is-default {
        filter: blur(50px);
      }
      .schedule-box {
        font-size: 28px;
        font-weight: 600;
        position: relative;
      }
      .left-and-bottom {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 10;
        width: 192px;
        height: 108px;
        .cover-box {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .icon-player {
          position: absolute;
          width: 38px;
          height: 38px;
          left: 77px;
          top: 37px;
        }
      }
      .right-and-top {
        position: absolute;
        right: 0;
        top: 0;
        left: 0;
        height: 80px;
        z-index: 10;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
        backdrop-filter: blur(0px);
        .msg {
          display: flex;
          align-items: baseline;
          justify-content: flex-end;
          margin-right: 25px;
          margin-top: 8px;
          .value {
            font-size: 20px;
            font-weight: 600;
            color: #ffffff;
          }
          .key {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.6);
            margin: 0 6px;
          }
        }
      }
    }
  }
  .play-area-card {
    width: 100%;
    height: 86px;
    background: rgba(41, 43, 60, 0.4);
    box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.06);
    border-radius: 0 0 8px 8px;
    padding: 0 20px 0 16px;
    display: flex;
    align-items: center;
    .name-and-count {
      .first-row {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        .name-box {
          max-width: 370px;
          height: 24px;
          font-size: 20px;
          font-weight: 500;
          color: #ffffff;
          line-height: 24px;
          margin-right: 16px;
        }
        .count-box {
          display: flex;
          align-items: center;
          .key {
            width: 18px;
            height: 18px;
            margin-right: 4px;
          }
          .value {
            font-size: 13px;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.6);
            margin-right: 8px;
          }
          .viewer {
            background: url(~@assets/images/designer/icon-viewer.png) no-repeat;
            background-size: 100%;
          }
          .comment {
            background: url(~@assets/images/designer/icon-commenter.png)
              no-repeat;
            background-size: 100%;
          }
        }
      }
      .description-box {
        max-width: 480px;
        word-break: break-all;
        height: 32px;
        font-size: 13px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.6);
        line-height: 16px;
      }
    }
    .share-box {
      display: flex;
      align-items: center;
      margin-left: auto;
      .share-item {
        .share-logo {
          width: 32px;
          height: 32px;
          margin: 0 auto 3px;
        }
        .share-text {
          height: 15px;
          font-size: 13px;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.6);
          line-height: 15px;
        }
        .twitter {
          background: url(~@assets/images/designer/icon-twitter.png) no-repeat;
          background-size: 100%;
        }
        .facebook {
          background: url(~@assets/images/designer/icon-facebook.png) no-repeat;
          background-size: 100%;
        }
        .copy-Link {
          background: url(~@assets/images/designer/icon-copy.png) no-repeat;
          background-size: 100%;
        }
      }
      .item-copy {
        margin-left: 14px;
      }
      .item-facebook {
        margin-left: 23px;
      }
    }
  }
  .document-box {
    width: 100%;
    height: 458px;
    background: rgba(41, 43, 60, 0.4);
    border-radius: 8px;
    margin-top: 16px;
    .document-title {
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
      line-height: 19px;
      padding-top: 16px;
      margin: 0 20px 12px 20px;
    }
    .document-content {
      padding: 0 20px;
      width: 100%;
      height: 391px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .videos-box {
    width: 100%;
    background: rgba(41, 43, 60, 0.4);
    border-radius: 8px;
    margin-top: 16px;
    .videos-title {
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
      line-height: 19px;
      padding-top: 16px;
      margin: 0 20px 12px 20px;
    }
    .videos-content {
      padding: 0 20px;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      .video-item {
        width: 222px;
        max-height: 151px;
        margin-right: 16px;
        margin-bottom: 12px;
        position: relative;
        .cover-box {
          width: 100%;
          height: 124px;
          object-fit: cover;
        }
        .icon-player {
          position: absolute;
          width: 38px;
          height: 38px;
          left: 94px;
          top: 45px;
        }
        .video-item-name {
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          line-height: 16px;
          margin-top: 8px;
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
