import { formPropsKeys } from '@pro/pro-form/src/config/form-props'
import { propsKeys as proFormPropsKeys } from '@pro/pro-form/src/config/props'
import { listPageFormPropsKeys } from './form-props'

const allFormPropsKeys = [
  ...formPropsKeys,
  ...proFormPropsKeys,
  ...listPageFormPropsKeys
]

export default allFormPropsKeys
