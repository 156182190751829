<template>
  <div class="_basic">
    <!-- _basic__info -->
    <div class="_basic__block _basic__info">
      <div class="_basic__block-inner c-no-scroll" v-scroll>
        <div class="_basic__head">
          <h4>Event</h4>
        </div>
        <div
          class="_basic__main"
          v-loading="loading"
          element-loading-background="rgba(0, 0, 0, 0.1)"
        >
          <!-- Share -->
          <!-- <div class="_basic__form-item _basic__form-item-share">
            <div class="_basic__form-item-label">Share</div>
            <div class="_basic__form-item-value">
              <copy-content :text="shareUrl"></copy-content>
              <span class="_basic__share-btn" @click="shareFacebook">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Share to Facebook"
                  placement="top"
                >
                  <i class="ui-icon-facebook"></i>
                </el-tooltip>
              </span>
              <span class="_basic__share-btn" @click="shareTwitter">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Share to Twitter"
                  placement="top"
                >
                  <i class="ui-icon-twitter"></i>
                </el-tooltip>
              </span>
            </div>
          </div> -->
          <!-- /Share -->

          <!-- event-title -->
          <div class="_basic__form-item _basic__form-item-event-title">
            <div class="_basic__form-item-label">Event Title</div>
            <div class="_basic__form-item-value">
              <y-input
                v-model="title"
                size="medium"
                :disabled="disabled"
                @change="save({ title })"
                :maxlength="100"
              ></y-input>
            </div>
          </div>
          <!-- /event-title -->

          <!-- Start Time -->
          <div class="_basic__form-item _basic__form-item-start-time">
            <div class="_basic__form-item-label">Start Time</div>
            <div class="_basic__form-item-value">
              <y-date-picker
                v-model="beginTime"
                size="medium"
                type="datetime"
                placeholder="please select"
                style="width: 100%"
                :disabled="disabled"
                :clearable="false"
                @change="save({ scheduleTime: +beginTime })"
                :pickerOptions="pickerOptions"
              ></y-date-picker>
            </div>
          </div>
          <!-- /Start Time -->

          <!-- Description -->
          <div class="_basic__form-item _basic__form-item-start-time">
            <div class="_basic__form-item-label">Description (optional)</div>
            <div class="_basic__form-item-value">
              <y-input
                v-model="description"
                type="textarea"
                placeholder="Enter an event description"
                :disabled="disabled"
                @change="save({ description })"
                :maxlength="300"
              ></y-input>
            </div>
          </div>
          <!-- /Description -->

          <div class="_basic__sub-title">Settings</div>

          <!-- Enable Live-Only -->
          <div
            class="_basic__form-item _basic__form-item-setting"
            v-loading="enableLiveOnlyLoading"
            element-loading-background="rgba(0, 0, 0, 0.1)"
          >
            <y-switch
              active-color="#FF4D7C"
              inactive-color="#292B3C"
              :value="enableLiveOnly"
              @change="enableLiveOnlyChange"
              style="margin-right: 8px"
            ></y-switch>
            Enable Live-Only
            <el-tooltip effect="dark" placement="right-end">
              <div slot="content">
                Enabling this option means viewers can only watch
                <br />
                the broadcast while it is live.
              </div>
              <i class="ui-icon-question"></i>
            </el-tooltip>
          </div>
          <!-- /Enable Live-Only -->

          <!-- Enable Password -->
          <div
            class="_basic__form-item _basic__form-item-setting"
            v-loading="enablePasswordLoading"
            element-loading-background="rgba(0, 0, 0, 0.1)"
          >
            <y-switch
              active-color="#FF4D7C"
              inactive-color="#292B3C"
              :value="enablePassword"
              @change="eenablePasswordChange"
              style="margin-right: 8px"
            ></y-switch>
            Enable Password Protection
          </div>
          <div
            v-if="enablePassword"
            class="_basic__form-item"
            style="padding: 0"
          >
            <y-input
              placeholder="Please enter 6-digit numeric code"
              size="small"
              v-model="password"
              show-password
              :minlength="6"
              :maxlength="6"
            >
              <el-button size="small" slot="append" @click="switchPasswordDone">
                Done
              </el-button>
            </y-input>
          </div>
          <!-- /Enable Password -->
        </div>
      </div>
    </div>
    <!-- /_basic__info -->

    <!-- _basic__playarea -->
    <div class="_basic__block _basic__playarea">
      <div class="_basic__block-inner c-no-scroll" v-scroll>
        <play-area :consoleInfo="consoleInfo" :liveInfo="liveInfo"></play-area>
      </div>
    </div>
    <!-- /_basic__playarea -->

    <templates-selector
      ref="templatesSelectorRef"
      @select="selectTemplate"
      :consoleInfo="consoleInfo"
    ></templates-selector>
  </div>
</template>

<script>
import queryString from 'query-string'
import TemplatesSelector from '@components/templates-selector'
// import CopyContent from '@components/copy-content'
import PlayArea from '../_play-area'

export default {
  components: {
    TemplatesSelector,
    // CopyContent,
    PlayArea
  },
  props: {
    consoleInfo: Object,
    liveInfo: Object
  },
  data() {
    return {
      activityId: this.$route.params.id,
      title: '',
      beginTime: '',
      description: '',
      disabled: false,
      enableLiveOnly: false,
      enablePassword: false,
      password: '',
      url: '',
      urls: [],
      pickerOptions: {
        disabledDate(time) {
          const current = new Date()
          const firstTime = new Date(
            current.setDate(current.getDate() - 1)
          ).getTime()
          const lastTime = new Date(
            current.setDate(current.getDate() + 7)
          ).getTime()
          return time.getTime() < firstTime || time.getTime() > lastTime
        }
      }
    }
  },
  computed: {
    loading() {
      return this.$store.state.loading[
        `/overseas/activity/getBasicConfig/${this.activityId}`
      ]
    },
    shareUrl() {
      if (!this.consoleInfo) {
        return 'loading'
      }

      return process.env.VUE_APP_H5 + '/live/' + this.consoleInfo.activityId
    },
    enableLiveOnlyLoading() {
      return this.$store.state.loading[`/overseas/activity/switchLiveOnly`]
    },
    enablePasswordLoading() {
      return this.$store.state.loading[`/overseas/activity/switchPassword`]
    },
    previewUrl() {
      return process.env.VUE_APP_H5 + '/live/' + this.activityId
    },
    iframeUrl() {
      const query = {
        platform: 'moblie',
        verify: false
      }
      return this.previewUrl + '?' + queryString.stringify(query)
    }
  },
  watch: {
    consoleInfo: {
      handler(val) {
        if (!val) {
          return
        }
        // 直播间状态0:未开始,1:已删除,2:结束,3:预约,4:直播中,5:暂停
        if ([0, 2, 4, 5].includes(val.activityStatus)) {
          this.disabled = true
        }
        this.title = val.title
        this.beginTime = val.beginTime * 1000
        this.description = val.description
      },
      immediate: true
    }
  },
  mounted() {
    this.fetchtActiveSetting()
    // this.fetchtActivityAllowEmbed()
  },
  methods: {
    // 获取基础配置信息
    async fetchtActiveSetting() {
      const res = await this.$serve.activity.setting({
        queryId: this.activityId
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.enableLiveOnly = !!res.enableLiveOnly
      this.enablePassword = !!res.enablePassword
      this.password = res.password
    },
    async fetchtActivityAllowEmbed() {
      const res = await this.$serve.activity.activityAllowEmbedGet({
        queryId: this.activityId
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.urls = res || []
    },
    // LiveOnly action
    async enableLiveOnlyChange(val) {
      const prEenableLiveOnly = this.enableLiveOnly
      this.enableLiveOnly = val
      const res = await this.$serve.activity.switchLiveOnly({
        data: {
          enableLiveOnly: val ? 1 : 0,
          activityId: this.activityId
        }
      })
      if (res === this.$serve.FAIL) {
        this.enableLiveOnly = prEenableLiveOnly
        return
      }
      this.$message.success('success')
    },
    // Password action
    async eenablePasswordChange(val) {
      const preEnablePassword = this.enablePassword
      this.enablePassword = val
      if (val) {
        return
      }
      const result = await this.switchPassword({
        enablePassword: val
      })
      if (!result) {
        this.enablePassword = preEnablePassword
      }
    },
    // 开启关闭密码保护
    async switchPassword({ enablePassword, password }) {
      const res = await this.$serve.activity.switchPassword({
        data: {
          enablePassword: enablePassword ? 1 : 0,
          password,
          activityId: this.activityId
        }
      })
      if (res === this.$serve.FAIL) {
        return false
      }
      this.$message.success('Success')
      return true
    },
    // 开启密码保护
    switchPasswordDone() {
      if (!/^\d{6}$/.test(this.password.trim())) {
        this.$message.warning('please enter-6-digit numeric code')
        return
      }
      this.switchPassword({
        enablePassword: true,
        password: this.password
      })
    },
    // 新增允许访问网址
    async activityAllowEmbedAdd() {
      if (!this.url.trim()) {
        this.$message.warning('url can not be empty')
        return
      }
      if (this.urls.length === 5) {
        this.$message.warning('Only allow to be embedded on 5 hosts at most.')
        return
      }
      const res = await this.$serve.activity.activityAllowEmbedAdd({
        data: {
          url: this.url,
          activityId: this.activityId
        }
      })
      if (res === this.$serve.FAIL) {
        return false
      }
      this.url = ''
      this.$message.success('success')
      this.fetchtActivityAllowEmbed()
    },
    // 删除允许访问网址
    async activityAllowEmbedDelete(item) {
      const res = await this.$serve.activity.activityAllowEmbedDelete({
        queryId: item.id
      })
      if (res === this.$serve.FAIL) {
        return false
      }
      this.$message.success('success')
      this.fetchtActivityAllowEmbed()
    },
    toTemplates() {
      this.$refs.templatesSelectorRef.show()
    },
    async toDecorate() {
      const b = this.consoleInfo.beginTime
      if (this.consoleInfo.isDecorated && this.consoleInfo.isDecorated == 1) {
        this.$router.push(
          `/yolo-cast-design/${this.activityId}?isLiveDecorated=true&b=${b}`
        )
      } else {
        this.$router.push(`/yolo-cast-design/${this.activityId}?b=${b}`)
      }
    },
    selectTemplate(item) {
      if (this.consoleInfo.isDecorated && this.consoleInfo.isDecorated == 1) {
        this.$confirm(
          'Once you choose a template, all the edits you made for the live event previously will not be saved. Are you sure to continue?',
          'Prompt',
          {
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        ).then(() => {
          this.applyTemplate(item)
        })
      } else {
        this.applyTemplate(item)
      }
    },
    async applyTemplate(data) {
      const res = await this.$serve.activity.applyDesigner({
        data: {
          type: 1,
          data: JSON.stringify(data),
          activityId: this.$route.params.id
        }
      })
      if (res === this.$serve.FAIL) {
        return false
      }
      this.$message({
        message: 'Apply Success！',
        type: 'success',
        duration: 1000
      })
      this.$refs.templatesSelectorRef.closeTemplate()
      this.$refs.templatesSelectorRef.hide()
    },
    async save(data) {
      if (!data.description) {
        data.description = ' '
      }
      const res = await this.$serve.activity.activityEdit({
        data: {
          // 推流类型：1:直推模式 2:多路推流模式
          pushModel: 1,
          activityId: this.activityId,
          title: this.consoleInfo.title,
          description: this.consoleInfo.description,
          scheduleTime: this.consoleInfo.beginTime * 1000,
          ...data
        },
        hideloading: true
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$store.dispatch('liveDetail/fetchConsoleInfo', this.activityId)
    },
    shareFacebook() {
      const params = [
        ['u', this.url],
        ['display', 'popup'],
        ['title', this.consoleInfo.title],
        ['quote', this.consoleInfo.description]
        // ['description', this.consoleInfo.description],
        // [
        //   'picture',
        //   'http://www.applezein.net/wordpress/wp-content/uploads/2015/03/facebook-logo.jpg'
        // ]
      ]
      const str = params.map((item) => item.join('=')).join('&')
      window.open(`https://www.facebook.com/sharer/sharer.php?${str}`)
    },
    shareTwitter() {
      const params = [
        ['url', this.url],
        ['text', this.consoleInfo.description]
      ]
      const str = params.map((item) => item.join('=')).join('&')
      window.open(`https://twitter.com/intent/tweet?${str}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/icon.scss';
._basic {
  display: flex;
  height: 100%;
  color: #fff;
}
._basic__head {
  margin-bottom: 20px;
}
._basic__block {
  position: relative;
}
._basic__block-inner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
._basic__info {
  width: 432px;
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  ._basic__block-inner {
    padding: 12px 20px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;
      background: transparent;
    }
    &::-webkit-scrollbar-track {
      border-radius: 0;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(102, 102, 102, 1);
      transition: all 0.2s;
      border-radius: 2px;
    }
  }
  h4 {
    margin: 0;
    font-size: 20px;
  }
}
._basic__main {
  color: rgba(255, 255, 255, 0.4);
  ._basic__form-item {
    padding: 12px 0;
    .ui-icon-question {
      vertical-align: -3px;
    }
  }
  ._basic__sub-title,
  ._basic__form-item-label {
    margin-bottom: 12px;
    color: #fff;
  }
  ._basic__form-item-setting {
    padding: 10px 0;
  }
  ::v-deep {
    .el-input__inner,
    .el-textarea__inner {
      background-color: #292b3c !important;
      border: none;
      caret-color: #ff0043;
      color: #fff !important;
      &::placeholder {
        color: rgba(255, 255, 255, 0.25) !important;
      }
      &::-webkit-scrollbar {
        width: 6px;
        background: transparent;
      }
      &::-webkit-scrollbar-track {
        border-radius: 0;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(102, 102, 102, 1);
        transition: all 0.2s;
        border-radius: 2px;
      }
    }

    .el-input__inner::placeholder {
      color: rgba(255, 255, 255, 0.25);
    }
    .el-input-group__append {
      border: none;
      background: #ff4d7c;
      overflow: hidden;
    }
    .el-input-group__append .el-button {
      padding-left: 12px;
      padding-right: 12px;
      font-size: 14px;
      background: #ff4d7c;
      color: #fff;
      border-radius: 0;
    }
    .el-checkbox {
      color: rgba(255, 255, 255, 0.4) !important;
    }
    .el-checkbox__inner:hover {
      border-color: #dcdfe6;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: rgba(255, 77, 124, 1) !important;
      border-color: rgba(255, 77, 124, 1) !important;
    }
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: rgba(255, 255, 255, 0.4) !important;
    }
    .el-checkbox__label {
      font-size: 16px;
    }
    .el-textarea__inner {
      padding: 8px 12px;
    }
  }
}
._basic__embedding-url {
  display: inline-block;
  background: rgba(255, 255, 255, 0.04);
  font-size: 12px;
  padding: 8px 12px;
  border-radius: 4px;
  position: relative;
  margin: 10px 10px 0 0;
  i {
    display: none;
    position: absolute;
    top: -8px;
    right: -8px;
    cursor: pointer;
  }
  &:hover {
    i {
      display: block;
    }
  }
}
._basic__form-item-share {
  ._basic__form-item-value {
    display: flex;
  }
  .com-copy-content {
    flex: 1;
  }
}
._basic__playarea {
  flex: 1;
  ._basic__block-inner {
    padding: 32px 48px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;
      background: transparent;
    }
    &::-webkit-scrollbar-track {
      border-radius: 0;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(102, 102, 102, 1);
      transition: all 0.2s;
      border-radius: 2px;
    }
  }
}
._basic__share-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: rgba(255, 255, 255, 0.06);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.5s;
  margin-left: 12px;
  &:hover {
    background-color: #ff4d7c;
  }
}
</style>
