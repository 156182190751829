const apis = [
  {
    // 保存自定义地址（PC）
    fn: 'saveCustom',
    method: 'POST',
    url: '/overseas/custom/saveCustom'
  }
]

export const baseUrl = process.env.VUE_APP_OVERSEAS_URL

export default apis
