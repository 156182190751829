<template>
  <div class="_control-area">
    <div class="_control-area__head">
      <h4>Live control</h4>
    </div>
    <div class="_control-area__main">
      <div class="_control-area__step">
        <ol class="_control-area__step-inner">
          <li
            v-for="(item, index) in ['Go Live', 'On Air', 'Live Ended']"
            :key="index"
            :class="[
              '_control-area__step-item',
              current === index + 1 ? '_control-area__step-current' : ''
            ]"
            :style="{ zIndex: 3 - index }"
          >
            <span>{{ index + 1 }}. {{ item }}</span>
          </li>
        </ol>
        <!-- <p class="_control-area__fold" @click="foldToggle">
          Back up
          <i
            class="ui-icon-arrow-down"
            :style="{ transform: 'rotate(' + (fold ? 0 : -180) + 'deg)' }"
          ></i>
        </p> -->
      </div>
      <!-- 暂时隐藏 -->
      <transition name="fade">
        <div class="_control-area__content" v-show="!fold">
          <template v-if="current === 1">
            <div class="_control-area__content-empty">
              The live event hasn’t started yet.
            </div>
          </template>
          <template v-else-if="current === 3">
            <div class="_control-area__content-empty">
              The live event has ended.
            </div>
          </template>
          <template v-else>
            <!-- 控制条流信息 -->
            <div class="_control-area__info">
              <div class="_control-area__info-data">
                <template v-if="networkStatus === 1">
                  <!-- <i class="ui-icon-living"></i> -->
                  <audio-icon style="margin-right: 24px"></audio-icon>
                  <p>The live event is in good progress.</p>
                </template>
                <template v-else-if="networkStatus === 2">
                  <i class="ui-icon-danger"></i>
                  <p>
                    Network is poor. Please check your network connection and
                    try again later.
                  </p>
                </template>
                <template v-else-if="networkStatus === 3">
                  <i class="ui-icon-warning"></i>
                  <p>
                    Your network connection is interrupted. Please try again.
                  </p>
                </template>
              </div>
            </div>
            <!-- /控制条流信息 -->

            <el-divider direction="vertical"></el-divider>
            <!-- 控制条流action -->
            <div class="_control-area__desc">
              <p class="_control-area__desc-on-living">
                <y-button
                  class="_control-area__action"
                  size="small"
                  @click="stopLivingVisible = true"
                >
                  Stop Living
                </y-button>
              </p>
            </div>
            <!-- /控制条流action -->
          </template>
        </div>
      </transition>
    </div>

    <y-dialog title="Prompt" :visible.sync="stopLivingVisible" width="504px">
      <div class="_control-area__stop-dialog">
        you want to pause the live stream or just end it?
      </div>

      <div slot="footer" class="_control-area__stop-actions">
        <div class="_control-area__stop-dialog-cancel">
          <y-button type="text" size="small" @click="stopLivingVisible = false">
            Cancel
          </y-button>
        </div>
        <div class="_control-area__stop-dialog-sure">
          <y-button size="small" @click="pause">Pause</y-button>
          <y-button size="small" type="primary" @click="end">End</y-button>
        </div>
      </div>
    </y-dialog>
  </div>
</template>

<script>
import AudioIcon from '@components/dynamic-icon/audio-icon'

function getRTMPData(pushUrl) {
  const index = pushUrl.lastIndexOf('/')
  return [pushUrl.slice(0, index), pushUrl.slice(index + 1)]
}

export default {
  components: {
    AudioIcon
  },
  props: {
    consoleInfo: Object,
    liveInfo: Object
  },
  data() {
    return {
      stopLivingVisible: false,
      fold: false,
      loopTImer: null,
      networkStatus: 1 // 网络流状态：1-稳定，2-差，3-中断
    }
  },
  computed: {
    // livestreamStatus: 直播流状态0:未开始,1:就绪,2:直播中,3:失败,4:连接中,5:结束
    // 3:失败,4:连接中 只在盒子当中使用
    current() {
      const consoleInfoLivestreamStatus = this.consoleInfo?.livestreamStatus
      const liveInfoLivestreamStatus = this.liveInfo?.livestreamStatus
      const livestreamStatus =
        liveInfoLivestreamStatus ?? consoleInfoLivestreamStatus
      switch (livestreamStatus) {
        case 0:
          return 1
        case 1:
          return 1
        case 2:
          return 2
        case 3:
          return 2
        case 4:
          return 2
        case 5:
          return 3
        default:
          return 1
      }
    },
    RTMPData() {
      return getRTMPData(this.consoleInfo?.pushUrl || '')
    }
  },
  watch: {
    consoleInfo(val) {
      // activityStatus: 直播间状态0:未开始,1:已删除,2:结束,3:预约,4:直播中,5:暂停
      const activityStatus = val?.activityStatus
      if (activityStatus === 4) {
        this.getNetworkStatusLoop()
        return
      }
    },
    liveInfo(val) {
      const activityStatus = val?.activityStatus
      if (activityStatus === 4) {
        this.getNetworkStatusLoop()
        return
      }
      if (this.loopTImer) {
        clearTimeout(this.loopTImer)
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.loopTImer)
  },
  methods: {
    foldToggle() {
      this.fold = !this.fold
    },
    async startLiving() {
      const res = await this.$serve.livestream.startPublish({
        data: {
          activityId: this.consoleInfo.activityId
        }
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$message.success('Stop Start Success')
    },
    pause() {
      this.endPublish(1)
    },
    end() {
      this.endPublish(2)
    },
    // 操作类型: 1-暂时离开，2-停止直播
    async endPublish(type) {
      const res = await this.$serve.livestream.endPublish({
        params: {
          activityId: this.consoleInfo.activityId,
          type
        }
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.stopLivingVisible = false
      this.$message.success('Action Success')
    },
    async getNetworkStatus() {
      const res = await this.$serve.console.getNetworkStatus({
        queryId: this.consoleInfo.livestreamId
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.networkStatus = res.networkStatus
    },
    getNetworkStatusLoop() {
      this.getNetworkStatus()
      if (this.loopTImer) {
        clearTimeout(this.loopTImer)
      }
      this.loopTImer = setTimeout(() => {
        this.getNetworkStatusLoop()
      }, 1000 * 5)
    }
  }
}
</script>

<style lang="scss" scoped>
._control-area__head {
  margin-bottom: 8px;
  h4 {
    margin: 0;
  }
}
._control-area {
  background: rgba(41, 43, 60, 0.4);
  border-radius: 8px;
  color: #fff;
  padding: 12px 24px;
}
._control-area__step {
  display: flex;
  background-color: #292b3c;
  color: #fff;
  font-size: 12px;
}
._control-area__step-inner {
  flex: 1;
  display: flex;
  text-align: center;
  align-items: center;
  overflow: hidden;
}

._control-area__step-item {
  flex: 1;
  position: relative;
  span {
    display: block;
    position: relative;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    height: 40px;
    line-height: 38px;
  }
  &:first-child {
    span {
      border-left-color: rgba(255, 255, 255, 0.15);
    }
  }
  &:last-child {
    span {
      border-right-color: rgba(255, 255, 255, 0.15);
    }
  }
  &._control-area__step-current {
    span {
      background: #ff4d7c;
      border-color: #ff4d7c;
    }
    &::before {
      background: #ff4d7c;
      border-right-color: #ff4d7c;
      border-top-color: #ff4d7c;
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: -11px;
    width: 50px;
    height: 50px;
    border-right: 1px solid rgba(255, 255, 255, 0.15);
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    transform: translateY(-50%) rotate(45deg);
    background: #292b3c;
  }
}
._control-area__fold {
  width: 112px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-left: none;
  height: 40px;
  line-height: 38px;
  text-align: center;
  cursor: pointer;
  i {
    transition: 0.5s all;
    vertical-align: -7px;
  }
}
._control-area__content {
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-top: none;
  display: flex;
  align-items: center;
  height: 97px;
  overflow: hidden;
}
._control-area__content-empty {
  flex: 1;
  padding: 38px 0;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  text-align: center;
}
._control-area__info {
  flex: 1;
  padding: 20px 5%;
}
._control-area__desc {
  width: 140px;
}
._control-area__info-data {
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  & + ._control-area__info-data {
    margin-top: 8px;
  }
  i {
    margin-right: 24px;
  }
}
._control-area__info-data-label {
  color: rgba(255, 255, 255, 0.6);
}
._control-area__info-data-value {
  flex: 1;
  background: rgba(255, 255, 255, 0.06);
  border-radius: 4px;
  padding: 0 8px;
  display: flex;
}
._control-area__info-data-content {
  flex: 1;
  position: relative;
}
._control-area__info-data-content-inner {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}
._control-area__info-data-copy {
  color: #ff4d7c;
  margin-left: 12px;
  cursor: pointer;
}
.el-divider {
  background-color: rgba(255, 255, 255, 0.15);
  height: 48px;
}
._control-area__desc {
  text-align: center;
}

._control-area__desc-streaming {
  font-size: 14px;
  width: 68px;
  line-height: 16px;
  margin: 0 auto;
}
._control-area__desc-start-living {
  ._control-area__action {
    background: #ff4d7c;
    border-color: #ff4d7c;
    color: #fff;
  }
}
._control-area__desc-on-living {
  ._control-area__action {
    background: #292b3c;
    border-color: #292b3c;
    color: #fff;
    &:hover {
      background: #ff4d7c;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter,
.fade-leave-to {
  height: 0px;
  opacity: 0;
}
._control-area__stop-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
::v-deep {
  .el-dialog__body {
    padding: 16px 24px;
  }
  .el-dialog__footer {
    padding: 10px 24px 24px;
  }
}
</style>
