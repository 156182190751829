<template>
  <el-container
    class="y-container"
    v-bind="$attrs"
    v-on="$listeners"
    :direction="computedDirection"
  >
    <slot></slot>
  </el-container>
</template>

<script>
import { Container } from 'element-ui'

export default {
  name: 'y-container',
  _name: 'Container 布局容器',
  $depends: [Container],
  components: {
    [Container.name]: Container
  },
  props: {
    direction: String
  },
  computed: {
    computedDirection() {
      if (['vertical', 'horizontal'].includes(this.direction)) {
        return this.direction
      }
      const isPresetSlots =
        this.$slots && this.$slots.default
          ? this.$slots.default.some((vnode) => {
              const tag = vnode.componentOptions && vnode.componentOptions.tag
              return tag === 'y-header' || tag === 'y-footer'
            })
          : false
      return isPresetSlots ? 'vertical' : 'horizontal'
    }
  }
}
</script>
