<template>
  <div class="menu-tab-edit">
    <div class="style-box">
      <div class="text">选择样式</div>
      <div class="position-box">
        <div class="position-left" @click="data.stylesheet.textAlign = 'start'">
          <div
            class="item choosed"
            v-if="data.stylesheet.textAlign === 'start'"
          >
            <div class="bg"></div>
            <div class="bg-text">居左</div>
          </div>
          <div class="item unchoosed" v-else>
            <div class="bg"></div>
            <div class="bg-text">居左</div>
          </div>
        </div>
        <div
          class="position-center"
          @click="data.stylesheet.textAlign = 'space-between'"
        >
          <div
            class="item choosed"
            v-if="data.stylesheet.textAlign === 'space-between'"
          >
            <div class="bg"></div>
            <div class="bg-text">居中</div>
          </div>
          <div class="item unchoosed" v-else>
            <div class="bg"></div>
            <div class="bg-text">居中</div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-box">
      <div class="text">菜单栏信息</div>
      <div class="tab-item" v-for="(item, index) in data.modules" :key="index">
        <el-input
          v-model="item.name"
          placeholder="请输入内容"
          maxlength="5"
        ></el-input>
        <div class="do-btn">
          <div class="to-top">
            <img
              src="~@assets/images/designer/up.png"
              alt=""
              v-if="index !== 0"
              @click.stop="toUp(item, index)"
            />
            <img
              src="~@assets/images/designer/operation_up@2x.png"
              alt=""
              v-else
            />
          </div>
          <div class="to-bottom">
            <img
              src="~@assets/images/designer/down.png"
              alt=""
              v-if="index !== data.modules.length - 1"
              @click.stop="toDown(item, index)"
            />
            <img
              src="~@assets/images/designer/operation_down@2x.png"
              alt=""
              v-else
            />
          </div>
          <div class="to-delete">
            <img
              src="~@assets/images/designer/delete@2x.png"
              alt=""
              @click.stop="toDelete(item, index)"
            />
          </div>
        </div>
      </div>
      <div class="add-tab" @click="addTab" v-if="data.modules.length <= 4">
        + 添加菜单栏
      </div>
    </div>
    <div class="fix-box">
      <div class="fix-select">
        <div class="key">悬浮固定</div>
        <div class="value">
          <el-switch v-model="data.float"> </el-switch>
        </div>
      </div>
      <div class="text">
        开启后，滑动页面超过展示区域顶部时，组件将始终悬浮在顶部，不跟随页面滑动消失
      </div>
    </div>
<!--    <div class="height-box">-->
<!--      <div class="text">组件高度</div>-->
<!--      <div class="seelct-source-radio">-->
<!--        <el-radio v-model="data.stylesheet.height" label="auto">自适应</el-radio>-->
<!--        <el-radio v-model="data.stylesheet.height" label="fixed">自定义</el-radio>-->
<!--      </div>-->
<!--    </div>-->
    <div class="bg-box">
      <div class="text">组件背景</div>
      <div class="seelct-source-radio">
        <el-radio v-model="data.stylesheet.background.type" label="default"
          >默认</el-radio
        >
        <el-radio v-model="data.stylesheet.background.type" label="image"
          >图片</el-radio
        >
      </div>
      <div
        class="add-bg-box"
        v-if="data.stylesheet.background.type === 'image'"
        @click="changeCover"
      >
        <div class="has-img" v-if="data.stylesheet.background.url">
          <img :src="data.stylesheet.background.url" alt="" />
          <div class="change-img">更换图片</div>
        </div>
        <div class="no-img" v-else>
          <div class="key">+</div>
          <div class="value">上传图片</div>
        </div>
      </div>
      <div class="tips" v-if="data.stylesheet.background.type === 'image'">
        <div class="title">提示：</div>
        <p>1.支持JGP、JPEG、PNG，大小不超过2MB；</p>
        <p>
          2.当上传的背景图片长度超过组件高度时将会自动裁剪图片；当上传的背景图片长度小于组件高度时，图片将会重复直到填充满背景。
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      Type: Object,
      default: {},
    },
  },
  data() {
    return {
      materialList: '', // 获取素材库分组列表
      imageGroupList: '', // 获取素材库图片
      visibleMediaCover: false, // 封面图处理
      visibleMediaShowCover: false, // 封面图处理
    }
  },
  watch: {
    'data.stylesheet.height': function (newV) {
      if (newV === 'fixed') {
        this.data.stylesheet.style.height = "340px";
        this.data.stylesheet.style.overflowY = "hidden";
      } else {
        this.data.stylesheet.style.height = "auto";
        this.data.stylesheet.style.overflowY = "auto";
      }
    },
  },
  methods: {
    swapArray (arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    toUp (el, index) {
      if (index <= 0) {
        return false;
      } else {
        // 上移
        this.swapArray(this.data.modules, index - 1, index);
      }
    },
    toDown (el, index) {
      if (index >= this.data.modules.length - 1) {
        return false;
      } else {
        // 下移
        this.swapArray(this.data.modules, index, index + 1);
      }
    },
    toDelete (el, index) {
      this.$confirm("确定要删除该菜单项？", "Prom", {
        confirmButtonText: "Done",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.data.modules.splice(index, 1);
      });
    },
    addTab () {
      this.data.modules.push({
        name: `菜单${this.data.modules.length + 1}`,
        modules: [],
        type: "menu-tab-content",
        className: "forbid",
      });
    },
    uploadSuccessCover(data, resultList) {
      if (data) {
        // 本地上传
        if (resultList[0].fromLocation) {
          this.data.stylesheet.background.url = resultList[0].url;
          this.data.stylesheet.style.backgroundImage = `url(${resultList[0].url})`;
        } else {
          // 媒体库选择
          this.data.stylesheet.background.url = resultList[0].material.url;
          this.data.stylesheet.style.backgroundImage = `url(${resultList[0].material.url})`;
        }
      }
      this.visibleMediaCover = false;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.visibleMediaShowCover = false;
      }, 200);
    },
    changeCover() {
      this.visibleMediaShowCover = true;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.visibleMediaCover = true;
      }, 200);
    },
  },
};
</script>
<style lang="scss">
@import './edit.scss';
</style>
