<template>
  <i></i>
</template>

<script>
export default {
  name: 'y-icon',
  _name: 'Icon 图标',
}
</script>
