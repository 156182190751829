<template>
  <div
    class="_viewers"
    v-loading="pageLoading"
    element-loading-background="rgba(0, 0, 0, 0.1)"
  >
    <!-- National Data -->
    <div class="_viewers__block">
      <div class="_viewers__block-head">National Data</div>
      <div class="_viewers__block-main" ref="nationalRef">
        <div class="_viewers__chart">
          <div class="_viewers__chart-country" ref="countryRef"></div>
          <div class="_viewers__chart-detail" ref="detailRef"></div>
          <y-button
            size="small"
            type="text"
            @click="downloadNationalImg"
            class="_viewer__download"
          >
            Download
            <i class="ui-icon-download" style="vertical-align: -2px"></i>
          </y-button>
        </div>
      </div>
    </div>
    <!-- /National Data -->

    <div class="_viewers__row" style="margin-top: 16px">
      <!-- Viewer's Source -->
      <div class="_viewers__col _viewers__block">
        <div class="_viewers__block-head">Viewer's Source</div>
        <div class="_viewers__block-main">
          <div class="_viewers__block-main-inner" ref="viewerSourceRef"></div>
          <y-button
            size="small"
            type="text"
            @click="downloadSourceImg"
            class="_viewer__download"
          >
            Download
            <i class="ui-icon-download" style="vertical-align: -2px"></i>
          </y-button>
        </div>
      </div>
      <!-- /Viewer's Source -->

      <div style="width: 20px"></div>

      <!-- Viewer's Portrait -->
      <div class="_viewers__col _viewers__block">
        <div class="_viewers__block-head">Viewer's Portrait</div>
        <div class="_viewers__block-main">
          <div class="_viewers__block-main-inner" ref="viewerPortraitRef"></div>
          <y-button
            size="small"
            type="text"
            @click="downloadPortraitImg"
            class="_viewer__download"
          >
            Download
            <i class="ui-icon-download" style="vertical-align: -2px"></i>
          </y-button>
        </div>
      </div>
      <!-- /Viewer's Portrait -->
    </div>

    <!-- Viewer List -->
    <div
      class="_viewers__block _viewers__list"
      style="margin-top: 16px"
      ref="listPageWrapRef"
    >
      <div class="_viewers__block-head">
        <h4>Viewer List</h4>
        <div class="_viewers__block-actions">
          <y-button size="small" type="text" @click="resetList">Reset</y-button>
          <y-divider
            style="background: rgba(255, 255, 255, 0.15); height: 12px"
            direction="vertical"
          ></y-divider>
          <y-button size="small" type="text" @click="downloadTablelImg">
            Export List
            <!-- <i class="ui-icon-download" style="vertical-align: -2px"></i> -->
          </y-button>
        </div>
      </div>
      <div class="_viewers__block-main">
        <y-list-page
          ref="listPageRef"
          :formProps="{
            size: 'small'
          }"
          inline
          background
          layout="prev, pager, next, sizes, jumper"
          :model="model"
          :fields="fields"
          :columns="columns"
          :fetch="fetchList"
          :submitter="{
            submitText: 'Search',
            resetButtonProps: {
              style: {
                display: 'none'
              }
            }
          }"
          empty-text="No events matched"
          @filter-change="filterChange"
        ></y-list-page>
      </div>
    </div>
    <!-- /Viewer List -->
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import * as echarts from 'echarts'
import { saveFile, secondToDate } from '@/utils/tools'

export default {
  props: {
    consoleInfo: Object
  },
  data() {
    return {
      activityId: this.$route.params.id,
      countryOption: {
        color: '#FF4D7C',
        tooltip: {
          showContent: false,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              color: 'rgba(255, 255, 255, 0.15)'
            }
          },
          axisLine: {
            show: true
          },
          minInterval: 1,
          max: 1200,
          min: 0
        },
        yAxis: {
          type: 'category',
          data: [],
          splitLine: {
            show: false,
            lineStyle: {
              color: 'rgba(255, 255, 255, 0.15)'
            }
          }
        },
        series: [
          {
            type: 'bar',
            data: [],
            barMaxWidth: '100',
            label: {
              show: true,
              position: 'right',
              color: 'rgba(255, 255, 255, 0.6)'
            }
          }
        ]
      },
      detailOption: {
        title: {
          text: 'Region',
          subtext: 'total: 0',
          top: 100,
          x: 'center',
          textStyle: {
            color: '#fff',
            fontSize: 13
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical', //垂直显示
          y: 'center', // 延Y轴居中
          right: 20,
          align: 'left',
          icon: 'circle',
          itemHeight: 8, // 修改icon图形大小
          textStyle: {
            color: '#FFFFFF'
          }
        },
        // color: ['rgba(255, 221, 97, 1)', 'rgba(93, 211, 255, 1)'],
        series: [
          {
            type: 'pie',
            radius: '40%',
            selectedMode: 'single',
            data: [],
            label: {
              normal: {
                show: false,
                position: 'inside',
                formatter: '{b}:{d}%'
              }
            }
          }
        ]
      },
      currentRegion: '',
      pageLoading: false,
      viewerSourceOption: {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical', //垂直显示
          y: 'center', // 延Y轴居中
          right: 80,
          align: 'left',
          icon: 'circle',
          itemHeight: 8, // 修改icon图形大小
          textStyle: {
            color: '#FFFFFF'
          }
        },
        series: [
          {
            type: 'pie',
            radius: '50%',
            selectedMode: 'single',
            data: [],
            label: {
              normal: {
                show: false,
                position: 'inside',
                formatter: '{b}:{d}%'
              }
            }
          }
        ]
      },
      viewerPortraitOption: {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical', //垂直显示
          y: 'center', // 延Y轴居中
          right: 80,
          align: 'left',
          icon: 'circle',
          itemHeight: 8, // 修改icon图形大小
          textStyle: {
            color: '#FFFFFF'
          }
        },
        color: ['rgba(255, 221, 97, 1)', 'rgba(93, 211, 255, 1)'],
        series: [
          {
            type: 'pie',
            radius: '50%',
            selectedMode: 'single',
            data: [
              {
                name: 'New viewers',
                value: 0
              },
              {
                name: 'Returning viewers ',
                value: 0
              }
            ],
            label: {
              normal: {
                show: false,
                position: 'inside',
                formatter: '{b}:{d}%'
              }
            }
          }
        ]
      },
      viewerSourceInstance: null,
      viewerPortraitInstance: null,
      model: {
        audienceName: ''
      },
      fields: [
        {
          type: 'input',
          formItemProps: {
            label: 'Nickname:',
            prop: 'audienceName',
            style: {
              width: '340px'
            }
          },
          attributes: {
            style: 'width: 240px',
            placeholder: 'Please enter nickname'
          }
        }
      ],
      columns: [
        {
          label: 'User Name',
          prop: 'audienceName',
          render(h, v) {
            return v || '-'
          }
        },
        {
          label: 'Source',
          prop: 'channelName',
          columnKey: 'channelIds',
          filters: [],
          render(h, v) {
            return v || '-'
          }
        },
        {
          label: 'Total View Times',
          prop: 'viewCount',
          // sortable: true,
          render(h, v) {
            return v ?? '-'
          }
        },
        {
          label: 'Total View Duration',
          prop: 'viewTime',
          render(h, v) {
            if (!v) {
              return '-'
            }
            return secondToDate(v)
          }
          // sortable: true
        },
        {
          label: 'Comments',
          prop: 'chatCount',
          render(h, v) {
            return v ?? '-'
          }
        }
        // {
        //   label: 'Shares',
        //   prop: 'Shares',
        //   render(h, v) {
        //     return v ?? '-'
        //   }
        // }
      ],
      select: [],
      channelIds: [],
      beginTimeOrder: '',
      audienceCountOrder: ''
    }
  },
  watch: {
    currentRegion(val) {
      this.fetchRegionAudience(val)
    }
  },
  mounted() {
    this.renderCountryChart()
    this.renderDetailChart()
    this.renderViewerSourceChart()
    this.renderViewerPortraitChart()
    // activityStatus: 直播间状态0:未开始,1:已删除,2:结束,3:预约,4:直播中,5:暂停
    if (!this.consoleInfo || this.consoleInfo.activityStatus === 2) {
      this.fetchRegion()
      this.fetchSourceProportion()
      this.fetchOldAudienceProportion()
      this.fetchChanelList()
    }
  },
  beforeDestroy() {
    this.detailEchartInstance.dispose()
    this.detailEchartInstance.dispose()
  },
  methods: {
    renderCountryChart() {
      this.countryEchartInstance = echarts.init(this.$refs.countryRef)
      this.countryEchartInstance.setOption(this.countryOption)
      this.countryEchartInstance.on('click', (params) => {
        this.currentRegion = params.name
      })
    },
    renderDetailChart() {
      this.detailEchartInstance = echarts.init(this.$refs.detailRef)
      this.detailEchartInstance.setOption(this.detailOption)
    },
    renderViewerSourceChart() {
      this.viewerSourceInstance = echarts.init(this.$refs.viewerSourceRef)
      this.viewerSourceInstance.setOption(this.viewerSourceOption)
    },
    renderViewerPortraitChart() {
      this.viewerPortraitInstance = echarts.init(this.$refs.viewerPortraitRef)
      this.viewerPortraitInstance.setOption(this.viewerPortraitOption)
    },
    async fetchRegion() {
      this.pageLoading = true
      const res = await this.$serve.statistics.region({
        queryId: this.activityId
      })
      this.pageLoading = false
      if (res === this.$serve.FAIL) {
        return
      }
      if (res.length === 0) {
        this.countryEchartInstance.setOption({
          yAxis: {
            data: []
          }
        })
        return
      }
      const regionData = [...res].reverse()
      this.countryEchartInstance.setOption({
        xAxis: {
          max: undefined,
          min: 0
        },
        yAxis: {
          data: regionData.map((item) => item.name)
        },
        series: [
          {
            data: regionData.map((item) => item.count)
          }
        ]
      })
      this.currentRegion = res[0].name
    },
    async fetchRegionAudience(currentRegion) {
      this.pageLoading = true
      const res = await this.$serve.statistics.regionAudience({
        processOptions: ({ url, ...option }) => {
          return {
            ...option,
            url: `${url}/${this.activityId}/${currentRegion}`
          }
        }
      })
      this.pageLoading = false
      if (res === this.$serve.FAIL) {
        return
      }
      const total = res.reduce((sum, item) => sum + item.count, 0)
      this.detailEchartInstance.setOption({
        title: {
          text: currentRegion,
          subtext: `total: ${total}`
        },
        series: [
          {
            data: res.map((item) => ({
              name: item.name,
              value: item.count
            }))
          }
        ]
      })
    },
    async fetchSourceProportion() {
      this.pageLoading = true
      const res = await this.$serve.statistics.sourceProportion({
        params: {
          activityId: this.activityId
        }
      })
      this.pageLoading = false
      if (res === this.$serve.FAIL) {
        return
      }
      this.viewerSourceInstance.setOption({
        series: [
          {
            data: res.map((item) => ({
              value: item.channelCount,
              name: item.channelName
            }))
          }
        ]
      })
    },
    async fetchOldAudienceProportion() {
      this.pageLoading = true
      const res = await this.$serve.statistics.oldAudienceProportion({
        params: {
          activityId: this.activityId
        }
      })
      this.pageLoading = false
      if (res === this.$serve.FAIL) {
        return
      }
      this.viewerPortraitInstance.setOption({
        series: [
          {
            data: [
              {
                value: res.newAudienceCount,
                name: 'New viewers'
              },
              {
                value: res.oldAudienceCount,
                name: 'Returning viewers '
              }
            ]
          }
        ]
      })
    },
    downloadNationalImg() {
      html2canvas(this.$refs.nationalRef, {
        useCORS: true, // 开启跨域设置，需要后台设置cors
        backgroundColor: 'rgba(41, 43, 60, 0.4)'
      }).then((canvas) => {
        // toDataURL函数生成img标签的可用数据  图片格式转成 base64
        let dataURL = canvas.toDataURL('image/png')
        saveFile(dataURL, 'national-data.png')
      })
    },
    downloadSourceImg() {
      saveFile(
        this.viewerSourceInstance.getDataURL({
          type: 'png',
          pixelRatio: 1.5,
          backgroundColor: 'rgba(41, 43, 60, 0.4)'
        }),
        'source-data.png'
      )
    },
    downloadPortraitImg() {
      saveFile(
        this.viewerPortraitInstance.getDataURL({
          type: 'png',
          pixelRatio: 1.5,
          backgroundColor: 'rgba(41, 43, 60, 0.4)'
        }),
        'portrait-data.png'
      )
    },
    downloadTablelImg() {
      html2canvas(this.$refs.listPageWrapRef, {
        useCORS: true, // 开启跨域设置，需要后台设置cors
        backgroundColor: 'rgba(41, 43, 60, 0.4)'
      }).then((canvas) => {
        // toDataURL函数生成img标签的可用数据  图片格式转成 base64
        let dataURL = canvas.toDataURL('image/png')
        saveFile(dataURL, 'table-data.png')
      })
    },
    async fetchChanelList() {
      const res = await this.$serve.console.shareChannelList({
        params: {
          activityId: this.activityId
        }
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.columns[1].filters = res.map((item) => ({
        text: item.channelName,
        value: item.channelId
      }))
    },
    async fetchList(params) {
      // activityStatus: 直播间状态0:未开始,1:已删除,2:结束,3:预约,4:直播中,5:暂停
      if (this.consoleInfo && this.consoleInfo.activityStatus !== 2) {
        return Promise.resolve({
          list: [],
          totalCount: 0
        })
      }
      const res = await this.$serve.statistics.audiencePage({
        data: {
          ...params,
          activityId: this.activityId,
          channelIds: this.channelIds
        }
      })
      if (res === this.$serve.FAIL) {
        return Promise.reject({})
      }
      const { records, totalCount } = res
      return {
        list: records,
        totalCount
      }
    },
    filterChange(filters) {
      this.channelIds = filters.channelIds
      this.$refs.listPageRef.fetchData({
        page: 1
      })
    },
    resetList() {
      this.channelIds = []
      this.model.audienceName = ''
      const originalTableRef =
        this.$refs.listPageRef.$refs.tableRef.$refs.originalTableRef
      originalTableRef.clearFilter('channelIds')
      this.$refs.listPageRef.fetchData({
        page: 1
      })
    }
  }
}
</script>

<style lang="scss" scoped>
._viewers {
  padding: 24px 20px;
  color: #fff;
}
._viewers__block-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 0;
  height: 38px;
  position: relative;
  font-size: 18px;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -10px;
    width: 4px;
    height: 20px;
    background: #ff4d7c;
    border-radius: 2px;
  }
  h4 {
    margin: 0;
  }
}
._viewers__block-main {
  margin-top: 16px;
}
._viewers__chart {
  position: relative;
  height: 555px;
  background: rgba(41, 43, 60, 0.4);
  overflow: hidden;
}
._viewers__chart-country {
  width: 60%;
  height: 555px;
  float: left;
}
._viewers__chart-detail {
  width: 40%;
  height: 555px;
  float: left;
}
._viewer__download {
  position: absolute;
  top: 10px;
  right: 16px;
}
._viewers__row {
  display: flex;
  ._viewers__block-main {
    background: rgba(41, 43, 60, 0.4);
    border-radius: 2px;
    position: relative;
  }
  ._viewers__block-main-inner {
    height: 344px;
  }
}
._viewers__col {
  flex: 1;
}
._viewers__list {
  ::v-deep {
    .el-button--primary {
      background: #ff4d7c;
    }
    .el-loading-mask {
      background: rgba(0, 0, 0, 0.1);
    }
    .el-form-item__label {
      color: #fff;
    }
    .el-input__inner {
      background: transparent;
      border: 1px solid rgba(255, 255, 255, 0.15);
      color: #fff;
      &::placeholder {
        color: rgba(255, 255, 255, 0.25);
      }
    }
    .el-table,
    .el-table tr {
      background: transparent;
    }
    .el-table {
      color: #fff;
    }
    .el-table td.el-table__cell {
      border-bottom-color: rgba(255, 255, 255, 0.06);
    }
    .el-table::before,
    .el-table--group::after,
    .el-table--border::after {
      background-color: rgba(255, 255, 255, 0.06);
    }
    .el-table th.el-table__cell {
      background: rgba(41, 43, 60, 0.4) !important;
      border-bottom: none;
      padding: 3px 0;
    }
    .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
      background: rgba(41, 43, 60, 0.2) !important;
    }
    .el-pagination {
      text-align: right;
    }
    .el-pagination .el-pager .more,
    .el-pagination .el-pager .number {
      background: transparent !important;
      color: rgba(255, 255, 255, 0.6);
      border: 1px solid rgba(255, 255, 255, 0.15);
    }
    .el-pagination .el-pager .number.active {
      color: #ff4d7c !important;
      border-color: #ff4d7c !important;
    }
    .el-pagination.is-background .btn-prev,
    .el-pagination.is-background .btn-next {
      background: transparent !important;
      border: 1px solid rgba(255, 255, 255, 0.15) !important;
    }
    .el-pagination .btn-prev .el-icon,
    .el-pagination .btn-next .el-icon {
      color: rgba(255, 255, 255, 0.6);
    }
    .el-pagination .el-select .el-input .el-input__inner {
      color: rgba(255, 255, 255, 0.6);
    }
    .el-pagination__jump {
      color: rgba(255, 255, 255, 0.6);
    }
    .el-pagination__editor.el-input .el-input__inner {
      color: rgba(255, 255, 255, 0.6);
    }
  }
}
.el-button--text {
  ::v-deep {
    color: #ff4d7c;
  }
}
</style>
