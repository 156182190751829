// import YRadioGroup from './src/main'

// YRadioGroup.install = function(Vue) {
//   Vue.component(YRadioGroup.name, YRadioGroup)
// }

// export default YRadioGroup

import { RadioGroup } from 'element-ui'

const YRadioGroup = RadioGroup
YRadioGroup.$depends = [RadioGroup]
YRadioGroup.$name = 'y-radio-group'
YRadioGroup._name = 'RadioGroup 单选框组'

export default YRadioGroup
