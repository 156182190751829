const apis = [
  {
    // 获取fb个人小组及公共主页信息（安卓）
    fn: 'getGroups',
    method: 'GET',
    url: '/overseas/facebook/get-groups'
  },
  {
    // 更新Facebook推流位置（安卓）
    fn: 'updatePage',
    method: 'POST',
    headers: {
      ['Content-Type']: 'application/x-www-form-urlencoded'
    },
    url: '/overseas/facebook/update-page'
  }
]

export const baseUrl = process.env.VUE_APP_OVERSEAS_URL

export default apis
