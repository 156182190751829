var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.pageLoading),expression:"pageLoading"}],staticClass:"_stream-health",attrs:{"element-loading-background":"rgba(0, 0, 0, 0.1)"}},[_c('div',{staticClass:"_stream-health__head"},[_c('y-button',{attrs:{"size":"small","plain":""},on:{"click":_vm.refresh}},[_vm._v("Refresh")]),_c('y-button',{attrs:{"size":"small","plain":""},on:{"click":_vm.exportReport}},[_vm._v(" Export Report ")])],1),_c('div',{ref:"healthRef",staticClass:"_stream-health__main"},[_c('div',{staticClass:"_stream-health__title"},[_vm._v("Viewer Engagement")]),_c('div',{staticClass:"_stream-health__avg-data"},[_c('div',{staticClass:"_stream-health__avg-item"},[_c('h4',[_vm._v("Avg Bitrate")]),_c('em',[_vm._v(_vm._s(_vm.avgBps))])]),_c('div',{staticClass:"_stream-health__avg-item"},[_c('h4',[_vm._v("Avg Frame Rate")]),_c('em',[_vm._v(_vm._s(_vm.avgFps))])])]),_c('div',{staticClass:"_stream-health__block"},[_c('div',{staticClass:"_stream-health__alert",class:{ '_stream-health__alert-empty': _vm.abnormalList.length === 0 }},[_c('div',{staticClass:"_stream-health__alert-title"},[_vm._v("Alert History")]),_vm._m(0),_c('div',{staticClass:"_stream-health__alert-content"},[_c('ol',[(_vm.abnormalList.length === 0)?_c('li',{staticClass:"_stream-health__alert-empty-item"},[_vm._v(" No data yet ")]):_vm._e(),_vm._l((_vm.abnormalList),function(item,index){return _c('li',{key:index,class:[
                '_stream-health__alert-item',
                item.streamStatus === 2
                  ? '_stream-health__alert-item--poor'
                  : item.streamStatus === 3
                  ? '_stream-health__alert-item--interruption'
                  : ''
              ]},[_c('span',{staticClass:"_stream-health__alert-time"},[_vm._v(" "+_vm._s(_vm._f("formatTime")(item.currentTimeMills))+" ")]),_c('span',{staticClass:"_stream-health__alert-warning"},[_c('b',{staticClass:"_health-area__info-logo-warn"}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.streamStatus === 2),expression:"item.streamStatus === 2"}]},[_vm._v(" Poor network status ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.streamStatus === 3),expression:"item.streamStatus === 3"}]},[_vm._v(" Network interruption ")])])])})],2)])]),_c('div',{ref:"metricsRef",staticClass:"_stream-health__metrics"})])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"_stream-health__alert-head"},[_c('ul',[_c('li',[_vm._v("Time")]),_c('li',[_vm._v("Waring")])])])}]

export { render, staticRenderFns }