<template>
  <div class="_side-bar">
    <y-menu
      :default-active="defaultActive"
      text-color="rgba(255, 255, 255, 0.4)"
      active-text-color="#fff"
      @select="select"
    >
      <y-menu-item v-for="tab in tabs" :key="tab.value" :index="tab.value">
        <div class="_side-bar__menu-item">
          <i :class="active === tab.value ? tab.activeIcon : tab.icon"></i>
          <span slot="title">{{ tab.label }}</span>
        </div>
      </y-menu-item>
    </y-menu>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'active',
    event: 'change'
  },
  props: {
    tabs: Array,
    active: {
      type: String,
      default: 'overview'
    },
    defaultActive: String
  },
  methods: {
    select(tabValue) {
      this.$emit('change', tabValue)
    }
  }
}
</script>

<style lang="scss" scoped>
._side-bar {
  padding: 16px 0;
  ::v-deep .el-menu {
    border-right: none;
  }
  ::v-deep .el-menu-item {
    height: 52px;
    padding: 0 12px !important;
    background: #1e1f2b !important;
    display: flex;
    align-items: center;
  }
}
._side-bar__menu-item {
  height: 44px;
  line-height: 44px;
  flex: 1;
  padding: 0 16px;
  border-radius: 4px;
  overflow: hidden;
  transition: background-color 0.5s;
  &:hover {
    background-color: #292b3c;
  }
  i {
    margin-right: 8px;
  }
}
.is-active {
  ._side-bar__menu-item {
    background-color: #292b3c;
  }
}
</style>
