import RouterHolder from '@components/router-holder'

const route = {
  path: 'settings',
  name: 'Settings',
  meta: {
    name: 'Settings',
    icon: 'ui-icon-setting',
    activeIcon: 'ui-icon-setting-hover',
    hideSubMenu: true
  },
  component: RouterHolder,
  redirect: '/settings/account',
  children: [
    {
      path: 'account',
      name: 'Settings-Account',
      meta: {
        name: 'Account',
        showUploadBox: true,
      },
      component: () =>
        import(/* webpackChunkName: "account" */ '@/views/settings/account')
    },
    {
      path: 'platforms',
      name: 'Settings-Platforms',
      meta: {
        name: 'Platforms',
        showUploadBox: true,
      },
      component: () =>
        import(/* webpackChunkName: "platforms" */ '@/views/settings/platforms')
    },
    // {
    //   path: 'billing',
    //   name: 'Settings-Billing',
    //   meta: {
    //     name: 'Billing'
    //   },
    //   component: () =>
    //     import(/* webpackChunkName: "billing" */ '@/views/settings/billing')
    // }
  ]
}

export default route
