import { RouteConfig } from 'vue-router'
import main from './main'
import YoloCastDetail from '@/views/yolo-cast/detail/index.vue'

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    meta: {
      name: 'YOLOLIV'
    },
    redirect: main[0].path,
    component: () =>
      import(/* webpackChunkName: "page" */ '@/views/home/index.vue'),
    children: main
  },
  {
    path: '/yolo-cast-detail/:id',
    name: 'yolo-cast-detail',
    component: YoloCastDetail
  },
  {
    path: '/yolo-cast-design/:id?',
    name: 'yolo-cast-design',
    component: () =>
      import(
        /* webpackChunkName: "yolo-cast-design" */ '@/views/yolo-cast/designer/designer.vue'
      )
  },
  {
    path: '/user',
    name: 'User',
    redirect: '/user/login',
    component: () =>
      import(/* webpackChunkName: "user" */ '@/views/user/index.vue'),
    children: [
      {
        path: 'login',
        name: 'User-Login',
        meta: {
          name: 'Log In'
        },
        component: () =>
          import(/* webpackChunkName: "login" */ '@/views/user/login/index.vue')
      },
      {
        path: 'register',
        name: 'User-Register',
        meta: {
          name: 'Sign Up'
        },
        component: () =>
          import(
            /* webpackChunkName: "register" */ '@/views/user/register/index.vue'
          )
      }
    ]
  },
  {
    path: '/room/:id',
    name: 'Room',
    component: () => import(/* webpackChunkName: "room" */ '@/views/room/index')
  },
  {
    path: '/blank',
    name: 'Blank',
    component: () =>
      import(/* webpackChunkName: "blank" */ '@/views/blank/index.vue')
  },
  {
    path: '/demo',
    name: 'Demo',
    component: () =>
      import(/* webpackChunkName: "demo" */ '@/views/demo/index.vue')
  },
  {
    path: '/*',
    redirect: '/'
  }
]

export default routes
