import { render, staticRenderFns } from "./border-form-item.vue?vue&type=template&id=af35aad2&scoped=true&"
import script from "./border-form-item.vue?vue&type=script&lang=js&"
export * from "./border-form-item.vue?vue&type=script&lang=js&"
import style0 from "./border-form-item.vue?vue&type=style&index=0&id=af35aad2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af35aad2",
  null
  
)

export default component.exports