<template>
  <el-input v-bind="$attrs" v-on="$listeners">
    <slot slot="prefix" name="prefix"></slot>
    <slot slot="suffix" name="suffix"></slot>
    <slot slot="prepend" name="prepend"></slot>
    <slot slot="append" name="append"></slot>
  </el-input>
</template>

<script>
import { Input } from 'element-ui'

export default {
  name: 'y-input',
  _name: 'Input 输入框',
  $depends: [Input],
  components: {
    [Input.name]: Input
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-input__inner {
    caret-color: #ff0043;
  }
  .el-textarea__inner {
    caret-color: #ff0043;
  }
}
</style>
