// 表单元素默认配置信息
const defaultFormItemConfig = {
  'key-input': {
    fn: 'renderInput',
    defaultAttr: {
      placeholder: '请输入'
    }
  },
  'key-number': {
    fn: 'renderNumber',
    defaultAttr: {
      placeholder: '请输入'
    }
  },
  'key-password': {
    fn: 'renderPassword',
    defaultAttr: {
      placeholder: '请输入'
    }
  },
  'key-input-number': {
    fn: 'renderInputNumber',
    defaultAttr: {
      placeholder: '请输入'
    }
  },
  'key-select': {
    fn: 'renderSelect',
    defaultAttr: {
      placeholder: '请选择'
    }
  },
  'key-switch': {
    fn: 'renderSwitch'
  },
  'key-checkbox': {
    fn: 'renderCheckbox'
  },
  'key-checkbox-group': {
    fn: 'renderCheckboxGroup'
  },
  'key-radio': {
    fn: 'renderRadio'
  },
  'key-radio-group': {
    fn: 'renderRadioGroup'
  },
  'key-textarea': {
    fn: 'renderTextarea',
    defaultAttr: {
      placeholder: '请输入'
    }
  },
  'key-date-picker': {
    fn: 'renderDatetPicker',
    defaultAttr: {
      placeholder: '选择日期'
    }
  },
  'key-date': {
    fn: 'renderDate',
    defaultAttr: {
      placeholder: '选择日期'
    }
  },
  'key-dates': {
    fn: 'renderDates',
    defaultAttr: {
      placeholder: '选择日期'
    }
  },
  'key-date-year': {
    fn: 'renderDateyear',
    defaultAttr: {
      placeholder: '选择年份'
    }
  },
  'key-date-month': {
    fn: 'renderDatemonth',
    defaultAttr: {
      placeholder: '选择月份'
    }
  },
  'key-date-week': {
    fn: 'renderDateweek',
    defaultAttr: {
      placeholder: '选择周期'
    }
  },
  'key-time': {
    fn: 'renderTime',
    defaultAttr: {
      placeholder: '选择时间'
    }
  },
  'key-date-time': {
    fn: 'renderDatetime',
    defaultAttr: {
      placeholder: '选择日期时间'
    }
  },
  'key-date-time-range': {
    fn: 'renderDatetimerange',
    defaultAttr: {
      rangeSeparator: '至',
      startPlaceholder: '开始日期',
      endPlaceholder: '结束日期'
    }
  },
  'key-date-month-range': {
    fn: 'renderDateMonthrange',
    defaultAttr: {
      rangeSeparator: '至',
      startPlaceholder: '开始月份',
      endPlaceholder: '结束月份'
    }
  },
  'key-rate': {
    fn: 'renderRate'
  },
  'key-color-picker': {
    fn: 'renderColorPicker'
  },
  'key-transfer': {
    fn: 'renderTransfer'
  },
  'key-slider': {
    fn: 'renderSlider'
  },
  'key-cascader': {
    fn: 'renderCascader'
  }
}

export default defaultFormItemConfig
