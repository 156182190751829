<template>
  <div class="introduction" :style="{background: data.stylesheet.style.background, backgroundImage: data.stylesheet.background.type === 'image' ? data.stylesheet.style.backgroundImage : ''}">
    <div class="first-row">{{ data.title }}</div>
    <div class="second-row">
      <div class="gkrs" v-show="$route.query.b">{{ moment($route.query.b * 1000).format('MMM D, YYYY hh:mm A') }}</div>
      <div class="detail" v-if="data.showDetail">{{ data.detailContent }}</div>
      <div class="share" v-if="data.share">
        <div class="twitter">
          <div class="key">
            <img src="~@assets/images/designer/twitter@2x.png" alt="">
          </div>
          <div class="value">Twitter</div>
        </div>
        <div class="facebook">
          <div class="key">
            <img src="~@assets/images/designer/facebook@2x.png" alt="">
          </div>
          <div class="value">Facebook</div>
        </div>
        <div class="copylink">
          <div class="key">
            <img src="~@assets/images/designer/copylink@2x.png" alt="">
          </div>
          <div class="value">Copy Link</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    data: {
      Type: Object,
      default: () => {
        return {};
      },
    },
    stylesheet: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      moment,
    }
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.introduction {
  padding: 0 12px;
  background-size: 100%;
  min-height: 60px;
  .first-row {
    margin-bottom: 4px;
    font-size: 16px;
    font-family: HelveticaNeue-Medium, HelveticaNeue;
    font-weight: 500;
    line-height: 24px;
    color: $-color-black-80;
    padding-top: 8px;
  }
  .second-row {
    width: 100%;
  }
  .gkrs {
    font-size: 12px;
    font-family: HelveticaNeue;
    color: $-color-black-40;
    padding-bottom: 8px;
  }
  .detail {
    font-size: 12px;
    font-family: HelveticaNeue;
    color: $-color-black-40;
    word-break: break-all;
    padding-bottom: 14px;
  }
  .share {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 4px;
    padding-bottom: 12px;
    .twitter, .facebook, .copylink {
      .key {
        width: 32px;
        height: 32px;
        margin-bottom: 2px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .value {
        font-size: 12px;
        font-family: HelveticaNeue;
        color: #000000;
        text-align: center;
      }
    }
  }
}
</style>
