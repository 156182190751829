import { propsKeys } from '../config/props'
import { tablePropsKeys } from '../config/table-props'

/**
 * 外部传入属性处理, 可兼容el-table属性
 * 和yProTableProps冲突的时候, 以yProTableProps设计为准
 */
const formatTableProps = (props) => {
  const { tableProps } = props
  const newTableProps = Object.entries(props).reduce((pre, [k, v]) => {
    // 外部传入的属性在propsKeys范围内, 不作处理
    if (propsKeys.includes(k)) {
      return {
        ...pre
      }
    }

    // 外部传入的属性在tablePropsKeys范围内, 和 tableProps 合并, 且以原有的tableProps为准
    if (tablePropsKeys.includes(k)) {
      return {
        [k]: v,
        ...pre
      }
    }

    // 暂不处理其他属性
    return pre
  }, tableProps)
  return newTableProps
}

export default formatTableProps
