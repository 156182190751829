const apis = [
  {
    // 创建直播活动
    fn: 'activityCreate',
    method: 'POST',
    headers: {
      ['Content-Type']: 'application/x-www-form-urlencoded'
    },
    url: '/overseas/activity/create'
  },
  {
    // 编辑直播活动
    fn: 'activityEdit',
    method: 'POST',
    headers: {
      ['Content-Type']: 'application/x-www-form-urlencoded'
    },
    url: '/overseas/activity/edit'
  },
  {
    // 查询直播活动列表
    fn: 'activityQueryList',
    method: 'GET',
    url: '/overseas/activity/queryList',
    hideloading: true
  },
  {
    // 获取我的直播流
    fn: 'activityListMyLiveStream',
    method: 'GET',
    url: '/overseas/activity/list-my-live-stream'
  },
  {
    // 有效直播间信息列表
    fn: 'activityListValidActivity',
    method: 'GET',
    url: '/overseas/activity/listValidActivity'
  },
  {
    // 活动详情
    fn: 'activityInfo',
    method: 'GET',
    url: '/overseas/activity/info',
  },
  {
    // 删除直播间
    fn: 'activityDel',
    method: 'GET',
    url: '/overseas/activity/del'
  },
  {
    // 创建直播间装修模板
    fn: 'createTemplate',
    method: 'POST',
    url: '/overseas/activity-decoration-template',
    hideloading: true
  },
  {
    // 模板列表
    fn: 'getTemplateList',
    method: 'GET',
    url: '/overseas/activity-decoration-template/list',
    hideloading: true
  },
  {
    // release模板到直播间
    fn: 'applyDesigner',
    method: 'POST',
    url: '/overseas/activity-decoration-template/apply',
    hideloading: true
  },
  {
    // 获取直播间基本配置信息
    fn: 'getBasicConfig',
    method: 'GET',
    url: '/overseas/activity/getBasicConfig',
    hideloading: true
  },
  {
    // 获取直播间基础配置
    fn: 'setting',
    method: 'GET',
    url: '/overseas/activity/setting',
    hideloading: true
  },
  {
    // 设置聊天开关
    fn: 'switchChat',
    method: 'POST',
    url: '/overseas/activity/switchChat',
    hideloading: true
  },
  {
    // 开启/关闭直播间仅直播
    fn: 'switchLiveOnly',
    method: 'POST',
    url: '/overseas/activity/switchLiveOnly',
    hideloading: true
  },
  {
    // 开启/关闭直播间密码保护
    fn: 'switchPassword',
    method: 'POST',
    url: '/overseas/activity/switchPassword',
    hideloading: true
  },
  {
    // 新增允许直播间嵌入的网站
    fn: 'activityAllowEmbedAdd',
    method: 'POST',
    url: '/overseas/activity-allow-embed',
    hideloading: true,
    processOptions(options) {
      return {
        ...options,
        storeKey: `${options.storeKey}/add`
      }
    }
  },
  {
    // 删除允许嵌入的网站
    fn: 'activityAllowEmbedDelete',
    method: 'DELETE',
    url: '/overseas/activity-allow-embed',
    hideloading: true,
    processOptions(options) {
      return {
        ...options,
        storeKey: `/overseas/activity-allow-embed/delete`
      }
    }
  },
  {
    // 获取直播间嵌入的网站
    fn: 'activityAllowEmbedGet',
    method: 'GET',
    url: '/overseas/activity-allow-embed',
    hideloading: true,
    processOptions(options) {
      return {
        ...options,
        storeKey: `/overseas/activity-allow-embed/get`
      }
    }
  },
  {
    // 获取直播间基本配置信息
    fn: 'getTemplateDetail',
    method: 'GET',
    url: `/overseas/activity-decoration-template`,
    hideloading: true
  },
  {
    // 修改模板信息
    fn: 'updataTemplate',
    method: 'POST',
    url: `/overseas/activity-decoration-template/update`,
    hideloading: true
  },
  {
    // 获取直播间装修信息
    fn: 'getLiveDesignerInfo',
    method: 'GET',
    url: `/overseas/activity-decoration`,
    hideloading: true
  },
  {
    // 根据直播活动id获取doucment组件
    fn: 'getDocumentByActivityId',
    method: 'GET',
    url: `/overseas/activity-decoration-component/getDocumentByActivityId`,
    hideloading: true
  },
  {
    // 直播间文档 开关
    fn: 'switchDocumentStatus',
    method: 'POST',
    url: `/overseas/activity-decoration-component/switchDocumentStatus`,
    hideloading: true
  },
  {
    // 更新直播间预告视频
    fn: 'updataLiveSetting',
    method: 'POST',
    url: `/overseas/activity-decoration/setting`,
    hideloading: true
  },
  {
    // 更新直播间缩略图
    fn: 'updataLiveThumbnail',
    method: 'POST',
    url: `/overseas/activity-decoration/thumbnail`,
    hideloading: true
  },
  {
    // 更新直播间预告视频
    fn: 'updataLiveTrailer',
    method: 'POST',
    url: `/overseas/activity-decoration/trailer`,
    hideloading: true
  },
  {
    // 删除预告视频
    fn: 'deleteLiveTrailer',
    method: 'DELETE',
    url: `/overseas/activity-decoration/trailer`,
    hideloading: true
  },
  {
    // 开启/关闭展示关联视频
    fn: 'changeRelatedVideoShow',
    method: 'PUT',
    url: `/overseas/activity-decoration/related-video`,
    hideloading: true
  },
  {
    // 新增关联视频
    fn: 'addRelatedVideo',
    method: 'POST',
    url: `/overseas/activity-decoration/related-video`,
    hideloading: true
  },
  {
    // 删除关联视频
    fn: 'deleteRelatedVideo',
    method: 'DELETE',
    url: `/overseas/activity-decoration/related-video`,
    hideloading: true
  },
  {
    // 更新logo
    fn: 'updataLiveLogo',
    method: 'POST',
    url: `/overseas/activity-decoration/logo`,
    hideloading: true
  },
  {
    // 更新署名
    fn: 'updataByline',
    method: 'POST',
    url: `/overseas/activity-decoration/byline`,
    hideloading: true
  },
  {
    // 获取直播间装修配置
    fn: 'getLiveSetting',
    method: 'GET',
    url: `/overseas/activity-decoration/setting`,
    hideloading: true
  },
  {
    // 更新直播间字体颜色
    fn: 'updataLiveColor',
    method: 'POST',
    url: `/overseas/activity-decoration/font-color`,
    hideloading: true
  },
  {
    // 更新直播间文档
    fn: 'updataLiveDocument',
    method: 'POST',
    url: `/overseas/activity-decoration/document`,
    hideloading: true
  }
]

export const baseUrl = process.env.VUE_APP_OVERSEAS_URL

export default apis
