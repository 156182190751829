<template>
  <div class="media-upload-modal" v-show="$route.meta.showUploadBox">
    <div class="uploading-title-box">
      <div class="result-complete" v-if="count ===  uploadList.length"></div>
      <div class="result-loading" v-else></div>
      <div class="upload-num">Uploading {{ count }} of {{ uploadList.length }} resources</div>
      <div class="toggle-btn">
        <div
          class="close-modal"
          @click="closeModal"
          v-show="count ===  uploadList.length"
        ></div>
        <div @click="showAllFn" :class="{'toggle-close': showAll, 'toggle-open': !showAll}"></div>
      </div>
    </div>
    <div class="overflow-box" ref="uploadContent" :class="{shrink: !showAll}">
      <div class="upload-content-box">
        <div class="upload-item" v-for="(item, index) in uploadList" :key="index">
          <div class="file-cover-box">
            <img :src="item.coverUrl" v-if="item.coverUrl">
            <img src="https://yololiv-host.oss-us-west-1.aliyuncs.com/yololiv-overseas/image/2021-11-11/XmY6JZFW6EkmGkJRZT6hQxemFi3Mj3QE.png" v-else-if="item.materialType === 2">
            <img src="~@assets/images/media/icon-media-document.png" v-else>
          </div>
          <div class="file-msg">
            <div class="name c-overout">{{ item.name }}</div>
            <div class="status" v-show="item.uoloadtype === undefined && count === index">Uploading</div>
            <div class="status" v-show="item.uoloadtype === undefined && count !== index">Waiting</div>
            <div class="status" v-show="item.uoloadtype === false">Failed</div>
            <div class="status" v-show="item.uoloadtype === true">Complete</div>
          </div>
          <div class="file-status">
            <div
              class="icon-status"
              v-show="item.uoloadtype === undefined && count === index"
            >
              <el-progress
                type="circle"
                :percentage="progressList[index].percentage"
                :width="24"
                :show-text="false"
                :stroke-width="2"
                color="#FF0043"
              ></el-progress>
            </div>
            <div
              class="icon-status status-wating"
              v-show="item.uoloadtype === undefined && count !== index"
            ></div>
            <div
              class="icon-status status-close"
              v-show="item.uoloadtype === undefined && count !== index"
              @click="deleteItem(item, index)"
            ></div>
            <div
              class="icon-status status-fail"
              v-show="item.uoloadtype === false"
            ></div>
            <div
              class="icon-status status-success"
              v-show="item.uoloadtype === true"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'

export default {
  data() {
    return {
      s3upload: null,
      showAll: true,
      uploadList: [], // 上传的资源列表
      progressList: [], // 上传进度数组，独立放置
      deleteResource: false, // 删除时的状态
      pushResource: false, // 新增上传的文件状态控制
      pushResourceAgain: false, // 再次新增上传的文件状态控制
    }
  },
  computed: {
    ...mapState({
      count(state) {
        return state.uploadModal.count || 0
      },
    }),
  },
  watch: {
    '$store.state.uploadModal.uploadList': {
      handler(newV) {
        if (newV && newV.length !== this.uploadList.length) {
          this.pushResource = true;
          this.uploadList = cloneDeep(newV || []);
        }
      }
    },
    'uploadList': {
      handler(newV, oldV) {
        // 如果uploadList存在且长度大于0，那么需要对进度数组进行同步过滤并赋值
        if (newV && newV.length > 0) {
          newV.forEach(item => {
            if (JSON.stringify(this.progressList).indexOf(item.uid) === -1) {
              this.progressList.push({
                percentage: 0,
                uid: item.uid
              })
            }
          })
         this.$nextTick(() => {
            // 计算弹窗高度
            if (this.showAll) {
              this.$refs.uploadContent.style.height = 56 * newV.length + 16 + 'px';
            }
          })
        }
        // 当监听的uploadList长度没有改变时，不需要进行下一步的操作
        if (!this.deleteResource) {
          let timer = setTimeout(() => {
            clearTimeout(timer);
            timer = null;
            if (this.uploadList.length !== this.count) {
              let msg = 'Uploading not ended. Are you sure you want to leave?'
              window.onbeforeunload = () => {
                return msg
              }
            } else {
              window.onbeforeunload = null
            }
            // 只有用户首次（包括关闭上传窗口）时,或者全部上传完毕后重新选择文件需要进行调用上传动作，
            // 其他情况只需将上传信息放入数组中进行递归即可
            if ((oldV.length === 0 && this.pushResource) || this.pushResourceAgain) {
              this.uploadOneByOne(newV)
            }
          }, 150)
        }
      }
    },
    '$route': {
      handler(val) {
        if (val.name === 'User-Login') {
          this.deleteResource = true;
          this.pushResource = false;
          this.pushResourceAgain = false;
          this.$store.commit('uploadModalUpdate', {
            visible: false,
            count: 0,
            uploadList: [],
          })
        }
      }
    }
  },
  created() {
    this.pushResource = true;
    this.uploadList = cloneDeep(this.$store.state.uploadModal.uploadList || []);
    this.uploadList.forEach(item => {
      if (JSON.stringify(this.progressList).indexOf(item.uid) === -1) {
        this.progressList.push({
          percentage: 0,
          uid: item.uid
        })
      }
    })
  },
  methods: {
    uploadOneByOne(val) {
      if (val) {
        let file = val[this.count]
        file && this.getAwsToken(file)
      }
    },
    async getAwsToken(file) {
      const res = await this.$serve.media.materialToken({
        params: {
          materialType: file.materialType,
          time: new Date().getTime()
        }
      })
      if (res === this.$serve.FAIL) {
        return this.$serve.FAIL
      }
      this.awsUpload(file, res)
    },
    async awsUpload(puploadFile, awsTokens) {
      const {
        bucketName,
        accessKeyId,
        secretAccessKey,
        sessionToken,
        region,
        dir,
        fileId
      } = awsTokens
      const file = puploadFile.getNative();
      const splitArr = file.name.split('.')
      const fileKey = `${dir}.${splitArr[splitArr.length - 1]}`
      const s3 = new AWS.S3({
        apiVersion: '2006-03-01',
        accessKeyId,
        secretAccessKey,
        sessionToken,
        region,
        'Access-Control-Allow-Credentials': '*',
        ACL: 'public-read'
      })
      const params = {
        Bucket: bucketName,
        Key: fileKey,
        ContentType: file.type,
        Body: file,
        time: new Date().getTime()
      }
      this.s3upload = s3.upload(params, {
        partSize: 10 * 1024 * 1024,
        queueSize: 1
      })
      this.s3upload
        .on('httpUploadProgress', (progress) => {
          this.$set(this.progressList[this.count], 'percentage', Number(((progress.loaded / progress.total) * 100).toFixed(0)))
        })
        .send(async (err, data) => {
          if (err) {
            if (err.code !== 'RequestAbortedError') {
              this.$message.error(err.message || 'error')
            }
            this.uploadList[this.count].uoloadtype = false;
            this.deleteResource = false;
            this.pushResource = false;
            this.getNext()
            return
          }
          const res = await this.$serve['media-center'].saveMediaUrl({
            data: {
              materialType: puploadFile.materialType,
              materialList: [
                {
                  url: data.Location,
                  fileId: fileId,
                  fileName: file.name,
                }
              ],
              groupId: puploadFile.groupId,
            }
          })
          this.uploadList[this.count] = {
            ...this.uploadList[this.count],
            uoloadtype: true,
            coverUrl: res.materials[0].coverUrl
          }
          this.deleteResource = false;
          this.pushResource = false;
          this.getNext()
        })
    },
    getNext() {
      this.$store.commit('uploadModalUpdate', {
        count: this.count + 1,
        uploadList: this.uploadList,
      })
      this.uploadOneByOne(this.uploadList)
      if (this.count ===  this.uploadList.length) {
        this.pushResourceAgain = true;
        window.onbeforeunload = null
      } else {
        this.pushResourceAgain = false;
        let msg = 'Uploading not ended. Are you sure you want to leave?'
        window.onbeforeunload = () => {
          return msg
        }
      }
    },
    deleteItem(item, index) {
      this.$confirm("Are you sure to delete the Resource？", "Prompt", {
        confirmButtonText: "Done",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        let msg = '';
        item.uoloadtype === true && (msg = 'Sorry, the resource has been uploaded.')
        item.uoloadtype === false && (msg = 'Sorry, the resource has failed to upload.')
        index === this.count && (msg = 'Sorry, the resource is uploading.')
        if (msg) {
          this.$message.warning(msg)
        } else {
          this.uploadList.splice(index, 1);
          this.progressList.splice(index, 1);
          this.deleteResource = true;
          this.pushResource = false;
          this.$store.commit('uploadModalUpdate', {
            uploadList: this.uploadList,
          })
        }
      });
    },
    closeModal() {
      this.$emit('close')
      let timer = setTimeout(() =>{
        clearTimeout(timer);
        timer = null;
        this.deleteResource = true;
        this.pushResource = false;
        this.pushResourceAgain = false;
        this.$store.commit('uploadModalUpdate', {
          visible: false,
          count: 0,
          uploadList: [],
        })
      }, 2000)
    },
    showAllFn() {
      this.showAll = !this.showAll;
      let height = 56 * this.uploadList.length + 16 + 'px';
      this.$refs.uploadContent.style.height = this.showAll ? height : 0;
    }
  }
}
</script>

<style lang="scss" scoped>
.media-upload-modal {
  width: 450px;
  max-height: 288px;
  background: #FFFFFF;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  @keyframes roundAnimation {
    0% {
      transform: rotate(0);
    }
    25% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(270deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .uploading-title-box {
    height: 48px;
    box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    padding: 0 16px;
  }
  .result-loading {
    animation: roundAnimation 1s linear infinite;
    width: 24px;
    height: 24px;
    background: url(~@assets/images/media/icon-doing.png) no-repeat;
    background-size: 100%;
  }
  .result-complete {
    width: 24px;
    height: 24px;
    background: url(~@assets/images/media/icon-complete.png) no-repeat;
    background-size: 100%;
  }
  .upload-num {
    margin-left: 12px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
  }
  .toggle-btn {
    margin-left: auto;
    display: flex;
    .toggle-open {
      width: 24px;
      height: 24px;
      background: url(~@assets/images/media/icon-open.png) no-repeat;
      background-size: 100%;
      cursor: pointer;
    }
    .toggle-close {
      width: 24px;
      height: 24px;
      background: url(~@assets/images/media/icon-close.png) no-repeat;
      background-size: 100%;
      cursor: pointer;
    }
    .close-modal {
      width: 24px;
      height: 24px;
      background: url(~@assets/images/media/icon-delete-media.png) no-repeat;
      background-size: 100%;
      cursor: pointer;
      margin-right: 12px;
    }
  }
  .overflow-box {
    max-height: 224px;
    overflow-y: auto;
    opacity: 1;
    transition: all 0.5s ease 0s;

    &::-webkit-scrollbar {
      width: 6px;
      background: transparent;
    }
    &::-webkit-scrollbar-track {
      border-radius: 0;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(102, 102, 102, 1);
      transition: all 0.2s;
      border-radius: 2px;
    }
  }
  .shrink {
    opacity: 0;
  }
  .upload-content-box {
    padding-bottom: 16px;
    margin: 0 16px;
    .upload-item {
      display: flex;
      align-items: center;
      height: 56px;
      .file-cover-box {
        width: 70px;
        height: 40px;
        margin-right: 12px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .file-msg {
        width: 278px;
        word-break: keep-all;
        .name {
          height: 16px;
          font-size: 14px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.8);
          line-height: 16px;
          margin-bottom: 2px;
        }
        .status {
          height: 14px;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.6);
          line-height: 14px;
        }
      }
      .file-status {
        margin-left: auto;
        width: 24px;
        height: 24px;
        &:hover {
          .status-wating {
            display: none;
          }
          .status-close {
            display: block;
          }
        }
        .icon-status {
          width: 24px;
          height: 24px;
        }
        /*.status-uploading {
          animation: roundAnimation 2s linear infinite;
          background: url(~@assets/images/media/icon-loading.png) no-repeat;
          background-size: 100%;
        }*/
        .status-wating {
          background: url(~@assets/images/media/icon-wating.png) no-repeat;
          background-size: 100%;
          display: block;
        }
        .status-close {
          background: url(~@assets/images/media/icon-delete-media.png) no-repeat;
          background-size: 100%;
          display: none;
          cursor: pointer;
        }
        .status-fail {
          background: url(~@assets/images/media/icon-error.png) no-repeat;
          background-size: 100%;
        }
        .status-success {
          background: url(~@assets/images/media/icon-complete.png) no-repeat;
          background-size: 100%;
        }
      }
    }
  }
}
</style>
