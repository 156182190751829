<template>
  <div
    class="page-yolo-cast-detail"
    v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0.1)"
  >
    <!-- head-area -->
    <div class="page-yolo-cast-detail__head">
      <head-area
        class="page-yolo-cast-detail__head-area"
        :consoleInfo="consoleInfo"
        :active="activeComponent"
        @toggle="toggle"
      ></head-area>
    </div>
    <!-- /head-area -->

    <div class="page-yolo-cast-detail__main">
      <div class="page-yolo-cast-detail__content">
        <component
          :is="activeComponent"
          :consoleInfo="consoleInfo"
          :liveInfo="liveInfo"
        ></component>
      </div>
    </div>

    <y-tooltip effect="dark" placement="left">
      <div class="page-yolo-cast-detail__help-content" slot="content">
        Whether you’re looking for product information,
        <br />
        purchase consultation or assistance for live streaming,
        <br />
        please contact us at
        <span style="color: #ff4d7c">contact@yololiv.com</span>
        <br />
        We’ll get back to you shortly. Thanks!
      </div>
      <span class="page-yolo-cast-detail__help">
        <i class="ui-icon-help-s"></i>
      </span>
    </y-tooltip>
  </div>
</template>

<script>
import HeadArea from './_head-area'
import Monitor from './monitor'
import Setup from './setup'
import Resports from './resports'

const mqttType = 'live-status'

export default {
  components: {
    HeadArea,
    Monitor,
    Setup,
    Resports
  },
  data() {
    const queryTab = ['monitor', 'setup'].includes(this.$route.query.tab)
      ? this.$route.query.tab
      : 'setup'
    return {
      activityId: this.$route.params.id,
      activeComponent: queryTab,
      /**
       * activityStatus: 直播间状态0:未开始,1:已删除,2:结束,3:预约,4:直播中,5:暂停
       * livestreamStatus: 直播流状态0:未开始,1:就绪,2:直播中,3:失败,4:连接中,5:结束
       */
      liveInfo: null
    }
  },
  beforeDestroy() {
    this.unSubscribeLiveStatus()
    this.$store.commit('liveDetail/consoleInfoUpdate', null)
  },
  computed: {
    mqttTopic() {
      return 'live/' + this.activityId
    },
    loading() {
      return this.$store.state.loading['/overseas/console/info']
    },
    /**
     * activityStatus: 直播间状态0:未开始,1:已删除,2:结束,3:预约,4:直播中,5:暂停
     * livestreamStatus: 直播流状态0:未开始,1:就绪,2:直播中,3:失败,4:连接中,5:结束
     */
    consoleInfo() {
      return this.$store.state.liveDetail.consoleInfo
    }
  },
  watch: {
    activityId: {
      handler(val) {
        this.$store.dispatch('liveDetail/fetchConsoleInfo', val)
      },
      immediate: true
    },
    consoleInfo(val) {
      if (val) {
        this.subscribeLiveStatus()
      }
    }
  },
  methods: {
    toggle(activeComponent) {
      this.activeComponent = activeComponent
    },
    subscribeLiveStatus() {
      this.$mqtt.on(this.mqttTopic, mqttType, this.subscribeLiveStatusCallback)
    },
    async subscribeLiveStatusCallback(data) {
      const liveinfo = JSON.parse(data).data
      // activityStatus: 直播间状态0:未开始,1:已删除,2:结束,3:预约,4:直播中,5:暂停
      if (liveinfo.activityStatus === 2) {
        location.reload()
      }
      this.liveInfo = JSON.parse(data).data
    },
    unSubscribeLiveStatus() {
      this.$mqtt.off(this.mqttTopic, mqttType, this.subscribeLiveStatusCallback)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-yolo-cast-detail {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: #1e1f2b;
}
.page-yolo-cast-detail__head {
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.15);
  position: relative;
  z-index: 1;
}
.page-yolo-cast-detail__main {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.page-yolo-cast-detail__tabs {
  ::v-deep {
    .el-tabs__header {
      margin: 0;
    }
    .el-tabs__nav-wrap {
      padding: 0 24px;
    }
    .el-tabs__nav-wrap::after {
      height: 1px;
      background-color: rgba(255, 255, 255, 0.15);
    }
    .el-tabs__item {
      padding: 0 16px !important;
      font-size: 18px;
      height: 56px;
      line-height: 56px;
      color: rgba(255, 255, 255, 0.4);
      &.is-active {
        color: #ffffff;
      }
    }
  }
}
.page-yolo-cast-detail__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  & > div {
    flex: 1;
  }
}
.page-yolo-cast-detail__help {
  position: fixed;
  right: 48px;
  bottom: 48px;
  width: 48px;
  height: 48px;
  background: #ff4d7c;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
}
.page-yolo-cast-detail__help-content {
  font-size: 13px;
  line-height: 16px;
}
</style>
