const apis = [
  {
    // 请求添加设备
    fn: 'addDevice',
    method: 'POST',
    headers: {
      ['Content-Type']: 'application/x-www-form-urlencoded'
    },
    url: '/overseas/device-manage/addDevice'
  },
  {
    // 绑定设备
    fn: 'bind',
    method: 'POST',
    headers: {
      ['Content-Type']: 'application/x-www-form-urlencoded'
    },
    url: '/overseas/device-manage/bind'
  },
  {
    // 获取绑定设备列表
    fn: 'boundList',
    method: 'GET',
    url: '/overseas/device-manage/boundList'
  },
  {
    // 获取设备直播活动信息列表
    fn: 'streamRecordList',
    method: 'GET',
    url: '/overseas/device-manage/streamRecordList'
  },
  {
    // 设备解绑
    fn: 'unbind',
    method: 'GET',
    url: '/overseas/device-manage/unbind'
  },
  {
    // 获取设备绑定的账号列表
    fn: 'bind',
    method: 'GET',
    url: '/overseas/device-manage/boundAccountList'
  }
]

export const baseUrl = process.env.VUE_APP_OVERSEAS_URL

export default apis
