const apis = [
  {
    // 文档转码接口
    fn: 'saveTransferFIle',
    method: 'POST',
    url: '/overseas/transfer/transferCode',
    hideloading: true
  },
  {
    // 获取文档内容列表
    fn: 'getFile',
    method: 'GET',
    url: '/overseas/activity-document-component/getDocumentImageUrl',
    hideloading: true
  },
  {
    // 上传完毕后通知C端
    fn: 'noticeC',
    method: 'GET',
    url: '/overseas/activity-document-component/changeDocument',
    hideloading: true
  }
]

export const baseUrl = process.env.VUE_APP_OVERSEAS_URL

export default apis
