/**
 * Mqtt
 * 核心依赖: [Paho.MQTT](https://github.com/eclipse/paho.mqtt.javascript)
 * 同步连接推荐使用 topic + type 组合, 如:
 * mqttInstance.topic('testTopic/xxx')
 * mqttInstance.type('testTopic/xxx', 'testType', function(data) { console.log(data) })
 * 异步连接推荐使用 on, 如:
 * mqttInstance.on('testTopic/xxx', 'testType', function(data) { console.log(data) })
 */

import { uid } from 'uid'

const DEFAULTTYPE = '_default'
const TIMEOUT = 30

class Mqtt {
  constructor({ userName, password, host, port } = {}) {
    this.userName = userName
    this.password = password
    this.host = host
    this.port = port
    this.socket = null
    this.es = {} // events subscription
    this.init()
  }
  /**
   * MQTT init
   */
  init() {
    try {
      this.connect()
    } catch (error) {
      console.log(error)
    }
  }
  /**
   * MQTT connect
   */
  connect() {
    this.subscribed = {} // subscribed collection
    this.socket = new window.Paho.MQTT.Client(
      this.host,
      this.port,
      this.getClientId()
    )
    this.socket.onConnectionLost = this.onConnectionLost.bind(this)
    this.socket.onConnected = this.onConnected.bind(this)
    this.socket.onMessageArrived = this.onMessageArrived.bind(this)
    this.socket.connect({
      useSSL: true,
      timeout: TIMEOUT,
      reconnect: false,
      onSuccess: this.onSuccess.bind(this),
      onFailure: this.onFailure.bind(this),
      keepAliveInterval: 30
    })
  }
  /**
   * called when a connection has been lost
   */
  onConnectionLost() {
    console.log('--- mqtt lost ---')
    this.success = false
    this.reconnect()
  }
  onConnected() {
    console.log('--- mqtt connected ---')
  }
  /**
   * called when a message has arrived
   */
  onMessageArrived(msg) {
    const { topic, payloadString } = msg
    try {
      const payload = JSON.parse(payloadString)
      const { msg: data, type } = payload
      const topicListeners = this.es[topic]
      if (!topicListeners) {
        return
      }
      const typeListeners = topicListeners[type] || []
      const defaultListeners = topicListeners[DEFAULTTYPE] || []
      this.emit(typeListeners, data, payload, msg)
      this.emit(defaultListeners, payload, msg)
    } catch (e) {
      console.log(e)
    }
  }
  onSuccess() {
    this.success = true
    console.log('--- mqtt success ---')
  }
  onFailure() {
    console.log('--- mqtt failure ---')
  }
  send(topic, payload) {
    this.socket.publish(topic, payload)
  }
  /**
   * call emit type of topic
   */
  emit(listeners, ...rest) {
    let l = listeners.length
    for (let i = 0; i < l; i++) {
      const { cb, once } = listeners[i]

      cb.call(this, ...rest)

      if (once) {
        listeners.splice(i, 1)
        i--
        l--
      }
    }
  }
  /**
   * subscribe topic only one time
   * @param {string} topic
   * @param {type} type
   * @param {function} cb
   */
  once(topic, type, cb) {
    this.on(topic, type, cb, true)
  }
  /**
   * only subscribe topic
   */
  topic(topic) {
    this.on(topic)
  }
  /**
   * type callback of appoint topic
   * need topic first
   */
  type(topic, type, cb, once) {
    // need topic first
    if (!this.subscribed[topic]) {
      console.error('must topic before call type')
      return
    }
    this.on(topic, type, cb, once)
  }
  /**
   * subscribe topic
   * @param {string} topic
   * @param {type} type
   * @param {function} cb
   * @param {boolean} once
   */
  on(topic, type, cb, once = false) {
    if (!topic) {
      throw new Error('topic can not be empty')
    }
    if (typeof topic !== 'string') {
      throw new Error('topic must be string')
    }
    // topic is subscribed by socket now
    this.subscribe(topic)
    if (!type) {
      return
    }
    // params compatibility
    if (typeof type === 'function') {
      cb = type
      type = DEFAULTTYPE
    }
    if (!this.es[topic]) {
      this.es[topic] = {}
    }
    if (!this.es[topic][type]) {
      this.es[topic][type] = []
    }
    this.es[topic][type].push({
      cb,
      once
    })
  }
  /**
   * unsubscribe topic
   * @param {string} topic
   * @param {type} type
   * @param {function} cb
   */
  off(topic, type, cb) {
    // if topic is undefined, unsubscribe all topic
    if (topic === undefined) {
      this.unsubscribeAll()
      this.es = {}
      return
    }

    // if topic error, do not deal anything
    if (!this.es[topic]) {
      return
    }

    // if type is undefined, unsubscribe topic
    if (type === undefined) {
      // empty topic listeners
      this.unsubscribe(topic)
      return
    }

    // params compatibility
    if (typeof type === 'function') {
      cb = type
      type = DEFAULTTYPE
    }

    // if cb is undefined, empty type callback of current topic
    if (cb === undefined) {
      delete this.es[topic][type]
      return
    }

    // delete type callback of current topic
    const listeners = this.es[topic][type] || []
    let l = listeners.length

    for (let i = 0; i < l; i++) {
      if (listeners[i].cb === cb) {
        listeners.splice(i, 1)
        i--
        l--
      }
    }
    // if all type callback is be emptyed, so empty type callback of current topic
    if (!listeners.length) {
      delete this.es[topic][type]
    }

    // when delete all topic types, then unsubscribe current topic
    if (!Object.keys(this.es[topic]).length) {
      this.unsubscribe(topic)
    }
  }
  /**
   * subscribe topic
   */
  subscribe(topic) {
    if (!this.subscribed[topic]) {
      this.subscribed[topic] = {
        init: false,
        connected: false
      }
    }
    this.subscribed[topic].init = true

    if (!this.success) {
      this.timer = setTimeout(() => {
        this.subscribe(topic)
      })
      return
    }
    if (this.timer) clearTimeout(this.timer)
    if (!this.subscribed[topic].connected) {
      this.socket.subscribe(topic)
      this.subscribed[topic].connected = true
    }
  }
  /**
   * unsubscribe topic
   */
  unsubscribe(topic) {
    this.socket.unsubscribe(topic)
    delete this.es[topic]
    delete this.subscribed[topic]
  }
  /**
   * subscribe all topic
   */
  subscribeAll() {
    const topics = Object.keys(this.es)
    topics.forEach((topic) => {
      this.subscribe(topic)
    })
  }
  /**
   * unsubscribe all topic
   */
  unsubscribeAll() {
    const topics = Object.keys(this.es)
    topics.forEach((topic) => {
      this.unsubscribe(topic)
    })
  }
  /**
   * socket disconnect
   */
  disconnect() {
    this.socket.disconnect()
  }
  /**
   * socket reconnect
   */
  reconnect() {
    if (this.timer) clearTimeout(this.timer)
    this.connect()
    this.subscribeAll()
  }
  /**
   * get MQTT ClientId
   */
  getClientId() {
    return uid()
  }
}

export default Mqtt
