import { Message } from 'element-ui'
import { localStore } from '@/utils/store'
import router from '@/router'
import serve from '@/serve'
import constStorage from '@constant/storage'

const common = {
  state: {
    currentRoute: null, // 当前路由
    menuCollapse: false, // 菜单是否折叠
    exception: {}, // 业务接口报错或者不合法信息
    loading: {}, // 请求状态集合
    userInfo: undefined,
    screenData: {},
    createRel: {
      // 全局event
      type: '',
      show: false,
      action: '',
      done: false,
      payload: null
    },
    uploadModal: {
      visible: false
    }
  },
  getters: {
    uploadModal(state) {
      return state.uploadModal
    }
  },
  mutations: {
    currentRouteUpdate(state, payload) {
      state.currentRoute = payload
    },
    menuCollapseUpdate(state, payload) {
      state.menuCollapse = payload
    },
    exceptionUpdate(state, payload) {
      state.exception = {
        ...state.exception,
        ...payload
      }
    },
    loadingUpdate(state, payload) {
      state.loading = {
        ...state.loading,
        ...payload
      }
    },
    userInfoUpdate(state, payload) {
      state.userInfo = payload
    },
    clearState(state) {
      Object.assign(state, {
        menuCollapse: false,
        exception: {},
        loading: {},
        userInfo: undefined
      })
    },
    screenDataUpdate(state, payload) {
      state.screenData = payload
    },
    createRelUpdate(state, payload) {
      state.createRel = payload
    },
    createRelReset(state) {
      state.createRel = {
        type: '',
        show: false,
        action: '',
        done: false,
        payload: null
      }
    },
    uploadModalUpdate(state, payload) {
      state.uploadModal = {
        ...state.uploadModal,
        ...payload
      }
    }
  },
  actions: {
    async fetchUserInfo({ commit }) {
      const res = await serve.user.queryInfo()
      if (res === serve.FAIL) {
        return false
      }
      commit('userInfoUpdate', res.userInfo)
      return true
    },
    async login(_, data) {
      const res = await serve.user.login({
        data
      })
      if (res === serve.FAIL) {
        return
      }
      localStore.set(constStorage.LOCAL.TOKENKEY, res.tokenKey)
      localStore.set(constStorage.LOCAL.TOKENVAL, res.tokenValue)
      router.push('/')
      Message.success('Login success')
    },
    async logout({ commit }, args) {
      const res = await serve.user.logout()
      if (res === serve.FAIL) {
        return
      }
      const locale = localStore.get('locale')
      localStore.clearAll()
      if (locale) {
        localStore.set('locale', locale)
      }
      commit('clearState')
      router.push({
        path: '/user',
        query: { forgot: args && args.forgot ? args.forgot : undefined }
      })
      Message.success('Logout success')
    }
  }
}

export default common
