<template>
  <el-col class="y-col" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </el-col>
</template>

<script>
import { Col } from 'element-ui'

export default {
  name: 'y-col',
  _name: 'Col 栅格布局列',
  $depends: [Col],
  components: {
    [Col.name]: Col
  }
}
</script>
