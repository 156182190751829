<template>
  <el-button class="y-button" v-bind="$attrs" v-on="$listeners">
    <slot></slot>
  </el-button>
</template>

<script>
import { Button } from 'element-ui'

export default {
  name: 'y-button',
  _name: 'Button 按钮',
  $depends: [Button],
  components: {
    [Button.name]: Button
  }
}
</script>

<style lang="scss" scoped>
.el-button {
  border-radius: 2px !important;
}
</style>
