import { install as installBase } from './base'
import { install as installPro } from './pro'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

locale.use(lang)

const install = function (Vue, opts) {
  installBase(Vue, opts)
  installPro(Vue, opts)
}

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}

export default {
  version: '1.0.0',
  install
}
